import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { map, take } from 'rxjs/operators';
import { MODAL } from 'src/app/models/constants.model';
import { TripStatus } from 'src/app/models/logistic_model';
import { CustomerOrder, Order, OrderStatus, Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';
import { ModalService } from 'src/app/services/modal.service';
import { OrderService } from 'src/app/services/order.service';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import { ModalOrderassignComponent } from '../modal-orderassign/modal-orderassign.component';
import { ModalSelectDriverComponent } from '../modal-select-driver/modal-select-driver.component';

@Component({
  selector: 'oil-modal-tripdetails',
  templateUrl: './modal-tripdetails.component.html',
  styleUrls: ['./modal-tripdetails.component.scss']
})
export class ModalTripdetailsComponent implements OnInit {

  public tripId;
  public trip: Trip;

  public highlightPoint: number[];
  public selectedStage: number = null;

  public isConfirmed: boolean;

  public dataSource;
  public resultsLength = 0;

  public fromLogistic: boolean;


  public displayedColumns: string[] = [
    'code',
    'name',
    'destination',
    'product',
    'quantity',
    'time',
    'status',
    'actions',
  ];

  constructor(
    protected ref: NbDialogRef<ModalTripdetailsComponent>,
    private logisticService: LogisticService,
    private dialogService: NbDialogService,
    private orderService: OrderService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    // if (!this.tripId) {
    //   console.log("NO TRIP ID!");
    // } else {
    // this.orderService.getTrip(this.tripId).subscribe(t => {
    //   this.trip = t;
    // })

    this.refresh();

    // }
  }

  refresh() {
    let func = (tripId, isConfirmed) => { return this.logisticService.getTrip(tripId, isConfirmed) }
    if (!this.fromLogistic) {
      func = (tripId, isConfirmed) => { return this.orderService.getTrip(tripId, isConfirmed) };
    }
    func(this.tripId, this.isConfirmed).subscribe(t => {
      this.trip = t;
      // this.trip.trips = null;
      // setTimeout(() => {
      //   this.trip.trips = Object.assign({}, t.trips);
      // }, 1);
      // console.log('----: ModalTripdetailsComponent -> refresh -> t', t);
      this.dataSource = new MatTableDataSource<CustomerOrder>(t.orders);
    }, err => {
      this.ref.close();
    })
  }

  moveOrder(order: Order) {
    // console.log('-------: ModalTripordersComponent -> moveOrder -> order', order);
    // this.modalService.assignOrder(order, this.tripId).subscribe(() => {
    //   this.refresh();
    // });

    let context: any = {
      order: order,
      provenienceTripId: this.tripId
    }
    let modal = this.dialogService.open(ModalOrderassignComponent, { context: context });
    // console.log('-------: ModalTripdetailsComponent -> moveOrder -> context', context);
    modal.onClose.subscribe(x => {
      this.refresh();
    })

  }

  removeOrder(order: Order) {
    console.log('-------: ModalTripordersComponent -> removeOrder -> order', order);
    this.logisticService.removeOrderFromTrip(this.tripId, order.orderId).subscribe(x => {
      this.refresh();
    });
  }

  // addOrder() {
  // }

  cancel() {
    this.ref.close();
  }

  productList(products: string[]) {
    if (products && products.length) {
      const uniq = [...new Set(products)];
      return uniq.join(' | ');
    } else {
      return '-';
    }
  }

  public enterStage(stage: CustomerOrder = null) {
    if (stage) {
      this.highlightPoint = stage.location.coordinates;
    }
    else {
      this.highlightPoint = null;
    }

  }

  public selectStage(id) {
    this.selectedStage = id - 1;
  }

  public getKm(trip) {
    if (trip?.trips?.trips?.length)
      return Math.round(trip?.trips?.trips[0].distance / 1000);
  }

  public isToday() {
    if (this.trip && this.trip.deliveryDate) {
      return moment().isSame(moment(this.trip.deliveryDate), 'day');
    }
    return false;

  }

  confirmOrder(order: Order, delivered: boolean) {
    this.logisticService.setOrderDelivered(order, delivered).subscribe(() => {
      this.refresh();
    })
  }

  transitTrip() {
    this.logisticService.setTripStatus(this.trip, TripStatus.in_transit).subscribe(() => {
      this.refresh();
    })
  }

  isAllOrdersDelivered(): boolean {
    return !this.trip.orders.find(x => x.status != OrderStatus.delivered)
  }

  progressBar(val) {
    return val > 0 ? Math.max(20, val * 100) : 0;
  }

  deliveryTrip() {

    if (this.isAllOrdersDelivered())
      this.logisticService.setTripStatus(this.trip, TripStatus.delivered).subscribe(() => {
        this.refresh();
      })
  }

  editDriver() {
    let context: any = {};
    context = {
      trip: this.trip,
      excludeDriver: this.trip.driverId
    };
    let modal = this.dialogService.open(ModalSelectDriverComponent, { context: context });
    modal.onClose.subscribe(res => {
      if (res) {
        console.log('---- ~ file: modal-tripdetails.component.ts ~ line 184 ~ ModalTripdetailsComponent ~ editDriver ~ res', res);
        this.logisticService.assignDriver(res, this.trip.routeId).subscribe(() => {
          this.refresh();
        })
      }
    });
  }

  openImg(imageUrl, photodialog) {
    this.dialogService.open(photodialog);
    this.getImage(imageUrl);
  }

  public img;

  async getImage(data) {
    this.logisticService.getOrderImage(data).subscribe(attach => {
      this.img = `data:image/png;base64,${attach.attachment}`;
    });
  }

  deleteTrip() {
    this.translate.get(['ORDERS.TRIPDETAILS.DELETECONFIRMTITLE', 'ORDERS.TRIPDETAILS.DELETECONFIRMMESSAGE']).subscribe(t => {
      this.confirm(t['ORDERS.TRIPDETAILS.DELETECONFIRMMESSAGE'], t['ORDERS.TRIPDETAILS.DELETECONFIRMTITLE']).pipe(take(1)).subscribe(res => {
        if (res == MODAL.confirmOk) {
          this.logisticService.deleteTrip(this.tripId).subscribe(()=>{
            this.cancel();
          })
          
        }
      })
    })
  }

  confirm(message: string, title: string) {
    let context: any = {};
    context = { message: message, title: title }
    let modal = this.dialogService.open(ModalConfirmComponent, { context: context });
    return modal.onClose;
  }

  getDepotsList(trip: Trip){
    let ret = [trip?.fuelDepot];
    if(trip?.fuelDepot.id != trip?.endfuelDepot.id){
      ret.push(trip?.endfuelDepot);
    }
    return ret;
  }
}
