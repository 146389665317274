import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service';
import { ModalNewEventComponent } from '../components/modal-new-event/modal-new-event.component';
import { NbDialogService } from '@nebular/theme';
import { mergeMap, flatMap } from 'rxjs/operators';
import { Moment } from 'moment';
import * as moment from 'moment';
import { SettingsService } from './settings.service';
import { SettingsComponent } from '../components/settings/settings.component';


@Injectable({
    providedIn: 'root'
})
export class CalendarService {


    constructor(
        private customerService: CustomerService,
        private dialogService: NbDialogService
    ) {


    }

    editEvent(eventId: number, modifyCustomerPoint = true) {
        return this.customerService.getEvent(eventId).pipe(flatMap(event => {
            console.log('-------: CalendarService -> editEvent -> event', event);
            let context: any = {
                customerEvent: event,
                modifyCustomer: modifyCustomerPoint,
                modifyPoint: modifyCustomerPoint
            }
            let modal = this.dialogService.open(ModalNewEventComponent, { context: context });
            return modal.onClose;
        }));
    }

    createEvent(dateMoment = null) {
        let context: any = {
            customerEvent: {
                date: dateMoment
            }
        }
        let modal = this.dialogService.open(ModalNewEventComponent, { context: context });
        return modal.onClose;
    }

    createEventForPoint(customerId, pointId) {
        let context: any = {
            customerEvent: {
                customerId: customerId,
                pointId: pointId,
            },
            modifyCustomer: false,
            modifyPoint: false
        }
        let modal = this.dialogService.open(ModalNewEventComponent, { context: context });
        return modal.onClose;

    }

    createEventForCustomer(customerId) {
        let context: any = {
            customerEvent: {
                customerId: customerId,
            },
            modifyCustomer: false
        }
        let modal = this.dialogService.open(ModalNewEventComponent, { context: context });
        return modal.onClose;

    }

    static nextWorkingDay(day: Moment = moment()): Moment {
        const weekLength = SettingsService.weekLength();
        let dayToAdd = 1;
        if (day.weekday() >= weekLength - 1) {
            dayToAdd = 7 - day.weekday();
        }
        let candidate = day.add(dayToAdd, 'day');
        if (SettingsService.isHolyday(candidate)) {
            return this.nextWorkingDay(candidate);
        }
        return candidate;
    }
}