<nb-card>
    <nb-card-header *ngIf="!thisPromotion.promotionId">{{'ORDERS.PROMOTIONMODAL.ADDPROMOTION' | translate}}
    </nb-card-header>
    <nb-card-header *ngIf="thisPromotion.promotionId">{{'ORDERS.PROMOTIONMODAL.EDITPROMOTION' | translate}}
    </nb-card-header>
    <nb-card-body>
        <div>
            <span class="label">
                {{'ORDERS.PROMOTIONMODAL.CUSTOMER' | translate}}
                <span class="sublabel"> {{'ORDERS.PROMOTIONMODAL.CUSTOMERATTR' | translate}}</span>
            </span>
            <!-- <input fullWidth status="basic" type="text" nbInput [(ngModel)]="customer"> -->
            <!-- <ng-select [items]="customers" bindLabel="name" bindValue="id" autofocus [(ngModel)]="thisPromotion.clientId"
                [disabled]="!modifyCustomer" (change)="updateCustomer($event)"> </ng-select> -->

            <input fullWidth nbInput type="text" [hidden]="modifyCustomer" [disabled]="!modifyCustomer"
                [ngModel]="clientInput?.name" />

            <!-- <input fullWidth #autoInput nbInput type="text" *ngIf="modifyCustomer"
                (ngModelChange)="onChangeClientInput($event)" [nbAutocomplete]="auto" [ngModel]="clientInput" />

            <nb-autocomplete #auto (selectedChange)="updateCustomer($event)" [handleDisplayFn]="viewHandle">
                <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    {{ option?.name }}
                </nb-option>
            </nb-autocomplete> -->

            <input fullWidth #autoInput nbInput type="text" *ngIf="modifyCustomer"
                (ngModelChange)="onChangeClientInput($event)" [matAutocomplete]="auto" [ngModel]="clientInput" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="viewHandle">
                <mat-option *ngIf="isLoadingSuggestion" class="is-loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    <span>{{ option?.name }}</span>
                </mat-option>
            </mat-autocomplete>
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.DISTRIBUTIONPOINT' | translate}}</span>
            <ng-select [items]="points" bindLabel="label" bindValue="id" autofocus
                [(ngModel)]="thisPromotion.deliveryId" [disabled]="!modifyPoint || !points || points.length === 0"
                (change)="updatePoint($event)"> </ng-select>
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.PRODUCT' | translate}}</span>
            <ng-select [items]="prices" [disabled]="!prices || prices.length === 0" bindLabel="product_name"
                (change)="updatedPrice($event)" bindValue="product_id" autofocus [(ngModel)]="thisPromotion.productId">
            </ng-select>
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.QUANTITY' | translate}} <span *ngIf="maxCapacity" class="sublabel"> {{'ORDERS.PROMOTIONMODAL.MAXQUANTITY' | translate}} {{maxCapacity | number | liter}}</span> </span>
            <input fullWidth status="basic" type="number" min="0" [max]="maxCapacity" nbInput [(ngModel)]="thisPromotion.maxQuantity">
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.PRICE' | translate}} 
                <span class="price-other" *ngIf="oldPrice">{{'ORDERS.PROMOTIONMODAL.ACTUALPRICE' | translate}} {{oldPrice | currency:'€': 'symbol' : '1.3-3' }}</span>
                <span class="price-other" *ngIf="minPrice">{{'ORDERS.PROMOTIONMODAL.MINPRICE' | translate}} {{minPrice | currency:'€': 'symbol' : '1.3-3' }}</span>
            </span>
            <input fullWidth status="basic" type="number" [min]="minPrice" step="0.001" nbInput [(ngModel)]="thisPromotion.price">
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.CREDIT' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput
                [value]="thisPromotion.remainingCredit | currency:'€'">
        </div>
        <div>
            <span class="label">{{'ORDERS.PROMOTIONMODAL.DATE' | translate}}</span>
            <input fullWidth status="basic" nbInput [nbDatepicker]="datepicker" disabled="linkedRoute"
                [(ngModel)]="dateMoment">
            <nb-datepicker #datepicker format="DD/MM/YYYY"></nb-datepicker>
        </div>
    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            *ngIf="!thisPromotion.promotionId" (click)="save()">{{'ORDERS.PROMOTIONMODAL.INSERTBTN' |
            translate}}</button>
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()" *ngIf="thisPromotion.promotionId"
            (click)="save()">{{'ORDERS.PROMOTIONMODAL.SAVEBTN' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'ORDERS.PROMOTIONMODAL.CANCELBTN' |
            translate}}</button>
    </nb-card-footer>
</nb-card>