<nb-card size="big">

    <nb-card-body>

        <div class="company-data">
            <div class="company-nameaddress">
                <div class="company-name">
                    {{customer?.name}}
                </div>
                <div class="company-address">
                    {{customer?.address}}
                    {{customer?.vatCode}}
                    {{customer?.province}}
                </div>
            </div>
            <div class="company-credit">
                <div class="company-credit-label">
                    {{'CLIENT.ORDERS.CREDITLABEL' | translate}}
                </div>
                <div class="company-credit-value">
                    {{customer?.remainingCredit | currency:'€'}}
                </div>
            </div>
        </div>



        <ng-container *ngIf="todayResultsLength == 0">
            {{'CLIENT.ORDERS.NODATATODAY' | translate}}
        </ng-container>


        <table mat-table [dataSource]="todayDataSource" #sortToday="matSort" matSort #tableToday
            [hidden]="todayResultsLength<1">


            <!-- Data -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLETODAY.DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.deliveryDate | amDateFormat:'DD/MM/YYYY' }}
                </td>
            </ng-container>

            <!-- Numero -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLETODAY.NUMBER' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.orderNumber}}</td>
            </ng-container>

            <!-- Deposito  -->
            <ng-container matColumnDef="deposit">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLETODAY.DEPOSIT' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit">
                    <div class="deposit-address">
                        {{element.deliveryName}}
                        {{element.deliveryAddress}},{{element.deliveryCity}}
                    </div>
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLETODAY.PRODUCT' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product}}</td>
            </ng-container>

            <!-- Quantità -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLETODAY.QUANTITY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
            </ng-container>

            <!-- Valore -->
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLETODAY.VALUE' | translate}}
                </th>
                <td mat-cell class="promotional" *matCellDef="let element">
                    {{element.price | currency:'€': 'symbol' : '1.3-3'}}
                    ({{element.price / element.quantity | currency:'€': 'symbol' : '1.3-3'}})
                    <img *ngIf="element.promotionalPrice || element.suggestedRoute" class="promo-ico"
                        src="/assets/images/gift.png">
                </td>
            </ng-container>


            <!-- Stato -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLETODAY.STATUS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="status  {{element.status}}">
                    {{'ORDERSTATUS.'+toUpperCase(element.status)|translate}}
                </td>
            </ng-container>

            <!-- Orario consegna -->
            <ng-container matColumnDef="deliveryTime">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLETODAY.DELIVERYTIME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="deliveryTime">
                    {{element.estimatedDelivery | amDateFormat:'HH:mm'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="todayDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: todayDisplayedColumns;">
            </tr>
        </table>

    </nb-card-body>
</nb-card>

<nb-card size="big" [hidden]="historyResultsLength<1">

    <nb-card-header>
        {{ 'CLIENT.ORDERS.HISTORY' | translate}}
    </nb-card-header>

    <nb-card-body [hidden]="historyResultsLength">
        {{ 'CLIENT.ORDERS.NODATAHISTORY' | translate}}
    </nb-card-body>

    <nb-card-body [hidden]="!historyResultsLength">


        <table mat-table [dataSource]="historyDataSource" #sortHistory="matSort" matSort #tableHistory>


            <!-- Data -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLEHISTORY.DATE' |
                    translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.deliveryDate | amDateFormat:'DD/MM/YYYY' }}
                </td>
            </ng-container>

            <!-- Numero -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLEHISTORY.NUMBER' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.orderNumber}}</td>
            </ng-container>

            <!-- Deposito  -->
            <ng-container matColumnDef="deposit">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLEHISTORY.DEPOSIT' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit">
                    <div class="deposit-address">
                        {{element.deliveryName}}
                        {{element.deliveryAddress}},{{element.deliveryCity}}
                    </div>
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.ORDERS.TABLEHISTORY.PRODUCT' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product}}</td>
            </ng-container>

            <!-- Quantità -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLEHISTORY.QUANTITY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
            </ng-container>

            <!-- Valore -->
            <ng-container matColumnDef="value">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLEHISTORY.VALUE' | translate}}
                </th>
                <td mat-cell class="promotional" *matCellDef="let element">
                    {{element.price | currency:'€': 'symbol' : '1.3-3'}}
                    ({{element.price / element.quantity | number : '1.2-2'}})
                    <img *ngIf="element.promotionalPrice  || element.suggestedRoute" class="promo-ico"
                        src="/assets/images/gift.png">
                </td>
            </ng-container>


            <!-- Stato -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.ORDERS.TABLEHISTORY.STATUS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="status  {{element.status}}">
                    {{'ORDERSTATUS.'+toUpperCase(element.status)|translate}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="historyDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;">
            </tr>
        </table>

        <mat-paginator pageSize="50" [pageSizeOptions]="[10,50,100]" [length]="historyResultsLength"></mat-paginator>

    </nb-card-body>
</nb-card>