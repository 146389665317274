<h1>{{'ORDERS.NEW.TITLE' | translate}}</h1>

<mat-expansion-panel (opened)="panelOpened()">
    <mat-expansion-panel-header>
        <mat-panel-description>
            {{searchtitle}}
        </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngTemplateOutlet="templateRef"></ng-container>
</mat-expansion-panel>


<ng-template #templateRef>
    <div class="searchfilter">

        <!-- <div class="line">
        <div class="column">
          <span class="label">{{'CUSTOMER.SEARCH.FILTER.ORDERIMPORT' | translate}}</span>
          <ng-select [items]="menu.orderOperators" bindLabel="nome" bindValue="id" autofocus
            [(ngModel)]="filter.orderImportOperator">
          </ng-select>
        </div>
        <div class="column">
          <div class="column">
            <input type="text" nbInput [(ngModel)]="filter.orderImportVal">
          </div>
          <div class="column">
            <ng-select [items]="menu.currencies" bindLabel="nome" bindValue="id" autofocus
              [(ngModel)]="filter.orderImportCurrency"> </ng-select>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="column">
          <span class="label">{{'CUSTOMER.SEARCH.FILTER.ORDERDATE' | translate}}</span>
          <ng-select [items]="menu.dateSizes" bindLabel="nome" bindValue="id" autofocus
            [(ngModel)]="filter.orderDateSize">
          </ng-select>
        </div>
        <div class="column">
          <input [nbDatepicker]="datepicker" [(ngModel)]="filter.orderDate">
          <nb-datepicker #datepicker>
            <nb-icon icon="calendar-outline"></nb-icon>
          </nb-datepicker>
        </div>
      </div> -->

        <button nbButton (click)="search()">{{'CUSTOMER.SEARCH.FILTER.SEARCH' | translate}}</button>

    </div>
</ng-template>