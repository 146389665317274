import { Component, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { SortPaginator } from 'src/app/models/generic.model';
import { MatTableDataSource } from '@angular/material/table';
import { OrderService } from 'src/app/services/order.service';
import { Promotion, Tank, Trip } from 'src/app/models/order.model';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'src/app/services/modal.service';
import { NbPopoverDirective, NbPosition, NbTrigger } from '@nebular/theme';
import { Moment } from 'moment';
import { LogisticService } from 'src/app/services/logistic.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'oil-orderpromotion',
  templateUrl: './orderpromotion.component.html',
  styleUrls: ['./orderpromotion.component.scss']
})
export class OrderpromotionComponent implements OnInit {

  public trips: Trip[] = [];
  public dataSourceTrip: MatTableDataSource<Trip>;
  public resultsLengthTrip = 0;

  public promotionEnds: Moment;

  public dataSourceProm: MatTableDataSource<Promotion>;
  public resultsLengthProm = 0;

  public toDelete: Promotion;
  public isWaiting = false;
  public waitingPromotion = false;

  public suggestedRouteId: number = null;

  public displayedColumnsTrip: string[] = [
    'name',
    'truck',
    'tow',
    'product',
    'delivery',
    'destinations',
    'filling',
    'remain',
    'promotion',
    'action'
  ]

  public displayedColumnsProm: string[] = [
    'promo-status',
    'promo-name',
    'promo-date',
    'promo-product',
    'promo-quantity',
    'promo-remainingQuantity',
    'promo-price',
    'promo-margin',
    'promo-fido',
    //'promo-route',
    'promo-send',
    'promo-actions',
  ]



  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild('sortTrips') sortTrips: MatSort;
  @ViewChild('sortProm') sortProm: MatSort;

  @ViewChildren(NbPopoverDirective) popovers: QueryList<NbPopoverDirective>;

  @ViewChild('deleteTemplate', { read: TemplateRef }) deleteTemplate: TemplateRef<any>;

  constructor(
    private orderService: OrderService,
    private translation: TranslateService,
    private modalService: ModalService,
    private logisticService: LogisticService
  ) { }

  ngOnInit(): void {

    this.refresh();


    setTimeout(() => {
      this.setSortTrip();
      this.setSortProm();
    }, 0)

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.refresh();
    //       return of([]);
    //     })
    //   ).subscribe(data => {
    //     console.log('-------: DashboardComponent -> ngOnInit -> data', data);
    //   });
  }

  setSortProm() {
    this.sortProm.sortChange.subscribe(() => {
      console.log('-------------------------------------', this.sortProm);

      const data = this.dataSourceProm.data;

      // this.dataSource.sort = this.sort;
      if (!this.sortProm.active || this.sortProm.direction === '') {
        return;
      }

      data.sort((a, b) => {
        const isAsc = this.sortProm.direction === 'asc';
        switch (this.sortProm.active) {
          case 'promo-status': return UtilsService.compare(a.clientStatus, b.clientStatus, isAsc);
          case 'promo-name': return UtilsService.compare(a.clientName, b.clientName, isAsc);
          case 'promo-product': return UtilsService.compare(a.product, b.product, isAsc);
          case 'promo-quantity': return UtilsService.compare(a.maxQuantity, b.maxQuantity, isAsc);
          case 'promo-remainingQuantity': return UtilsService.compare(a.remainingQuantity, b.remainingQuantity, isAsc);
          case 'promo-price': return UtilsService.compare(a.price, b.price, isAsc);
          case 'promo-fido': return UtilsService.compare(a.remainingCredit, b.remainingCredit, isAsc);
          case 'promo-send': return UtilsService.compare(a.promotionStatus, b.promotionStatus, isAsc);
          case 'promo-actions': return 0;
          default: return 0;
        }
      });

      this.dataSourceProm = new MatTableDataSource<Promotion>(data);

    });
  }

  setSortTrip() {

    this.sortTrips.sortChange.subscribe(() => {
      console.log('-------------------------------------', this.sortTrips);

      const data = this.dataSourceTrip.data;



      // this.dataSource.sort = this.sort;
      if (!this.sortTrips.active || this.sortTrips.direction === '') {
        return;
      }

      data.sort((a, b) => {
        const isAsc = this.sortTrips.direction === 'asc';
        switch (this.sortTrips.active) {
          case 'name': return UtilsService.compare(a.name, b.name, isAsc);
          case 'truck': return UtilsService.compare(a.vehicleName, b.vehicleName, isAsc);
          case 'tow': return UtilsService.compare(a.towName, b.towName, isAsc);
          case 'product': return UtilsService.compare(this.AllProductsList(a.tanks), this.AllProductsList(b.tanks), isAsc);
          case 'delivery': return UtilsService.compare(a.orders.length, b.orders.length, isAsc);
          case 'destinations': return UtilsService.compare(a.destinations.length, b.destinations.length, isAsc);
          case 'filling': return UtilsService.compare(a.fillingPercentual, b.fillingPercentual, isAsc);
          case 'remain': return UtilsService.compare(this.allRemain(a.tanks), this.allRemain(b.tanks), isAsc);
          case 'promotion': return UtilsService.compare(this.allPromotion(a.tanks), this.allPromotion(a.tanks), isAsc);
          default: return 0;
        }
      });

      this.dataSourceTrip = new MatTableDataSource<Trip>(data);

    });
  }



  refresh() {
    const paginator: SortPaginator = {
      orderBy: null, // this.sort.active,
      start: null,//this.paginator.pageIndex,
      limit: null,//this.paginator.pageSize
    }
    // if(this.sort.direction){paginator.orderDirection = this.sort.direction}

    this.translation.get('ORDERS.PROMOTION.TRIPBASENAME').subscribe(tripbasename => {
      this.orderService.getTrips(paginator, new Date(), tripbasename).subscribe(x => {
        // console.log('-------: OrderpromotionComponent -> refresh -> x', x);
        this.dataSourceTrip = new MatTableDataSource<Trip>(x.items);
        this.trips = x.items;
        this.resultsLengthTrip = x.total;
        if (x.expireDate) {
          this.promotionEnds = moment(x.expireDate);
        }
      });
      this.orderService.getPromotion(paginator).subscribe(x => {
        this.dataSourceProm = new MatTableDataSource<Promotion>(x.items);
        this.resultsLengthProm = x.total;
      });
    })


    this.orderService.isWorkingPromotions().subscribe(res => {
      this.waitingPromotion = res.isWorking;
    })
    this.logisticService.isWorkingTrips().subscribe(res => {
      this.isWaiting = res.isWorking;
    })


  }

  public isPromotionActive() {
    let ret = this.promotionEnds && this.remainingMinutes() > 0;
    return ret;
  }


  public remainingtime() {
    return this.promotionEnds.from(moment());
  };

  public remainingMinutes() {
    if (this.promotionEnds) {
      return this.promotionEnds.diff(moment(), 'minute');
    } else { return 0; }
  };


  openTrip(element: Trip) {
    this.modalService.tripDetails(element.routeId ? element.routeId : 1, element.confirmed, false).pipe(take(1)).subscribe(x => {
    })
  }

  addClient(route: Trip = null) {
    this.modalService.createPromotion(null, route, this.remainigCapacity(route)).pipe(take(1)).subscribe(x => {
      this.refresh();
    })
  }


  edit(element) {
    this.modalService.createPromotion(element).pipe(take(1)).subscribe(x => {
      this.refresh();
    })

  }

  sendPromotion(element) {
    this.orderService.confirmPromotion(element).subscribe(x => {
      this.refresh();
    })
  }

  delete(element) {
    this.toDelete = element;
    //this.popover.show();
  }

  confirmDelete($event) {
    this.orderService.deletePromotion(this.toDelete).subscribe(x => {
      this.refresh();
    })
    this.popoverHide();

  }

  newOrder(element: Promotion) {
    this.modalService.createOrderForPromotion(element).pipe(take(1)).subscribe(x => {

    })
  }

  cancelDelete($event) {
    this.popoverHide();

  }

  popoverHide() {
    this.popovers.forEach(pop => {
      pop.hide();
    });
  }

  allRemain(tanks: Tank[]) {
    let ret = 0;
    tanks.forEach(tank => {
      ret += tank.maxCapacity - tank.fillingQuantity;
    });
    return ret;
  }

  allPromotion(tanks: Tank[]) {
    let ret = 0;
    tanks.forEach(tank => {
      ret += tank.inPromotion;
    });
    return ret;
  }

  AllProductsList(tanks: Tank[]) {
    let res = [];
    tanks.forEach(tank => {
      res = [...res, this.productList(tank.products)]
    });
    return res.join(' | ');
  }

  productList(products: string[]) {
    if (products && products.length) {
      const uniq = [...new Set(products)];
      return uniq.join(' | ');
    } else {
      return '-';
    }
  }

  routeName(id) {
    let routes = this.trips.filter((x: Trip) => x.routeId == id);

    return routes && routes.length ? routes[0].routeId : '';
  }

  setSuggestedRoute(routeId = null) {
    this.suggestedRouteId = routeId;
  }

  openPromotionTrip(promotion: Promotion) {
    this.modalService.promotionTrip(promotion).pipe(take(1)).subscribe(() => {

    });
  }

  isToday(date) {
    return moment().isSame(moment(date), 'day');
  }

  remainigCapacity(trip: Trip) {
    let tanksTotal = 0;
    trip.tanks.forEach(tank => {
      tanksTotal += tank.fillingQuantity;
    })
    return trip.maxTowCapacity + trip.maxTruckCapacity - tanksTotal;
  }

}
