import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { User, UserListApiResponse } from '../models/user.model';
import { SortPaginator } from '../models/generic.model';
import { config } from 'src/environments/config';


@Injectable({
    providedIn: 'root'
})
export class AdminService {


    constructor(
        private api: ApiService
    ) {

    }

    public getUsers(paginator: SortPaginator, roles: string[], includeDeleted: boolean = false): Observable<UserListApiResponse> {
        const parameters = {
            'roles[]': roles,
            includeDeleted: includeDeleted
        };
        Object.assign(parameters, paginator);
        console.log('---- ~ file: admin.service.ts ~ line 42 ~ AdminService ~ getUsers ~ parameters', parameters);
        return this.api.call('cms/config/user/list', 'GET', null, parameters);
    }


    public createUser(user: User) {
        const data = Object.assign({}, user);
        delete data.id;
        if (!data.clientId) {
            delete data.clientId;
        }
        return this.api.call(`admin/config/user/add`, 'POST', data);
    }
    public editUser(user: User) {
        const data = user;
        return this.api.call(`admin/config/user/edit`, 'POST', data);
    }
    public deleteUser(userId: number) {
        return this.api.call(`admin/config/user/${userId}/delete`, 'POST');
    }

    public changePwd(oldpwd: string, newpwd: string, isClient: boolean) {
        const data = {
            oldPassword: oldpwd,
            newPassword: newpwd
        };
        return this.api.call(isClient?`client/config/user/change-password`:`admin/config/user/change-password`, 'POST', data, null, null);
    }
}
