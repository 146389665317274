import { Component, OnInit } from '@angular/core';
import { CustomerService } from 'src/app/services/customer.service';
import { map } from 'rxjs/operators';
import { CustomerEvent } from 'src/app/models/customer.model';
import { CalendarService } from 'src/app/services/calendar.service';
import * as moment from 'moment';
import { CalendarView, CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Subject } from 'rxjs';



@Component({
  selector: 'oil-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {



  private apievents;

  events: CalendarEvent<CustomerEvent>[];

  viewDate: Date = new Date();
  view: CalendarView = CalendarView.Month;

  constructor(
    private customerService: CustomerService,
    private calendarService: CalendarService,
  ) {

  }

  ngOnInit() {
    this.refresh();
  }


  refresh() {
    this.customerService.getEvents(this.viewDate.getMonth(), this.viewDate.getFullYear()).pipe(map(apiresponse => {
      const res: CalendarEvent<CustomerEvent>[] = [];
      console.log('-------: CalendarComponent -> refresh -> res', res);
      let getEvents= (list)=>{list.forEach(event => {
        res.push({
          start: moment(event.date).toDate(),
          title: event.customerName,
          meta: event
        })
      });}
      getEvents(apiresponse.next.items);
      console.log('-------: CalendarComponent -> refresh -> res', res);
      getEvents(apiresponse.current.items);
      console.log('-------: CalendarComponent -> refresh -> res', res);
      getEvents(apiresponse.previous.items);
      console.log('-------: CalendarComponent -> refresh -> res', res);
      return res;
    })).subscribe(apievents => {
      this.events = apievents;
    })
  }

  addEventDay(day) {
    console.log('-------: addEventDay -> day', day);
    this.addEvent(null, day);
  }

  editEvent(event: CalendarEvent<CustomerEvent>) {
    console.log('-------: editEvent -> event', event);
    this.addEvent(event.meta.id);
  }

  addEvent(eventId = null, day = new Date()) {
    if (eventId) {
      this.calendarService.editEvent(eventId).subscribe(x => {
        this.refresh();
      });
    } else {
      this.calendarService.createEvent(moment(day)).subscribe(x => {
        if (x) {
          this.refresh();
        }
      });;
    }
  }

}
