import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { User, agendaEvent, agenda } from 'src/app/models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { NbMenuService } from '@nebular/theme';
import { AuthGuard } from 'src/app/services/auth-guard.service';
import { NbAuthService } from '@nebular/auth';
import { ModalService } from 'src/app/services/modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'oil-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {

  public agendaEvents: agendaEvent[] = [];

  public myUser: User = {
    firstName: '',
    lastName: '',
    id: null,
    // roles:[]
    role: ''
  };

  PROFILEACTION = 'profile';
  LOGOUTACTION = 'logout';
  PASSWORDACTION = 'passrod';

  public profilemenu: any[] = [{ title: 'SIDEBAR.PROFILE' }];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private translate: TranslateService,
    private nbMenuService: NbMenuService,
    private authService: NbAuthService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.translate.get(['SIDEBAR.PROFILE', 'SIDEBAR.PASSWORD', 'SIDEBAR.LOGOUT']).subscribe(t => {
      this.profilemenu = [{ title: t['SIDEBAR.PROFILE'], action: this.PROFILEACTION }, { title: t['SIDEBAR.PASSWORD'], action: this.PASSWORDACTION }, { title: t['SIDEBAR.LOGOUT'], action: this.LOGOUTACTION }];
    })


    this.subscriptions.add(this.nbMenuService.onItemClick().subscribe(event => {
      // console.log('---- ~ file: sidebar.component.ts ~ line 49 ~ SidebarComponent ~ this.nbMenuService.onItemClick ~ event.item[action]', event.item['action']);
      switch (event.item['action']) {
        case this.LOGOUTACTION:
          this.logout(); break;
        case this.PROFILEACTION:
          this.modalService.profile(this.myUser);
          break;
        case this.PASSWORDACTION:
          this.modalService.changePassword(this.myUser);
          break;

      }
    }));

    this.subscriptions.add(this.authService.onTokenChange().subscribe((refresh) => {
      this.refresh();
    }));

    // this.refresh();

    this.subscriptions.add(this.userService.agenda.subscribe(agenda => {
      this.refreshAgenda(agenda);
    }));

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();

  }

  refresh() {
    setTimeout(() => {
      this.userService.getMe(true).subscribe(me => {
        this.myUser = me;

        if (this.myUser && this.myUser.defaultPassword) {
          this.modalService.changePassword(this.myUser, true).subscribe(res => {
            if (!res) {
              this.userService.logout();
            } else {
              this.refresh();
              this.modalService.profile(this.myUser, true).subscribe(res => { });
            }

          });
        }
      });
    }, 1)

  }

  logout() {
    // console.log(' ++++ LOGOUT ++++');
    this.userService.logout();

  }

  refreshAgenda(agenda: agenda) {
    if (agenda) {
      let agendaEv = [{
        counter: agenda.clients ? agenda.clients.todayEvents : 0,
        url: '/customers/calendar',
        title: 'SIDEBAR.AGENDA.EVENTS'
      }, {
        counter: agenda.prices ? agenda.prices.toSendFavorite : 0,
        url: '/prices/list',
        title: 'SIDEBAR.AGENDA.PRICE_TOSENDFAVORITE'
      }, {
        counter: agenda.prices ? agenda.prices.attendedPrices : 0,
        url: '/prices/list',
        title: 'SIDEBAR.AGENDA.PRICE_ATTENDENDPRICE'
      }, {
        counter: agenda.prices ? agenda.prices.toSend : 0,
        url: '/prices/request',
        title: 'SIDEBAR.AGENDA.PRICE_TOSEND'
      }, {
        counter: agenda.prices ? agenda.prices.toApprove : 0,
        url: '/prices/approval',
        title: 'SIDEBAR.AGENDA.PRICE_TOAPPROVE'
      }, {
        counter: agenda.prices ? (agenda.prices.hasDailyPrices ? 0 : 1) : 0,
        url: '/prices/list',
        title: 'SIDEBAR.AGENDA.PRICE_TOGENERATE'
      }, {
        counter: agenda.orders ? agenda.orders.toSend : 0,
        url: '/orders/request',
        title: 'SIDEBAR.AGENDA.ORDER_TOSEND'
      }, {
        counter: agenda.orders ? agenda.orders.toApprove : 0,
        url: '/orders/approval',
        title: 'SIDEBAR.AGENDA.ORDER_TOAPPROVE'
      }, {
        counter: agenda.orders ? agenda.orders.promotionsToSend : 0,
        url: '/orders/promotion',
        title: 'SIDEBAR.AGENDA.ORDER_PROMOTIONSTOSEND'
      }, {
        counter: agenda.logistic ? agenda.logistic.unassignedOrdersToday : 0,
        url: '/logistic/unassigned/today',
        title: 'SIDEBAR.AGENDA.UNASSIGNED_ORDERS_TODAY'
      }, {
        counter: agenda.logistic ? agenda.logistic.unassignedOrdersTomorrow : 0,
        url: '/logistic/unassigned/tomorrow',
        title: 'SIDEBAR.AGENDA.UNASSIGNED_ORDERS_TOMORROW'
      }, {
        counter: agenda.orders ? agenda.orders.inPromotion : 0,
        url: '/orders/promotion',
        title: 'SIDEBAR.AGENDA.PROMOTION_ORDERS'
      }, {
        counter: agenda.client ? agenda.client.hasActivePromotion ? 1 : 0 : 0,
        url: '/client/prices',
        title: 'SIDEBAR.AGENDA.CLIENTPROMOTION'
      },];
      this.agendaEvents = agendaEv.filter(x => { return x.counter > 0 });
    }

  }

}
