<div class="filters">
    <div class="filter-container" *ngFor="let filter of filters">
        <div class="filter-title">
            {{'FILTERS.FILTERBY' | translate}}
            {{'FILTERS.'+filter.name+'.LABEL' | translate}}
        </div>

        <div *ngFor="let value of filter.values" class="filter-container" (click)="toggle(filter,value)">
            <nb-icon [icon]="value.icon" class="filter-icon" pack="3logic"
                [ngStyle]="{'color': isSelected(value)? filter.color:null,'fill': isSelected(value)? filter.color:null,'border-color': isSelected(value)? filter.color:null}">
            </nb-icon>
            <label>
                {{'FILTERS.'+filter.name+'.'+value.label | translate}}
            </label>
        </div>
        
    </div>

    <div class="filter-container">
        <div class="filter-title">
            {{'FILTERS.'+option.name+'.LABEL' | translate}}
        </div>
        <div *ngFor="let value of option.values" class="filter-container" (click)="toggleOption(value)">
            <div *ngIf="value.isSelected">
                <nb-icon [icon]="value['icon_'+value.value]" class="filter-icon"
                    [ngStyle]="{'color': isSelected(value)? option.color:null,'fill': isSelected(value)? option.color:null,'border-color': isSelected(value)? option.color:null}">
                </nb-icon>
                <label>
                    {{'FILTERS.'+option.name+'.'+value['label_'+value.value] | translate}}
                </label>
            </div>
            
        </div>
    </div>
</div>