import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { CustomerService } from 'src/app/services/customer.service';
import { MatTableDataSource } from '@angular/material/table';
import { Customer, CustomerSearch } from 'src/app/models/customer.model';
import { CustomerDashboardFilter, CustomerSearchFilter, ValuesComparators } from 'src/app/models/customerfilter.model';
import { config } from 'src/environments/config';
import { AnagraficaService } from 'src/app/services/anagrafica.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { MatSort } from '@angular/material/sort';
import { SortPaginator, IdNome } from 'src/app/models/generic.model';
import { publishReplay, startWith, switchMap, take } from 'rxjs/operators';
import { merge, pipe, of } from 'rxjs';
import * as moment from 'moment';
import { CalendarService } from 'src/app/services/calendar.service';
import { OrderService } from 'src/app/services/order.service';
import { CustomerFilterComponent } from 'src/app/components/customer-filter/customer-filter.component';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'oil-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  public dataSource;
  public resultsLength = 0;
  public PAGE_SIZE = 10;

  public selected: string[] = [];

  public filterString;
  public showReset: boolean = false;

  private regionString;
  private provinceString;
  private cityString;

  public initFilter: CustomerSearchFilter = {
    name: '',
    vat: '',
    region: '',
    province: '',
    district: '',
    productId: '',
    cluster: '',
    orderImportOperator: '>',
    orderImportVal: null,
    orderImportCurrency: 'eur',
    orderDateSize: '1',
    orderDate: '',
    nonewcustomer: false,
    includeWithoutOrders: false
  }

  public filter: CustomerSearchFilter = this.initFilter;

  public regions = [];
  public provinces = [];
  public cities = [];
  public menu = {
    products: [],
    clusters: CustomerFilterComponent.filterForSelect(CustomerFilterComponent.CLUSTER),
    orderOperators: [{
      id: ValuesComparators.LT,
      name: "CUSTOMER.SEARCH.FILTER.LT"
    }, {
      id: ValuesComparators.GT,
      name: "CUSTOMER.SEARCH.FILTER.GT"
    }, {
      id: ValuesComparators.EQ,
      name: "CUSTOMER.SEARCH.FILTER.EQ"
    }],
    currencies: [{
      id: "eur",
      name: "CUSTOMER.SEARCH.FILTER.EURO"
    }],
    dateSizes: [{
      id: '1',
      name: "CUSTOMER.SEARCH.FILTER.DAY"
    }, {
      id: '7',
      name: "CUSTOMER.SEARCH.FILTER.WEEK"
    }, {
      id: '30',
      name: "CUSTOMER.SEARCH.FILTER.MONTH"
    }]
  }

  private translations;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    //'selected',
    'favorite',
    // 'id',
    'name',
    'vat',
    'deliveryCity',
    'deliveryAddress',
    // 'product',
    // 'lastOrderDate',
    'lastOrderQty',
    'cta'
  ];

  constructor(
    private customerService: CustomerService,
    private anagraficaSerice: AnagraficaService,
    private translate: TranslateService,
    private calendarService: CalendarService,
    private router: Router,
    private orderService: OrderService,
    private modalService: ModalService,
  ) {

  }

  setTranslations() {
    for (let menu in this.menu) {
      if (this.menu[menu]) {
        this.menu[menu].forEach(elem => {
          elem.name = this.translate.instant(elem.name);
        })
        this.menu[menu] = [...this.menu[menu]];
      }
    }
  }

  ngOnInit() {
    this.translate.get('CUSTOMER.SEARCH.ALLPRODUCTS').subscribe(t => {
      this.orderService.getProductList().subscribe(productsApi => {
        this.menu.products = productsApi;
        this.menu.products.unshift({
          id: null,
          name: t
        })
      })
    })
    this.paginator.pageSize = this.PAGE_SIZE;


    this.anagraficaSerice.getRegions().subscribe(regions => {
      this.regions = regions;
    })

    this.translate.get(['CUSTOMER.SEARCH.SELECTPLACEHOLDER', 'CUSTOMER.SEARCH.FILTER.NONEWCUSTOMERSHORT', 'CUSTOMER.SEARCH.FILTER.NOWITHOUTORDERSHORT']).subscribe(t => {
      this.translations = t;
      this.reset();
    })

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
        console.log('-------: DashboardComponent -> ngOnInit -> data', data);
      });

    this.translate.onLangChange.subscribe(() => {
      this.setTranslations();
    });
    this.setTranslations();
  }

  refresh() {
    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if(this.sort.direction){paginator.orderDirection = this.sort.direction}
    this.customerService.getSearchTable(this.filter, paginator).subscribe(x => {
      this.dataSource = new MatTableDataSource<Customer>(x.items);
      this.resultsLength = x.total;
    });

  }

  updateFilterString(reset = false) {    
    if (reset) {
      this.filterString = this.translations['CUSTOMER.SEARCH.SELECTPLACEHOLDER'];      
    } else {
      this.filterString = '';      
      if (this.filter.name) {
        this.filterString += this.filter.name + ' ';
      }
      if (this.filter.vat) {
        this.filterString += this.filter.vat + ' ';
      }
      if (this.filter.region) {
        this.filterString += this.regionString + ' ';        
      }
      if (this.filter.province) {
        this.filterString += this.provinceString + ' ';
      }
      if (this.filter.district) {
        // this.filterString += this.cityString + ' ';
        this.filterString += this.filter.district + ' ';
      }
      if (this.filter.productId) {
        const product = this.menu.products.find(x => x.id === this.filter.productId);
        this.filterString += product.name + ' ';
      }
      if (this.filter.cluster) {
        this.filterString += this.filter.cluster + ' ';
      }
      if (this.filter.orderImportVal) {
        this.filterString += this.filter.orderImportOperator + this.filter.orderImportVal + this.filter.orderImportCurrency + ' ';
      }
      if (this.filter.orderDate) {
        const dateStr = moment(this.filter.orderDate).format('DD/MM/YY');
        this.filterString += ` ${dateStr}±${this.filter.orderDateSize}  `;
      }
      if (this.filter.nonewcustomer) {
        this.filterString += this.translations['CUSTOMER.SEARCH.FILTER.NONEWCUSTOMERSHORT'] + ' ';
      }
      if (this.filter.includeWithoutOrders) {
        this.filterString += this.translations['CUSTOMER.SEARCH.FILTER.NOWITHOUTORDERSHORT'] + ' ';
      }
    }
  }

  search() {
    this.showReset = true;
    this.updateFilterString();
    this.refresh();
  }

  reset() {
    this.filter = JSON.parse(JSON.stringify(this.initFilter));
    this.showReset = false;
    this.updateFilterString(true);
    this.refresh();
  }

  updateRegion(event : IdNome) {
    this.regionString = event.nome;
    this.cities = [];
    this.filter.province = null;
    this.filter.district = null;
    this.provinces = [];
    if (this.filter.region) {
      this.anagraficaSerice.getProvinces(this.filter.region).subscribe(provinces => {
        this.provinces = provinces;
      })
    }

  }

  updateProvince(event : IdNome) {
    this.provinceString = event.nome;
    this.cities = [];
    this.filter.district = null;
    if (this.filter.region && this.filter.province) {
      // this.anagraficaSerice.getCities(this.filter.region, this.filter.province).subscribe(cities => {
      //   this.cities = cities;
      // });
      this.cities.push(1);
    }
  }

  // updateCity(event) {
  //   this.cityString = event.nome;

  // }

  // setFavorite(item){
  //   item.favorite =  !item.favorite;

  // }

  isSelected(id: string) {
    return this.selected.indexOf(id) > -1;
  }

  // clickRow(row) {
  //   this.router.navigateByUrl(`customers/details/${row.id}`).then(x => { });
  // }

  toggleSelect(id, isSelected: boolean) {
    // override by clickrow
    if (id) {
      const idx = this.selected.indexOf(id);
      if (isSelected && idx === -1) {
        this.selected.push(id);
      } else if (!this.selected && idx > -1) {
        this.selected.splice(idx, 1);
      }
    } else {
      this.selected = [];
      if (isSelected) {
        this.dataSource.filteredData.forEach(element => {
          this.selected.push(element.id);

        });
      }
    }
  }

  addEvent(element) {
    console.log('-------: SearchComponent -> addEvent -> element', element);
    this.calendarService.createEventForCustomer(element.id);
  }



  addOrder(element: CustomerSearch) {
    console.log('-------: SearchComponent -> addOrder -> element', element);
    this.modalService.createOrderForPoint(element.id, element.deliveryCode).pipe(take(1)).subscribe(()=>{});
  }

  
  setFavorite(point: CustomerSearch) {
    this.customerService.setCustomerPointFavorite(point.deliveryId, !point.favorite).subscribe(() => {
      point.favorite = !point.favorite;
    })
  }

}
