import { Component, OnInit } from '@angular/core';
import { NbDialogService, NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerEvent, CustomerPoint, CustomerBase } from 'src/app/models/customer.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'oil-modal-new-event',
  templateUrl: './modal-new-event.component.html',
  styleUrls: ['./modal-new-event.component.scss']
})
export class ModalNewEventComponent implements OnInit {

  public customers: CustomerBase[];
  public points: CustomerPoint[];

  public ckEditor = ClassicEditor;

  public charCount = 0;
  public CHARMAX = 5000;
  public lastNote = '';

  // public title : string;
  public customerEvent: CustomerEvent = {
    customerId: null,
    pointId: null,
    id: null,
    date: new Date(),
    note: ''
  };

  public modifyCustomer = true;
  public modifyPoint = true;
  public isLoadingSuggestion = false;

  public dateMoment = moment();

  clientInput: CustomerBase;
  filteredOptions$: Observable<CustomerBase[]>;

  constructor(
    protected ref: NbDialogRef<ModalNewEventComponent>,
    private customerService: CustomerService
  ) {


  }

  ngOnInit(): void {
    // this.customerService.getAll().subscribe(res => {
    //   this.customers = res;
    //   if (this.customerEvent.customerId) {
    //     this.updateCustomer({ id: this.customerEvent.customerId });
    //   }
    // })

    if (this.customerEvent.customerId) {
      this.customerService.getDetails('' + this.customerEvent.customerId, true).subscribe(res => {
        if (res && res.name) {
          this.updateCustomer({ id: this.customerEvent.customerId, name: res.name });
          this.clientInput = { id: this.customerEvent.customerId, name: res.name };
        }
      })

    }

    if (!this.customerEvent.note) {
      this.customerEvent.note = '';
    }

    console.log('-------: ModalNewEventComponent -> this.customerEvent', this.customerEvent);
    if (this.customerEvent && this.customerEvent.date) {
      this.dateMoment = moment(this.customerEvent.date)
    }

    // this.ckEditor.config.
    console.log('-------: ModalNewEventComponent -> ngOnInit -> this.ckEditor.config', this.ckEditor, this.ckEditor.config);
  }

  save() {

    this.customerEvent.date = this.dateMoment.toDate();
    this.customerService.saveEvent(this.customerEvent).subscribe(() => {
      this.ref.close(this.customerEvent);
      console.log('-------: ModalNewEventComponent -> save -> newevent', this.customerEvent);

    });


  }

  cancel() {
    this.ref.close();
  }

  viewHandle(value: CustomerBase) {
    if (value && value.name)
      return value.name;
    return value;
  }

  limitData($event: ChangeEvent) {
    this.charCount = $event.editor.getData().replace(/<.*?>/g, '').length;
    if (this.charCount > this.CHARMAX) {
      $event.editor.setData(this.lastNote);
    } else {
      this.lastNote = $event.editor.getData();
    }
  }

  updateCustomer($event: CustomerBase) {
    this.customerEvent.pointId = this.modifyPoint ? null : this.customerEvent.pointId;
    if ($event && $event.id) {
      this.customerEvent.customerId = $event.id;
      this.customerService.getDetails('' + $event.id, true).subscribe(res => {
        const list = res.delivery_addresses.map((i) => { i.label = `${i.name ? i.name : ''}-${i.address_1 ? i.address_1 : ''}-${i.city ? i.city : ''}`; return i; });
        this.points = list;
        // if (!this.customerEvent.pointId && this.points.length === 1) {
        if (this.points.length === 1) {
          this.customerEvent.pointId = this.points[0].id;
        } else {
          this.customerEvent.pointId = null;
        }
        this.charCount = this.customerEvent.note.replace(/<.*?>/g, '').length;
      })
    } else {
      this.points = null;
    }

  }


  getClientFilteredOptions(filter: string): Observable<CustomerBase[]> {
    this.isLoadingSuggestion = true;
    return this.customerService.getFiltered(filter).pipe(map(x => { this.isLoadingSuggestion = false; return x.items; }));
  }

  onChangeClientInput(value: string | CustomerBase) {
    if (typeof value == 'string') {
      // this.filteredOptions$ = of(null);
      this.filteredOptions$ = this.getClientFilteredOptions(value);
      // return this.filteredOptions$;
    } else {
      this.updateCustomer(value);
    }
  }


}
