import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { IdNome,District,Province } from '../models/generic.model';




@Injectable({
    providedIn: 'root'
})
export class AnagraficaService {


    constructor(
        private api: ApiService
    ) {


    }

    public getRegions(): Observable<IdNome[]> {
        return this.api.call('cms/anagrafica/regioni');
    }

    public getProvinces(region: string): Observable<Province[]> {
        return this.api.call(`admin/anagrafica/province/${region}`,'GET'); 
    }

    public getCities(region: string, province: string): Observable<District[]> {
        return this.api.call(`admin/anagrafica/comuni/${province}`,'GET'); 
    }



}