import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
  
  public showCookie: boolean = false;
  
  constructor() { }

  ngOnInit(): void {
  
  }
  
  showCookieNow(){
    console.log('---- ~ file: policy.component.ts ~ line 19 ~ PolicyComponent ~ showCookieNow ~ showCookieNow');
    this.showCookie = true;
  }
}
