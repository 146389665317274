<nb-card>
    <nb-card-header>
        <p *ngIf="!forced">{{'SIDEBAR.CHANGEPWD.TITLE' | translate}} </p>
        <p *ngIf="forced">{{'SIDEBAR.CHANGEPWD.TITLEFORCED' | translate}} </p>
    </nb-card-header>
    <nb-card-body>
            {{'SIDEBAR.CHANGEPWD.OLDPWD' | translate}}
            <input fullWidth nbInput type="password" [(ngModel)]="oldpwd" />
            {{'SIDEBAR.CHANGEPWD.NEWPWD' | translate}}
            <input fullWidth nbInput type="password" [(ngModel)]="newpwd" />
            {{'SIDEBAR.CHANGEPWD.NEWPWD2' | translate}}
            <input fullWidth nbInput type="password" [(ngModel)]="newpwd2" />
            <div class="error" *ngIf="error">{{error}}</div>
    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="save()">{{'SIDEBAR.CHANGEPWD.OK' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'SIDEBAR.CHANGEPWD.CANCEL' |
            translate}}</button>
    </nb-card-footer>
</nb-card>