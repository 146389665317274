import { Injectable } from '@angular/core';
import { NbToastrService, NbToastRef, NbComponentStatus,  NbGlobalPhysicalPosition } from '@nebular/theme';


@Injectable({
    providedIn: 'root'
})
export class NotifyService {

    constructor(private toastrService: NbToastrService) { }

    public toast(msg, type: NbComponentStatus = 'success', duration: number = 3000, position: NbGlobalPhysicalPosition = NbGlobalPhysicalPosition.TOP_RIGHT) {
        const toastRef: NbToastRef = this.toastrService.show(
            msg,
            null,
            {
                status: type,
                duration,
                position
            });

    }
}