<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected" [dateSync]="true"
    showFuture="true">
</oil-dateribbon>

<div class="daily">
    <div class="daily-row">
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">{{'LOGISTIC.LIST.TRIPSNUMBER' | translate}}</div>
                    <img src="/assets/icons/card/viaggi.svg" alt="trip icon" class="daily-icon" />
                    <oil-daily-chart class="daily-chart" [input]='chartInput'></oil-daily-chart>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'LOGISTIC.LIST.TRIPSDELTA' | translate}}
                        <span class="daily-subtitle">({{'LOGISTIC.LIST.TRIPSDELTASUBTITLE' | translate}})</span>
                    </div>
                    <img src="/assets/icons/card/viaggi.svg" alt="trip icon" class="daily-icon" />
                    <div class="daily-value">
                        <span *ngIf="tripsDelta>0"> + </span>
                        {{tripsDelta}}
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'LOGISTIC.LIST.TRUCKSNUMBER' | translate}}
                    </div>
                    <img src="/assets/icons/card/autobotte-piena.svg" alt="trip icon" class="daily-icon" />
                    <div class="daily-value">
                        <span>{{trucksNumber}}</span>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        <span> {{'LOGISTIC.LIST.TRIPSFILLING' | translate}} </span>

                    </div>
                    <img src="/assets/icons/card/autobotte-semipiena.svg" alt="trip icon" class="daily-icon" />
                    <div class="daily-value">
                        <img class="daily-value-pre" src="/assets/icons/card/arrow-up-green.svg" alt="increase"
                            *ngIf="tripsfillingDelta>0" />
                        <img class="daily-value-pre" src="/assets/icons/card/arrow-down-red.svg" alt="decrease"
                            *ngIf="tripsfillingDelta<0" />
                        <span>{{tripsfilling | number}}</span>
                        <span class="daily-value-post">%</span>

                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'LOGISTIC.LIST.TRIPSKM' | translate}}
                    </div>
                    <img src="/assets/icons/card/autobotte-KM.svg" alt="trip icon" class="daily-icon" />
                    <div class="daily-value"><span nbTooltip="{{tripskm | number}}">{{tripskm | smallNumber :
                            'value'}}</span>
                        <span class="daily-value-post">{{tripskm | smallNumber : 'order'}}</span>
                    </div>


                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>

<!-- <div class="routes-actions">
    <button nbButton class="calculate-button" (click)="calculateTrips()" [hidden]="!showCalculateTrip()">
        <nb-icon icon="map-outline"></nb-icon> {{'LOGISTIC.LIST.CALCUATE' | translate}}
    </button>
    <button nbButton class="confirmall-button" (click)="confirmTrips()" [hidden]="!showConfirmTrips()">
        <nb-icon icon="car-outline"></nb-icon> {{'LOGISTIC.LIST.CONFIRMALL' | translate}}
    </button>
    <button nbButton class="promotion-button" (click)="launchPromotion()" [hidden]="!showLaunchPromotion()">
        <nb-icon icon="gift-outline"></nb-icon>
        <ng-container *ngIf="!promotionActive">{{'LOGISTIC.LIST.PROMOTION' | translate}}</ng-container>
        <ng-container *ngIf="promotionActive"><span
                nbTooltip="{{promotionActive | amDateFormat:'HH:mm:ss'}}">{{'LOGISTIC.LIST.PROMOTIONACTIVE' |
                translate}} {{remainingtime()}} <br> {{'LOGISTIC.LIST.PROMOTIONMODIFY' | translate}}</span>
        </ng-container>
    </button>
    <button nbButton class="calculate-button" (click)="open(dialog)" [hidden]="!hasData || !showClean()">
        DEBUG - clean
    </button>

</div> -->

<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>{{'LOGISTIC.LIST.DIALOGTITLE' | translate}}</nb-card-header>
        <nb-card-body>{{'LOGISTIC.LIST.DIALOGMESSAGE' | translate}}</nb-card-body>
        <nb-card-footer>
            <button status="danger" class="modal-buttons" nbButton (click)="ref.close()">{{'LOGISTIC.LIST.DIALOGCANCEL'
                | translate}}</button>
            <button class="modal-buttons" nbButton (click)="deleteTrips();ref.close()">{{'LOGISTIC.LIST.DIALOGOK' |
                translate}} </button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<nb-card size="big">
    <nb-card-header>
        <div class="header-title">{{'LOGISTIC.LIST.TRIPSTITLE' | translate}}</div>
        <div class="header-right">
            <button nbButton class="tool-button calculate-button" (click)="calculateTrips()"
                [hidden]="!showCalculateTrip()">
                <nb-icon icon="map-outline"></nb-icon> {{'LOGISTIC.LIST.CALCUATE' | translate}}
            </button>
            <button nbButton class="tool-button confirmall-button" (click)="confirmTrips()"
                [hidden]="!showConfirmTrips()">
                <nb-icon icon="done-all-outline"></nb-icon> {{'LOGISTIC.LIST.CONFIRMALL' | translate}}
            </button>
            <button nbButton class="tool-button promotion-button" (click)="launchPromotion()"
                [hidden]="!showLaunchPromotion()"
                [disabled]="!enableLaunchPromotion()">
                <nb-icon icon="gift-outline"></nb-icon>
                <ng-container *ngIf="!promotionActive">{{'LOGISTIC.LIST.PROMOTION' | translate}}</ng-container>
                <ng-container *ngIf="promotionActive"><span
                        nbTooltip="{{promotionActive | amDateFormat:'HH:mm:ss'}}">{{'LOGISTIC.LIST.PROMOTIONACTIVE' |
                        translate}} {{remainingtime()}} <br> {{'LOGISTIC.LIST.PROMOTIONMODIFY' | translate}}</span>
                </ng-container>
            </button>
            <button nbButton class="tool-button calculate-button" (click)="open(dialog)"
                [hidden]="!hasData || !showClean()">
                {{'LOGISTIC.LIST.RESET' | translate}}
            </button>
        </div>
    </nb-card-header>

    <nb-card-body *ngIf="isWaiting">
        <nb-card [nbSpinner]="isWaiting" nbSpinnerStatus="warning">
            <nb-card-body>
                {{'LOGISTIC.LIST.NORESULT' | translate}}
            </nb-card-body>
        </nb-card>
        <button nbButton class="tryagain-btn" (click)="refresh()">{{'LOGISTIC.LIST.TRYAGAIN' | translate}}</button>
    </nb-card-body>

    <nb-card-body *ngIf="!hasData && !isWaiting">

        <div *ngIf="error" class="error">
            <nb-alert status="warning" closable (close)="onCloseAlert()">
                {{'LOGISTIC.LIST.ERRORONLAST' | translate}} <br />
                <span *ngFor="let msg of errorList">{{msg}} </span>
            </nb-alert>
        </div>

        {{'LOGISTIC.LIST.NODATA' | translate}}
    </nb-card-body>

    <nb-card-body [hidden]="!hasData || isWaiting">

        <div *ngIf="error" class="error">
            <nb-alert status="warning" closable (close)="onCloseAlert()">
                {{'LOGISTIC.LIST.ERRORONLAST' | translate}} <br />
                <span *ngFor="let msg of errorList">{{msg}} </span>
            </nb-alert>
        </div>

        <table mat-table [dataSource]="dataSource" matSort #table matSortActive="truck" matSortDirection="asc">

            <!-- Selected -->
            <ng-container matColumnDef="selected">
                <th class="" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <input type="checkbox" [(ngModel)]="element.checked"> -->
                </td>
            </ng-container>

            <!-- Viaggio -->
            <ng-container matColumnDef="name">
                <th class="" mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.LIST.TABLE.RUOTE' |
                    translate}} </th>
                <td mat-cell *matCellDef="let element" class="cell-trip"><a (click)="openTrip(element)">
                        {{element.routeId}} </a></td>
            </ng-container>

            <!-- Autobotte -->
            <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.LIST.TABLE.TRUCK' | translate}} </th>
                <td mat-cell *matCellDef="let element; let i = index" class="cell-truck"
                    [ngClass]="{'top-truck':isToptruck(element,i),'bottom-truck':isBottomtruck(element,i)}">
                    {{element.vehicleName}} {{element.towName?(' - ' + element.towName):''}}</td>
            </ng-container>

            <!-- Rimorchio -->
            <!-- <ng-container matColumnDef="tow">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.LIST.TABLE.TOW' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.towName}} </td>
            </ng-container> -->

            <!-- Autista -->
            <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.LIST.TABLE.DRIVER' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.driverFirstName}} {{element.driverLastName}}</td>
            </ng-container>

            <!-- ORDINI -->
            <ng-container matColumnDef="orders">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.LIST.TABLE.ORDERS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.orders?.length}} </td>
            </ng-container>

            <!-- Destinazioni -->
            <ng-container matColumnDef="destinations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.LIST.TABLE.DESTINATION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <span>{{fuelDepot(element)}} &nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <span *ngFor="let dest of element.destinations; let last = last">{{dest}} <span *ngIf="!last">
                            &nbsp;&nbsp;|&nbsp;&nbsp; </span></span>
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;{{fuelDepot(element,true)}} </span>
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="products">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.LIST.TABLE.PRODUCTS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> <span
                        *ngFor="let prod of productsList(element); let last = last">{{prod}} <span *ngIf="!last">
                            &nbsp;&nbsp;|&nbsp;&nbsp; </span></span></td>
            </ng-container>

            <!-- Riempimento autobotte -->
            <ng-container matColumnDef="filling">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.LIST.TABLE.FILLING' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="filling">
                    <div class="filling-bar">
                        <nb-progress-bar [value]="element.fillingPercentual" size="big" status="warning">
                        </nb-progress-bar>
                    </div>
                    <div class="filling-percentual">{{element.fillingPercentual}}% </div>

                </td>
            </ng-container>

            <!-- Actions  -->
            <ng-container matColumnDef="modify">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'LOGISTIC.LIST.TABLE.ACTIONS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="actions">
                        <div *ngIf="!element.confirmed">
                            <button nbButton class="action-button confirm-button" (click)="confirmTrip(element)"
                                *ngIf="showConfirmActions()">
                                <nb-icon icon="checkmark-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.CONFIRM' |
                                translate}}
                            </button>
                            <button nbButton class="action-button delay-button" (click)="delayTrip(element)">
                                <nb-icon icon="clock-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.DELAY' | translate}}
                            </button>                            
                        </div>
                        <div *ngIf="element.confirmed && showConfirmActions()">
                            <div *ngIf="element.tripStatus != 'in_transit' && element.tripStatus != 'delivered'"
                                class="confirmed">
                                <button nbButton class="action-button unconfirm-button"
                                    [ngClass]="{'fullWidth':!isToday}" (click)="unConfirmTrip(element)">
                                    <nb-icon icon="undo-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.UNCONFIRM' |
                                    translate}}
                                </button>
                                <button nbButton class="action-button start-button" (click)="startTrip(element)"
                                    *ngIf="isToday">
                                    <nb-icon icon="navigation-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.STARTTRUCK' |
                                    translate}}
                                </button>
                            </div>
                            <div *ngIf="element.tripStatus == 'in_transit'" class="delivering">
                                <div class="status-box">
                                    <nb-icon icon="car-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.DELIVERING' |
                                    translate}}
                                </div>
                            </div>
                            <div *ngIf="element.tripStatus == 'delivered'" class="delivered">
                                <div class="status-box">
                                    <nb-icon icon="home-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.DELIVERED' |
                                    translate}}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="element.confirmed && !showConfirmActions()">
                            <nb-icon icon="car-outline"></nb-icon> {{'LOGISTIC.LIST.TABLE.CONFIRMED' | translate}}
                        </div>
                    </div>
                </td>
            </ng-container>

            <!-- Remove  -->
            <ng-container matColumnDef="remove">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button nbButton ghost class="remove-button" (click)="removeTrip(element)"  *ngIf="!element.confirmed">
                        <nb-icon icon="trash-2-outline"></nb-icon>
                    </button>                      
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'confirmed':row.confirmed}"></tr>
        </table>

        <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator> -->

    </nb-card-body>
</nb-card>