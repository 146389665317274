<nb-layout>
    <nb-layout-column>
      <nb-card [size]="'small'">
        <!-- <nb-card-header>
          <nav class="navigation">
            <a href="#" (click)="back()" class="link back-link" aria-label="Back">
              <nb-icon icon="arrow-back"></nb-icon>
            </a>
          </nav>
        </nb-card-header> -->
        <nb-card-body>
          <nb-auth-block>
            <router-outlet></router-outlet>
          </nb-auth-block>
        </nb-card-body>
      </nb-card>

      <div class="forgot-container">
      <span class="label-with-link" *ngIf="isLogin()">
          <a class="forgot-password caption-2" routerLink="reset-password">{{'LOGIN.FORGOT' | translate}}</a>
        </span>
      </div>

      <div class="logo-quinn">
        <img src="/assets/images/logoquinn.png">
      </div>
    </nb-layout-column>
  </nb-layout>