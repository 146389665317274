import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { NbAccessChecker } from '@nebular/security';
import { UserService } from 'src/app/services/user.service';
import { agenda } from 'src/app/models/user.model';

@Component({
  selector: 'oil-sectionheader',
  templateUrl: './sectionheader.component.html',
  styleUrls: ['./sectionheader.component.scss']
})
export class SectionheaderComponent implements OnInit {

  public sections = [];

  private agenda: agenda;


  constructor(
    private router: Router,
    private location: Location,
    public accessChecker: NbAccessChecker,
    public userService: UserService
  ) {

    location.onUrlChange((val) => {
      // console.log('-------: SectionheaderComponent -> val', val);
      this.updateValues();
    });

    userService.agenda.subscribe(agenda => {
      this.agenda = agenda;
      this.updateValues();
    })

  }

  ngOnInit(): void {
    this.updateValues();

  }

  goto($event) {
    const path = $event.tabId;
    this.router.navigateByUrl(path).then(x => {
    });
  }

  public badgeNumber(counter) {
    if (counter > 99) {
      return '99+';
    }
    return counter;
  }

  async updateValues() {
    this.sections = [];
    const actualpath = this.location.path();
    for (let conf of this.router.config) {
      if (conf.path && actualpath.indexOf(conf.path) === 1 && conf.children) {
        for (let child of conf.children) {
          if (child.path) {
            let completepath = `${conf.path}/${child.path}`;
            let hidden = child.data && child.data.menuhidden;
            completepath = completepath.split(':')[0];


            let permission = child.data && child.data.permission ? child.data.permission : null;
            let resource = child.data && child.data.resource ? child.data.resource : null;

            // TODO fix: async call for a sync situation (push to array)
            this.accessChecker.isGranted(permission ?? 'x', resource ?? 'x').subscribe(res => {
              if (permission && permission !== null && resource) {
                hidden = !res;
              }

              let count = 0;
              if (child.data && child.data.agenda) {
                const checkElement = (element) => {
                  if (element.section && element.page && this.agenda && this.agenda[element.section] && this.agenda[element.section][element.page]) {
                    count = Math.max(this.agenda[element.section][element.page], count);
                    // count += this.agenda[element.section][element.page];
                  }
                }
                if (Array.isArray(child.data.agenda)) {
                  child.data.agenda.forEach(element => {
                    checkElement(element);
                  });
                } else { checkElement(child.data.agenda) }
              }
              this.sections.push({
                path: completepath,
                isSelected: 0 === actualpath.indexOf(`/${completepath}`),
                label: !hidden ? `SECTIONS.${conf.path.toUpperCase()}.${child.path.toUpperCase()}` : '',
                isVisible: !hidden,
                counter: count
              });
            })


            // if (!hidden && child.data && child.data.permission && child.data.resource) {
            //   console.log('-------: SectionheaderComponent -> updateValues -> child.data && child.data.permission && child.data.resource', child.data,this.accessChecker.isGranted(child.data.permission, child.data.resource),this.accessChecker.isGranted(child.data.permission, child.data.resource).toPromise());
            //   hidden = !<boolean>await this.accessChecker.isGranted(child.data.permission, child.data.resource).toPromise();  
            //   console.log('-------: SectionheaderComponent -> updateValues -> child.data.permission, child.data.resource', child.data.permission, child.data.resource, hidden);
            // }

            // this.sections.push({
            //   path: completepath,
            //   isSelected: 0 === actualpath.indexOf(`/${completepath}`),
            //   label: !hidden ? `SECTIONS.${conf.path.toUpperCase()}.${child.path.toUpperCase()}` : '',
            //   isVisible: !hidden
            // });
          }

        }
      }
    };
  }

}
