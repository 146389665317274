import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { SortPaginator } from 'src/app/models/generic.model';
import { User } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/admin.service';
import { ModalService } from 'src/app/services/modal.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  public dataSource;
  public filter = 'clients';
  public resultsLength: number;
  public clientPosition = 4;
  public clientColumn = 'client';
  public displayedColumns: string[] = [
    'email',
    'firstName',
    'lastName',
    'role',
    this.clientColumn,
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private adminService: AdminService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {

  }

  ngOnInit(): void {
    // this.refresh();

    if (this.sort) {
      this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0
      );
      merge(this.sort.sortChange, this.paginator.page).pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
      });
    }
  }

  refresh() {

    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

    const insertClientColumn = (insert: boolean) => {
      if (insert && this.displayedColumns[this.clientPosition] != this.clientColumn) {
        this.displayedColumns.splice(this.clientPosition, 0, this.clientColumn);
      }
      if (!insert && this.displayedColumns[this.clientPosition] == this.clientColumn) {
        this.displayedColumns.splice(this.clientPosition, 1);
      }
    }

    let roles = [];
    switch (this.filter) {
      case 'clients':
        roles = [config.userType.CLIENT];
        insertClientColumn(true);
        break;
      case 'drivers':
        roles = [config.userType.DRIVER];
        insertClientColumn(false);
        break;
      case 'workers':
        roles = Object.values(config.userType).filter(x => x != config.userType.CLIENT && x != config.userType.DRIVER);
        insertClientColumn(false);
        break;
      case 'all':
        roles = Object.values(config.userType)
        insertClientColumn(false);
        break;
    }

    this.adminService.getUsers(paginator, roles).subscribe(x => {
      this.dataSource = new MatTableDataSource<User>(x.items);
      this.resultsLength = x.total;
      // this.dataSource = new MatTableDataSource<User>(x.records);
      // this.resultsLength = x.rows;
    });
  }

  addUser() {
    this.modalService.newUser().pipe(take(1)).subscribe(res => {
      if (res) {
        this.refresh();
      }
    })
  }

  edit(selectedUser: User) {
    this.modalService.editUser(selectedUser).pipe(take(1)).subscribe(res => {
      if (res) {
        this.refresh();
      }
    })

  }

  delete(usr: User) {
    this.translate.get(['ADMIN.USERS.DELETEMODALTITLE', 'ADMIN.USERS.DELETEMODALTEXT'], { username: usr.email }).subscribe(t => {
      this.modalService.confirm(t['ADMIN.USERS.DELETEMODALTEXT'], t['ADMIN.USERS.DELETEMODALTITLE']).subscribe(res => {
        if (res) {
          this.adminService.deleteUser(usr.id).subscribe(() => {
            this.refresh();
          })
        }
      })
    })
  }

}
