import { Component, OnInit, Input, Output, SimpleChanges } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { CustomerDashboardFilter, multifilter } from 'src/app/models/customerfilter.model';
import { GenericFilterValue, GenericFilter } from 'src/app/models/generic.model';
import { CLUSTER_VALUES, CATEGORIES_VALUES, STATUS_VALUES, ORDERSTATUS_VALUES } from 'src/app/models/constants.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oil-customer-filter',
  templateUrl: './customer-filter.component.html',
  styleUrls: ['./customer-filter.component.scss']
})
export class CustomerFilterComponent implements OnInit {

  private activeFilters: multifilter[] = [];

  private storage = window.localStorage;

  @Input() filters: CustomerDashboardFilter[];
  
  AllFilters: {};

  @Input() startWithAll: boolean = false;

  @Output() selectedChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

    this.filters.forEach(filter => {
      const filterValue: multifilter = {
        name: filter.name,
        value: []
      };
      filter.values.forEach(elem => {
        if (this.startWithAll) {
          filterValue.value.push(elem.value);
        }
      })
      this.activeFilters.push(filterValue);

    })
    
    this.AllFilters = {
      CATEGORIES : CustomerFilterComponent.CATEGORIES().values,
      CLUSTER : CustomerFilterComponent.CLUSTER().values,
      STATUS : CustomerFilterComponent.STATUS().values,
      PUSHONLY : CustomerFilterComponent.PUSHONLY().values,
      ORDERSTATUS : CustomerFilterComponent.ORDERSTATUS().values,
    }

    this.setPersistedFilters();
  }

  isSelected(val: GenericFilterValue) {
    if (this.startWithAll) {
      return !!(typeof (val.isSelected) === 'undefined' || val.isSelected);
    }
    return val.isSelected;
  }

  toggle(filter: GenericFilter, val: GenericFilterValue) {
    val.isSelected = !this.isSelected(val);

    //check per il NON CLASSIFICATO
    if(val.value == 'NO-STATUS'){
      this.filters.map(filter => {
        filter.values.map(v => {
          if(v.value == 'NO-STATUS'){ v.isSelected = val.isSelected; }
        });
      }); 
    }
    
    this.applyFilter();

  }

  toggleOption(option){
    
    this.filters.map(filter => {
      filter.values.map(v => {
        v.isSelected = option.value == 'APPLY' ? true : false;
      });
    });   
    
    this.applyFilter();
    option.value = option.value == 'APPLY' ? 'REMOVE' : 'APPLY';

  }
  

  static filterForSelect(filt) {
    const val = filt();
    const ret = [];
    val.values.forEach(element => {
      ret.push({
        name: `FILTERS.${val.name}.${element.label}`,
        id: element.value
      })
    });
    return ret;
  }

  static CATEGORIES = () => {
    return {
      name: 'CATEGORIES',
      color: '#163970',
      values: [
        {
          label: 'HOME',
          value: CATEGORIES_VALUES.DOMESTICO,
          icon: 'cat-domestico'
        },
        {
          label: 'TRANSPORTS',
          value: CATEGORIES_VALUES.TRASPORTI,
          icon: 'cat-trasporti'
        },
        {
          label: 'AGRICULTURE',
          value: CATEGORIES_VALUES.AGRICOLO,
          icon: 'cat-agricolo'
        },
        {
          label: 'OTHERS',
          value: CATEGORIES_VALUES.ALTRI,
          icon: 'cat-altro'
        },
      ]
    }
  }

  static CLUSTER = () => {
    return {
      name: 'CLUSTER',
      color: '#E50046',
      values: [
        {
          label: 'TOP-CLIENT',
          value: CLUSTER_VALUES.TOPCLIENT,
          icon: 'cluster-top'
        },
        {
          label: 'HIGH-POTENTIAL',
          value: CLUSTER_VALUES.HIGHPOTENTIAL,
          icon: 'cluster-high'
        },
        {
          label: 'LOW-PROFIT',
          value: CLUSTER_VALUES.LOWPROFIT,
          icon: 'cluster-low'
        },
        {
          label: 'OCCASIONAL',
          value: CLUSTER_VALUES.OCCASIONAL,
          icon: 'cluster-occasional'
        },
        {
          label: 'NO-STATUS',
          value: CLUSTER_VALUES.NOSTATUS,
          icon: 'no-status'
        },
      ]
    }
  };


  static STATUS = () => {
    return {
      name: 'STATUS',
      color: '#F9B104',
      values: [
        {
          label: 'ACQUISIZIONE',
          value: STATUS_VALUES.ACQUISIZIONE,
          icon: 'status-acquisizione'
        },
        {
          label: 'UPSELLING',
          value: STATUS_VALUES.UPSELLING,
          icon: 'status-upselling'
        },
        {
          label: 'MANTENIMENTO',
          value: STATUS_VALUES.MANTENIMENTO,
          icon: 'status-mantenimento'
        },
        {
          label: 'CHURN-PREVENTION',
          value: STATUS_VALUES.CHURNPREVENTION,
          icon: 'status-churn'
        },
        {
          label: 'DA-RISVEGLIARE',
          value: STATUS_VALUES.DARISVEGLIARE,
          icon: 'status-risvegliare'
        },
        {
          label: 'CHIUSO',
          value: STATUS_VALUES.CHIUSO,
          icon: 'status-chiuso'
        },
        {
          label: 'NO-STATUS',
          value: STATUS_VALUES.NOSTATUS,
          icon: 'no-status'
        },
      ]
    }
  };


  static PUSHONLY = () => {
    return {
      name: 'PUSHONLY',
      color: '#31AA2B',
      values: [
        {
          label: 'REFUSE',
          value: '0',
          icon: 'no-push-notification'
        },
        {
          label: 'ACCEPT',
          value: '1',
          icon: 'push-notification'
        }
      ]
    }
  };


  static ORDERSTATUS = () => {
    return {
      name: 'ORDERSTATUS',
      color: '#4D4D4D',
      values: [
        {
          label: 'OUT',
          value: ORDERSTATUS_VALUES.INSUFFICIENT_CREDIT,
          icon: 'orderstatus-fuorifido'
        },
        {
          label: 'WAITING',
          value: ORDERSTATUS_VALUES.WAITING,
          icon: 'orderstatus-in-attesa'
        },
        {
          label: 'REJECTED',
          value: ORDERSTATUS_VALUES.REJECTED,
          icon: 'orderstatus-rifiutati'
        },
        {
          label: 'APPROVED',
          value: ORDERSTATUS_VALUES.APPROVED,
          icon: 'orderstatus-approvati'
        },
        {
          label: 'PROGRAMMED',
          value: ORDERSTATUS_VALUES.SCHEDULED,
          icon: 'orderstatus-programmati'
        },
        {
          label: 'DELIVERING',
          value: ORDERSTATUS_VALUES.IN_TRANSIT,
          icon: 'orderstatus-inconsegna'
        },
        {
          label: 'DELIVERED',
          value: ORDERSTATUS_VALUES.DELIVERED,
          icon: 'orderstatus-consegnato'
        },
      ]
    }
  };

  public option = {
   
    name: 'OPTION',
    color: '#61c4f3',
    values: [
      {
        label_APPLY: 'APPLY',
        label_REMOVE: 'REMOVE',
        icon_APPLY: 'funnel-outline',
        icon_REMOVE: 'trash-2-outline',
        isSelected: true,
        value: 'APPLY'
      }
    ]
    
  }

  public static addFilters(parameters, filters) {
    const booleanFilters = {};
    filters.forEach(f => {
        if (f.value[0] == '1' || f.value[0] == '0') {
            booleanFilters[f.name] = f.value
        } else {
            parameters[f.name.toLowerCase() + '[]'] = f.value;
        }
    })
    for (let p in booleanFilters) {
        const values = booleanFilters[p];
        if (values.length == 1) {
            parameters[p.toLowerCase()] = values[0] == '1' ? true : false
        }
    }

  }
  
  applyFilter(){
    this.filters.map(filter => {
      const filterSelected = [];
      
      filter.values.map((v, index) => {
        if(this.isSelected(v)){
          filterSelected.push(v.value);   
        }
        this.AllFilters[filter.name][index].isSelected = v.isSelected; 

      });  
      
      this.activeFilters.find(x => x.name === filter.name).value = filterSelected;
          
    }); 
    this.storage.setItem('persistent_filters', JSON.stringify(this.AllFilters));
    this.selectedChange.emit(this.activeFilters);  
  }

  checkIsSelectForOption(isSelect){
    if(isSelect){
      this.option.values[0].value = 'REMOVE';
    }
    else{
      //vedo se c'è almeno un filtro selezionato
      let find = false; 
      this.filters.map(filter => {

        filter.values.map(v => {
          if(v.isSelected){ find = true; }                     
        });

      });
      if(!find){ this.option.values[0].value = 'APPLY'; }
    }    
  }


  setPersistedFilters(){
    const persistedFilters = this.storage.getItem('persistent_filters');
    if(persistedFilters){
      this.AllFilters = JSON.parse(persistedFilters);
      let findFilterSelect = false;
      this.filters.map(f => {
        const filterSelected = [];
        
        f.values.map((value, index) => {
          value.isSelected = this.AllFilters[f.name][index].isSelected;
          if(this.isSelected(value)){
            filterSelected.push(value.value);  
            findFilterSelect = true; 
          }
        });

        if(findFilterSelect){ this.checkIsSelectForOption(true); }        
        this.activeFilters.find(x => x.name === f.name).value = filterSelected;
      });
       
    }
    //TODO: andrebbe fatto solo se ci sono dei filtri attivi, ma bisogna sistemare le pagine prima
    this.selectedChange.emit(this.activeFilters); 
  }
}
