import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbLayoutModule, NbIconModule, NbCheckboxModule, NbPopoverModule, NbInputModule, NbButtonModule } from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'src/app/components/component.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { UsersComponent } from './users/users.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbCardModule,
    NbLayoutModule,
    NbInputModule,
    NbButtonModule,
    NbIconModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbInputModule,    
    TranslateModule,
    ComponentModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
    MatButtonToggleModule,
    NgSelectModule,    
    NbMomentDateModule,
    MomentModule,
    PipesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [    
    UsersComponent
  ]
})
export class AdminModule {
}