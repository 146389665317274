import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthGuard } from './auth-guard.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, tap, timeInterval, timeout } from 'rxjs/operators';
import { LoadingService } from './loading.service';
import { NotifyService } from './notify.service';
import { of, AsyncSubject, Subject, Observable } from 'rxjs';
import { postEvent } from '../models/generic.model';
import * as Sentry from "@sentry/angular";
import { CookieService } from './cookie.service';



export interface ErrorResponse {
  headers: any;
  status: number;
  statusText: string;
  url: string;
  ok: boolean;
  name: string;
  message: string;
  error: Error;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_BASE: string = environment.baseurl;
  // API_BASE_ADMIN: string = environment.baseurl;
  // API_BASE_CLIENT: string = environment.baseurlclient;
  public translations;

  public postExecuted = new Subject<postEvent>();

  private alwaysUseMoks = false;
  private neverUseMocks = false;
  private mocks = [
    {
      url: 'calendar/',
      useMock: false,
      mockfile: 'assets/mocks/customereventsmock.json'
    },
    {
      url: 'clients/event/',
      useMock: false,
      mockfile: 'assets/mocks/customereventmock.json'
    },
    {
      url: 'orders/routes',
      useMock: false,
      mockfile: 'assets/mocks/orderstripmock.json'
    },
    {
      url: 'orders',
      useMock: false,
      mockfile: 'assets/mocks/orderslistsmock.json'
    },
    {
      url: 'products',
      useMock: false,
      mockfile: 'assets/mocks/productsmock.json'
    }
  ]

  constructor(
    private httpClient: HttpClient,
    private authGuard: AuthGuard,
    private loadingService: LoadingService,
    private notify: NotifyService,
    private translate: TranslateService,
    private cookieService: CookieService
  ) {
    setTimeout(() => {
      this.translate.get(['ERRORS.0', 'ERRORS.400', 'ERRORS.422', 'ERRORS.404', 'ERRORS.401', 'ERRORS.425', 'ERRORS.500', 'ERRORS.505'])
        .subscribe(t => {
          this.translations = t;
        })
    }, 0);


  }


  public call(path: string, method: string = 'GET', data: any = null, parameters: any = null, backgroundCall: boolean = false, blob: boolean = false): Observable<any>  {
    // console.log('---- ~ file: api.service.ts ~ line 81 ~ ApiService ~ call ~ path, method, data, parameters, backgroundCall, isAdmin -----------', path, method, data, parameters, backgroundCall, isAdmin);

    const myMock = this.mocks.find(x => path.indexOf(x.url) === 0);
    if (method === 'GET' && !this.neverUseMocks && myMock && (myMock.useMock || this.alwaysUseMoks)) {
      return this.httpClient.get(myMock.mockfile);
    }

    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    }

    if (this.authGuard.getToken()) {
      headers = Object.assign(headers, {
        Authorization: 'Bearer ' + this.authGuard.getToken(),
        Token: this.authGuard.getToken()
      })
    }

    if (blob) {
      headers = Object.assign(headers, {
        responseType: 'blob'
      })
    }

    const ServiceHeaders = new HttpHeaders(headers);


    if (!backgroundCall) {
      this.loadingService.start();
    }

    const handlingCall = (mycall, isPost = false, path = null) => {
      return mycall.pipe(
        tap(x => {
          if (!backgroundCall) {
            this.loadingService.stop();
          }
          if (isPost) {
            this.postExecuted.next({ callUrl: path });
          }
        }),
        catchError(backgroundCall ? this.handlingErrorNoNotify : this.handlingError));
    };

    const options = { headers: ServiceHeaders, params: parameters };
    // const baseUrl = isAdmin ? this.API_BASE_ADMIN : this.API_BASE_CLIENT;
    const baseUrl = this.API_BASE;
    switch (method.toUpperCase()) {
      case 'POST':
        return handlingCall(this.httpClient.post(`${baseUrl}/${path}`, data, options), true, path); 
      case 'DELETE':
        return handlingCall(this.httpClient.delete(`${baseUrl}/${path}`, options)); 
      case 'GET':
      default:
        return handlingCall(this.httpClient.get(`${baseUrl}/${path}`, options)); 
    }
  }

  public handlingErrorNoNotify = (error: HttpErrorResponse, input) => {
    return this.handleError(error, input, false);
  }

  public handlingError = (error: HttpErrorResponse, input) => {
    return this.handleError(error, input, true);
  }

  private handleError = (error: HttpErrorResponse, input, notify: boolean) => {

    console.error("API error", error, input);
    this.sentryErrorLogging(error, input);

    this.loadingService.stop();

    setTimeout(() => {
      if (notify && this.translations) {
        switch (error.status) {
          case 400:
            if (error.error.error.code) {
              this.translate.get(`ERRORS.${error.error.error.code}`).subscribe(tran => {
                this.notify.toast(tran, 'warning');
              });
            } else {
              this.notify.toast(this.translations[`ERRORS.400`] + ' ' + error?.message, 'danger');
            }
            break;
          case 0:
          case 404:
          case 500:
          case 505:
          case 422:
            this.notify.toast(this.translations[`ERRORS.${error.status}`] + ' ' + error?.message, 'danger');
            break;
          case 425:
            this.notify.toast(this.translations['ERRORS.425'], 'info', 6000);
            break;
          case 401:
            this.notify.toast(this.translations['ERRORS.401'], 'danger');
            // this.authGuard.logout();
            break;
          default:
            this.notify.toast(this.translations['ERRORS.default'], 'danger');
            break;
        }
      }
    })

    return of(error);

  }

  sentryErrorLogging(error: HttpErrorResponse, input) {
    if (this.cookieService.isAnalyticsEnabled()) {
      const sentryError = Object.assign({}, error, { error: JSON.stringify(error) });
      console.log('---- ~ file: api.service.ts ~ line 138 ~ ApiService ~ error.error.code', sentryError);
      Sentry.captureException(sentryError);
    }
  }

  public clearParameters(obj) {
    let res = {};
    for (let prop in obj) {
      if (obj[prop]) {
        res[prop] = obj[prop];
      }
    }

    return res;
  }



}
