<nb-layout *ngIf="fullStructure">

  <nb-layout-header fixed class="oil-header">
    <div class="logo-container">
      <img class="logo-img" src="/assets/images/company-logo.png">
      <!-- p class="company-name">There will be blood</p -->
    </div>
    <oil-header></oil-header>
  </nb-layout-header>

  <nb-layout-column [nbSpinner]="isLoading" nbSpinnerSize="giant">
    <nb-layout-header subheader class="section-header">
      <oil-sectionheader></oil-sectionheader>

    </nb-layout-header>
    <router-outlet *ngIf="fullStructure"></router-outlet>

  </nb-layout-column>

  <!-- <nb-layout-footer>
    footer
  </nb-layout-footer> -->

  <nb-sidebar class="oil-sidebar">
    <oil-sidebar></oil-sidebar>
    <nb-sidebar-footer class="footer" *ngIf="showVersion">
      <div><a class="cookie-detail-link" [routerLink]="'/auth/policy'">Privacy Policy</a></div>
      <div>version {{version.mayor}}.{{version.minor}}.{{version.build}}</div>
      <img class="footerImg" src="/assets/images/quinnasso.png">
    </nb-sidebar-footer>
  </nb-sidebar>

</nb-layout>

<router-outlet *ngIf="!fullStructure"></router-outlet>

<oil-cookies *ngIf="requireCookie"></oil-cookies>