<nb-card>
  <nb-card-body>

    <!-- <nb-select placeholder="{{'CUSTOMER.SEARCH.SELECTPLACEHOLDER' | translate}}" [nbPopover]="templateRef"
      nbPopoverTrigger="click" nbPopoverPlacement="bottom">
    </nb-select>

      
     -->

    <div class="search-filter">

      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-description>
            {{filterString}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="templateRef"></ng-container>
      </mat-expansion-panel>
      <button (click)="reset()" *ngIf="showReset">
        <nb-icon icon="close-circle-outline"></nb-icon>
      </button>

    </div>

    <ng-template #templateRef>

      <div class="searchfilter">

        <div class="line">
          <span class="label">{{'CUSTOMER.SEARCH.FILTER.NAME' | translate}}</span>
          <input class="outline-box" fullWidth type="text" nbInput [(ngModel)]="filter.name">
        </div>
        <div class="line">
          <span class="label">{{'CUSTOMER.SEARCH.FILTER.VAT' | translate}}</span>
          <input class="outline-box" fullWidth type="text" nbInput [(ngModel)]="filter.vat">
        </div>

        <div class="line">
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.REGION' | translate}}</span>
            <div class="outline-select">
              <ng-select [items]="regions" bindLabel="nome" bindValue="id" autofocus [(ngModel)]="filter.region"
                (change)="updateRegion($event)">
              </ng-select>
            </div>
          </div>
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.PROVINCE' | translate}}</span>
            <div class="outline-select">
              <ng-select [items]="provinces" bindLabel="nome" bindValue="id" autofocus [(ngModel)]="filter.province"
                (change)="updateProvince($event)" [disabled]="!provinces || provinces.length === 0">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.CITY' | translate}}</span>
            <!-- <div class="outline-select">
              <ng-select [items]="cities" bindLabel="nome" bindValue="id" autofocus [(ngModel)]="filter.city"
              [disabled]="!cities || cities.length === 0" (change)="updateCity($event)">
            </ng-select>
          </div> -->
            <input class="outline-box" [disabled]="!cities || cities.length === 0" type="text" nbInput
              [(ngModel)]="filter.district">
          </div>
        </div>
        <div class="line">
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.PRODUCT' | translate}}</span>
            <div class="outline-select">
              <ng-select [items]="menu.products" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="filter.productId">
              </ng-select>
            </div>
          </div>
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.CLUSTER' | translate}}</span>
            <div class="outline-select">
              <ng-select [items]="menu.clusters" bindLabel="name" bindValue="id" autofocus [(ngModel)]="filter.cluster">
              </ng-select>
            </div>
          </div>
        </div>
        <div class="line">
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.ORDERIMPORT' | translate}}</span>
            <div class="outline-select">
              <ng-select [items]="menu.orderOperators" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="filter.orderImportOperator">
              </ng-select>
            </div>
          </div>
          <div class="column">

            <span class="label">{{"GENERIC."+filter.orderImportCurrency | translate}}</span>
            <div class="normal-input">
              <input class="outline-box" type="text" nbInput [(ngModel)]="filter.orderImportVal">
            </div>
            <!--<div class="outline-currencies">
              <ng-select [items]="menu.currencies" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="filter.orderImportCurrency"> </ng-select>
            </div> -->


            <!--div class="column">
              <ng-select [items]="menu.currencies" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="filter.orderImportCurrency"> </ng-select>
            </div-->
          </div>
        </div>
        <!-- <div class="line">
          <div class="column">
            <span class="label">{{'CUSTOMER.SEARCH.FILTER.ORDERDATE' | translate}} <nb-icon
                icon="alert-triangle-outline" nbTooltip="Work in progress"></nb-icon></span>
            <div class="outline-select">
              <ng-select size="small" [items]="menu.dateSizes" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="filter.orderDateSize">
              </ng-select>
            </div>
          </div>
          <div class="column">
            <input class="outline-box" nbInput [nbDatepicker]="datepicker" [(ngModel)]="filter.orderDate">
            <nb-datepicker #datepicker>
              <nb-icon icon="calendar-outline"></nb-icon>
            </nb-datepicker>
          </div>
        </div> -->


        <div class="line">
          <div class="checkbox-container">
            <!-- <div class="search-checkbox">
              <nb-checkbox [(ngModel)]="filter.nonewcustomer">{{'CUSTOMER.SEARCH.FILTER.NONEWCUSTOMER' | translate}}
              </nb-checkbox>
              <nb-icon icon="alert-triangle-outline" nbTooltip="Work in progress"></nb-icon>
            </div> -->
            <div class="search-checkbox">
              <nb-checkbox [(ngModel)]="filter.includeWithoutOrders">
                {{'CUSTOMER.SEARCH.FILTER.NOWITHOUTORDER' | translate}}
              </nb-checkbox>
            </div>
          </div>
        </div>

        <div class="search-button">
          <button nbButton status="danger" (click)="search()">{{'CUSTOMER.SEARCH.FILTER.SEARCH' | translate}}</button>
        </div>
      </div>

    </ng-template>


    <table mat-table [dataSource]="dataSource" matSort #table>

      <!-- Checkbox -->
      <ng-container matColumnDef="selected">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <nb-checkbox (checkedChange)="toggleSelect(null,$event)"></nb-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <nb-checkbox [checked]="isSelected(element.id)" (checkedChange)="toggleSelect(element.id,$event)">
          </nb-checkbox>
        </td>
      </ng-container>

      <!-- Preferiti -->
      <ng-container matColumnDef="favorite">
        <th class="favorite" mat-header-cell *matHeaderCellDef>
          <nb-icon icon="star-on" pack="3logic">
          </nb-icon>
        </th>
        <td class="favorite" mat-cell *matCellDef="let element">
          <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic"  (click)="setFavorite(element)">
          </nb-icon>
        </td>
      </ng-container>

      <!-- id -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.ID' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
      </ng-container>

      <!-- Ragione sociale -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.NAME' | translate}} </th>
        <td class="company-name" mat-cell *matCellDef="let element" nbTooltip="{{'CTA.DETAILS'|translate}}"
          nbTooltipPlacement="left">
          <oil-company-name [element]="element" [id]="element.id" [hideDelivery]="true"></oil-company-name>
        </td>
      </ng-container>

      <!-- Partita IVA -->
      <ng-container matColumnDef="vat">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.VAT' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.vatCode}} </td>
      </ng-container>

      <!-- Città -->
      <ng-container matColumnDef="deliveryCity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.CITY' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryCity}} </td>
      </ng-container>

      <!-- Indirizzo deposito -->
      <ng-container matColumnDef="deliveryAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.ADDRESS' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryAddress}} </td>
      </ng-container>

      <!-- Prodotto -->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.PRODUCT' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.product}}</td>
      </ng-container>

      <!-- Data ultimo ordine -->
      <ng-container matColumnDef="lastOrderDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.SEARCH.TABLE.LASTORDERDATE' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.deliveryLastOrderDate | amDateFormat:'DD/MM/yy' }}</td>
      </ng-container>

      <!-- Quantità ultimo ordine -->
      <ng-container matColumnDef="lastOrderQty">
        <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.SEARCH.TABLE.LASTORDERQTY' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="order-qty">{{element.deliveryLastOrderQty | number | liter}}</div>
          <div class="order-date">{{element.deliveryLastOrderDate | amDateFormat:'DD/MM/yy' }}</div>
        </td>
      </ng-container>

      <!-- Call to action -->
      <ng-container matColumnDef="cta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let element">
          <oil-cta [customerId]="element.id" [pointId]="element.deliveryId"></oil-cta>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator pageSize="50" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>

  </nb-card-body>
</nb-card>