import { multifilter } from './customerfilter.model';
import { ApiResponse } from './generic.model';
import { IUserEntity } from './user.model';
import { Location } from './order.model';

export interface CustomerBase {
    id: number,
    name: string,

}
export interface BaseCustomerResult extends CustomerBase {
    code: string,
    vatCode: string,
    address: string,
    city: string,
    cap: string,
    province: string,
    regionCode: string,
    lastOrderDate: Date,

    user?: IUserEntity
}

export interface Customer extends BaseCustomerResult {
    distance: number,
    favorite: boolean,
    deliveryCode: string,
    monthlyOrders: number,


    deliveryAvgOrderQuantity: number,
    deliveryMaxOrderQuantity: number,
    clusterAvgOrder: number,
    clusterAvgOrderFreq: string,
    clusterAvgOrderNetMargin: number,
    paymentmode: string,
    deliveryAvgLatePayment: number,

    deliveryAvgOrderNetMargin: number,
    deliveryName: string,
    deliveryAddress: string,
    deliveryCity: string,
    deliveryLastOrderQty: number,
    deliveryLastOrderDate: Date,
    deliveryId:number

}

export interface CustomerDashboard extends Customer {
    deliveryTotalOrders: number,

    annualOrders: number,
    IntervalInDays: number,
    partialAnnualData: boolean
}

export interface CustomerSearch extends Customer {
    product: string,
}

export interface Policy {
    policy: string,
    accept: boolean
}


export interface CustomerDetail extends Customer {
    delivery_addresses: CustomerPoint[]
    remainingCredit: number,
    paymentcondition: string,
    note: string,
    haveRequestedPrice: boolean,
    policies?: Policy[]
}

export interface ClosePoint extends CustomerPoint {
    client_delivery_id: number,
    location: Location
    nearestDateDiff: number,
    products: null
}


export interface CustomerPoint {


    address_2: string,
    address_1: string,
    avgLatePayment: number,
    avgOrderNetMargin: string,
    cap: string,
    city: string,
    clientId: number,
    code: string,
    deleted: number,
    id: number,
    lat: number,
    long: number,
    name: string,
    province: string,
    salesAgentId: number,
    tags: tag[],


    monthlyOrders: number,
    monthpromotions: number,
    monthcompetitor: number,
    contacs: string[],

    label: string,
    events: CustomerEvent[],

    credit?: number,

    depot: DepotForPoint
    // extensionCost: [{cost: 0.009287671, date: "2021-03-15 17:09:48.000000", productCategory: "GAS.AUTO"}]
    paymentCondition: string
    paymentMethod: string
    secondary: number
    favorite?: boolean

    salesAgent?: IUserEntity

    orders_ranges: orderRange[]

    maxTruckSize: string;
    deliveryTime: deliveryTimePeriod;

    estimedDeliveryTime?: string;
}
export interface deliveryTimePeriod {
    name: string,
    deliveryTimeEnd: string,
    deliveryTimeStart: string,
}

export interface orderRange {
    category: string
    max_range: Date
    min_range: Date
}

export interface DepotForPoint {
    id: number,
    name: string,
    distance: number
}

export interface tag {
    name: string,
    type: string,
    category: string
}

export interface CustomerEvent {
    date: Date | string,
    id: number,
    customerId: number,
    customerName?: string,
    pointId: number,
    pointLabel?: string,
    note?: string,
}

export interface CustomerBaseApiResponse extends ApiResponse {
    items: CustomerBase[]
}

export interface CustomerDashboardApiResponse extends ApiResponse {
    items: CustomerDashboard[]
}

export interface clientCategory {
    total: number,
    category: string
}

export interface CustomerDailyApiResponse {
    newClients: number,
    totalClients: number,
    totalQuantity: number,
    activeClients: number,
    avgMargin: number,
    avgMarginWeight: number,
    categories: clientCategory[]
}

export interface CustomerSearchApiResponse extends ApiResponse {
    items: CustomerSearch[]
}

export interface CustomerEventApiResponse {
    previous: CustomerEventApiResponseList,
    current: CustomerEventApiResponseList,
    next: CustomerEventApiResponseList,
}

export interface CustomerEventApiResponseList extends ApiResponse {
    items: CustomerEvent[]
}

export enum ClientChartType {
    avgOrders = 'avgOrders',
    totalOrders = 'totalOrders',
    avgMargin = 'avgMargin',
    totalMargin = 'totalMargin',
    clientsQtyMargin = "clientsQtyMargin"
}


export enum ChartGroupBy {
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly'
}


export interface ChartInputs {
    startDate: moment.Moment,
    endDate: moment.Moment,
    groupBy: ChartGroupBy,
    favoriteonly?: boolean,
    filters: multifilter[]
}

export interface ChartAvgOrders {
    avgQty: number,
    avgNum: number,
    period: string,
    periodN: string
}

export interface ChartAvgOrdersApiResponse extends ApiResponse {
    items: ChartAvgOrders[]
}

export interface ChartTotalOrders {
    quantity: number,
    orders: number,
    clients: number,
    period: string,
    periodN: string
}

export interface ChartTotalOrdersApiResponse extends ApiResponse {
    items: ChartTotalOrders[]
}

export interface ChartAvgMargin {
    avgMargin: number,
    dev: number,
    period: string,
    periodN: string
}

export interface ChartAvgMarginApiResponse extends ApiResponse {
    items: ChartAvgMargin[]
}

export interface ChartTotalMargin {
    totalMargin: number,
    period: string,
    periodN: string
}

export interface ChartTotalMarginApiResponse extends ApiResponse {
    items: ChartTotalMargin[]
}

export interface ClientQtyMargin {
    qtyTotal: number,
    avgMargin: number,
    clientName: string
}

export interface ClientQtyMarginApiResponse extends ApiResponse {
    items: ClientQtyMargin[]
}