<nb-card size="big">
  <nb-card-body>
    <!-- <full-calendar deepChangeDetection="true" #calendar [options]="calendarOptions" ></full-calendar> -->

    <div>
      <div class="btn-group">
        <div class="actions-container">
          <button nbButton status="danger" size="small" class="actions-button" mwlCalendarPreviousView [view]="view"
            [(viewDate)]="viewDate" (viewDateChange)="refresh()">
            {{'CUSTOMER.CALENDAR.PREV'|translate}}
          </button>

          <button nbButton status="danger" size="small" class="actions-button" mwlCalendarNextView [view]="view"
            [(viewDate)]="viewDate" (viewDateChange)="refresh()">
            {{'CUSTOMER.CALENDAR.NEXT'|translate}}
          </button>

          <button nbButton status="danger" size="small" class="today-button" mwlCalendarToday [(viewDate)]="viewDate"
            (viewDateChange)="refresh()">
            {{'CUSTOMER.CALENDAR.TODAY'|translate}}
          </button>
        </div>

        <div class="calendar-date">
          {{ viewDate | calendarDate:(view + 'ViewTitle') }}
        </div>

        <div class="buttonadd-container">
          <button nbButton status="danger" size="small" class="today-button" (click)="addEvent()">
            {{'CUSTOMER.CALENDAR.ADD'|translate}}
          </button>
        </div>
      </div>
    </div>

    <ng-template #customCellTemplate let-day="day" let-locale="locale">
      <div class="cal-cell-top" (mouseleave)="day.over = false" (mouseenter)="day.over = true">
        <button  nbButton status="danger" size="small" class="add-event" (click)="addEventDay(day.date)" *ngIf="day.over">
          {{'CUSTOMER.CALENDAR.ADD'|translate}} </button>
        <div class="cal-day-number">
          <span>{{ day.date | calendarDate:'monthViewDayNumber':locale }}</span></div>
      </div>
      <div class="event-container">
        <div class="cal-cell-event" *ngFor="let event of day.events" (click)="editEvent(event)"> {{ event.title }}
        </div>
      </div>
    </ng-template>


    <mwl-calendar-month-view [viewDate]="viewDate" [events]="events" [cellTemplate]="customCellTemplate">
    </mwl-calendar-month-view>

  </nb-card-body>
</nb-card>