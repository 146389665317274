import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'oil-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public user = '';
  public img = '';
  public role = '';
  public email = '';

  constructor(
    private userService: UserService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.userService.getMe(true).subscribe(u => { if (!u) { return } this.user = u.name; this.img = u.img; this.role = u.role; this.email = u.email; })
    }, 1)

  }

}
