import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule,  NbIconModule, NbTooltipModule, NbSpinnerModule, NbCheckboxModule } from '@nebular/theme';

import { TranslateModule } from '@ngx-translate/core';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { ListinoComponent } from './listino/listino.component';
import { CompetitorComponent } from './competitor/competitor.component';
import { RequestapprovalComponent } from './requestapproval/requestapproval.component';
import { ApprovalComponent } from './approval/approval.component';
import { ComponentModule } from 'src/app/components/component.module';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbCardModule,
    // NbLayoutModule,
    // NbSelectModule,
    NbIconModule,
    NbCheckboxModule,
    // NbPopoverModule,    
    // NbDatepickerModule,
    NbSpinnerModule,
    NbTooltipModule,
    TranslateModule,
    ComponentModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    NgSelectModule,
    MomentModule,
    PipesModule,
    NgxEchartsModule.forRoot({
      echarts: () => {
        return import('echarts')
      }
    })
    ],
  declarations: [
    ListinoComponent,
    CompetitorComponent,
    RequestapprovalComponent,
    ApprovalComponent
  ],
})
export class PrezziModule {
}
