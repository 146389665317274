import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Moment } from 'moment';
import { modalTruckResponse, Truck, TrucksResponse } from 'src/app/models/logistic_model';
import { LogisticService } from 'src/app/services/logistic.service';

@Component({
  selector: 'oil-modal-select-truck',
  templateUrl: './modal-select-truck.component.html',
  styleUrls: ['./modal-select-truck.component.scss']
})
export class ModalSelectTruckComponent implements OnInit {

  public date: Moment;

  public truck: string;
  public tow: string;

  public trucks: Truck[];
  public tows: Truck[];

  constructor(
    protected ref: NbDialogRef<ModalSelectTruckComponent>,
    private logisticService: LogisticService
  ) { }

  ngOnInit(): void {
    this.refresh();
  }

  refresh() {
    this.logisticService.getTrucksShortList(this.date).subscribe((res: TrucksResponse) => {
      this.trucks = [];
      this.tows = [];
      res.items.forEach((vehicle: Truck) => {
        if (vehicle.targaMotrice) {
          this.tows.push(vehicle);
        } else {
          this.trucks.push(vehicle);
        }
      });
    })
  }

  isValid() {
    return !!this.truck;
  }

  ok() {
    const res: modalTruckResponse = {
      truck: this.truck,
      tow: this.tow
    }
    this.ref.close(res);
  }

  cancel() {
    this.ref.close();
  }

}
