<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected">
</oil-dateribbon>

<div class="daily">
    <div class="daily-row">
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">{{'PRICES.LIST.DAILY.MARGINTOTAL' | translate}}
                        <span class="daily-subtitle">({{'PRICES.LIST.DAILY.MARGINTOTALSUB' | translate}})</span>
                    </div>
                    <img src="/assets/icons/card/prezzi-margine-totale.svg" alt="trip icon" class="daily-icon" />
                    <oil-daily-chart class="daily-chart" [input]='chartInput' [chartType]="'stacked'"></oil-daily-chart>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'PRICES.LIST.DAILY.MARGINAVG' | translate}}
                        <span class="daily-subtitle">({{'PRICES.LIST.DAILY.MARGINAVGSUB' | translate}})</span>
                        <span class="daily-righttitle">{{'PRICES.LIST.DAILY.MARGINAVGRIGHT' | translate}}</span>
                    </div>
                    <div class="avgmargin-container" echarts [options]="avgMarginChartOptions" [loading]="isLoading">
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5-doble">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'PRICES.LIST.DAILY.PLATT' | translate}}
                        <span class="daily-subtitle">({{'PRICES.LIST.DAILY.PLATTSUB' | translate}})</span>
                    </div>
                    <div class="platt">
                        <img src="/assets/icons/card/prezzi-platts.svg" alt="trip icon" class="platt-card" />
                        <div class="platt-card" *ngFor="let platt of platts">
                            <div class="platt-title">{{'PRICES.PLATT.'+ plattsCode(platt.id) | translate}}</div>
                            <div class="platt-value">{{platt.value * 1000| number}}</div>
                            <div [ngClass]="{'negative':platt.delta<0}"
                                nbTooltip="{{'PRICES.LIST.DAILY.PLATTTOOLTIP'|translate}}" class="platt-delta">
                                {{platt.delta>0?'+':''}} {{platt.delta * 1000| number}}</div>
                        </div>
                    </div>
                    <!-- <div class="daily-value">
                        <span>{{trucksNumber}}</span>
                    </div> -->
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'PRICES.LIST.DAILY.SOLD' | translate}}
                        <span class="daily-righttitle">{{'PRICES.LIST.DAILY.SOLDRIGHT' | translate}}</span>
                    </div>
                    <img src="/assets/icons/card/autobotte-semipiena.svg" alt="trip icon" class="daily-icon" />
                    <div class="daily-value">
                        <span nbTooltip="{{sold | number}}">{{sold | smallNumber : 'value'}}
                            <span class="daily-value-post">{{sold | smallNumber : 'order'}}</span></span>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>

    </div>
</div>

<nb-card size="big">

    <nb-card-body *ngIf="isWaiting && hasLoaded">
        {{'PRICES.LIST.NORESULT' | translate}}
        <button nbButton class="tryagain-btn" (click)="refresh()">{{'PRICES.LIST.TRYAGAIN' | translate}}</button>
    </nb-card-body>

    <ng-container *ngIf="!hasPrice && !isWaiting && !isToday() && hasLoaded">
        {{'PRICES.LIST.NODATA' | translate}}
    </ng-container>

    <ng-container *ngIf="!hasPrice && !isWaiting && isToday() && hasLoaded">
        <nb-card-body class="create">
            <div class="intro">
                {{'PRICES.LIST.NOCREATE' | translate}}
            </div>
            <div class="line">
                <span class="label">{{'PRICES.LIST.PLATTSRISC' | translate}}</span>
                <input status="basic" type="number" min="0" max="10" step="0.001" nbInput
                    [(ngModel)]="priceModel.plattsRisc">
            </div>
            <div class="line">
                <span class="label">{{'PRICES.LIST.PLATTSAUTO' | translate}}</span>
                <input status="basic" type="number" min="0" max="10" step="0.001" nbInput
                    [(ngModel)]="priceModel.plattsAuto">
            </div>
            <div class="line">
                <span class="label">{{'PRICES.LIST.PLATTSAGR' | translate}}</span>
                <input status="basic" type="number" min="0" max="10" step="0.001" nbInput
                    [(ngModel)]="priceModel.plattsAgricolo">
            </div>
            <div class="line">
                <span class="label">{{'PRICES.LIST.PLATTSBENZ' | translate}}</span>
                <input status="basic" type="number" min="0" max="10" step="0.001" nbInput
                    [(ngModel)]="priceModel.plattsBenzina">
            </div>

            <table class="generate-table">
                <tr>
                    <th>{{'PRICES.LIST.GENERATE.PRODUCT' | translate}}</th>
                    <th *ngFor="let value of priceModel?.manual_margins[0].ranges">
                        <span *ngIf="value.max_range > 0">{{'PRICES.LIST.GENERATE.RANGE' |
                            translate}}{{value.max_range}}</span>
                        <span *ngIf="value.max_range <= 0">{{'PRICES.LIST.GENERATE.MAXRANGE' |
                            translate}}</span>
                    </th>
                </tr>

                <tr *ngFor="let product of priceModel.manual_margins">
                    <td >{{product.name}}</td>
                    <td *ngFor="let value of product?.ranges">
                        <input status="basic" type="number" min="0" max="10" step="0.001" nbInput
                            [(ngModel)]="value.value">
                    </td>
                </tr>
            </table>

        </nb-card-body>

        <nb-card-footer class="modal-footer">
            <div class="actions">
                <button nbButton class="actions-button" size="large" [disabled]="!isValid()"
                    (click)="calculatePrices()">{{'PRICES.LIST.CALCULATE' | translate}}</button>
            </div>
        </nb-card-footer>

    </ng-container>

    <nb-card-body [hidden]="!hasPrice || isWaiting || !hasLoaded">
        <div *ngIf="customer">
            <div class="priceforcustomer">{{'PRICES.LIST.PRICEFOR' | translate}}
                <strong>{{customer.name}}</strong>
                <span class="priceforcustomer" *ngIf="distributionPoint">
                    {{distributionPoint.address_1}} - {{distributionPoint.city}}
                </span>
                <a [routerLink]="'/prices/list'">({{'PRICES.LIST.SEEALL' | translate}})</a>
            </div>

        </div>

        <div class="customer-filter" *ngIf="!customer">
            <span class="label">{{'PRICES.LIST.CUSTOMER' | translate}}</span>

            <input fullWidth #autoInput nbInput type="text" (ngModelChange)="onChangeClientInput($event)"
                [matAutocomplete]="auto" [ngModel]="client" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="viewHandle">
                <mat-option *ngIf="isLoadingSuggestion" class="is-loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    <span>{{ option?.name }}</span>
                </mat-option>
            </mat-autocomplete>

            <div>
                <nb-checkbox (checkedChange)="onChangeFavoriteOnly($event)" [(ngModel)]="favoriteonly">
                    {{'PRICES.LIST.FAVORITEONLY' | translate}}
                </nb-checkbox>
                <nb-icon icon="star-on" pack="3logic" class="listino-filter-icon-start"> </nb-icon>
            </div>
            <div>
                <nb-checkbox (checkedChange)="onChangeRequestOnly($event)" [(ngModel)]="requestOnly">
                    {{'PRICES.LIST.REQUESTONLY' | translate}}
                </nb-checkbox>
                <nb-icon icon="listino-on" pack="3logic" class="listino-filter-icon-request"> </nb-icon>
            </div>
        </div>

        <div class="filters" *ngIf="!customer">

            <oil-customer-filter [filters]="filters" (selectedChange)="filterChange($event)">

            </oil-customer-filter>
        </div>

        <table mat-table [dataSource]="dataSource" matSort #table>


            <!-- Preferiti -->
            <ng-container matColumnDef="favorite">
                <th class="favorite" mat-header-cell *matHeaderCellDef>
                    <nb-icon icon="star-on" pack="3logic">
                    </nb-icon>
                </th>
                <td class="favorite" mat-cell *matCellDef="let element">
                    <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic"
                        (click)="setFavorite(element)">
                    </nb-icon>
                </td>
            </ng-container>


            <!-- price requested -->
            <ng-container matColumnDef="request">
                <th mat-header-cell *matHeaderCellDef>
                    <nb-icon class="euro" icon="listino-on" pack="3logic">
                    </nb-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    <nb-icon class="euro" [icon]="element.haveRequestedPrice?'listino-on':'listino-off'" pack="3logic">
                    </nb-icon>
                </td>
            </ng-container>

            <!-- Ragione sociale -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'PRICES.LIST.TABLE.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <oil-company-name [element]="element" [id]="element.clientId"></oil-company-name>
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> {{ 'PRICES.LIST.TABLE.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product_name}}</td>
            </ng-container>

            <!-- Fido residuo  -->
            <ng-container matColumnDef="fido">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.LIST.TABLE.FIDO' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit"
                    [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                    {{element.remainingCredit | currency:'€'}}</td>
            </ng-container>

            <!-- Prezzo proposto-->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.LIST.TABLE.PRICPROPOSED' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'price-proposed':isFinalPrice(element,element.price_proposed)}">
                    {{element.price_proposed | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>

            <!-- Prezzo calcolato-->
            <ng-container matColumnDef="price_computed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.LIST.TABLE.PRICECOMPUTED' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"
                    [ngClass]="{'price-price_computed':isFinalPrice(element,element.price_computed)}">
                    {{element.price_computed | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>

            <!-- Range -->
            <ng-container matColumnDef="range">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.LIST.TABLE.RANGE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.price_min | currency:'€': 'symbol' : '1.3-3'}} -
                    {{element.price_max | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>

            <!-- Modifica  -->
            <ng-container matColumnDef="modify">
                <th mat-header-cell *matHeaderCellDef>{{ 'PRICES.LIST.TABLE.MODIFY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="isToday()">
                        <button *ngIf="showBtnModify(element) && !element.modifyPrice"
                            (click)="element.modifyPrice=true ;element.price_edit=element.price_proposed">{{'PRICES.LIST.TABLE.MODIFYBTN'
                            | translate}}</button>
                        <div *ngIf="element.modifyPrice" class="modify">
                            <input class="outline-box" type="number" step="0.001" nbInput
                                [(ngModel)]="element.price_edit"> €
                            <nb-icon icon="checkmark-circle-2-outline" (click)="sendPrice(element)"
                                class="modify-accept" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                            <nb-icon icon="close-circle-outline" (click)="cancelModify(element)" class="modify-refuse"
                                [options]="{ animation: { type: 'pulse' } }">
                            </nb-icon>

                        </div>
                        <div class="modified status" *ngIf="showPriceModify(element)">
                            <span class="{{priceClass(element)}} modified"
                                [ngClass]="{'price-proposed':isFinalPrice(element,element.price_modified)}">{{element.price_modified
                                |
                                currency:'€': 'symbol' : '1.3-3'}}</span>
                            <nb-icon icon="close-circle-outline" (click)="refusePrice(element)" class="modify-refuse"
                                [options]="{ animation: { type: 'pulse' } }">
                            </nb-icon>
                        </div>
                    </div>
                    <div *ngIf="!isToday()">
                        <span class="price_approved"
                            [ngClass]="{'price-proposed':isFinalPrice(element,element.price_approved)}">{{element.price_approved
                            | currency:'€': 'symbol' : '1.3-3'}}</span>
                    </div>

                </td>
            </ng-container>


            <!-- Margine -->
            <ng-container matColumnDef="calculated_margin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.LIST.TABLE.MARGIN' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="price-proposed"
                    [ngClass]="{'marginNegative':actualMargin(element)<0}"> {{actualMargin(element) |
                    currency:'€':'symbol' : '1.3-3'}}</td>
            </ng-container>


            <!-- Prezzo inviato -->
            <ng-container matColumnDef="priceseleted">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'PRICES.LIST.TABLE.PRICESELECTED' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.price_sent_to_client | currency:'€': 'symbol' :
                    '1.3-3'}}</td>
            </ng-container>

            <!-- Prezzo promozione -->
            <ng-container matColumnDef="pricepromotion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'PRICES.LIST.TABLE.PRICEPROMOTION' | translate}}
                </th>
                <td mat-cell class="promotional" *matCellDef="let element"
                    [ngClass]="{'price-proposed':isFinalPrice(element,element.price_promotional)}">
                    {{element.quantity_promotional ? element.price_promotional : null | currency:'€': 'symbol' :
                    '1.3-3'}}</td>
            </ng-container>

            <!-- Invio prezzo -->
            <ng-container matColumnDef="sendprice">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let element">
                    <button nbButton *ngIf="isToday()" [ngClass]="{'no-privacy':!element.push_notification}"
                        (click)="send(element.id,!element.push_notification)" class="actions"> {{
                        'PRICES.LIST.TABLE.SEND' | translate}}</button>
                </td>
            </ng-container>
            <!-- Prezzo concorrenza -->
            <ng-container matColumnDef="competitor">
                <th mat-header-cell *matHeaderCellDef>

                </th>
                <td mat-cell *matCellDef="let element">
                    <button nbButton *ngIf="isToday()" (click)="competitor(element)" class="actions"> {{
                        'PRICES.LIST.TABLE.COMPETITOR' | translate}}</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator pageSize="50" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>

        <div class="legend-container">

            <div class="status" *ngFor="let status of ['not_sent','sent','approved','not_approved']">
                <div class="legend legend-box {{status}}"></div>
                <span class="legend">{{'PRICES.LEGEND.' + status.toUpperCase() | translate}}</span>
            </div>

            <!-- <div class="status">
                <div class="legend legend-box no-privacy"></div>
                <span class="legend">{{'PRICES.LEGEND.NOPRIVACY' | translate}}</span>
            </div> -->

            <div class="actions" *ngIf="!customerId">
                <!-- <button nbButton class="actions-button" size="large" (click)="send()">
                    {{'PRICES.LIST.SEND' | translate}}
                </button> -->
                <button nbButton class="actions-button light" size="large" (click)="reset()">
                    {{'PRICES.LIST.RESET' | translate}}
                </button>
            </div>
        </div>


    </nb-card-body>
</nb-card>