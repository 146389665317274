import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { Customer, CustomerDetail } from 'src/app/models/customer.model';
import { SortPaginator } from 'src/app/models/generic.model';
import { Price } from 'src/app/models/price.model';
import { CustomerService } from 'src/app/services/customer.service';
import { ModalService } from 'src/app/services/modal.service';
import { PriceService } from 'src/app/services/price.service';
import { UserService } from 'src/app/services/user.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-client-prices',
  templateUrl: './client-prices.component.html',
  styleUrls: ['./client-prices.component.scss']
})
export class ClientPricesComponent implements OnInit {


  public selected = moment();
  public dateFilter = moment(this.selected);

  public dataSource;

  public todayHasPrice = false;

  public paginatedList = true;

  public customer: CustomerDetail;

  public resultsLength: number;

  public promotionExpire: Date = null;

  public displayedColumns: string[] = [
    'name',
    'product',
    'priceseleted',
    'pricepromotion',
    'date',
    'action'
  ];

  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  constructor(
    private priceService: PriceService,
    // private translate: TranslateService,    
    // private customerService: CustomerService
    private modalService: ModalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.userService.getMeAsClient().subscribe(customer => {
      this.customer = customer;
    })

    this.refresh();

    // this.sort.sortChange.subscribe(() => { }//this.paginator.pageIndex = 0
    // );
    // merge(this.sort.sortChange)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.refresh();
    //       return of([]);
    //     })
    //   ).subscribe(data => {
    //   });

  }


  refresh() {
    const paginator: SortPaginator = {
      orderBy: null,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.paginatedList) {
      this.priceService.getClientPriceListHistory(paginator).subscribe(x => {
        this.dataSource = new MatTableDataSource<Price>(x.items);
        this.resultsLength = x.total;
        this.todayHasPrice = x.items && !!x.items.find(x => moment(x.date).isSame(moment(), 'day'));
        this.promotionExpire = x.promotionExpire;
      });

    } else {
      this.priceService.getClientPriceListByDate(this.dateFilter.format(config.apiDateFormat)).subscribe(x => {
        this.dataSource = new MatTableDataSource<Price>(x.items);
        this.resultsLength = x.total;
        this.todayHasPrice = !!x.items.find(x => moment(x.date).isSame(moment(), 'day'));
        this.promotionExpire = x.promotionExpire;
      });
    }


  }

  selectedDateChange(date) {
    console.log('-------: OrderlistComponent -> selectedDateChange -> date', date);
    this.dateFilter = date;
    this.refresh();
  }

  addOrder(price: Price) {
    this.modalService.createClientOrder(price, this.customer).pipe(take(1)).subscribe(() => {

    });
  }

  requestPrice() {
    this.priceService.requestPrice().subscribe(() => {
      this.customer.haveRequestedPrice = true;
    })
  }

  isToday(price: Price = null) {
    return moment().isSame(price ? moment(price.date) : this.dateFilter, 'day');
  }

  public isPromotionActive(price: Price) {
    return price.quantity_promotional && moment().isSame(moment(price.date), 'day');
  }


  public remainingtime() {
    return moment(this.promotionExpire).from(moment());
  };

  hasValidPrice(price : Price){
    return price.price_sent_to_client || (price.quantity_promotional && moment().isBefore(this.promotionExpire));
  }

}
