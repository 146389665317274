<div class="send-email" *ngIf="!code && !response">
    <div class="title">{{'LOGIN.RESETPWD.TITLEEMAIL' | translate}}</div>

    <label>{{'LOGIN.RESETPWD.EMAIL' | translate}}</label>
    <input fullWidth nbInput type="text" [(ngModel)]="email" />

    <div class="error" *ngIf="error">{{error}}</div>

    <div class="actions">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValidSend()"
            (click)="send()">{{'LOGIN.RESETPWD.OK' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'LOGIN.RESETPWD.CANCEL' |
            translate}}</button>
    </div>
</div>

<div *ngIf="response" class="response">
    <div *ngIf="response=='sent'"> {{'LOGIN.RESETPWD.EMAILSENT' | translate}}</div>
    <div *ngIf="response=='reset'"> {{'LOGIN.RESETPWD.RESETDONE' | translate}}</div>
    
    <div class="actions">
    <button nbButton class="modal-buttons" (click)="cancel()">{{'LOGIN.RESETPWD.GOLOGIN' |
        translate}}</button>
    </div>
</div>

<div class="reset-password" *ngIf="code && !response">
    <div class="title">{{'LOGIN.RESETPWD.TITLERESET' | translate}}</div>

    <label>{{'LOGIN.RESETPWD.NEWPWD' | translate}}</label>
    <input fullWidth nbInput type="password" [(ngModel)]="newpwd" />
    <label>{{'LOGIN.RESETPWD.NEWPWD2' | translate}}</label>
    <input fullWidth nbInput type="password" [(ngModel)]="newpwd2" />

    <div class="error" *ngIf="error">{{error}}</div>

    <div class="actions">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValidReset()"
            (click)="reset()">{{'LOGIN.RESETPWD.OK' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'LOGIN.RESETPWD.CANCEL' |
            translate}}</button>
    </div>
</div>