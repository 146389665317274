import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'oil-trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit {

  public selected = CalendarService.nextWorkingDay();
  public dateFilter = moment(this.selected);

  constructor() { }

  ngOnInit(): void {
  }

  

  selectedDateChange(date) {
    this.dateFilter = date;
  }

}
