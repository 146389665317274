<nb-card>
    <nb-card-header>{{'PRICES.LIST.MODALCOMPETITOR.TITLE' | translate}}</nb-card-header>

    <nb-card-body>
        <div class="input-div">
            <label>{{'PRICES.LIST.MODALCOMPETITOR.PRICELABEL' | translate}}</label>
            <input fullWidth #autoInput nbInput type="number" min="0" max="10" step="0.001"
                [(ngModel)]="competitorPrice" />
            {{'PRICES.LIST.MODALCOMPETITOR.MARGIN' | translate}}
            <strong>{{newMargin() | number}}</strong>
        </div>
        <div class="input-div">
            <label>{{'PRICES.LIST.MODALCOMPETITOR.NOTELABEL' | translate}}</label>
            <input fullWidth nbInput type="text" [(ngModel)]="note" />
        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="send()">{{'PRICES.LIST.MODALCOMPETITOR.SEND' | translate}}</button>
        <button nbButton class="modal-buttons right" (click)="cancel()">{{'PRICES.LIST.MODALCOMPETITOR.CANCEL' |
            translate}}</button>
    </nb-card-footer>
</nb-card>