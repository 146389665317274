import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User, agenda, MeResult } from '../models/user.model';
import { ApiService } from './api.service';
import { AuthGuard } from './auth-guard.service';
import { Md5 } from 'ts-md5/dist/md5';
import { config } from 'src/environments/config';
import { postEvent } from '../models/generic.model';
import { Moment } from 'moment';
import { StatusService } from './status.service';
import { Customer, CustomerDetail } from '../models/customer.model';



@Injectable({
    providedIn: 'root'
})
export class UserService {

    private me: User;
    private meAsClient: CustomerDetail;
    private actualAgenda: agenda;

    private timeout;

    public agenda: Subject<agenda> = new Subject<agenda>();

    constructor(
        private api: ApiService,
        private authService: AuthGuard,
        private statusService: StatusService
    ) {
        this.initialize();
    }

    public initialize() {

        // if (typeof this.api.postExecuted.subscribe === "function") this.api.postExecuted.unsubscribe();
        // if (typeof this.statusService.onUpdateSelectedDate.subscribe === "function") this.statusService.onUpdateSelectedDate.unsubscribe();
        clearInterval(this.timeout);


        this.timeout = setInterval(() => {
            this.updateAgendaRangeDate(this.statusService.getSelectedDate(), this.statusService.getLastWeekDay());
        }, config.agendaPolling);

        this.api.postExecuted.subscribe((x: postEvent) => {
            this.updateAgendaRangeDate(this.statusService.getSelectedDate(), this.statusService.getLastWeekDay());
        }
        )

        this.statusService.onUpdateSelectedDate.subscribe(date => {
            this.updateAgendaRangeDate(this.statusService.getSelectedDate(), this.statusService.getLastWeekDay());
        });

        this.updateAgendaRangeDate(this.statusService.getSelectedDate(), this.statusService.getLastWeekDay());

    }

   /*  private updateAgenda(date: Moment) {
        if (!this.authService.isLoggedin()) return of(null);
        return this.api.call(this.statusService.isAdmin ? `admin/user/agenda/` : `client/user/agenda/`, 'GET', null, { date: date.format(config.apiDateFormat) }, true).subscribe((newAgenda: agenda) => {

            if (newAgenda && JSON.stringify(newAgenda) !== JSON.stringify(this.actualAgenda)) {
                this.actualAgenda = newAgenda;
                this.agenda.next(newAgenda);
            }
        });
    } */

    public updateAgendaRangeDate(rangeStart: Moment, rangeEnd: Moment): Observable<agenda> {
        if (!this.authService.isLoggedin()) return of(null);
        return this.api.call(this.statusService.isAdmin ? `admin/user/agenda/` : `client/user/agenda/`, 'GET', null, { date: rangeStart.format(config.apiDateFormat), rangeStart: rangeStart.format(config.apiDateFormat), rangeEnd: rangeEnd.format(config.apiDateFormat) }, true).pipe(map((newAgenda: agenda) => {
        //return this.api.call(this.statusService.isAdmin ? `admin/user/agenda/` : `client/user/agenda/`, 'GET', null, { date: rangeStart.format(config.apiDateFormat) }, true).pipe(map((newAgenda: agenda) => {

            if (newAgenda && JSON.stringify(newAgenda) !== JSON.stringify(this.actualAgenda)) {
                this.actualAgenda = newAgenda;
                this.agenda.next(newAgenda);
            }
            return newAgenda;
        }));
    }

    public getMe(forceRefresh = false): Observable<User> {

        this.updateAgendaRangeDate(this.statusService.getSelectedDate(), this.statusService.getLastWeekDay());

        if (!this.authService.isLoggedin()) return of(null);

        if (this.me && !forceRefresh) {
            return of(this.me);
        }
        return this.api.call(this.statusService.isAdmin ? 'cms/user/me' : 'client/user/me', 'GET', null, null, true,).pipe(map((x: User | MeResult) => {
            let myself: User;
            if (!this.statusService.isAdmin) {
                myself = (x as MeResult).userProfile;
                this.meAsClient = (x as MeResult).clientProfile;
            } else {
                myself = x as User;
            }

            const md5 = (myself && myself.email) ? Md5.hashStr((myself.email).toLowerCase().trim()) : '';
            if (myself) {
                myself.img = `https://www.gravatar.com/avatar/${md5}`;
                // myself.role = myself.roles.join(',');
                myself.name = `${myself.firstName ? myself.firstName : ''} ${myself.lastName ? myself.lastName : ''}`;
                this.me = myself;
            }

            return myself;
        }));
    }

    public iMClient(): boolean {
        return !!this.meAsClient;// && !this.me;
    }


    public getMeAsClient(forceRefresh = false): Observable<CustomerDetail> {

        if (this.meAsClient && !forceRefresh) {
            return of(this.meAsClient);
        }
        return this.api.call('client/user/me', 'GET', null, null, true).pipe(map((x: MeResult) => {
            this.meAsClient = x.clientProfile;
            return this.meAsClient;
        }));
    }

    public logout() {
        this.authService.logout();
    }

    public sendResetPassword(email: string) {
        const data = {
            email
        };
        return this.api.call(`auth/request-password`, 'POST', data);
    }

    public resetPassword(code: string, password: string) {
        const data = {
            token: code,
            password: password
        };
        return this.api.call(`auth/reset-password`, 'POST', data);
    }

    public getPrivacyPolicies(): Observable<any> { //        policies?: Policy[]
        return this.api.call('client/config/get-privacy-policies', 'GET');
    }

    public setPrivacyPolicy(policyName: string, value: boolean) {
        const data = {
            policy: policyName,
            accept: !!value
        }
        return this.api.call(`client/config/set-privacy-policy`, 'POST', data);
    }





}
