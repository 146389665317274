import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minZero'
})
export class MinZero implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) { return value }
    return 0;
  }

}
