import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-modal-promotion-start',
  templateUrl: './modal-promotion-start.component.html',
  styleUrls: ['./modal-promotion-start.component.scss']
})
export class ModalPromotionStartComponent implements OnInit {


  public trips: Trip[];
  public endHour: number;
  public endMinute: number;
  public selectedDate: Moment;

  constructor(
    protected ref: NbDialogRef<ModalPromotionStartComponent>,
    private logisticService: LogisticService
  ) { }

  ngOnInit(): void {
    this.endHour = config.promotionDefaultEndTime.hour;
    this.endMinute = config.promotionDefaultEndTime.minute;
  }

  save() {
    const endTime = moment().hour(this.endHour).minute(this.endMinute).second(0)
   this.logisticService.lauchPromotion(this.trips, this.selectedDate, endTime).subscribe(() => {
      this.ref.close();
    });
  }

  cancel() {
    this.ref.close();
  }

}
