
export const CATEGORIES_VALUES = {
    DOMESTICO: 'GAS.RISC',
    TRASPORTI: 'GAS.AUTO',
    AGRICOLO: 'GAS.AGR',
    ALTRI: 'ALTRI'
}

export const CATEGORIES_COLORS = {
    'GAS.RISC': '#d7191c',
    'GAS.AUTO': '#fdae61',
    'GAS.AGR': '#abd9e9',
    'ALTRI': '#2c7bb6'
}


export const TRIPSTATUS_COLORS = {
    'confirmed': '#31AA2B',
    'unconfirmed': '#fdae61',
    'in_transit': '#abd9e9',
    'delivered': '#2c7bb6'
}


export const CLUSTER_VALUES = {
    'TOPCLIENT': 'TOP-CLIENT',
    'HIGHPOTENTIAL': 'HIGH-POTENTIAL',
    'LOWPROFIT': 'LOW-PROFIT',
    'OCCASIONAL': 'OCCASIONAL',
    'NOSTATUS': 'NO-STATUS'
}

export const STATUS_VALUES = {
    'ACQUISIZIONE': 'ACQUISIZIONE',
    'UPSELLING': 'UPSELLING',
    'MANTENIMENTO': 'MANTENIMENTO',
    'CHURNPREVENTION': 'CHURN-PREVENTION',
    'DARISVEGLIARE': 'DA-RISVEGLIARE',
    'CHIUSO': 'CHIUSO',
    'NOSTATUS': 'NO-STATUS'
}

export const ORDERSTATUS_VALUES = {
    'INSUFFICIENT_CREDIT': 'insufficient_credit',
    'WAITING': 'waiting',
    'REJECTED': 'rejected',
    'APPROVED': 'approved',
    'SCHEDULED': 'scheduled',
    'IN_TRANSIT': 'in_transit',
    'DELIVERED': 'delivered',
}

export const MODAL = {
    confirmOk : 'ok'
}