import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { MODAL } from 'src/app/models/constants.model';

@Component({
  selector: 'oil-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {

  public message = '';
  public title = '';

  public onlyInfo = false;

  constructor(
    protected ref: NbDialogRef<ModalConfirmComponent>,
  ) { }

  ngOnInit(): void {
  }

  cancel() {
    this.ref.close();
  }

  ok() {
    this.ref.close(MODAL.confirmOk);
  }


}
