import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'src/app/services/cookie.service';

@Component({
  selector: 'oil-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss']
})
export class CookiesComponent implements OnInit {
  // logo_scuro: string = globalJSON["images"].cdn + globalJSON["images"].logo;

  isDetailVisible: boolean = false;
  detailCollapsed: boolean = true;

  bannerOpenStateClass: string = 'closed';

  @Input('forceShow') forceShow: boolean = false;

  /**
   * each item can be ->
   *  0: not setted |
   *  1: accepted |
   * -1: NOT accepted
   */
  cookiePreference: number[] = [1, 0];

  private _isBannerVisible: boolean = false;

  constructor(
    public translate: TranslateService,
    private cookieService: CookieService) {

  }

  ngOnInit(): void {
    const cookieSettingsLoaded = this.cookieService.isCookiesStored();
    // console.log("cookieSettingsLoaded", cookieSettingsLoaded);
    this._isBannerVisible = !cookieSettingsLoaded;
    this.isDetailVisible = false;

    setTimeout(() => {
      this.bannerOpenStateClass = 'open';
    }, 10);
  }


  isBannerVisible(): boolean {
    return this._isBannerVisible || this.forceShow;
  }


  acceptBasic() {
    this.cookiePreference = [1, -1];
    this.forceShow = false;
    this._isBannerVisible = false;
    this.setCookies();
  }

  acceptAll() {
    this.forceShow = false;
    this._isBannerVisible = false;
    this.setCookies();
  }

  preferences() {
    this.detailCollapsed = false;

    setTimeout(() => {
      this.isDetailVisible = true;
    }, 1);
  }

  openDetails(ev) {
    this.isDetailVisible = !ev;
  }


  accept() {
    this.forceShow = false;
    this._isBannerVisible = false;
    this.isDetailVisible = false;
    this.setCookies();
  }

  private setCookies() {
    this.cookieService.setMainCookie();
    if (!this.allCookiesAreSetted()) {
      this.cookiePreference = [1, 1];
    }
    console.log("------- ~ CookiesComponent ~ setCookies ~  this.cookiePreference", this.cookiePreference);
    this.cookieService.setCookiePreferences(this.cookiePreference);

    setTimeout(() => {
      window.location.reload();
    }, 10);
  }

  setCookiePreference(index: number, value: boolean): void {
    if (value) this.cookiePreference[index] = 1;
    else this.cookiePreference[index] = -1;
  }

  isCookieAccepted(index: number): number {
    return this.cookiePreference[index];
  }

  allCookiesAreSetted(): boolean {
    let res = 1;

    this.cookiePreference.forEach(item => {
      res = res * item;
    });

    if (res == 0) return false;
    return true;
  }


  closeDetail() {
    this.isDetailVisible = false;
  }


}
