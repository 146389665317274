import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OrderService } from 'src/app/services/order.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { Order } from 'src/app/models/order.model';
import { SortPaginator } from 'src/app/models/generic.model';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer } from 'src/app/models/customer.model';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';
import { MODAL } from 'src/app/models/constants.model';

@Component({
  selector: 'oil-orderapproval',
  templateUrl: './orderapproval.component.html',
  styleUrls: ['./orderapproval.component.scss']
})
export class OrderapprovalComponent implements OnInit {

  public displayedColumns: string[] = [
    //"favorite",
    "name",
    "deliveryDate",
    "product",
    "quantity",
    "price",
    "value",
    "fido",
    "requestTotal",
    "requestAprroved",
    "modify",
  ];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  public dataSource;
  public resultsLength = -1;
  public timeInterval = 3;


  constructor(
    private orderService: OrderService,
    private customerService: CustomerService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.refresh();

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
      });

  }


  refresh() {
    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

    this.orderService.getOrderApproveList(paginator, this.timeInterval).subscribe(x => {
      this.dataSource = new MatTableDataSource<Order>(x.items);
      this.resultsLength = x.total;
    });

  }

  approve(element: Order) {
    this.orderService.approve(element.id).subscribe(x => {
      this.refresh();
    });
  }
  refuse(element: Order) {
    this.translate.get(['ORDERS.APPROVE.DELETECONFIRMMSG', 'ORDERS.APPROVE.DELETECONFIRMTITLE']).subscribe(translations => {
      this.modalService.confirm(translations['ORDERS.APPROVE.DELETECONFIRMMSG'], translations['ORDERS.APPROVE.DELETECONFIRMTITLE']).subscribe(res => {
        if (res == MODAL.confirmOk) {
          this.orderService.refuse(element.id).subscribe(x => {
            this.refresh();
          });
        }
      })
    })
  }

  checkTimeInterval(event) {
    this.timeInterval = event.value;
    this.refresh()
  }


  setFavorite(point: Customer) {
    this.customerService.setCustomerPointFavorite(point.id, !point.favorite).subscribe(() => {
      point.favorite = !point.favorite;
    })
  }

}
