import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
  NbRegisterComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { AuthGuard } from './services/auth-guard.service';
import { DashboardComponent } from './pages/clienti/dashboard/dashboard.component';
import { NgxLoginComponent } from './pages/auth/auth/login/login.component';
import { NgxAuthComponent } from './pages/auth/auth/auth.component';
import { CalendarComponent } from './pages/clienti/calendar/calendar.component';
import { SearchComponent } from './pages/clienti/search/search.component';
import { DetailsComponent } from './pages/clienti/details/details.component';
import { OrderlistComponent } from './pages/ordini/orderlist/orderlist.component';
import { OrderpromotionComponent } from './pages/ordini/orderpromotion/orderpromotion.component';
import { OrderdetailComponent } from './pages/ordini/orderdetail/orderdetail.component';
import { OrderrequestComponent } from './pages/ordini/orderrequest/orderrequest.component';
import { OrderapprovalComponent } from './pages/ordini/orderapproval/orderapproval.component';
import { OrdernewComponent } from './pages/ordini/ordernew/ordernew.component';
import { ListinoComponent } from './pages/prezzi/listino/listino.component';
import { CompetitorComponent } from './pages/prezzi/competitor/competitor.component';
import { RequestapprovalComponent } from './pages/prezzi/requestapproval/requestapproval.component';
import { ApprovalComponent } from './pages/prezzi/approval/approval.component';
import { HomeComponent } from './pages/home/home.component';
import { RoutesComponent } from './pages/logistic/routes/routes.component';
import { UnassignedordersComponent } from './pages/logistic/unassignedorders/unassignedorders.component';
import { DeliveryComponent } from './pages/logistic/delivery/delivery.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ClientPricesComponent } from './pages/client/client-prices/client-prices.component';
import { ClientOrdersComponent } from './pages/client/client-orders/client-orders.component';
import { ClientDeliveryComponent } from './pages/client/client-delivery/client-delivery.component';
import { TrucksComponent } from './pages/logistic/trucks/trucks.component';
import { UsersComponent } from './pages/admin/users/users.component';
import { ResetpwdComponent } from './pages/auth/resetpwd/resetpwd.component';
import { PolicyComponent } from './pages/policy/policy.component';


const routes: Routes = [{
  path: '',
  canActivate: [AuthGuard],
  pathMatch: 'full',
  component: HomeComponent,
}, {
  path: 'settings',
  pathMatch: 'full',
  component: SettingsComponent,
  canActivate: [AuthGuard],
  data: { action: 'view', section: 'settings' },
}, {
  path: 'client',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'CLIENT' },
  children: [
    {
      path: '',
      redirectTo: 'prices',
      pathMatch: 'full',
    },
    {
      path: 'prices',
      pathMatch: 'full',
      component: ClientPricesComponent,
    },
    {
      path: 'orders',
      pathMatch: 'full',
      component: ClientOrdersComponent,
    },
    {
      path: 'delivery',
      pathMatch: 'full',
      component: ClientDeliveryComponent,
      data: { menuhidden: true },
    }
  ]
}, {
  path: 'supply',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'SUPPLY' },
  redirectTo: 'supply/dashboard',
  pathMatch: 'full'
}, {
  path: 'customers',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'CUSTOMERS' },
  children: [
    {
      path: '',
      redirectTo: 'dashboard',
      pathMatch: 'full',
    },
    {
      path: 'dashboard',
      pathMatch: 'full',
      component: DashboardComponent,
    },
    {
      path: 'search',
      pathMatch: 'full',
      canActivate: [AuthGuard],
      data: { action: 'create', section: 'customer' },
      component: SearchComponent
    },
    {
      path: 'details/:code',
      pathMatch: 'full',
      component: DetailsComponent,
      data: { menuhidden: true },
    },
    {
      path: 'calendar',
      component: CalendarComponent,
      data: {
        agenda: { section: 'clients', page: 'todayEvents' }
      }
    }
  ]
}, {
  path: 'prices',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'PRICES' },
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full'
    },
    {
      path: 'list',
      pathMatch: 'full',
      component: ListinoComponent,
      data: { agenda: [{ section: 'prices', page: 'toSendFavorite' }, { section: 'prices', page: 'attendedPrices' }] },
    },
    {
      path: 'list/:id',
      pathMatch: 'full',
      data: { menuhidden: true },
      component: ListinoComponent
    },
    {
      path: 'list/:id/:pointid',
      pathMatch: 'full',
      data: { menuhidden: true },
      component: ListinoComponent
    },
    {
      path: 'competitor',
      pathMatch: 'full',
      component: CompetitorComponent,
      data: { menuhidden: true }
    },
    {
      path: 'request',
      pathMatch: 'full',
      component: RequestapprovalComponent,
      data: {
        agenda: { section: 'prices', page: 'toSend' }
      }
    },
    {
      path: 'approval',
      pathMatch: 'full',
      canActivate: [AuthGuard],
      data: { permission: 'approve', resource: 'PRICES', agenda: { section: 'prices', page: 'toApprove' } },
      component: ApprovalComponent
    }
  ]
}, {
  path: 'orders',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'ORDERS' },
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full'
    },
    {
      path: 'list',
      pathMatch: 'full',
      component: OrderlistComponent
    },
    {
      path: 'promotion',
      pathMatch: 'full',
      data: { agenda: [{ section: 'orders', page: 'promotionsToSend' }, { section: 'orders', page: 'inPromotion' }] },
      component: OrderpromotionComponent
    },
    {
      path: 'request',
      pathMatch: 'full',
      data: { agenda: { section: 'orders', page: 'toSend' } },
      component: OrderrequestComponent
    },
    {
      path: 'approval',
      pathMatch: 'full',
      data: { permission: 'approve', resource: 'ORDERS', agenda: { section: 'orders', page: 'toApprove' } },
      component: OrderapprovalComponent
    },
    {
      path: 'details/:code',
      pathMatch: 'full',
      data: { menuhidden: true },
      component: OrderdetailComponent
    },
    {
      path: 'new/:code',
      pathMatch: 'full',
      data: { menuhidden: true },
      component: OrdernewComponent

    }
  ]
}, {
  path: 'logistic',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'LOGISTIC' },
  children: [
    {
      path: '',
      redirectTo: 'list',
      pathMatch: 'full'
    },
    {
      path: 'unassigned', redirectTo: 'unassigned/', pathMatch: 'full',
      data: { agenda: [{ section: 'logistic', page: 'unassignedOrdersToday' }, { section: 'logistic', page: 'unassignedOrdersTomorrow' }] }
    },
    {
      path: 'unassigned/:date',
      // pathMatch: 'full',
      component: UnassignedordersComponent,
      data: { menuhidden: true }
    },
    {
      path: 'trucks',
      pathMatch: 'full',
      component: TrucksComponent
    },
    {
      path: 'list',
      pathMatch: 'full',
      component: RoutesComponent
    },
    {
      path: 'delivery',
      pathMatch: 'full',
      data: { menuhidden: true },
      component: DeliveryComponent
    }
  ]
}, {
  path: 'admin',
  canActivate: [AuthGuard],
  data: { permission: 'view', resource: 'ADMIN' },
  children: [
    {
      path: '',
      redirectTo: 'users',
      pathMatch: 'full'
    },
    {
      path: 'users',
      pathMatch: 'full',
      component: UsersComponent
    }
  ]
}, {
  path: 'auth',
  component: NgxAuthComponent,
  children: [
    {
      path: '',
      component: NgxLoginComponent,
    },
    {
      path: 'login',
      component: NgxLoginComponent,
    },
    {
      path: 'register',
      component: NbRegisterComponent,
    },
    {
      path: 'logout',
      component: NbLogoutComponent,
    },
    {
      path: 'policy',
      component: PolicyComponent,
    },
    // {
    //   path: 'request-password',
    //   component: NbRequestPasswordComponent,
    // },
    {
      path: 'reset-password', redirectTo: 'reset-password/', pathMatch: 'full',
    },
    {
      path: 'reset-password/:code',
      // component: NbResetPasswordComponent,
      component: ResetpwdComponent
    }
  ],
},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
