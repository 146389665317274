<nb-card size="big">
    <nb-card-body>
        <div class="no-data" [hidden]="resultsLength>0"> {{ 'PRICES.APPROVE.NODATA' | translate}}
        </div>

        <div [hidden]="!resultsLength">
            <table mat-table [dataSource]="dataSource" matSort #table>


                <!-- Preferiti -->
                <ng-container matColumnDef="favorite">
                    <th mat-header-cell *matHeaderCellDef>
                        <nb-icon icon="star-on" pack="3logic">
                        </nb-icon>
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic" >
                        </nb-icon>
                    </td>
                </ng-container>

                <!-- Ragione sociale -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.APPROVE.TABLE.NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <oil-company-name [element]="element" [id]="element.clientId" ></oil-company-name>
                    </td>
                </ng-container>

                <!-- Prodotto -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'PRICES.APPROVE.TABLE.PRODUCT' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.product_name}}</td>
                </ng-container>

                <!-- Fido residuo  -->
                <ng-container matColumnDef="fido">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'PRICES.APPROVE.TABLE.FIDO' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="credit"
                        [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                        {{element.remainingCredit | currency:'€'}}</td>
                </ng-container>

                <!-- Prezzo proposto-->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'PRICES.APPROVE.TABLE.PRICPROPOSED' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.price_proposed | currency:'€': 'symbol' : '1.3-3'}}</td>
                </ng-container>

                <!-- Prezzo modificato-->
                <ng-container matColumnDef="price_modified">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'PRICES.APPROVE.TABLE.PRICEMODIFIED' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.price_modified | currency:'€': 'symbol' : '1.3-3'}}</td>
                </ng-container>
                
                <!-- Margine Prezzo modificato-->
                <ng-container matColumnDef="margin_modified">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'PRICES.APPROVE.TABLE.PRICEMODIFIEDMARGIN' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{newMargin(element) | currency:'€':'symbol' : '1.3-3'}}</td>
                </ng-container>

                <!-- Modifica  -->
                <ng-container matColumnDef="modify">
                    <th mat-header-cell *matHeaderCellDef>{{ 'PRICES.APPROVE.TABLE.MODIFY' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="actions">
                            <button nbButton class="confirm-button"
                                (click)="approve(element)">{{'PRICES.APPROVE.TABLE.APPROVE' | translate}}</button>
                            <button nbButton class="delete-button"
                                (click)="refuse(element)">{{'PRICES.APPROVE.TABLE.REFUSE' | translate}}</button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>

            <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>
        </div>

    </nb-card-body>
</nb-card>