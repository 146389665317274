import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";
import { version } from 'src/environments/version';



Sentry.init({
  dsn: environment.sendErrors ? "https://11f62ddb5da341c8a7c31705cabbe77d@o572945.ingest.sentry.io/5723336" : "",
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "/api"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],
  debug: false,
  environment: environment.production ? 'production' : 'pre',
  release: `${version.mayor}.${version.minor}.${version.build}`,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: environment.production ? 0.4 : 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
