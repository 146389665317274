<oil-dateribbon *ngIf="!paginatedList" (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected">
</oil-dateribbon> 

<nb-card size="big">


    <nb-card-body>

        <div class="company-data">
            <div class="company-nameaddress">
                <div class="company-name">
                    {{customer?.name}}
                </div>
                <div class="company-address">
                    {{customer?.address}}
                    {{customer?.vatCode}}
                    {{customer?.province}}
                </div>
            </div>
            <div class="company-credit">
                <div class="company-credit-label">
                    {{'CLIENT.PRICES.CREDITLABEL' | translate}}
                </div>
                <div class="company-credit-value">
                    {{customer?.remainingCredit | currency:'€'}}
                </div>
            </div>
        </div>



        <ng-container *ngIf="resultsLength == 0 && !isToday()">
            <div>{{'CLIENT.PRICES.NODATA' | translate}}</div>
        </ng-container>



        <ng-container *ngIf="isToday() && !todayHasPrice">
            <div >{{'CLIENT.PRICES.NODATATODAY' | translate}}</div>
            <div class="request">
                <button *ngIf="!customer?.haveRequestedPrice" nButton (click)="requestPrice()">
                    {{'CLIENT.PRICES.REQUESTPRICE' | translate}}
                </button>
                <div class="request-sent" *ngIf="customer?.haveRequestedPrice">
                    {{'CLIENT.PRICES.REQUESTPRICESENT' | translate}}
                </div>
            </div>
        </ng-container>




        <table mat-table [dataSource]="dataSource" matSort #table [hidden]="resultsLength<1">


            <!-- Deposito -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.PRICES.TABLE.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <div class="deposit-name">{{element.deliveryName}}</div>
                    <div class="deposit-address">
                        {{element.deliveryAddress}} {{element.deliveryCap}} {{element.deliveryCity}}
                        ({{element.deliveryProvince}})
                    </div>
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.PRICES.TABLE.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product_name}}</td>
            </ng-container>

            <!-- Fido residuo  -->
            <ng-container matColumnDef="fido">
                <th mat-header-cell *matHeaderCellDef> {{ 'CLIENT.PRICES.TABLE.FIDO' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit"
                    [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                    {{element.remainingCredit | currency:'€'}}</td>
            </ng-container>

            <!-- Prezzo inviato -->
            <ng-container matColumnDef="priceseleted">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.PRICES.TABLE.PRICESELECTED' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.price_sent_to_client | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>

            <!-- Prezzo promozione -->
            <ng-container matColumnDef="pricepromotion">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.PRICES.TABLE.PRICEPROMOTION' | translate}}
                </th>
                <td mat-cell class="promotional" *matCellDef="let element">
                    {{isPromotionActive(element) ? element.price_promotional : null | currency:'€': 'symbol' : '1.3-3'}}
                    <span *ngIf="isPromotionActive(element)">({{ 'CLIENT.PRICES.TABLE.MAXPROMOTION' | translate}}
                        {{element.quantity_promotional | liter}})</span>
                        
                    <div *ngIf="isPromotionActive(element)" class="promotion-expire">({{ 'CLIENT.PRICES.TABLE.EXPIREPROMOTION' | translate}}
                        {{remainingtime()}})</div>
                </td>
            </ng-container>

            <!-- Date -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>
                    {{ 'CLIENT.PRICES.TABLE.DATE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.date | amDateFormat:'DD/MM/YYYY'}}</td>
            </ng-container>

            <!-- ordina -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="isToday(element)" nbButton (click)="addOrder(element)">{{ 'CLIENT.PRICES.TABLE.ORDER'
                        | translate}}</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row [ngClass]="{'today-price':isToday(row)}" [hidden]="!hasValidPrice(row)"  *matRowDef="let row; columns: displayedColumns;"></tr>
            
        </table>

        
        <mat-paginator [hidden]="!paginatedList" pageSize="30" [pageSizeOptions]="[30,50,100]" [length]="resultsLength"></mat-paginator>


    </nb-card-body>
</nb-card>