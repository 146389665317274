export const config = {

    apiDateFormat: 'YYYY-MM-DD',
    shortDateFormat: 'DD/MM/YY',

    agendaPolling: 60000,

    assignDriverOnCofirmTrip: false,

    mainAreas: [
        // { label: 'SUPPLY', area: 'SUPPLY', url: '/supply' },
        { label: 'CLIENT', area: 'CLIENT', url: '/client' },
        { label: 'CUSTOMERS', area: 'CUSTOMERS', url: '/customers' },
        { label: 'PRICES', area: 'PRICES', url: '/prices' },
        { label: 'ORDERS', area: 'ORDERS', url: '/orders' },
        { label: 'LOGISTIC', area: 'LOGISTIC', url: '/logistic' },
        { label: 'ADMIN', area: 'ADMIN', url: '/admin' }
    ],

    userType: {
        SALES_AGENT: "SALES_AGENT",
        OPERATION: "OPERATION",
        CLIENT: "CLIENT",
        ADMIN: "ADMIN",
        DRIVER: "DRIVER",
    },

    map: {
        center: [11.034267, 45.917033],
        defaultZoom: 4,
        routeColor: '#6FA3FA',
        routeWidth: 7
    },

    promotionDefaultEndTime: {
        hour: 17,
        minute: 0
    },

    defaultRanges:[{
        max_range: 400,
        value: 0.05
    }, {
        max_range: 1000,
        value: 0.04
    }, {
        max_range: 2000,
        value: 0.03
    }, {
        max_range: -1,
        value: 0.02
    }]
}
