<div *ngIf="!modify">
    <span>
        {{(value ? value : 0) | number : '1.2-4'}}
    </span>
    <nb-icon icon="edit-outline" (click)="startModify()" class="modify-start"
        [options]="{ animation: { type: 'pulse' } }"></nb-icon>
</div>

<div *ngIf="modify">
    <input class="outline-box" type="number" [step]="step" nbInput [(ngModel)]="newValue">
    <nb-icon icon="checkmark-circle-2-outline" (click)="sendValue()" class="modify-accept"
        [options]="{ animation: { type: 'pulse' } }"></nb-icon>
    <nb-icon icon="close-circle-outline" (click)="endModify()" class="modify-refuse"
        [options]="{ animation: { type: 'pulse' } }">
    </nb-icon>

</div>