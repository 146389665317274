<nb-card>
    <nb-card-header>{{'ORDERS.TRIPDETAILS.TITLE'|translate}} {{trip?.routeId}}</nb-card-header>

    <nb-card-body class="modal">

        <nb-tabset>
            <nb-tab tabTitle="{{'ORDERS.TRIPDETAILS.STAGES'|translate}}">
                <div class="stages-tab">
                    <div class="leftlist">
                        <div>
                            <div class="stage-title">
                                {{'ORDERS.TRIPDETAILS.DEPOT'|translate}} {{trip?.fuelDepot?.name}}
                            </div>
                            <div class="stage-name">
                                {{trip?.fuelDepot?.address}}
                            </div>
                        </div>
                        <div *ngFor="let stage of trip?.destinations;let idx=index"
                            [ngClass]="{'highlight':idx == selectedStage}" (mouseenter)="enterStage(stage)"
                            (mouseleave)="enterStage()">
                            <div class="stage-title">
                                {{'ORDERS.TRIPDETAILS.STAGE'|translate}} {{idx+1}}
                                <nb-icon icon="checkmark-outline" *ngIf="stage.status== 'delivered'"> </nb-icon>
                            </div>
                            <div class="stage-name">
                                {{stage.deliveryName}} <br />
                                {{stage.deliveryAddress_1}} - {{stage.deliveryCity}} - {{stage.deliveryProvince}}
                            </div>
                        </div> 
                        <div *ngIf="1 || trip?.fuelDepot?.name != trip?.endfuelDepot?.name">
                            <div class="stage-title">
                                {{'ORDERS.TRIPDETAILS.ENDDEPOT'|translate}} {{trip?.endfuelDepot?.name}}
                            </div>
                            <div class="stage-name">
                                {{trip?.endfuelDepot?.address}}
                            </div>
                        </div>
                    </div>
                    <div class="map" *ngIf="!trip?.createdByClient">
                        <oil-tripmap class="" [geojson]="trip?.trips" [destinations]="trip?.destinations"
                            [depots]="getDepotsList(trip)" [highlight]="highlightPoint"
                            (selectedStage)="selectStage($event)"></oil-tripmap>
                        <div class="right">
                            {{getKm(trip)}} Km
                        </div>
                    </div>
                    <div class="nomap" *ngIf="trip?.createdByClient">
                        {{'ORDERS.TRIPDETAILS.NOMAP'|translate}}
                    </div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="{{'ORDERS.TRIPDETAILS.ORDERS'|translate}}">
                <div class="orders-tab">
                    <div class="leftlist">
                        {{'ORDERS.TRIPDETAILS.SUBTITLE'|translate}}
                        <table mat-table [dataSource]="dataSource" matSort #table>
                            <!-- Order number -->
                            <ng-container matColumnDef="code">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.CODE'|translate}}</th>
                                <td mat-cell *matCellDef="let order" class="cell-trip">
                                    <div class="order-code">{{order.orderCode}}</div>
                                    <div class="order-id">{{order.orderId}}</div>
                                </td>
                            </ng-container>
                            <!--Ragione sociale -->
                            <ng-container matColumnDef="name">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.NAME'|translate}} </th>
                                <td mat-cell *matCellDef="let order" class="cell-trip">
                                    <oil-company-name [element]="order" [id]="order.clientId"></oil-company-name>
                                </td>
                            </ng-container>
                            <!-- Destinazione -->
                            <ng-container matColumnDef="destination">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.DESTINATION'|translate}}</th>
                                <td mat-cell *matCellDef="let order" class="cell-trip"> {{order.deliveryCity}}</td>
                            </ng-container>
                            <!-- Prodotto -->
                            <ng-container matColumnDef="product">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.PRODUCT'|translate}} </th>
                                <td mat-cell *matCellDef="let order" class="cell-trip"> {{order.orderProductName}}</td>
                            </ng-container>
                            <!--Quantità -->
                            <ng-container matColumnDef="quantity">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.QUANTITY'|translate}}</th>
                                <td mat-cell *matCellDef="let order" class="cell-trip">
                                    {{order.orderQty}}
                                    <img *ngIf="order.promotionalPrice  || order.suggestedRoute" class="promo-ico"
                                        src="/assets/images/gift.png">
                                </td>
                            </ng-container>
                            <!-- Oraio -->
                            <ng-container matColumnDef="time">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.DELIVERYTIME'|translate}} </th>
                                <td mat-cell *matCellDef="let order" class="cell-trip">
                                    {{'time.'+order.deliveryTime?.name | translate}}
                                    ({{order.estimedDeliveryTime | slice:0:5}})
                                </td>
                            </ng-container>
                            <!--Status -->
                            <ng-container matColumnDef="status">
                                <th class="" mat-header-cell *matHeaderCellDef>
                                    {{'ORDERS.TRIPDETAILS.ORDERSTABLE.STATUS'|translate}}</th>
                                <td mat-cell *matCellDef="let order" class="cell-trip">
                                    <div>{{(order.status == "delivered" ?
                                        "ORDERS.TRIPDETAILS.ORDERSTABLE.STATUSDELIVERED":
                                        "ORDERS.TRIPDETAILS.ORDERSTABLE.STATUSUNDELIVERED") | translate}}
                                        <img *ngIf="order.promotionalPrice  || order.suggestedRoute" class="promo-ico"
                                            src="/assets/images/gift.png">
                                        <nb-icon *ngFor="let image of order.images" class="" icon="camera-outline"
                                            (click)="openImg(image,photodialog)"
                                            [options]="{ animation: { type: 'pulse' } }"
                                            nbTooltip="{{'ORDERS.TRIPDETAILS.ORDERSTABLE.ATTACHED'|translate}}">
                                        </nb-icon>
                                    </div>
                                    <div *ngIf="order.status != 'delivered' && trip.tripStatus=='in_transit'">
                                        {{"ORDERS.TRIPDETAILS.ORDERSTABLE.STATUSTIME"| translate}}
                                        {{order.estimatedDelivery| amDateFormat:'HH:mm' }}
                                    </div>
                                </td>
                            </ng-container>
                            <!-- Actions  -->
                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef>
                                </th>
                                <td mat-cell *matCellDef="let order">
                                    <div *ngIf="fromLogistic">
                                        <div class="actions" *ngIf="!isConfirmed && trip.createdByClient">
                                            <button nbButton class="action-button" size="small"
                                                (click)="moveOrder(order)">{{'ORDERS.TRIPDETAILS.MOVE'|translate}}</button>
                                            <button nbButton class="action-button" size="small"
                                                (click)="removeOrder(order)">{{'ORDERS.TRIPDETAILS.REMOVE'|translate}}</button>
                                        </div>
                                        <div class="actions"
                                            *ngIf="isConfirmed && isToday() && trip?.tripStatus == 'in_transit'">
                                            <button nbButton class="action-button delivered-button" size="small"
                                                *ngIf="order?.status != 'delivered'"
                                                (click)="confirmOrder(order,true)">{{'ORDERS.TRIPDETAILS.ORDERSTABLE.CONFIRMORDER'|translate}}</button>
                                            <button nbButton class="action-button undelivered-button" size="small"
                                                *ngIf="order?.status == 'delivered'"
                                                (click)="confirmOrder(order,false)">{{'ORDERS.TRIPDETAILS.ORDERSTABLE.UNCONFIRMORDER'|translate}}</button>
                                        </div>
                                    </div>
                                </td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
                        </table>
                        <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator> -->
                    </div>
                    <!-- <div class="map">
                        <oil-tripmap class="" [geojson]="trip?.trips" [destinations]="trip?.destinations"></oil-tripmap>
                    </div> -->
                </div>

                <ng-template #photodialog let-data let-ref="dialogRef">
                    <nb-card class="photodialog">
                        <nb-card-header>{{'ORDERS.TRIPDETAILS.PHOTOMODALTITLE'|translate}}</nb-card-header>
                        <nb-card-body>
                            <img class="photodialog-img" [src]="img">
                        </nb-card-body>
                        <nb-card-footer>
                            <button nbButton
                                (click)="ref.close()">{{'ORDERS.TRIPDETAILS.PHOTOMODALCLOSE'|translate}}</button>
                        </nb-card-footer>
                    </nb-card>
                </ng-template>

                <div class="actions">
                    <!-- <button nbButton (click)="cancel()">{{'ORDERS.TRIPDETAILS.CANCEL'|translate}}</button> -->
                    <!-- <button nbButton status="danger" class="modal-buttons"
                        (click)="addOrder()">{{'ORDERS.TRIPDETAILS.ADDORDER'|translate}}</button> -->
                    <button nbButton status="danger" class="modal-buttons"
                        *ngIf="isConfirmed && isToday() && trip?.tripStatus != 'in_transit' && trip?.tripStatus != 'delivered'"
                        (click)="transitTrip()">{{'ORDERS.TRIPDETAILS.TRANSITTRIP'|translate}}</button>
                    <button nbButton status="danger" class="modal-buttons"
                        *ngIf="isConfirmed && isToday() && trip?.tripStatus == 'in_transit' && trip?.tripStatus != 'delivered' && isAllOrdersDelivered()"
                        (click)="deliveryTrip()">{{'ORDERS.TRIPDETAILS.DELIVERYTRIP'|translate}}</button>
                    <div nbButton status="danger" class="modal-buttons"
                        *ngIf="trip?.tripStatus == 'delivered' && isAllOrdersDelivered()">
                        {{'ORDERS.TRIPDETAILS.DELIVEREDTRIP'|translate}}</div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="{{'ORDERS.TRIPDETAILS.TRUCK'|translate}}">
                <div class="leftlist">
                    <label>{{'ORDERS.TRIPDETAILS.PLATE'|translate}} </label>
                    <span>{{trip?.vehicleName}} </span>
                    <label>{{'ORDERS.TRIPDETAILS.DRIVER'|translate}} </label>
                    <span>{{trip?.driverFirstName}} {{trip?.driverLastName}} <nb-icon (click)="editDriver()"
                            icon="edit-outline"
                            *ngIf="trip?.driverId && trip?.tripStatus != 'in_transit' && trip?.tripStatus != 'delivered'">
                        </nb-icon></span>
                    <label>{{'ORDERS.TRIPDETAILS.FRAME'|translate}} </label>
                    <span>{{trip?.frame}} </span>
                    <label>{{'ORDERS.TRIPDETAILS.MATRICULATION'|translate}} </label>
                    <span>{{trip?.matriculation}} </span>
                    <label>{{'ORDERS.TRIPDETAILS.MAXCAPACITY'|translate}} </label>
                    <span>L {{(trip?.maxTruckCapacity + (trip?.maxTowCapacity ? trip?.maxTowCapacity : 0)) | number
                        }}</span>
                </div>
                <div class="tanks">
                    <div *ngFor="let tank of trip?.tanks" nbTooltip="{{productList(tank.products)}}">
                        <div class="bar">
                            <nb-progress-bar [value]="progressBar(tank.fillingQuantity/tank.maxCapacity)" size="tiny">
                                {{tank.fillingQuantity/tank.maxCapacity*100 | number:'1.0-0'}}%
                            </nb-progress-bar>
                        </div>
                        <div class="liters">{{tank.fillingQuantity}}/{{tank.maxCapacity}}</div>
                        <div class="element">{{tank.products?productList(tank.products):'-'}}</div>
                    </div>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card-body>


    <nb-card-footer>
        <button nbButton (click)="cancel()">{{'ORDERS.TRIPDETAILS.CLOSE'|translate}}</button>
        <button status="danger"
        *ngIf="!isConfirmed"
        class="rightbtn" nbButton (click)="deleteTrip()">{{'ORDERS.TRIPDETAILS.DELETE'|translate}}</button>
    </nb-card-footer>

</nb-card>