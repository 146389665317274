import { ClosePoint, Customer, CustomerDetail } from './customer.model';
import { ApiResponse } from './generic.model';
import { TripStatus } from './logistic_model';

export enum OrderStatus {
    approved = 'approved',
    rejected = 'rejected',
    insufficient_credit = 'insufficient_credit',
    scheduled = 'scheduled',
    in_transit = 'in_transit',
    delivered = 'delivered',
}

export interface BasicOrder {
    client_delivery_id?: number,
    deliveryDate?: Date | string,
    notes?: string,
    price?: number,
    product_id?: number,
    quantity: number,
    orderQty?: number,
}


export interface ClientOrder {// extends BasicOrder {
    client_id: number
    deliveryAddress: string
    deliveryCity: string
    deliveryDate: string
    deliveryName: string
    name: string
    orderNumber: string
    price: string
    product: string
    promotionalPrice: number
    quantity: number
    remainingCredit: number
    status: OrderStatus
}

export interface Order extends BasicOrder {
    id?: number,
    orderId?: number,
    order_id?: number,
    orderNumber?: string,
    product_code?: string,
    product?: string,
    category?: string,
    client_id?: number,
    client_code?: string,
    name?: string,
    client_delivery_name?: string,
    client_delivery_code?: string,
    client_delivery_address_1?: string,
    client_delivery_address_2?: string,
    client_delivery_cap?: string,
    client_delivery_city?: string,
    client_delivery_province?: string,
    maxQuantity?: number,
    sales_agent_id?: number,
    sales_agent_code?: string,
    creationDate?: Date,
    deliveryTimeRangeFrom?: Date,
    deliveryTimeRangeTo?: Date,
    deliveryTime?: string,
    totalOrdersInsufficient?: number
    totalOrdersApproved?: number
    ignore_expiration?: boolean,
    promotionalPrice?: boolean
    suggestedRoute?: number,
    status?: string,
    estimatedDelivery?: Date
    deliveryTimeName?:string
}

export interface Tank {
    tankId?: number,
    products: string[],
    fillingQuantity: number,
    maxCapacity: number,
    inPromotion: number,
    depotName: string,
    enddepotName: string
}

export interface Location {
    type: string,
    coordinates: number[]
}

export interface CustomerOrder extends Customer {
    orderId: number,
    clientId: number,
    location: Location,
    orderQty: number,
    deliveryId: number,
    orderPrice: number,
    deliveryCap: string,
    deliveryCity: string,
    deliveryName: string,
    orderProductId: number,
    deliveryProvince: string,
    orderProductName: string,
    deliveryAddress_1: string,
    deliveryAddress_2: string,
    // deliveryOrder?: number,
    deliverySequence?: number,
    status?: OrderStatus,

    images?: number[]
}

export interface Attachment {
    id: number;
    routeId: number;
    orderId: number;
    attachment: string;
    mimeType: string;
    fileName?: string;
}

export interface FuelDepot {
    id: number,
    code: number,
    name: string,
    address: string,
    location: Location,
}

export interface TripWaypoint {
    distance: number
    hint: string
    location: number[]
    name: string
    trips_index: number
    waypoint_index: number
}

export interface TripGeoInfo {
    code: string,
    trips: any[],
    waypoints: TripWaypoint[],

}

export interface Trip {
    routeId: number,
    name: string,
    vehicleName: string,
    towName: string,
    deliveryDate: Date,
    destinations: CustomerOrder[],
    orders: CustomerOrder[],
    driverId?: number,
    driverFirstName?: string,
    driverLastName?: string,
    trips: TripGeoInfo,
    tanks: Tank[],
    fuelDepot: FuelDepot,
    fuelDepotName: string,
    endfuelDepot: FuelDepot,
    endfuelDepotName: string,
    confirmed: number,
    maxTruckCapacity: number;
    maxTowCapacity: number;
    frame?: string,
    matriculation?: Date
    fillingPercentual?: number;
    checked?: boolean;
    distance?: number;
    tripStatus?: TripStatus;
    createdByClient?: boolean;
}


export interface Promotion {
    promotionId: number,
    promotionStatus?: string,
    clientStatus?: string,
    clientName?: string,
    clientId?: number,
    deliveryId?: number,
    deliveryName?: string,
    deliveryAddress?: string,
    deliveryCity?: string,
    productId?: number,
    product?: string,
    maxQuantity?: number,
    remainingQuantity?: number,
    price?: number,
    remainingCredit?: number,
    suggestedByIA?: boolean,
    promotionDate: Date
    priceMargin?: number,
    suggestedRoute?: number,
    deliveryLocation?: GeoPoint
}

export interface GeoPoint {
    coordinates: number[],
    type: string
}

export interface OrderList extends Order {
    remainingCredit: number,
    status: string,
    product_category: string,
    marginUnitary?: number
}

export interface OrderDetail extends Order {
}

export interface TripApiResponse extends ApiResponse {
    items: Trip[]
    expireDate: Date
}


export interface OrderListApiResponse extends ApiResponse {
    items: OrderList[]
    expireDate: Date
}

export interface OrderClientListApiResponse extends ApiResponse {
    items: ClientOrder[]
    expireDate: Date
}

export interface PromotionApiResponse extends ApiResponse {
    items: Promotion[]
}
export interface NearbyApiResponse extends ApiResponse {
    items: ClosePoint[];
    order_delivery_location: OrderLocation
}

export interface DeliveryTime {
    name: string,
    startTime: string,
    endTime: string,
}

export interface DeliveryTimesApiResponse extends ApiResponse {
    items: DeliveryTime[]
}

export interface OrderLocation {
    location: Location
}

export interface OrderListFilter {
    date?: Date,
}
export interface ordersCategory {
    category: string,
    total: number
}

export interface DailyOrdersApiResponse {
    totalOrders: number,
    ordersDelta: number,
    totalQuantity: number,
    avgQuantity: number,
    avgQuantityDelta: number,
    ordersCategories: ordersCategory[]
}