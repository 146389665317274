<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected" [dateSync]="true" showFuture="true">
</oil-dateribbon>


<nb-card size="big">
    <nb-card-body [hidden]="resultsLength">
        <div class="nodata">{{ 'LOGISTIC.UNASSIGNED.NODATA' | translate}}</div>
    </nb-card-body>
    <nb-card-body [hidden]="!resultsLength">

        <table mat-table [dataSource]="dataSource" matSort #table>

            <!-- numero -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.UNASSIGNED.TABLE.NUMBER' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.orderNumber}} </td>
            </ng-container>

            <!-- Ragione sociale -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.UNASSIGNED.TABLE.NAME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <oil-company-name [element]="element" [id]="element.client_id" ></oil-company-name>
                </td>
            </ng-container>

            <!-- Categoria -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.UNASSIGNED.TABLE.CATEGORY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.UNASSIGNED.TABLE.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product}}</td>
            </ng-container>

            <!-- Quantità  -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.UNASSIGNED.TABLE.QUANTITY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
            </ng-container>

            <!-- Prezzo -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.UNASSIGNED.TABLE.PRICE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> 
                    {{element.price | currency:'€': 'symbol' : '1.3-3'}}
                    <img *ngIf="element.promotionalPrice  || element.suggestedRoute" class="promo-ico" src="/assets/images/gift.png">
                </td>
            </ng-container>


            <!-- Fascia oraria  -->
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.UNASSIGNED.TABLE.TIME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{'time.'+element.deliveryTime.name | translate}}</td>
            </ng-container>

            <!-- action  -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="actions">
                        <button nbButton class="delete-button" (click)="assign(element)">
                            {{'LOGISTIC.UNASSIGNED.TABLE.ASSIGN' | translate}}
                        </button>
                        <button nbButton class="delete-button" (click)="delayOrder(element)">
                            {{'LOGISTIC.UNASSIGNED.TABLE.DELAY' | translate}}
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;">
            </tr>
        </table>

        <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator> -->

    </nb-card-body>
</nb-card>