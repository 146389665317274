import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Price } from 'src/app/models/price.model';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'oil-modal-competitor',
  templateUrl: './modal-competitor.component.html',
  styleUrls: ['./modal-competitor.component.scss']
})
export class ModalCompetitorComponent implements OnInit {

  public price: Price;
  public competitorPrice: number;
  public note: string;

  constructor(
    private priceService: PriceService,
    protected ref: NbDialogRef<ModalCompetitorComponent>,
  ) {

  }

  ngOnInit(): void {
    this.competitorPrice = this.price.price_approved ?? this.price.price_proposed;
  }

  newMargin() {
    if (this.price && this.competitorPrice) {
      return this.competitorPrice - (this.price.price_proposed - this.price.margin);
    }
    return '-';
  }

  send() {
    this.priceService.addCompetitor(this.price, this.competitorPrice, this.note).subscribe(x => {
      this.ref.close();
    })
  }

  isValid(): boolean {
    return !!this.competitorPrice;
  }

  cancel() {
    this.ref.close();
  }

}
