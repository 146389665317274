<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected" [dateSync]="true"
    showFuture="true">
</oil-dateribbon>

<div class="daily">
    <div class="daily-row">
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">{{'ORDERS.LIST.ORDERSNUMBER' | translate}}</div>
                    <img src="/assets/icons/card/ordini.svg" alt="orders icon" class="daily-icon" />
                    <oil-daily-chart class="daily-chart" [input]='chartInput'></oil-daily-chart>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'ORDERS.LIST.ORDERS' | translate}}
                        <span class="daily-subtitle">({{'ORDERS.LIST.ORDERSDELTASUBTITLE' | translate}})</span>
                    </div>
                    <img src="/assets/icons/card/ordini.svg" alt="orders icon" class="daily-icon" />
                    <div class="daily-value">
                        <span *ngIf="ordersDelta>0"> + </span>
                        {{ordersDelta}}
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        {{'ORDERS.LIST.ORDERSAVG' | translate}}
                        <span class="daily-subtitle">({{'ORDERS.LIST.ORDERSAVGSUBTITLE' | translate}})</span>
                    </div>
                    <img src="/assets/icons/card/ordine-medio.svg" alt="orders icon" class="daily-icon" />
                    <div class="daily-value">
                        <img class="daily-value-pre" src="/assets/icons/card/arrow-up-green.svg" alt="increase"
                            *ngIf="ordersAvgDelta>0" />
                        <img class="daily-value-pre" src="/assets/icons/card/arrow-down-red.svg" alt="decrease"
                            *ngIf="ordersAvgDelta<0" />
                        <span nbTooltip="{{ordersAvg | number : '0.0-0'}}">{{ordersAvg | smallNumber : 'value'}}</span>
                        <span class="daily-value-post">{{ordersAvg | smallNumber : 'order'}}</span>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
        <div class="daily-col-5">
            <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
                <nb-card-body>
                    <div class="daily-title">
                        <span> {{'ORDERS.LIST.ORDERSTOTAL' | translate}} </span>
                        <span class="daily-subtitle">({{'ORDERS.LIST.ORDERSTOTALSUBTITLE' | translate}})</span>

                    </div>
                    <img src="/assets/icons/card/autobotte-semipiena.svg" alt="orders icon" class="daily-icon" />
                    <div class="daily-value">
                        <span nbTooltip="{{ordersTotal | number}}">{{ordersTotal | smallNumber : 'value'}}</span>
                        <span class="daily-value-post">{{ordersTotal | smallNumber : 'order'}}</span>
                    </div>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</div>

<nb-card size="big">
    <nb-card-body>

        <div class="filters">

            <div class="searchbox">
                <div class="filter-title">{{ 'ORDERS.LIST.SELECTPRODUCT' | translate}}</div>
                <ng-select size="small" (change)="refresh()" [items]="products" bindLabel="name" bindValue="id"
                    autofocus [(ngModel)]="selectedProduct" placeholder="{{ 'ORDERS.LIST.SELECTPRODUCT' |translate}}">
                </ng-select>
                <!-- <input nbInput placeholder="{{'ORDERS.LIST.SEARCH' | translate}}" (keyup)="searchUpdate()"
                    [(ngModel)]="searchstring"> -->
            </div>

            <oil-customer-filter [filters]="filters" (selectedChange)="filterChange($event)"> </oil-customer-filter>

        </div>

        <table mat-table [dataSource]="dataSource" matSort #table>


            <!-- Preferiti -->
            <ng-container matColumnDef="favorite">
                <th class="favorite" mat-header-cell *matHeaderCellDef>
                    <nb-icon icon="star-on" pack="3logic">
                    </nb-icon>
                </th>
                <td class="favorite" mat-cell *matCellDef="let element">
                    <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic"  (click)="setFavorite(element)">
                    </nb-icon>
                </td>
            </ng-container>


            <!-- euro -->
            <ng-container matColumnDef="euro">
                <th mat-header-cell *matHeaderCellDef>
                    <nb-icon class="euro" icon="listino-on" pack="3logic">
                    </nb-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    <nb-icon class="euro" [icon]="element?.listino?'listino-on':'listino-off'" pack="3logic">
                    </nb-icon>
                </td>
            </ng-container>

            <!-- numero -->
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.LIST.TABLE.NUMBER' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.orderNumber}}<br><span
                        class="small">{{element.creationDate | date : 'dd/MM/yyyy'}}</span></td>
            </ng-container>

            <!-- Ragione sociale -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'ORDERS.LIST.TABLE.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <oil-company-name [element]="element" [id]="element.client_id"></oil-company-name>
                </td>
            </ng-container>

            <!-- Categoria -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> {{ 'ORDERS.LIST.TABLE.CATEGORY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef> {{ 'ORDERS.LIST.TABLE.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product}}</td>
            </ng-container>

            <!-- Quantità  -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.LIST.TABLE.QUANTITY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
            </ng-container>

            <!-- Prezzo unitario  -->
            <ng-container matColumnDef="priceunit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.LIST.TABLE.PRICEUNIT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.price/element.quantity | currency:'€': 'symbol' : '1.3-3'}}
                    <img *ngIf="element.promotionalPrice  || element.suggestedRoute" class="promo-ico"
                        src="/assets/images/gift.png">
                </td>
            </ng-container>

            <!-- Prezzo -->
            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.LIST.TABLE.PRICE' | translate}}
                </th>
                <td mat-cell class="price" *matCellDef="let element"> {{element.price | currency:'€'}}</td>
            </ng-container>

            <!-- Fido residuo  -->
            <ng-container matColumnDef="fido">
                <th mat-header-cell *matHeaderCellDef> {{ 'ORDERS.LIST.TABLE.FIDO' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit"
                    [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                    {{element.remainingCredit | currency:'€'}}</td>
            </ng-container>

            <!-- Data consegna  -->
            <ng-container matColumnDef="delivery">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.LIST.TABLE.DELIVERY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.deliveryDate | amDateFormat:'DD/MM/YYYY' }}  {{'time.'+element.deliveryTime.name | translate}}</td>
            </ng-container>

            <!-- Stato ordine  -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef> {{ 'ORDERS.LIST.TABLE.STATUS' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{'ORDERSTATUS.'+ element.status.toUpperCase() | translate}}
                </td>
            </ng-container>

            <!-- delete  -->
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="button-icon" *ngIf="!isStatusOk(element)">
                        <nb-icon icon="trash-2-outline" (click)="delete(element)"
                            [options]="{ animation: { type: 'pulse' } }"
                            nbTooltip="{{'CTA.DELETE'|translate}}">
                        </nb-icon>
                    </div>
                </td>
            </ng-container>


            <!-- vicini  -->
            <ng-container matColumnDef="closeby">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="button-icon" *ngIf="isStatusOk(element) && isNotPast()">
                        <nb-icon icon="globe-2-outline" (click)="closeby(element)"
                            [options]="{ animation: { type: 'pulse' } }" nbTooltip="{{'CTA.CLOSEBY'|translate}}">
                        </nb-icon>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'row-status-ok':isStatusOk(row)}">
            </tr>
        </table>

        <mat-paginator pageSize="50" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>

        <div class="pricenote">{{'ORDERS.LIST.PRICENOTE' | translate}}</div>
        <div class="button-container">
            <button nbButton status="danger" class="actions-button" (click)="add()">
                {{'ORDERS.LIST.ADD' | translate}}
            </button>

        </div>
        <button *ngIf="isToday()" nbButton (click)="cloneDaily()">
            {{'ORDERS.LIST.CLONEDAILY' | translate}}
        </button>
    </nb-card-body>
</nb-card>