<div class="button-icon" *ngIf="details">
    <a [routerLink]="'/customers/details/'+customerId">
    <nb-icon class="btn" icon="info-outline"
        [options]="{ animation: { type: 'pulse' } }" nbTooltip="{{'CTA.DETAILS'|translate}}"></nb-icon>
    </a>
</div>
<div class="button-icon">
    <nb-icon class="btn" icon="calendar-outline" (click)="addEvent()" [options]="{ animation: { type: 'pulse' } }"
        nbTooltip="{{'CTA.EVENT'|translate}}"></nb-icon>
</div>
<div class="button-icon">
    <nb-icon class="btn" icon="clipboard-outline" (click)="addOrder()" [options]="{ animation: { type: 'pulse' } }"
        nbTooltip="{{'CTA.ORDER'|translate}}"></nb-icon>
</div>
<div class="button-icon">
    <a [routerLink]="'/prices/list/'+customerId">
    <nb-icon class="btn" icon="trending-up-outline" 
        [options]="{ animation: { type: 'pulse' } }" nbTooltip="{{'CTA.PRICES'|translate}}"></nb-icon>
    </a>
</div>