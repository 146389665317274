import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { MODAL } from 'src/app/models/constants.model';
import { User } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/admin.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'oil-modal-change-password',
  templateUrl: './modal-change-password.component.html',
  styleUrls: ['./modal-change-password.component.scss']
})
export class ModalChangePasswordComponent implements OnInit {

  public user: User

  public forced:boolean = false;

  public oldpwd: string = null;
  public newpwd: string = null;
  public newpwd2: string = null;
  public error: string = null;

  constructor(
    protected ref: NbDialogRef<ModalChangePasswordComponent>,
    private userService : UserService,
    private adminService: AdminService) { }

  ngOnInit(): void {
  }

  isValid() {
    return this.oldpwd && UtilsService.isValidPassword(this.newpwd) && this.newpwd == this.newpwd2;
  }

  save() {    
    this.adminService.changePwd(this.oldpwd, this.newpwd,this.userService.iMClient()).subscribe(res => {
      if (!res.error) {
        this.ref.close(MODAL.confirmOk);
      }
      else {
        this.error = res.error.message;
      }
    });
  }

  cancel() {
    this.ref.close();
  }

}
