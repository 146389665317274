import { Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { CalendarService } from './calendar.service';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class StatusService {

    private selectedDate: moment.Moment;

    @Output() onUpdateSelectedDate = new EventEmitter();

    private _isAdmin: boolean;
    @Input() set isAdmin(value: boolean) {
        this._isAdmin = value;
    }
    get isAdmin(): boolean {
        return this._isAdmin;
    }

    constructor(
    ) {
        this.selectedDate = CalendarService.nextWorkingDay();
    }


    public getSelectedDate(): moment.Moment {
        return this.selectedDate;
    }

    public getLastWeekDay(): moment.Moment{
        return moment(this.selectedDate).add(6, 'day');
    }

    public setSelectedDate(date: moment.Moment) {
        this.selectedDate = date;
        this.onUpdateSelectedDate.emit(this.selectedDate);
    }
}
