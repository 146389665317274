<nb-card>
    <nb-card-header>{{'ORDERS.ORDERMODAL.ADDORDER' | translate}}</nb-card-header>
    <nb-card-body>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.CUSTOMER' | translate}}</span>
            <!-- <input fullWidth status="basic" type="text" nbInput [(ngModel)]="customer"> -->
            <!-- <ng-select [items]="customers" bindLabel="name" bindValue="id" autofocus [(ngModel)]="thisOrder.client_id"
                [disabled]="!modifyCustomer" (change)="updateCustomer($event)"> </ng-select> -->


            <input fullWidth nbInput type="text" [hidden]="modifyCustomer" [disabled]="!modifyCustomer"
                [ngModel]="clientInput?.name" />


            <input fullWidth #autoInput nbInput type="text" *ngIf="modifyCustomer"
                (ngModelChange)="onChangeClientInput($event)" [matAutocomplete]="auto" [ngModel]="clientInput" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="viewHandle">
                <mat-option *ngIf="isLoadingSuggestion" class="is-loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    <span>{{ option?.name }}</span>
                </mat-option>
            </mat-autocomplete>

        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.DISTRIBUTIONPOINT' | translate}}</span>
            <ng-select [items]="points" bindLabel="label" bindValue="id" autofocus
                [(ngModel)]="thisOrder.client_delivery_id" [disabled]="!modifyPoint || !points || points.length === 0"
                (change)="updatePoint($event)"> </ng-select>

        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.PRODUCT' | translate}}</span>
            <ng-select [items]="prices" [disabled]="!prices || prices.length === 0 || !modifyProduct"
                bindLabel="product_name" bindValue="product_id" autofocus [(ngModel)]="thisOrder.product_id"
                (change)="updateProduct($event)">
            </ng-select>
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.QUANTITY' | translate}} <span
                    *ngIf="thisOrder.maxQuantity">({{'ORDERS.ORDERMODAL.MAXQUANTITY' | translate}}
                    {{thisOrder.maxQuantity | number | liter}})</span> </span>
            <input fullWidth status="basic" type="number" (change)="changeQuantity()" (keyup)="changeQuantity()" nbInput
                [(ngModel)]="thisOrder.quantity">
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.PRICE' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput
                [ngModel]="thisOrder.price | currency:'€': 'symbol' : '1.3-3'">
            <nb-checkbox [(ngModel)]="thisOrder.ignore_expiration">
                {{'ORDERS.ORDERMODAL.IGNOREPROMOTIONEXPIRED' | translate}}
            </nb-checkbox>
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.CREDIT' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput
                [value]="remainingCredit-((thisOrder.price?thisOrder.price:0)*(thisOrder.quantity?thisOrder.quantity:0)) | currency:'€'">
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.DELIVERYDATE' | translate}}</span>
            <input fullWidth status="basic" nbInput [nbDatepicker]="datepicker" [(ngModel)]="deliveryDateMoment"
                [disabled]="!modifyDate">
            <nb-datepicker #datepicker format="DD/MM/YYYY"></nb-datepicker>
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.DELIVERYTIME' | translate}}</span>
            <ng-select fullWidth [items]="deliveryTimes" [searchable]="false" [clearable]="false" bindLabel="name" bindValue="name" autofocus [(ngModel)]="thisOrder.deliveryTimeName"></ng-select>
        </div>
        <div>
            <span class="label">{{'ORDERS.ORDERMODAL.NOTE' | translate}}</span>
            <input fullWidth status="basic" type="text" nbInput [(ngModel)]="thisOrder.notes">
        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="save()">{{'ORDERS.ORDERMODAL.INSERT' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'ORDERS.ORDERMODAL.CANCEL' | translate}}</button>
    </nb-card-footer>
</nb-card>