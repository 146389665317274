import { Component, OnInit } from '@angular/core';
import { DeltaApiResponse, DeltaProduct, Settings } from 'src/app/models/settings.model';
import { OrderService } from 'src/app/services/order.service';
import { SettingsService } from 'src/app/services/settings.service';
import { PriceService } from 'src/app/services/price.service';
import { Product } from 'src/app/models/generic.model';
import { Depot } from 'src/app/models/logistic_model';
import { LogisticService } from 'src/app/services/logistic.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'oil-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('1000ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class SettingsComponent implements OnInit {

  // public settings: Settings;

  public expandedElement;

  public workdays: number;
  public structure: number;

  public dataSourceProducts;
  public displayedColumnsProducts: string[] = [
    'name',
    'category',
    'accisa',
    'aliquota_iva',
    'platt',
    'availability',
  ];


  public dataSourceDepots;
  public displayedColumnsDepots: string[] = [
    'code',
    'name',
    'address',
    'primary',
    // 'secondary',
    'delta',
  ];

  public isActiveFilters: boolean = false;

  constructor(
    private settingsService: SettingsService,
    private orderService: OrderService,
    private logisticService: LogisticService
  ) { }

  ngOnInit(): void {

    this.refresh();

  }

  refresh() {
    this.orderService.getProductList().subscribe(productsApi => {
      productsApi.forEach(x => {
        x.availability = !!x.availability;
      })
      this.dataSourceProducts = productsApi;
      console.log('---- ~ file: settings.component.ts ~ line 60 ~ SettingsComponent ~ this.orderService.getProductList ~ this.dataSourceProducts', this.dataSourceProducts);
    })

    this.logisticService.getDepots().subscribe(depotsApi => {
      this.dataSourceDepots = depotsApi;
    })

    this.settingsService.getWorkdays().subscribe(res => {
      this.workdays = res.total;
    })

    this.settingsService.getStructureCost().subscribe((res) => {
      this.structure = res.value;
    })
  }


  plattsCode(id) {
    return PriceService.plattsCode(id);
  }

  changeAvailability(event, element: Product) {
    let availability = event.checked;
    console.log('---- ~ file: settings.component.ts ~ line 70 ~ SettingsComponent ~ changeAvailability ~ availability, element', availability, element);
    this.settingsService.updateProductAvailability(element, availability).subscribe((res) => {
      if (res.ok != false) {
        element.availability = availability;
      } else {
        element.availability = !availability;
      }
    })
  }

  updateAliquota(aliquota: number, element: Product) {
    this.settingsService.updateProductAliquota(element, aliquota).subscribe((res) => {
      if (res.ok != false) {
        element.aliquota_iva = aliquota;
      }

    })
  }

  updateAccisa(accisa: number, element: Product) {
    this.settingsService.updateProductAccisa(element, accisa).subscribe((res) => {
      if (res.ok != false) {
        element.accisa = accisa;
      }

    })
  }


  updateDepotPrimary(primary: number, element: Depot) {
    this.settingsService.updateDepotPrimary(element, primary).subscribe((res) => {
      if (res.ok != false) {
        element.primeCost = primary;
      }

    })
  }

  updateDepotSecondary(secondary: number, element: Depot) {
    this.settingsService.updateDepotSecondary(element, secondary).subscribe((res) => {
      if (res.ok != false) {
        element.secondary = secondary;
      }

    })
  }

  updateDeltas(depot: Depot): DeltaProduct[] {
    if (depot.deltas) {
      return;
    } else {
      this.settingsService.getDepotDelta(depot.id).subscribe((x: DeltaApiResponse) => { depot.deltas = x.items });
    }
  }

  updateDepotDelta(value: number, delta: DeltaProduct) {
    this.settingsService.updateDepotDelta(delta, value, delta.available == 1).subscribe((res) => {
      if (res.ok != false) {
        delta.delta = value;
      }

    })
  }

  changeDepotProductAvailability(event, delta: DeltaProduct) {
    let availability = event.checked;
    this.settingsService.updateDepotDelta(delta, delta.delta, availability).subscribe((res) => {
      if (res.ok != false) {
        delta.available = availability ? 1:0;
      }

    })
  }

  updateStructure(structValue: number) {
    this.settingsService.updateStructureCost(structValue).subscribe((res) => {
    })
  }

  updateWeek() {
    // this.workdays;
    console.log('---- ~ file: settings.component.ts ~ line 151 ~ SettingsComponent ~ updateWeek ~ this.workdays;', this.workdays);
    this.settingsService.saveWorkdays(this.workdays).subscribe(() => { })
  }

  expandToogle(element) {
    this.expandedElement = this.expandedElement === element ? null : element;
    this.updateDeltas(element);
  }

}
