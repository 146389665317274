<nb-card>
  <nb-card-body>
    <div class="filters">


      <div>
        <nb-checkbox (checkedChange)="onChangeFavoriteOnly($event)" [(ngModel)]="favoriteonly">
          {{'PRICES.LIST.FAVORITEONLY' | translate}}
        </nb-checkbox>
        <nb-icon icon="star-on" pack="3logic" class=""> </nb-icon>
      </div>


      <oil-customer-filter [filters]="filters" (selectedChange)="filterChange($event)">
      </oil-customer-filter>
    </div>

  </nb-card-body>
</nb-card>

<div class="daily">
  <div class="daily-row">
    <div class="daily-col-5">
      <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
        <nb-card-body>
          <div class="daily-title">{{'CUSTOMER.DASHBOARD.DAILY.ACTIVECLIENTS' | translate}}
            <span class="daily-subtitle">({{currentPeriodString | translate}})</span>
            <span class="daily-righttitle">{{dailyChartInput?.centerValue}}</span>
          </div>
          <img src="/assets/icons/card/clienti-attivi.svg" alt="trip icon" class="daily-icon" />
          <oil-daily-chart class="daily-chart" [input]='dailyChartInput' [chartType]="'bar'"></oil-daily-chart>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="daily-col-5">
      <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
        <nb-card-body>
          <div class="daily-title">
            {{'CUSTOMER.DASHBOARD.DAILY.ACTIVECLIENTSPERC' | translate}}
            <span class="daily-subtitle">({{currentPeriodString | translate}})</span>
          </div>
          <img src="/assets/icons/card/clienti-attivi-su-totali.svg" alt="trip icon" class="daily-icon" />
          <div class="daily-value">
            {{activeClientsPerc | number:'1.1-1'}}
            <span class="daily-value-post">%</span>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="daily-col-5">
      <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
        <nb-card-body>
          <div class="daily-title">
            {{'CUSTOMER.DASHBOARD.DAILY.CLIENTSNEW' | translate}}
            <span class="daily-subtitle">({{currentPeriodString | translate}})</span>
          </div>
          <img src="/assets/icons/card/nuovi-clienti.svg" alt="trip icon" class="daily-icon" />
          <div class="daily-value">
            <span nbTooltip="{{newClients | number}}">{{newClients | smallNumber :'value'}}</span>
            <span class="daily-value-post">{{newClients | smallNumber :'order'}} </span>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="daily-col-5">
      <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
        <nb-card-body>
          <div class="daily-title">
            <span> {{'CUSTOMER.DASHBOARD.DAILY.SOLDTOTAL' | translate}}
              <span class="daily-subtitle">({{currentPeriodString | translate}})</span> </span>

          </div>
          <img src="/assets/icons/card/mc-venduti.svg" alt="trip icon" class="daily-icon" />
          <div class="daily-value">
            <span nbTooltip="{{soldTotalMC | number : '1.0-0'}}">{{soldTotalMC | smallNumber :'value'}}</span>
            <span class="daily-value-post">{{soldTotalMC | smallNumber :'order'}}</span>

          </div>
        </nb-card-body>
      </nb-card>
    </div>
    <div class="daily-col-5">
      <nb-card [nbSpinner]="isLoading" size="big" class="daily-card">
        <nb-card-body>
          <div class="daily-title">
            {{'CUSTOMER.DASHBOARD.DAILY.MARGINAVG' | translate}}
            <span class="daily-subtitle">({{currentPeriodString | translate}})</span>
          </div>
          <img src="/assets/icons/card/margine-medio.svg" alt="trip icon" class="daily-icon" />
          <div class="daily-value">{{marginAvg | number :'1.1-1'}}
            <span class="daily-value-post">€/MC</span>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </div>
</div>

<nb-card>
  <nb-card-body>

    <div class="card-row">
      <div class="card-col">
        <nb-card>
          <nb-card-header>{{ 'CUSTOMER.DASHBOARD.CHART.AVGORDERS.TITLE' | translate}}</nb-card-header>
          <nb-card-body>
            <div class="graph-container">
              <oil-client-chart [type]="'avgOrders'" [divId]="'avgOrders'" [input]="chartInput"></oil-client-chart>
            </div>
          </nb-card-body>
        </nb-card>

      </div>
      <div class="card-col">
        <nb-card>
          <nb-card-header>{{ 'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.TITLE' | translate}}</nb-card-header>
          <nb-card-body>
            <div class="graph-container">
              <oil-client-chart [type]="'totalOrders'" [divId]="'totalOrders'" [input]="chartInput"></oil-client-chart>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>


    <div class="card-row">
      <div class="card-col">
        <nb-card>
          <nb-card-header>{{ 'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.TITLE' | translate}}</nb-card-header>
          <nb-card-body>
            <div class="graph-container">
              <oil-client-chart [type]="'avgMargin'" [divId]="'avgMargin'" [input]="chartInput"></oil-client-chart>
            </div>
          </nb-card-body>
        </nb-card>

      </div>
      <div class="card-col">
        <nb-card>
          <nb-card-header>{{ 'CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.TITLE' | translate}}</nb-card-header>
          <nb-card-body>
            <div class="graph-container">
              <oil-client-chart [type]="'totalMargin'" [divId]="'totalMargin'" [input]="chartInput"></oil-client-chart>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>


    <div class="card-row">
      <div class="card-col">
        <nb-card>
          <nb-card-header>{{ 'CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.TITLE' | translate}}</nb-card-header>
          <nb-card-body>
            <div class="graph-container">
              <oil-client-chart [type]="'clientsQtyMargin'" [input]="chartInput"></oil-client-chart>
            </div>
          </nb-card-body>
        </nb-card>
      </div>
    </div>


    <div class="card-row chart-options">
      <!-- {{ 'CUSTOMER.DASHBOARD.CHART.OPTIONS.TITLE' | translate}} -->
      <div class="card-col">
        <label class="chart-option-label">{{ 'CUSTOMER.DASHBOARD.CHART.OPTIONS.PERIOD' | translate}}</label>
        <ng-select [items]="periods" bindLabel="name" bindValue="value" (change)="updateCharts()" autofocus
          [(ngModel)]="chartPeriod" [clearable]="false" dropdownPosition="top"> </ng-select>
      </div>
      <div class="card-col">
        <label class="chart-option-label">{{ 'CUSTOMER.DASHBOARD.CHART.OPTIONS.GROUPBY' | translate}}</label>
        <ng-select [items]="groupby" bindLabel="name" bindValue="value" (change)="updateCharts()" autofocus
          [(ngModel)]="chartGroupby" [clearable]="false" dropdownPosition="top"> </ng-select>
      </div>
    </div>

  </nb-card-body>
</nb-card>

<nb-card>
  <nb-card-body>
    <table mat-table [dataSource]="dataSource" matSort #table matSortActive="nearestDateDiffABS" matSortDirection="asc">


      <!-- Preferiti -->
      <ng-container matColumnDef="favorite">
        <th class="favorite" mat-header-cell *matHeaderCellDef>
          <nb-icon icon="star-on" pack="3logic">
          </nb-icon>
        </th>
        <td class="favorite" mat-cell *matCellDef="let element">
          <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic"  (click)="setFavorite(element)">
          </nb-icon>
        </td>
      </ng-container>


      <!-- Ragione sociale -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.DASHBOARD.TABLE.NAME' | translate}} </th>
        <td class="company-name" mat-cell *matCellDef="let element" nbTooltip="{{'CTA.DETAILS'|translate}}"
          nbTooltipPlacement="left">
          <oil-company-name [element]="element" [id]="element.id"></oil-company-name>
        </td>
      </ng-container>

      <!-- Quantità ordine medio cluster -->
      <!-- <ng-container matColumnDef="orderavgcluster">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CUSTOMER.DASHBOARD.TABLE.ORDERAVGCLUSTER' | translate}} </th>
        <td mat-cell *matCellDef="let element">L {{element.clusterAvgOrder | number}} </td>
      </ng-container> -->

      <!-- Quantità ordine medio -->
      <ng-container matColumnDef="deliveryAvgOrderQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.ORDERAVG' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="data-customer">{{element.deliveryAvgOrderQuantity | number | liter}} </div>
          <div class="data-cluster">{{element.clusterAvgOrder | number | liter}} </div>
        </td>
      </ng-container>

      <!-- Quantità massima ordinata -->
      <ng-container matColumnDef="deliveryMaxOrderQuantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.MAXORDER' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="data-customer">{{element.deliveryMaxOrderQuantity | number | liter}} </div>
          <div class="data-cluster">{{element.clusterDeliveryMaxOrderQuantity | number | liter}} </div>
        </td>
      </ng-container>

      <!-- Frequenza ordine media cluster -->
      <ng-container matColumnDef="clusterAvgOrderFreq">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CUSTOMER.DASHBOARD.TABLE.ORDERAVGCLUSTERFREQ' | translate}} </th>
        <td mat-cell *matCellDef="let element">

          <div class="data-customer">{{element.avgOrderFreq | number : '1.0-1'}}
            {{((!element.avgOrderFreq)?'': element.avgOrderFreq ==
            1?'CUSTOMER.DASHBOARD.TABLE.DAY':'CUSTOMER.DASHBOARD.TABLE.DAYS') | translate}}
          </div>
          <div class="data-cluster">{{element.clusterAvgOrderFreq | number : '1.0-1'}}
            {{((!element.clusterAvgOrderFreq)?'': element.clusterAvgOrderFreq ==
            1?'CUSTOMER.DASHBOARD.TABLE.DAY':'CUSTOMER.DASHBOARD.TABLE.DAYS') | translate}}
          </div>

        </td>
      </ng-container>

      <!-- Frequenza Ordini -->
      <ng-container matColumnDef="annualOrders">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CUSTOMER.DASHBOARD.TABLE.ANNUALORDERS' | translate}} </th>
        <td mat-cell *matCellDef="let element">         
          <div class="data-customer">{{getInterationOrder(element.intervalInDays, element.totalOrders)}}</div>
        </td>
      </ng-container>

      <!-- Margine medio cluster -->
      <!-- <ng-container matColumnDef="marginavgcluster">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'CUSTOMER.DASHBOARD.TABLE.MARGINAVGCLUSTER' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.clusterAvgOrderNetMargin | currency:'€'}}</td>
      </ng-container> -->

      <!-- Margine medio -->
      <ng-container matColumnDef="deliveryAvgOrderNetMargin">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.MARGINAVG' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="data-customer">{{element.deliveryAvgOrderNetMargin | currency:'€': 'symbol' : '1.3-3'}} </div>
          <div class="data-cluster">{{element.clusterAvgOrderNetMargin | currency:'€': 'symbol' : '1.3-3'}} </div>
        </td>
      </ng-container>

      <!-- Distanza dal mio deposito -->
      <!-- <ng-container matColumnDef="distance">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.DISTANCE' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.distance}}</td>
      </ng-container> -->

      <!-- Modalità pagamento -->
      <!-- <ng-container matColumnDef="paymentmode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.PAYMENTMODE' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.paymentmode}}</td>
      </ng-container> -->

      <!-- Attesa pagamento -->
      <ng-container matColumnDef="deliveryAvgLatePayment">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.PAYMENTDELAY' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="data-customer">{{element.deliveryAvgLatePayment}} </div>
          <div class="data-cluster">{{element.clusterDeliveryAvgLatePayment}} </div>
        </td>
      </ng-container>

      <!-- Prossimo ordine previsto -->
      <ng-container matColumnDef="nearestDateDiffABS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'CUSTOMER.DASHBOARD.TABLE.ORDERRANGE' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="data-customer range-{{rangeClass(element.orders_ranges)}}"
            *ngIf="rangeValue(element.orders_ranges)">

            <nb-accordion>
              <nb-accordion-item>
                <nb-accordion-item-header>
                  {{ 'CUSTOMER.DASHBOARD.TABLE.'+getNearstDate(element.nearestDateDiff).label | translate}}
                  
                  {{ getNearstDate(element.nearestDateDiff).date | number: '1.0-0' }}
                  
                  {{ 'CUSTOMER.DASHBOARD.TABLE.'+getNearstDate(element.nearestDateDiff).type | translate}}

                </nb-accordion-item-header>
                <nb-accordion-item-body>
                  <div *ngFor="let range of element.orders_ranges">
                    <span>{{'CATEGORIES.'+range.category| translate}}</span>
                    {{range.min_range | amDateFormat:'DD/MM/YY'}} - {{range.max_range | amDateFormat:'DD/MM/YY'}}
                  </div>
                </nb-accordion-item-body>
              </nb-accordion-item>
            </nb-accordion>


          </div>
        </td>
      </ng-container>

      <!-- Call to action -->
      <ng-container matColumnDef="cta">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
        <td mat-cell *matCellDef="let element">
          <oil-cta [customerId]="element.id" [pointId]="element.deliveryId"></oil-cta>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator pageSize="50" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>

    <div class="legend-container">

      <div class="status" *ngFor="let status of ['past','present','future']">
        <div class="legend legend-box {{status}}"></div>
        <span class="legend">{{ 'CUSTOMER.DASHBOARD.LEGEND.' + status.toUpperCase() | translate}}</span>
      </div>
      
    </div>

  </nb-card-body>
</nb-card>