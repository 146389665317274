import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'oil-resetpwd',
  templateUrl: './resetpwd.component.html',
  styleUrls: ['./resetpwd.component.scss']
})
export class ResetpwdComponent implements OnInit {

  public email: string = null;
  public error: string = null;
  public response: string = null;
  public code: string = null;
  public newpwd: string = null;
  public newpwd2: string = null;

  constructor(
    private router: Router,
    private activatedRouter: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.activatedRouter.paramMap.subscribe(navParams => {
      this.code = navParams.get('code');
      console.log('---- ~ file: resetpwd.component.ts ~ line 27 ~ ResetpwdComponent ~ ngOnInit ~ code', this.code);
    })
  }

  isValidSend() {
    return UtilsService.isValidEmail(this.email);
  }

  send() {
    this.userService.sendResetPassword(this.email).subscribe(res => {
      if (res.error) {
        this.error = res.error.message;
      } else {
        this.response = 'sent';
      }
    })


  }

  isValidReset() {
    return UtilsService.isValidPassword(this.newpwd) && this.newpwd == this.newpwd2;
  }

  reset() {


    this.userService.resetPassword(this.code, this.newpwd).subscribe(res => {
      if (res && res.error) {
        this.error = res.error.message;
      } else {
        this.response = 'reset';
      }
    })
  }

  cancel() {
    this.router.navigate(['auth/login']);
  }


}
