<ng-container *ngIf="isBannerVisible()">
    <div class="block-all"></div>
</ng-container>

<div class="privacy-and-cookies {{bannerOpenStateClass}}" *ngIf="isBannerVisible()">
    <nb-card class="modal">
        <nb-card-header>{{ 'COOKIES.TITLE' | translate }}
            <button nbButton class="exit-button" 
                (click)="acceptBasic()">x</button>
        </nb-card-header>
        <nb-card-body>
            {{ 'COOKIES.TEXT' | translate }}

            <nb-accordion>
                <nb-accordion-item (collapsedChange)="openDetails($event)" [collapsed]="detailCollapsed">
                    <nb-accordion-item-header>
                        {{ 'COOKIES.BANNER_TITLE' | translate }}
                    </nb-accordion-item-header>
                    <nb-accordion-item-body class="detail">
                        <nb-list>
                            <nb-list-item class="cookie-detail-item">
                                <div>
                                    <div class="cookie-detail-title">{{ 'COOKIES.NECESSARY_COOKIES_TITLE' | translate }}</div>
                                    <div class="cookie-detail-buttons">{{ 'COOKIES.NECESSARY_COOKIES_CONSTRAIN' | translate }}</div>
                                    <div class="cookie-detail-text"> {{ 'COOKIES.NECESSARY_COOKIES_TEXT' | translate }} </div>

                                    <nb-accordion>
                                        <nb-accordion-item>
                                            <nb-accordion-item-header>
                                                {{ 'COOKIES.SHOW_REQUESTED' | translate }}
                                            </nb-accordion-item-header>
                                            <nb-accordion-item-body>
                                                <table class="table">
                                                    <thead>
                                                        <tr class="table--header">
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.COMPANY' |
                                                                translate }} </th>
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.DOMAIN' |
                                                                translate }} </th>
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.MORE_INFO'
                                                                | translate }} </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr class="table--row no-border-bottom">
                                                            <td class="table--row--cell cell"> 3logic </td>
                                                            <td class="table--row--cell cell"> 3logic.it </td>
                                                            <td class="table--row--cell cell"><a class="cookie-detail-link"
                                                                    [routerLink]="'/auth/policy'">Cookie
                                                                    Policy</a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </nb-accordion-item-body>
                                        </nb-accordion-item>
                                    </nb-accordion>
                                </div>
                            </nb-list-item>

                            <nb-list-item class="cookie-detail-item">
                                <div>
                                    <div class="cookie-detail-title">{{ 'COOKIES.STAT_COOKIES_TITLE' | translate }}
                                    </div>
                                    <div class="cookie-detail-buttons">
                                        <button nbButton size="tiny" [status]="isCookieAccepted(1) > 0?'danger':0"
                                            (click)="setCookiePreference(1, true)">{{'COOKIES.YES' |
                                            translate}}</button>
                                        <button nbButton size="tiny" [status]="isCookieAccepted(1) < 0?'danger':0"
                                            (click)="setCookiePreference(1, false)">{{'COOKIES.NO' |
                                            translate}}</button>
                                    </div>
                                    <div class="cookie-detail-text"> {{ 'COOKIES.STAT_COOKIES_TEXT' | translate }} </div>

                                    <nb-accordion>
                                        <nb-accordion-item>
                                            <nb-accordion-item-header>
                                                {{ 'COOKIES.SHOW_REQUESTED' | translate }}
                                            </nb-accordion-item-header>
                                            <nb-accordion-item-body>
                                                <table class="table">
                                                    <thead>
                                                        <tr class="table--header">
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.COMPANY' |
                                                                translate }} </th>
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.DOMAIN' |
                                                                translate }} </th>
                                                            <th class="table--header--cell cell"> {{ 'COOKIES.MORE_INFO'
                                                                | translate }} </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr class="table--row no-border-bottom">
                                                            <td class="table--row--cell cell"> sentry.io </td>
                                                            <td class="table--row--cell cell"> https://sentry.io/ </td>
                                                            <td class="table--row--cell cell">
                                                                <a class="cookie-detail-link"
                                                                    href="https://sentry.io/terms/">Terms and
                                                                    Conditions</a>
                                                                <a class="cookie-detail-link"
                                                                    href="https://sentry.io/privacy/">Privacy
                                                                    Policy</a>
                                                            </td>
                                                        </tr>
                                                        <tr class="table--row no-border-bottom">
                                                            <td class="table--row--cell cell"> google analitycs </td>
                                                            <td class="table--row--cell cell"> www.google.com/analytics </td>
                                                            <td class="table--row--cell cell">
                                                                <a class="cookie-detail-link"
                                                                    href="https://www.google.com/analytics/terms/">Terms and
                                                                    Conditions</a>
                                                                <a class="cookie-detail-link"
                                                                    href="https://policies.google.com/technologies/partner-sites">Privacy
                                                                    Policy</a>
                                                            </td>
                                                        </tr>
                                                        <!-- <tr class="table--row no-border-bottom">
                                                            <td class="table--row--cell cell"> google analitycs </td>
                                                            <td class="table--row--cell cell"> www.google.com/analytics </td>
                                                            <td class="table--row--cell cell">
                                                                <a class="cookie-detail-link"
                                                                    href="https://www.google.com/analytics/terms/">Terms and
                                                                    Conditions</a>
                                                                <a class="cookie-detail-link"
                                                                    href="https://policies.google.com/technologies/partner-sites">Privacy
                                                                    Policy</a>
                                                            </td>
                                                        </tr> -->
                                                    </tbody>

                                                </table>
                                            </nb-accordion-item-body>
                                        </nb-accordion-item>
                                    </nb-accordion>
                                </div>
                            </nb-list-item>

                        </nb-list>
                    </nb-accordion-item-body>
                </nb-accordion-item>
            </nb-accordion>

        </nb-card-body>

        <nb-card-footer class="modal-footer">
            <button nbButton class="right modal-buttons" *ngIf="!isDetailVisible" status="danger"
                (click)="acceptBasic()">{{
                'COOKIES.ACCEPTBASIC' | translate }}</button>

            <button nbButton class="left modal-buttons" *ngIf="!isDetailVisible" (click)="preferences()">{{
                'COOKIES.SETTINGS' | translate }}</button>

            <button nbButton class="right modal-buttons" *ngIf="!isDetailVisible" status="danger"
                (click)="acceptAll()">{{
                'COOKIES.ACCEPTALL' | translate }}</button>

            <button nbButton class="right modal-buttons" *ngIf="isDetailVisible" [disabled]="!allCookiesAreSetted()"
                status="danger" (click)="accept()">{{
                'COOKIES.ACCEPT' | translate }}</button>

        </nb-card-footer>

    </nb-card>
</div>