import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { MatSort } from '@angular/material/sort';
import { OrderService } from 'src/app/services/order.service';
import { Order, OrderList } from 'src/app/models/order.model';
import { MatTableDataSource } from '@angular/material/table';
import { dailyInputs, GenericFilter, SortPaginator } from 'src/app/models/generic.model';
import { Router } from '@angular/router';
import { multifilter } from 'src/app/models/customerfilter.model';
import { MatPaginator } from '@angular/material/paginator';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CustomerFilterComponent } from 'src/app/components/customer-filter/customer-filter.component';
import { ModalService } from 'src/app/services/modal.service';
import { CalendarService } from 'src/app/services/calendar.service';
import { CATEGORIES_COLORS, MODAL, ORDERSTATUS_VALUES } from 'src/app/models/constants.model';
import { PriceService } from 'src/app/services/price.service';
import { Customer } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';

@Component({
  selector: 'oil-orderlist',
  templateUrl: './orderlist.component.html',
  styleUrls: ['./orderlist.component.scss']
})
export class OrderlistComponent implements OnInit {

  public selected = CalendarService.nextWorkingDay();
  public dateFilter = moment(this.selected);

  public dataSource;
  public searchstring = null;
  public activeFilters: multifilter[] = [];

  public resultsLength = 0;
  private initializing = true;

  public selectedProduct;
  public products = [];

  public isLoading: boolean = false;
  public ordersDelta: number;
  public ordersAvgDelta: number;
  public ordersAvg: number;
  public ordersTotal: number;
  public chartInput: dailyInputs;

  public translations;

  private storage = window.localStorage;

  public filters: GenericFilter[] = [
    CustomerFilterComponent.STATUS(),
    CustomerFilterComponent.ORDERSTATUS()
  ];

  public displayedColumns: string[] = [
    // 'favorite',
    // 'euro',
    'number',
    'name',
    'category',
    'product',
    'quantity',
    'priceunit',
    'price',
    'fido',
    'delivery',
    'status',
    'delete',
    'closeby'
  ];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private orderService: OrderService,
    private priceService: PriceService,
    private router: Router,
    private translate: TranslateService,
    private customerService: CustomerService,
    private modalService: ModalService
  ) {
    setTimeout(() => {
      this.translate.get(
        [
          'ORDERS.LIST.CHARTLABEL',
          'CATEGORIES.GAS.RISC',
          'CATEGORIES.GAS.AUTO',
          'CATEGORIES.GAS.AGR',
          'CATEGORIES.ALTRO',
          'ORDERS.LIST.TABLE.DELETECONFIRMMSG',
          'ORDERS.LIST.TABLE.DELETECONFIRMTITLE',
        ]).subscribe(translations => {
          this.translations = translations;
        })
    }, 1);

  }

  ngOnInit(): void {


    this.translate.get('ORDERS.LIST.ALLPRODUCTS').subscribe(t => {
      this.orderService.getProductList().subscribe(productsApi => {
        this.products = productsApi;
        this.products.unshift({
          id: null,
          name: t
        })
      })
    })

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (!this.initializing) {
            this.refresh();
          }
          return of([]);
        })
      ).subscribe(data => {
        // console.log('-------: DashboardComponent -> ngOnInit -> data', data);
      });


    setTimeout(() => {
      // this.refresh();
      this.initializing = false;
    }, 1);
  }

  refresh() {
    this.isLoading = true;
    setTimeout(() => {
      const paginator: SortPaginator = {
        orderBy: this.sort.active,
        start: this.paginator.pageIndex,
        limit: this.paginator.pageSize
      }
      if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

      this.orderService.getListTable(this.activeFilters, this.dateFilter, this.selectedProduct, paginator).subscribe(x => {

        if (x.total && x.items) {
          this.dataSource = new MatTableDataSource<OrderList>(x.items);
          this.resultsLength = x.total;
        } else {
          this.dataSource = []
          this.resultsLength = 0;
        }
      });

      this.orderService.getDailyDetails(this.activeFilters, this.dateFilter, this.selectedProduct, paginator).subscribe(x => {
        this.ordersDelta = x.ordersDelta;
        this.ordersAvgDelta = x.avgQuantityDelta;
        this.ordersAvg = x.avgQuantity;
        this.ordersTotal = Math.round(x.totalQuantity);
        let series = [];
        x.ordersCategories.forEach(ordCat => {
          series.push({
            name: this.translations[`CATEGORIES.${ordCat.category}`],
            value: ordCat.total,
            color: CATEGORIES_COLORS[ordCat.category]
          })
        })
        this.chartInput = {
          series: series,
          centerValue: x.totalOrders,
          // centerLabel: this.translations['ORDERS.LIST.CHARTLABEL']
        }
        this.isLoading = false;
      });

    }, 0)
  }

  filterChange($event) {
    this.activeFilters = $event;
    //if (!this.initializing) { 
    this.refresh();
    // }
  }

  selectedDateChange(date) {
    this.dateFilter = date;
    if (!this.initializing) {
      this.refresh();
    }
  }

  delete(element: OrderList) {
    this.modalService.confirm(this.translations['ORDERS.LIST.TABLE.DELETECONFIRMMSG'], this.translations['ORDERS.LIST.TABLE.DELETECONFIRMTITLE']).subscribe(res => {
      if (res == MODAL.confirmOk) {
        this.orderService.delete(element.order_id).subscribe(() => {
          this.refresh();
        });

      }
    })

  }

  async add() {

    if (await this.checkPrices()) {
      console.log('-------: OrderlistComponent -> add -> add');
      // this.router.navigateByUrl(`orders/new/0`).then(x => { });
      this.modalService.createOrder().pipe(take(1)).subscribe(() => {
        this.refresh();
      });
    }
  }

  addOrder(element) {
    console.log('-------: OrderlistComponent -> addOrder -> element', element);
    // this.orderService.createOrderForCustomer(element.client_id)
    // this.orderService.createOrderForPoint(element.client_id,element.client_delivery_id);
  }

  searchUpdate() {
    console.log('-------: OrderlistComponent -> searchUpdate -> this.searchstring', this.searchstring);
  }

  isStatusOk(row) {
    return [
      ORDERSTATUS_VALUES.APPROVED,
      ORDERSTATUS_VALUES.DELIVERED,
      ORDERSTATUS_VALUES.IN_TRANSIT,
      ORDERSTATUS_VALUES.SCHEDULED
    ].find(x => x == row.status);
  }

  isStatusApproved(row) {
    return [
      ORDERSTATUS_VALUES.APPROVED
    ].find(x => x == row.status);
  }

  isNotPast() {
    return this.dateFilter.isSameOrAfter(moment(), 'day');
  }

  closeby(order) {
    this.modalService.closebyOrder(order).pipe(take(1)).subscribe(() => {
      this.refresh();
    });
  }

  async cloneDaily() {
    if (await this.checkPrices()) {
      this.orderService.cloneOrderForToday().pipe(take(1)).subscribe(() => {
        this.refresh();
      });
    }
  }

  async checkPrices(): Promise<boolean> {
    let res = await this.priceService.hasTodayPrices(moment()).toPromise();
    if (!res.success) {

      this.translate.get(['ORDERS.LIST.NOPRICETITLE', 'ORDERS.LIST.NOPRICEMESAGE']).subscribe(t => {
        this.modalService.info(t['ORDERS.LIST.NOPRICEMESAGE'], t['ORDERS.LIST.NOPRICETITLE'])
      });
    }
    return res.success;
  }


  setFavorite(point: Customer) {
    this.customerService.setCustomerPointFavorite(point.id, !point.favorite).subscribe(() => {
      point.favorite = !point.favorite;
    })
  }

  isToday() {
    return moment().isSame(this.dateFilter, 'day');
  }

}
