<nb-card>
    <nb-card-header>{{(!customerEvent.id?'CUSTOMER.CALENDAR.ADDEVENT' : 'CUSTOMER.CALENDAR.EDITEVENT') | translate}}
    </nb-card-header>
    <nb-card-body>
        <div>
            <span class="label">{{'CUSTOMER.CALENDAR.CUSTOMER' | translate}}</span>
            <!-- <input fullWidth status="basic" type="text" nbInput [(ngModel)]="customer"> -->
            <!-- <ng-select [items]="customers" bindLabel="name" bindValue="id" autofocus
                [(ngModel)]="customerEvent.customerId" [disabled]="!modifyCustomer" (change)="updateCustomer($event)">
            </ng-select> -->

            <input fullWidth nbInput type="text" [hidden]="modifyCustomer" [disabled]="!modifyCustomer"
                [ngModel]="clientInput?.name" />

            <!-- <input fullWidth #autoInput nbInput type="text" *ngIf="modifyCustomer"
                (ngModelChange)="onChangeClientInput($event)" [nbAutocomplete]="auto" [ngModel]="clientInput" />

            <nb-autocomplete #auto (selectedChange)="updateCustomer($event)" [handleDisplayFn]="viewHandle">
                <nb-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    {{ option?.name }}
                </nb-option>
            </nb-autocomplete> -->

            <input fullWidth #autoInput nbInput type="text" *ngIf="modifyCustomer"
                (ngModelChange)="onChangeClientInput($event)" [matAutocomplete]="auto" [ngModel]="clientInput" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="viewHandle">
                <mat-option *ngIf="isLoadingSuggestion" class="is-loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    <span>{{ option?.name }}</span>
                </mat-option>
            </mat-autocomplete>
        </div>
        <div>
            <span class="label">{{'CUSTOMER.CALENDAR.DISTRIBUTIONPOINT' | translate}}</span>
            <ng-select [items]="points" bindLabel="label" bindValue="id" autofocus [(ngModel)]="customerEvent.pointId"
                [disabled]="!modifyPoint || !points || points.length === 0"> </ng-select>
        </div>
        <div class="notes">
            <span class="label">{{'CUSTOMER.CALENDAR.NOTE' | translate}}</span>
            <!-- <input fullWidth status="basic" type="text" nbInput [(ngModel)]="customerEvent.note"> -->
            <ckeditor class="notes-editor" tagName="textarea" [editor]="ckEditor" (change)="limitData($event)"
                [(ngModel)]="customerEvent.note"
                [config]="{ toolbar: [ 'bold', 'italic', 'bulletedList', 'numberedList' ]  }"></ckeditor>
            <div class="charcounter"> {{charCount}}/{{CHARMAX}} </div>
        </div>
        <div>
            <span class="label">{{'CUSTOMER.CALENDAR.DATE' | translate}}</span>
            <input fullWidth status="basic" nbInput [nbDatepicker]="datepicker" [(ngModel)]="dateMoment">
            <nb-datepicker #datepicker format="DD/MM/YYYY"></nb-datepicker>
        </div>
    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons"
            [disabled]="!customerEvent.customerId||!customerEvent.pointId||!dateMoment"
            (click)="save()">{{(!customerEvent.id?'CUSTOMER.CALENDAR.INSERT':'CUSTOMER.CALENDAR.SAVE') |
            translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'CUSTOMER.CALENDAR.CANCEL' | translate}}</button>
    </nb-card-footer>
</nb-card>