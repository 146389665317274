import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { OrderListApiResponse, Order, TripApiResponse, Trip, Promotion, Attachment } from '../models/order.model';
import { SortPaginator, Product } from '../models/generic.model';
import { multifilter } from '../models/customerfilter.model';
import * as moment from 'moment';
import { config } from 'src/environments/config';
import { map } from 'rxjs/operators';
import { DailyTripsResponse, Depot, DepotsResponse, DriversApiResponse, TripStatus, TrucksResponse, WorkingRespone } from '../models/logistic_model';
import { Moment } from 'moment';
import { NULL_EXPR } from '@angular/compiler/src/output/output_ast';


@Injectable({
    providedIn: 'root'
})
export class LogisticService {


    public depots: Depot[] = null;


    constructor(
        private api: ApiService
    ) {

    }

    public getOrderUnassignedList(
        // paginator: SortPaginator, 
        selectedDate: Date): Observable<OrderListApiResponse> {
        const parameters = { date: moment(selectedDate).format(config.apiDateFormat) };
        // Object.assign(parameters, paginator);
        return this.api.call('cms/logistic/orders/unallocated', 'GET', null, parameters);
    }

    public getTrips(paginator: SortPaginator, baseName: string, date: Date): Observable<TripApiResponse> {
        const parameters = { data: moment(date).format(config.apiDateFormat) };
        Object.assign(parameters, paginator);
        return this.api.call('cms/logistic', 'GET', null, parameters).pipe(map((resp: TripApiResponse) => {
            // console.log('-------: LogisticService -> resp', resp);
            if (resp && resp.items) {
                resp.items.forEach((trip, index) => {
                    // this.addDestinations(trip);
                    trip.name = baseName + ' ' + trip.routeId; //+ (index + 1);
                    LogisticService.setFillingQuantity(trip);
                })
            }
            return resp;
        })); // TODO logistic version service
    }

    public static setFillingQuantity(trip: Trip) {
        let total = trip.maxTowCapacity + trip.maxTruckCapacity;
        let filling = 0;
        if (trip.tanks) {
            trip.tanks.forEach(t => {
                // total += t.maxCapacity
                filling += t.fillingQuantity
            })
            trip.fillingPercentual = Math.round(filling / total * 100);
        }
    }

    public getTravelsTrips(paginator: SortPaginator, baseName: string, date: Date, filterString: string, filterType: string) {
        const parameters = { data: moment(date).format(config.apiDateFormat), searchString: filterString, filterType: filterType };
        Object.assign(parameters, paginator);
        return this.api.call('cms/logistic', 'GET', null, parameters).pipe(map((resp: TripApiResponse) => {
            if (resp.items) {
                resp.items.forEach((trip, index) => {
                    // this.addDestinations(trip);
                    trip.name = baseName + ' ' + (index + 1);

                    LogisticService.setFillingQuantity(trip);
                })
            }
            return resp;
        })); // TODO logistic version service
    }

    private sameCoordinates(locationA: number[], locationB: number[], precision: number = 2): boolean {
        let round = x => Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision);
        if (locationA &&
            locationA.length > 1 &&
            locationB &&
            locationB.length > 1) {
            return round(locationA[0]) === round(locationB[0]) && round(locationA[1]) === round(locationB[1])
        }
        return false
    }

    public addDestinations(trip: Trip) {
        trip.destinations = [];
        // trip.trips.waypoints.sort((a,b) => a.waypoint_index - b.waypoint_index).forEach(waypoint => {
        //     console.log('----', waypoint.name);
        //     trip.orders.forEach(order => {
        //         if (this.sameCoordinates(waypoint.location, order.location.coordinates) &&
        //             !trip.destinations.find(d => d.deliveryId === order.deliveryId)) {
        //             trip.destinations.push(order);
        //         }
        //     })
        // })
        trip.orders.sort((a, b) => {
            // a.images=[ //TODO remove this!!!!
            //     'https://picsum.photos/5000/3000',
            //     'https://picsum.photos/300/300'
            // ]
            return a.deliverySequence - b.deliverySequence
        }

        ).forEach(order => {
            if (!trip.destinations.find(d => d.deliveryId === order.deliveryId)) {
                trip.destinations.push(order);
            }
        })
    }

    public getTrip(tripId: number, isConfirmed: boolean): Observable<Trip> {
        const params = { confirmed: isConfirmed ? 1 : 0 };
        return this.api.call(`admin/logistic/${tripId}/trip`, 'GET', null, params).pipe(map((trip: Trip) => {
            this.addDestinations(trip);
            return trip;;
        }))
    }

    public calculateTrips(date: Moment) : Observable<any> {
        const params = { data: date.format('YYYY-MM-DD') };
        console.log('-------: LogisticService -> calculateTrips -> calculateTrips');
        return this.api.call(`admin/logistic/routes/create`, 'POST', null, params);
    }

    public cleanTrips(date: Moment) : Observable<any> {
        const params = { data: date.format('YYYY-MM-DD') };
        console.log('-------: LogisticService -> cleanTrips -> cleanTrips');
        return this.api.call(`admin/logistic/routes/clean`, 'POST', null, params);
    }

    public confirmTrips(date: Moment) : Observable<any> {
        console.log('-------: LogisticService -> confirmTrips -> confirmTrips');
        const params = { date: date.format('YYYY-MM-DD') };
        return this.api.call(`admin/logistic/routes/close`, 'POST', params);
    }

    public confirmTrip(tripId: number, driverId: number = null) : Observable<any> {
        console.log('-------: LogisticService -> confirmTrips -> confirmTrip', tripId);
        let data = {};
        if (driverId) {
            data = { driverId };
        }
        return this.api.call(`admin/logistic/route/${tripId}/close`, 'POST', data);
    }

    public removeTrip(tripId: number) : Observable<any> {
        console.log('-------: LogisticService -> removeTrip -> removeTrip', tripId);
        let data = {};        
        return this.api.call(`admin/logistic/route/${tripId}`, 'DELETE', data);
    }

    public unconfirmTrip(tripId: number) : Observable<any> {
        return this.api.call(`admin/logistic/route/${tripId}/reopen`, 'POST');
    }

    public addOrderToTrip(tripId: number, orderId: number) : Observable<any> {
        const parameters = { routeId: tripId, orderId: orderId };
        return this.api.call(`admin/logistic/route/orders/add`, 'POST', parameters);
    }

    public moveOrderToTrip(oldTripId: number, newTripId: number, orderId: number) : Observable<any> {
        const parameters = { newRouteId: newTripId, oldRouteId: oldTripId, orderId: orderId };
        return this.api.call(`admin/logistic/route/orders/move`, 'POST', parameters);
    }

    public removeOrderFromTrip(tripId: number, orderId: number) : Observable<any> {
        const parameters = { routeId: tripId, orderId: orderId };
        return this.api.call(`admin/logistic/route/orders/remove`, 'POST', parameters);
    }

    public deleteTrip(tripId: number): Observable<any> {
        return this.api.call(`admin/logistic/route/${tripId}`, 'DELETE');
    }

    public lauchPromotion(trips: Trip[], date: Moment, endingTime: moment.Moment) {
        const parameters = {
            date: date.format(config.apiDateFormat),
            hour: endingTime.hour(),
            minutes: endingTime.minute(),
            //'tripsId': trips 
        };
        return this.api.call(`admin/logistic/promotions/start`, 'POST', parameters); // TODO add parameters

    }

    public async getLastOptimDate(): Promise<Date> {
        return new Date((await this.api.call(`admin/logistic/routes/lastOptimDate`, 'GET').toPromise()).lastOptimDate);
    }

    public setOrderDelivered(order: Order, delivered: boolean = true) : Observable<any> {
        if (delivered) {
            return this.api.call(`admin/logistic/orders/${order.orderId}/confirm-delivery`, 'POST');
        } else {
            return this.api.call(`admin/logistic/orders/${order.orderId}/unconfirm-delivery`, 'POST');
        }
    }

    public setTripStatus(trip: Trip, tripStatus: TripStatus) : Observable<any> {
        return this.api.call(`admin/logistic/route/${trip.routeId}/update-status`, 'POST', null, { status: tripStatus });
    }

    public addPromotion(promotion: Promotion) : Observable<any> {
        return this.api.call(`admin/logistic/promotion/add`, 'POST', promotion); // TODO check post data format

    }

    public sendPromotion(promotionId: number) : Observable<any> {
        return this.api.call(`admin/logistic/promotions/${promotionId}/confirm`, 'POST');
    }

    public delayOrder(order: Order) : Observable<any> {
        return this.api.call(`admin/logistic/orders/${order.orderId}/delay`, 'POST');
    }

    public delayTrip(trip: Trip) : Observable<any> {
        return this.api.call(`admin/logistic/route/${trip.routeId}/delay`, 'POST');
    }

    public isWorkingTrips(): Observable<WorkingRespone> {
        return this.api.call(`admin/trips/sync`, 'GET')
    }

    public createTrip(orderId: number, truck: string, tow: string) : Observable<any> {
        const data = {
            orderId: orderId,
            targaTruck: truck,
            targaTow: tow
        };
        return this.api.call(`admin/logistic/route/add`, 'POST', data);

    }

    public getDepots(): Observable<Depot[]> {
        if (this.depots) {
            return of(this.depots);
        } else {
            return this.api.call(`admin/logistic/depots`, 'GET').pipe(map((res: DepotsResponse) => {
                this.depots = res.items;
                return res.items;
            }));

        }
    } v

    public getTrucksShortList(date: Moment): Observable<TrucksResponse> {
        return this.api.call(`admin/logistic/trucks`, 'GET', null, { date: date.format(config.apiDateFormat), 'status[]': 'available' })
    }

    public getTrucksCompleteList(date: Moment): Observable<TrucksResponse> {
        return this.api.call(`admin/logistic/trucks`, 'GET', null, { date: date.format(config.apiDateFormat) })
    }

    public updateTruckStatus(date: Moment, targa: string, status: string, depotId: number) : Observable<any> {
        const data = {
            date: date.format(config.apiDateFormat),
            targa: targa,
            status: status,
            depotId: depotId,
        };
        return this.api.call(`admin/logistic/trucks/setStatus`, 'POST', data)
    }

    public getDailyDetails(date: Moment): Observable<DailyTripsResponse> {
        return this.api.call(`admin/logistic/daily-details`, 'GET', null, { date: date.format(config.apiDateFormat) })
    }

    public getDriversList(tripId: number): Observable<DriversApiResponse> {
        return this.api.call(`admin/logistic/drivers/list`, 'GET', null, { id: tripId });
    }

    public assignDriver(driverId: number, tripId: number) : Observable<any> {
        const data = { driverId, tripId };
        return this.api.call(`admin/logistic/drivers/assign-trip`, 'POST', data)
    }

    public getOrderImage(imgId): Observable<Attachment> {
        return this.api.call(`admin/logistic/route/orders/attachment/${imgId}`, 'GET', null, null, false, true);
    }
}
