import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MODAL } from 'src/app/models/constants.model';
import { Customer, CustomerBase } from 'src/app/models/customer.model';
import { User } from 'src/app/models/user.model';
import { AdminService } from 'src/app/services/admin.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UtilsService } from 'src/app/services/utils.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-modal-new-user',
  templateUrl: './modal-new-user.component.html',
  styleUrls: ['./modal-new-user.component.scss']
})
export class ModalNewUserComponent implements OnInit {

  // public name: string = null;
  // public lastName: string = null;
  // public role: string = null;

  // public email: string = null;
  public email2: string = null;
  public client: CustomerBase = null;
  public clientId: number = null;
  public user: User = null;

  public isLoadingSuggestion: boolean = false;
  public filteredOptions$: Observable<CustomerBase[]>;

  public roleList = Object.values(config.userType);
  public roleList2 = ["a", "b"];

  public lockForClient: boolean = false;

  constructor(
    protected ref: NbDialogRef<ModalNewUserComponent>,
    private customerService: CustomerService,
    private adminService: AdminService
  ) {


  }

  ngOnInit(): void {

    if (!this.user) {
      this.user = { id: null, clientId: this.clientId };
    } else {
      // this.user = Object.assign({},this.user);
      this.email2 = this.user.email;
    }
    if (this.lockForClient) {
      this.user.role = config.userType.CLIENT;
    }
    if (this.user.clientId) {
      this.customerService.getDetails(this.user.clientId.toString(), true).subscribe(customer => {
        this.client = customer;
        this.onChangeClientInput(customer);
      })
    }

  }

  save() {

    let fun = this.adminService.createUser(this.user);
    if (this.user && this.user.id) {
      fun = this.adminService.editUser(this.user);
    }
    fun.subscribe(() => {
      this.ref.close(MODAL.confirmOk);
    });
  }

  roleForClient() {
    return this.user.role == config.userType.CLIENT
  }

  isValid() {
    const ret = UtilsService.isValidEmail(this.user.email) &&
      this.user.email == this.email2 &&
      this.user.firstName &&
      this.user.lastName &&
      this.user.role &&
      (!this.roleForClient() || this.user.clientId);
    console.log('---- ~ file: modal-new-user.component.ts ~ line 90 ~ ModalNewUserComponent ~ isValid ~ ret', ret, this.user, this.email2);
    return ret;
  }

  cancel() {
    this.ref.close();
  }



  getClientFilteredOptions(filter: string): Observable<CustomerBase[]> {
    this.isLoadingSuggestion = true;
    return this.customerService.getFiltered(filter).pipe(map(x => { this.isLoadingSuggestion = false; return x.items; }));
  }

  onChangeClientInput(value: string | CustomerBase) {
    if (typeof value == 'string') {
      // this.filteredOptions$ = of(null);
      this.filteredOptions$ = this.getClientFilteredOptions(value);
      // return this.filteredOptions$;
    } else {
      this.updateCustomer(value);
    }
  }

  updateCustomer($event: CustomerBase) {
    console.log('---- ~ file: modal-new-user.component.ts ~ line 100 ~ ModalNewUserComponent ~ updateCustomer ~ $event', $event);
    this.clientId = $event.id;
    this.user.clientId = this.clientId;
  }

  viewHandle(value: CustomerBase) {
    if (value && value.name)
      return value.name;
    return value;
  }


}
