<div class="ribbon">
    <!-- <nb-icon class="btn" icon="arrowhead-left-outline" (click)="prev(49)" [options]="{ animation: { type: 'pulse' } }"></nb-icon> -->
    <div class="wrap-badge"> 
        <nb-icon class="btn" icon="arrow-ios-back-outline" (click)="prev()" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
        <nb-badge *ngIf="badge['unassignedOlderOrders'] > 0" [text]="'+'" status="danger" position="top right" class="badge-arrow"></nb-badge>
    
    </div>
    
    <ng-container *ngFor="let day of week">
        <div (click)="isFuture(day)?null:select(day)" [ngClass]="{'selected':isSelected(day),'future':isFuture(day)}"
            class="ribbon-day wrap-badge">
            <nb-badge *ngIf="badge[day.format('yyyy-MM-DD')]" [text]="badge[day.format('yyyy-MM-DD')]" status="danger" position="top right" class="badge-day"></nb-badge>

            <div class="ribbon-day-date">{{day | amDateFormat:'DD/MM'}}</div>
            <div class="ribbon-day-weekday" *ngIf="!isToday(day) && !isTomorrow(day)">{{day | amDateFormat:'dddd'}}
            </div>
            <div class="ribbon-day-weekday" *ngIf="isToday(day)">{{'DATERIBBON.TODAY' | translate}}</div>
            <div class="ribbon-day-weekday" *ngIf="isTomorrow(day)">{{'DATERIBBON.TOMORROW' | translate}}</div>

        </div>
    </ng-container>
    <div class="wrap-badge"> 
        <nb-icon class="btn" icon="arrow-ios-forward-outline" (click)="canNext()?next():null"
        [ngClass]="{'future':!canNext()}" [options]="{ animation: { type: canNext()?'pulse':null } }"></nb-icon>
        <nb-badge *ngIf="badge['unassignedFutureOrders'] > 0" [text]="'+'" status="danger" position="top right" class="badge-arrow"></nb-badge>

    </div>
    <nb-icon class="btn dpicker" icon="calendar-outline" (click)="openCalendar()" [options]="{ animation: { type: canNext()?'pulse':null } }"></nb-icon>
    
</div>