<nb-card class="modal">
    <nb-card-header>{{'ORDERS.PROMOTIONTRIPMODAL.TITLE'|translate}} {{thisPromotion?.clientName}}</nb-card-header>

    <nb-card-body class="modal">
        <div class="map">
            <oil-tripmap class="" [geojson]="trip?.trips" [destinations]="trip?.destinations"
                [depots]="[trip?.fuelDepot]" [extraMarker]="thisPromotion?.deliveryLocation?.coordinates">
            </oil-tripmap>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton (click)="cancel()">{{'ORDERS.PROMOTIONTRIPMODAL.CLOSE'|translate}}</button>
    </nb-card-footer>

</nb-card>