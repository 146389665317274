import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smallNumber'
})
export class SmallNumber implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let retVal = 0;
    let retOrder = '';
    if (!value) { if (args[0] && args[0] == 'order') return ''; else return 0; }

    retVal = Math.abs(value)
    if (retVal > 1000) {
      retVal = retVal / 1000;
      retOrder = 'K'
      if (retVal > 1000) {
        retVal = retVal / 1000;
        retOrder = 'M'
      }
      if (retVal > 1000) {
        retVal = retVal / 1000;
        retOrder = 'B'
      }
      if (retVal > 1000) {
        retVal = retVal / 1000;
        retOrder = 'T'
      }
    }

    if (retVal > 100) {
      retVal = Math.round(retVal);
    } else if (retVal > 10) {
      retVal = Math.round(retVal * 10) / 10;
    } else {
      retVal = Math.round(retVal * 100) / 100;
    }

    retVal * Math.sign(value);

    let retValString = (retVal+'').replace('.',',');

    
    if (args && args[0] && args[0] == 'value')
      return retValString;
    
    if (args && args[0] && args[0] == 'order')
      return retOrder;
    return retValString + retOrder;
  }
}
