import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    private counter = 0;
    public isLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
    ) {
    }

    public async start() {
        if (this.counter === 0) {
            this.isLoading.next(true);
        }
        this.counter++;
    }

    public async stop() {
        if (this.counter > 0) {
            this.counter--;
        }
        if (this.counter <= 0) { this.isLoading.next(false); }
    }

}
