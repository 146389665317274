import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'oil-competitor',
  templateUrl: './competitor.component.html',
  styleUrls: ['./competitor.component.scss']
})
export class CompetitorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
