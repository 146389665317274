import { Component, OnInit, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'oil-ordernew',
  templateUrl: './ordernew.component.html',
  styleUrls: ['./ordernew.component.scss']
})
export class OrdernewComponent implements OnInit {

  public searchtitle;

  public filter = null;

  
  @ViewChild(MatExpansionPanel, { static: true }) matExp: MatExpansionPanel;

  constructor() { }

  ngOnInit(): void {
    if(!this.filter){
      this.matExp.open();
    }
  }

  panelOpened(){
    console.log('-------: OrdernewComponent -> panelOpened -> panelOpened');
    this.searchtitle = "Cerca cliente per l'ordine";
  }

  search(){
    this.matExp.close();
    this.searchtitle = "facets";
  }

}
