import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Moment } from 'moment';
import { MODAL } from 'src/app/models/constants.model';
import { ModalSelectTruckComponent } from '../modal-select-truck/modal-select-truck.component';

@Component({
  selector: 'oil-modal-truckslist',
  templateUrl: './modal-truckslist.component.html',
  styleUrls: ['./modal-truckslist.component.scss']
})
export class ModalTruckslistComponent implements OnInit {

  public date: Moment;

  constructor(
    protected ref: NbDialogRef<ModalSelectTruckComponent>
    ) { }

  ngOnInit(): void {
  }

  cancel(){
    this.ref.close();
  }

  ok(){
    this.ref.close(MODAL.confirmOk);
  }

}
