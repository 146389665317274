import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import * as moment from 'moment';
import { Moment } from 'moment';
import { config } from 'src/environments/config';
import { ModalCalendarComponent } from '../components/modal-calendar/modal-calendar.component';
import { ModalChangePasswordComponent } from '../components/modal-change-password/modal-change-password.component';
import { ModalClientOrderComponent } from '../components/modal-client-order/modal-client-order.component';
import { ModalClosebyComponent } from '../components/modal-closeby/modal-closeby.component';
import { ModalCompetitorComponent } from '../components/modal-competitor/modal-competitor.component';
import { ModalConfirmComponent } from '../components/modal-confirm/modal-confirm.component';
import { ModalNewOrderComponent } from '../components/modal-new-order/modal-new-order.component';
import { ModalNewPromotionComponent } from '../components/modal-new-promotion/modal-new-promotion.component';
import { ModalNewUserComponent } from '../components/modal-new-user/modal-new-user.component';
import { ModalOrderassignComponent } from '../components/modal-orderassign/modal-orderassign.component';
import { ModalProfileComponent } from '../components/modal-profile/modal-profile.component';
import { ModalPromotionMapComponent } from '../components/modal-promotion-map/modal-promotion-map.component';
import { ModalPromotionStartComponent } from '../components/modal-promotion-start/modal-promotion-start.component';
import { ModalSelectDriverComponent } from '../components/modal-select-driver/modal-select-driver.component';
import { ModalSelectTruckComponent } from '../components/modal-select-truck/modal-select-truck.component';
import { ModalTripdetailsComponent } from '../components/modal-tripdetails/modal-tripdetails.component';
import { ModalTruckslistComponent } from '../components/modal-truckslist/modal-truckslist.component';
import { CustomerDetail } from '../models/customer.model';
import { Order, Promotion, Trip } from '../models/order.model';
import { Price } from '../models/price.model';
import { User } from '../models/user.model';


@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(
        private dialogService: NbDialogService
    ) {


    }


    createOrder(customerId: string = null) {
        console.log('-------: ModalService -> createOrder -> customerId', customerId);
        let context: any = {
            thisOrder: {
                client_id: customerId,
            }
        }
        let modal = this.dialogService.open(ModalNewOrderComponent, { context: context });
        return modal.onClose;
    }

    createClientOrder(price: Price, customer: CustomerDetail) {
        let context: any = { price, customer }
        let modal = this.dialogService.open(ModalClientOrderComponent, { context: context });
        return modal.onClose;
    }

    createOrderForPoint(customerId: number, pointId, productId: number = null, price: number = null, date: Date = null) {
        let context: any = {
            thisOrder: {
                client_id: customerId,
                pointId: pointId,
                product_id: productId,
                price: price,
                deliveryDate: date
            },
            modifyCustomer: false,
            modifyPoint: false
        }
        let modal = this.dialogService.open(ModalNewOrderComponent, { context: context });
        return modal.onClose;

    }

    createOrderForPromotion(promotion: Promotion) {
        let context: any = {
            thisOrder: {
                client_id: promotion.clientId,
                pointId: promotion.deliveryId,
                client_delivery_id: promotion.deliveryId,
                product_id: promotion.productId,
                price: promotion.price,
                deliveryDate: promotion.promotionDate
            },
            modifyCustomer: false,
            modifyPoint: false,
            modifyProduct: false,
            modifyDate: false
        }
        let modal = this.dialogService.open(ModalNewOrderComponent, { context: context });
        return modal.onClose;

    }

    // createOrderForCustomer(customerId: number) {
    //     console.log('-------: ModalService -> createOrderForCustomer -> customerId', customerId);
    //     let context: any = {
    //         thisOrder: {
    //             customerId: customerId,
    //         },
    //         modifyCustomer: false
    //     }
    //     let modal = this.dialogService.open(ModalNewOrderComponent, { context: context });
    //     return modal.onClose;

    // }

    tripDetails(tripId: number, isConfirmed: number, fromLogistic: boolean) {
        console.log('-------: ModalService -> tripDetails -> tripId', tripId);
        let context: any = {
            tripId: tripId,
            isConfirmed: isConfirmed,
            fromLogistic
        }
        let modal = this.dialogService.open(ModalTripdetailsComponent, { context: context });
        return modal.onClose;

    }


    assignOrder(order: Order, provenienceTripId: number = null, suggestedRouteId: number = null) {
        console.log('-------: ModalService -> assignOrder -> tripId', order);
        let context: any = {
            order: order,
            provenienceTripId: provenienceTripId
        }
        let modal = this.dialogService.open(ModalOrderassignComponent, { context: context });
        return modal.onClose;

    }


    startPromotion(trips: Trip[], date: Moment) {
        let context: any = {
            trips: trips,
            selectedDate: moment(date)
        };
        let modal = this.dialogService.open(ModalPromotionStartComponent, { context: context });
        return modal.onClose;

    }

    createPromotion(currentProm: Promotion = null, route: Trip = null, maxCapacity: number = null) {
        let context: any = {};
        if (currentProm) {
            context = {
                thisPromotion: currentProm
            }
        } else {
            context = {
                linkedRoute: route,
                maxCapacity: maxCapacity
            }
        }
        let modal = this.dialogService.open(ModalNewPromotionComponent, { context: context });
        return modal.onClose;
    }

    promotionTrip(promotion: Promotion) {
        let context: any = {};
        if (promotion) {
            context = {
                thisPromotion: promotion
            }
        }
        let modal = this.dialogService.open(ModalPromotionMapComponent, { context: context });
        return modal.onClose;
    }

    selectTruck(date: Moment) {
        let context: any = {};
        context = { date: date }
        let modal = this.dialogService.open(ModalSelectTruckComponent, { context: context });
        return modal.onClose;
    }

    trucksList(date: Moment) {
        let context: any = {};
        context = { date: date }
        let modal = this.dialogService.open(ModalTruckslistComponent, { context: context });
        return modal.onClose;
    }

    createClientUser(clientId: number) {
        let context: any = {};
        context = { clientId: clientId, lockForClient: true }
        let modal = this.dialogService.open(ModalNewUserComponent, { context: context });
        return modal.onClose;
    }

    createUser() {
        let context: any = {};
        let modal = this.dialogService.open(ModalNewUserComponent, { context: context });
        return modal.onClose;
    }

    editUser(user: User) {
        let context: any = {};
        context = { user: user }
        let modal = this.dialogService.open(ModalNewUserComponent, { context: context });
        return modal.onClose;
    }

    dateSelector(date: Moment, maxDate: Moment) {
        let context: any = {};
        context = { selectedDate: date, maxDate: maxDate }
        let modal = this.dialogService.open(ModalCalendarComponent, { context: context });
        return modal.onClose;
    }

    confirm(message: string, title: string) {
        let context: any = {};
        context = { message: message, title: title }
        let modal = this.dialogService.open(ModalConfirmComponent, { context: context });
        return modal.onClose;
    }

    info(message: string, title: string) {
        let context: any = {};
        context = { message: message, title: title, onlyInfo:true }
        let modal = this.dialogService.open(ModalConfirmComponent, { context: context });
        return modal.onClose;
    }

    competitor(price: Price) {
        let context: any = {};
        context = { price: price }
        let modal = this.dialogService.open(ModalCompetitorComponent, { context: context });
        return modal.onClose;
    }

    newUser() {
        let modal = this.dialogService.open(ModalNewUserComponent);
        return modal.onClose;
    }


    changePassword(user: User, forced: boolean = false) {
        let context: any = {};
        context = { user: user, forced }
        let modal = this.dialogService.open(ModalChangePasswordComponent, { context: context, closeOnBackdropClick: !forced });
        return modal.onClose;
    }

    profile(user: User, forced: boolean = false) {
        let context: any = {};
        context = { user: user, forced }
        let modal = this.dialogService.open(ModalProfileComponent, { context: context, closeOnBackdropClick: !forced },);
        return modal.onClose;
    }

    selectDriver(trip: Trip) {
        let context: any = {};
        context = {
            trip
        };
        let modal = this.dialogService.open(ModalSelectDriverComponent, { context: context });
        return modal.onClose;
    }

    closebyOrder(order: Order) {
        let context: any = {};
        context = {
            order
        };
        let modal = this.dialogService.open(ModalClosebyComponent, { context: context });
        return modal.onClose;
    }
}