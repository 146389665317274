import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { ManualPrice, plattsVal, Price, PriceDailyApiResponse, PriceListApiResponse, RawPrices, RawPricesAPI, SuccessResponse } from '../models/price.model';
import { multifilter } from 'src/app/models/customerfilter.model';
import { config } from 'src/environments/config';
import * as moment from 'moment';
import { SortPaginator, IPagination } from '../models/generic.model';
import { map } from 'rxjs/operators';
import { WorkingRespone } from '../models/logistic_model';
import { CustomerFilterComponent } from '../components/customer-filter/customer-filter.component';


@Injectable({
    providedIn: 'root'
})
export class PriceService {

    constructor(
        private api: ApiService
    ) {


    }


    public static plattsCode(id) {
        switch (id) {
            case plattsVal.plattsRisc: return 'PLATTSRISC';
            case plattsVal.plattsAuto: return 'PLATTSAUTO';
            case plattsVal.plattsAgricolo: return 'PLATTSAGR';
            case plattsVal.plattsBenzina: return 'PLATTSBENZ';
            default: return 'PLATTSDEFAULT';
        }
    }

    public getClientPriceListByDate(date, deliveryId: string = null): Observable<PriceListApiResponse> {
        const parameters = { date: moment(date).format(config.apiDateFormat) };
        if (deliveryId) { Object.assign(parameters, { deliveryId: deliveryId }) };
        return this.api.call(`client/prices`, 'GET', null, parameters, false);
    }

    public getClientPriceListHistory(paginator: IPagination, deliveryId: string = null): Observable<PriceListApiResponse> {
        const parameters = {};
        Object.assign(parameters, paginator);
        if (deliveryId) { Object.assign(parameters, { deliveryId: deliveryId }) };
        return this.api.call(`client/prices`, 'GET', null, parameters, false);
    }

    public hasTodayPrices(date): Observable<SuccessResponse> {
        const parameters = { date: moment(date).format(config.apiDateFormat) };
        return this.api.call(`admin/prices/has-prices`, 'GET', null, parameters);
    }

    public getPriceList(paginator: IPagination, date, customerId: string, distributionPointId, filters: multifilter[], favoriteonly: boolean = false, requestOnly: boolean = false): Observable<PriceListApiResponse> {
        const parameters = { date: moment(date).format(config.apiDateFormat), favoriteonly: favoriteonly, requestedOnly: requestOnly };
        Object.assign(parameters, paginator);
        // console.log('-------: PriceService -> parameters', parameters);
        let res = null;
        if (!customerId) {
            CustomerFilterComponent.addFilters(parameters, filters)
            // filters.forEach(f => {
            //     parameters[f.name.toLowerCase() + '[]'] = f.value;
            // })
            res = this.api.call('cms/prices', 'GET', null, parameters);
        } else {
            if (!distributionPointId) {
                res = this.api.call(`admin/prices/${customerId}`, 'GET', null, parameters)
            } else {
                res = this.api.call(`admin/prices/${customerId}/${distributionPointId}`, 'GET', null, parameters);
            }

        }
        return res.pipe(map((x: PriceListApiResponse) => {
            x.items.forEach(price => {
                if (price.price_approved && !price.price_modified) {
                    price.price_modified = price.price_approved;
                    // price.modifyPrice = true
                    price.status = "price_approved"
                }
            })
            return x;
        }));
    }

    public getDailyDetails(date, filters: multifilter[]): Observable<PriceDailyApiResponse> {
        const parameters = { date: moment(date).format(config.apiDateFormat) };
        CustomerFilterComponent.addFilters(parameters, filters);
        // filters.forEach(f => {
        //     parameters[f.name.toLowerCase() + '[]'] = f.value;
        // })
        return this.api.call('cms/prices/daily-details', 'GET', null, parameters);
    }
    // public getProductPrice(customerId, distributionPointId, productID) {
    //     const parameters = { date: moment().format(config.apiDateFormat) };
    //     return this.api.call(`admin/prices/${customerId}/${distributionPointId}`, 'GET', null, parameters).pipe(map((res: PriceListApiResponse) => {
    //         const ret = res.items.find(x => x.product_id === productID);
    //         return ret;
    //     }))
    // }

    public getLastPriceModel(selectedDate: moment.Moment): Observable<RawPrices> {
        const parameters = { date: moment(selectedDate).format(config.apiDateFormat) };
        return this.api.call(`admin/prices/raw`, 'GET', null, parameters).pipe(map((x: RawPricesAPI) => {

            x.manual_margins.forEach(m => {
                if (!m.ranges) {
                    this.addDefaultRanges(m)
                } else {
                    m.ranges = m.ranges.sort((a, b) => { return a.max_range < 0 ? 1 : b.max_range < 0 ? -1 : a.max_range - b.max_range; })
                }
                if (!m.product_id) {
                    m.product_id = m['id'];
                }
            });


            if (!x.platts) return { plattsRisc: 0, plattsAuto: 0, plattsAgricolo: 0, plattsBenzina: 0, manual_margins: x.manual_margins }
            return {
                plattsRisc: x.platts.find((p) => p.id == plattsVal.plattsRisc)?.value,
                plattsAuto: x.platts.find((p) => p.id == plattsVal.plattsAuto)?.value,
                plattsAgricolo: x.platts.find((p) => p.id == plattsVal.plattsAgricolo)?.value,
                plattsBenzina: x.platts.find((p) => p.id == plattsVal.plattsBenzina)?.value,
                manual_margins: x.manual_margins
            }
        }));
    }

    private addDefaultRanges(m: ManualPrice) {
        m.ranges = [];
        config.defaultRanges.forEach(x => m.ranges.push(Object.assign({}, x)));
    }

    public resetPrices() {
        return this.api.call(`admin/prices/clean`, 'POST');
    }

    public requestPrice() {
        return this.api.call(`client/prices/request-list`, 'POST', null, null, null);
    }

    public createPrices(rawPrices: RawPrices) {
        let parameters = {
            // "structure": rawPrices.structure,
            "platts": [
                { "id": plattsVal.plattsRisc, "value": rawPrices.plattsRisc },
                { "id": plattsVal.plattsAuto, "value": rawPrices.plattsAuto },
                { "id": plattsVal.plattsAgricolo, "value": rawPrices.plattsAgricolo },
                { "id": plattsVal.plattsBenzina, "value": rawPrices.plattsBenzina }
            ],
            manual_margins: rawPrices.manual_margins
        }
        return this.api.call(`admin/prices/create`, 'POST', parameters);
    }

    public isWorkingPrices(): Observable<WorkingRespone> {
        return this.api.call(`admin/prices/sync`, 'GET')
    }

    public modify(priceId, newPrice) {
        return this.api.call(`admin/prices/${priceId}/modify`, 'POST', null, { price: newPrice });
    }

    public getPriceRequestList(paginator: SortPaginator) {
        // const parameters = { date: moment().format(config.apiDateFormat), priceStatus : 'request_not_sent' };
        // Object.assign(parameters, paginator);
        // return this.api.call('cms/prices', 'GET', null, parameters);
        return this.api.call('cms/prices/toSend', 'GET');
    }

    public getPriceApproveList(paginator: SortPaginator) {
        // const parameters = { date: moment().format(config.apiDateFormat), priceStatus : 'request_sent' };
        // Object.assign(parameters, paginator);
        // return this.api.call('cms/prices', 'GET', null, parameters);
        return this.api.call('cms/prices/toApprove', 'GET');
    }

    public requestConfirm(priceId) {
        return this.api.call(`admin/prices/${priceId}/send`, 'POST', null, null);
    }

    public requestReset(priceId) {
        return this.api.call(`admin/prices/${priceId}/reset`, 'POST', null, null);
    }

    public approve(priceId) {
        return this.api.call(`admin/prices/${priceId}/approve`, 'POST', null, null);
    }

    public refuse(priceId) {
        return this.api.call(`admin/prices/${priceId}/reject`, 'POST', null, null);
    }

    public sendToClient(priceId: number = null) {
        return this.api.call(`admin/prices/send-to-client${priceId ? ('/' + priceId) : ''}`, 'POST', null, null);
    }

    public static getEffectivePrice(price: Price): number {
        if (price.price_promotional && price.quantity_promotional) { return price.price_promotional; }
        return price.price_approved ? price.price_approved : price.price_proposed;
    }

    public static getBasePromotionPrice(price: Price): number {
        return price.price_promotional_base;
    }

    public addCompetitor(price: Price, competitorPrice: number, note: string) {
        console.log('---- ~ file: price.service.ts ~ line 164 ~ PriceService ~ addCompetitor ~ competitorPrice', competitorPrice);
        console.log('---- ~ file: price.service.ts ~ line 164 ~ PriceService ~ addCompetitor ~ price', price);

        const parameters = {
            deliveryId: price.client_delivery_address_id,
            productId: price.product_id,
            price: competitorPrice,
            note: note
        };

        return this.api.call(`admin/prices/competitor/add`, 'POST', parameters);

    }




}
