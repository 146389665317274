<nb-card>
    <nb-card-header >{{'DATERIBBON.CALENDARMODAL.TITLE' | translate}}</nb-card-header>
    <nb-card-body>        
        <input fullWidth status="basic" nbInput [nbDatepicker]="datepicker" [(ngModel)]="selectedDate">
        <nb-datepicker #datepicker format="DD/MM/YYYY" [max]="maxDate"></nb-datepicker>
    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"  (click)="ok()">{{'DATERIBBON.CALENDARMODAL.OK' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'DATERIBBON.CALENDARMODAL.CANCEL' | translate}}</button>
    </nb-card-footer>
</nb-card>
