<table mat-table [dataSource]="dataSource" matSort matSortActive="depot" matSortDirection="asc" #table>

    <!-- targa -->
    <ng-container matColumnDef="targa">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOGISTIC.TRUCKSLIST.TABLE.PLATE' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{element.targa}} </td>
    </ng-container>

    <!-- rimorchio -->
    <ng-container matColumnDef="tow">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'LOGISTIC.TRUCKSLIST.TABLE.TOW' | translate}} </th>
        <td mat-cell *matCellDef="let element"> {{(element.targaMotrice ?
            'LOGISTIC.TRUCKSLIST.TABLE.TOWTRUE':'LOGISTIC.TRUCKSLIST.TABLE.TOWFALSE')| translate}} </td>
    </ng-container>

    <!-- capacità -->
    <ng-container matColumnDef="load">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.TRUCKSLIST.TABLE.LOAD' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> {{element.load | number | liter}} </td>
    </ng-container>

    <!-- stato -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.TRUCKSLIST.TABLE.STATUS' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-select *ngIf="!past" [items]="statuses" [clearable]="false" bindLabel="label" bindValue="id" autofocus (change)="updateStatus(element,$event.id)" [(ngModel)]="element.status"> </ng-select>
            <span *ngIf="past">{{statusLabel(element.status)}}</span>
        </td>
    </ng-container>

    <!-- deposito  -->
    <ng-container matColumnDef="depot">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.TRUCKSLIST.TABLE.DEPOT' | translate}}
        </th>
        <td mat-cell *matCellDef="let element"> 
            <ng-select *ngIf="!past"  [items]="depots" [clearable]="false" bindLabel="name" bindValue="id" autofocus (change)="updateDepot(element,$event.id)" [(ngModel)]="element.depotId"> </ng-select>        
            <span *ngIf="past">{{depotLabel(element.depotId)}}</span>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'disable':row.status != 'available'}">
    </tr>
</table>
