<nb-card class="modal" size="large">
    <nb-card-header>{{'ORDERS.CLOSEBYMODAL.TITLE'|translate}} {{order?.client_delivery_name}}</nb-card-header>

    <nb-card-body class="modal">
        <div class="map">
            <oil-tripmap class="" 
                [radius]="rangeKm"
                [centerMarker]="center"
                [closeClients]="clients"
            >
            </oil-tripmap>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton (click)="cancel()">{{'ORDERS.CLOSEBYMODAL.CLOSE'|translate}}</button>
        <div class="right">
            <label>{{'ORDERS.CLOSEBYMODAL.RADIUS'|translate}}</label> 
            <mat-slider min="1" max="15" step="1" tickInterval="auto" [(ngModel)]="rangeKm"  thumbLabel [displayWith]="formatLabel" (change)="refresh()"></mat-slider>
        
            <label>{{'ORDERS.CLOSEBYMODAL.TIMEPERIOD'|translate}}</label> 
            <mat-slider min="15" max="500" step="5" tickInterval="auto" [(ngModel)]="timePeriodDays"  thumbLabel  (change)="updateTimePeriod()"></mat-slider>        
        </div>
    </nb-card-footer>

</nb-card>
