import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';
import { UserService } from './user.service';
import { StatusService } from './status.service';


@Injectable()
export class RoleProvider implements NbRoleProvider {

  constructor(
    private authService: NbAuthService,
    private statusService: StatusService
  ) {
    this.getRole().pipe(take(1)).subscribe();
  }

  getRole(): Observable<string> {
    return this.authService.onTokenChange()
      .pipe(
        map((token: NbAuthJWTToken) => {
          // console.log('-------: RoleProvider -> token', token);
          if (token.isValid() && token.getPayload()) {
            let role = token.getPayload()['roles'] ? token.getPayload()['roles'] : token.getPayload()['role'] ? token.getPayload()['role'] : 'guest';
            this.statusService.isAdmin = this.isAdmin(role);
            return role;
          } else {
            return null;
          }
        }),
      );
  }

  isAdmin(role: string): boolean {
    if (role.toLowerCase() == 'client') {
      return false;
    }
    return true;
  }
}