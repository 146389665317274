
import { NgModule } from '@angular/core';
import { LiterPipe } from 'src/app/pipes/liter.pipe';
import { SmallNumber } from 'src/app/pipes/smallNumber.pipe';
import { MinZero } from './minZero.pipe';


@NgModule({
  imports: [
  ],
  declarations: [
    LiterPipe,
    SmallNumber,
    MinZero
  ],
  exports:[
    LiterPipe,
    SmallNumber,
    MinZero
  ]
})
export class PipesModule {
}
