import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { modalTruckResponse } from 'src/app/models/logistic_model';
import { Order, Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';
import { ModalService } from 'src/app/services/modal.service';
import { NotifyService } from 'src/app/services/notify.service';
import { ModalSelectTruckComponent } from '../modal-select-truck/modal-select-truck.component';

@Component({
  selector: 'oil-modal-orderassign',
  templateUrl: './modal-orderassign.component.html',
  styleUrls: ['./modal-orderassign.component.scss']
})
export class ModalOrderassignComponent implements OnInit {


  public order: Order;
  public provenienceTripId: number;

  public dataSource;
  public resultsLength = 0;


  public displayedColumns: string[] = [
    'name',
    'product',
    'filling',
    'remain',
    'actions',
  ];


  constructor(
    protected ref: NbDialogRef<ModalOrderassignComponent>,
    private logisticService: LogisticService,
    private translation: TranslateService,
    private notify: NotifyService,
    private dialogService: NbDialogService
  ) { }

  ngOnInit(): void {

    this.translation.get('LOGISTIC.ASSIGNORDER.TRIPBASENAME').subscribe(tripbasename => {
      this.logisticService.getTrips(null, tripbasename, moment(this.order.deliveryDate).toDate()).subscribe(x => {
        if (x.items) {
          const filteredList = x.items.filter(x => x.routeId !== this.provenienceTripId && !x.confirmed);
          //console.log('-------: ModalOrderassignComponent -> ngOnInit -> filteredList', x.items, filteredList, this.provenienceTripId);
          this.dataSource = new MatTableDataSource<Trip>(filteredList);
          this.resultsLength = filteredList.length;
        }
      });
    })

  }

  add(trip: Trip) {
    //console.log('-------: ModalOrderassignComponent -> add -> trip', trip, this.order);
    const after = (resp) => {
      if (resp && resp.status && resp.status == 400) {
        
      } else {
        this.ref.close();
      }
    }
    if (!this.provenienceTripId) {
      this.logisticService.addOrderToTrip(trip.routeId, this.order.id ? this.order.id : this.order.orderId).subscribe(resp => after(resp));
    } else {
      this.logisticService.moveOrderToTrip(this.provenienceTripId, trip.routeId, this.order.id ? this.order.id : this.order.orderId).subscribe(resp => after(resp));
    }

  }

  createTrip() {
    let context: any = { date: moment(this.order.deliveryDate) };
    let modal = this.dialogService.open(ModalSelectTruckComponent, { context: context });

    modal.onClose.subscribe((res: modalTruckResponse) => {
      if (res.truck) {
        const create = () => {
          this.logisticService.createTrip(this.order.id ? this.order.id : this.order.orderId, res.truck, res.tow).subscribe(x => {
            this.ref.close();
          })
        }
        if (this.provenienceTripId) {
          this.logisticService.removeOrderFromTrip(this.provenienceTripId, this.order.id ? this.order.id : this.order.orderId).subscribe(x => {
            create();
          });
        } else {
          create();
        }

      }
    })


  }

  cancel() {
    this.ref.close();
  }

  productList(products: string[]) {
    if (products && products.length) {
      const uniq = [...new Set(products)];
      return uniq.join(' | ');
    } else {
      return '-';
    }
  }

  canAssign(trip: Trip) {
    const orderQuantity = this.order.quantity || this.order.orderQty;
    let filling = 0;
    trip.tanks.forEach(tank => {
      filling += tank.fillingQuantity;
    })
    return (filling + orderQuantity) < (trip.maxTruckCapacity + trip.maxTowCapacity) && trip.createdByClient;
    }


}
