import { Component, OnInit, DoCheck, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { NbIconLibraries } from '@nebular/theme';
import { Location } from '@angular/common';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { LoadingService } from './services/loading.service';
import * as moment from 'moment';
import 'moment/locale/it';
import { icons } from 'src/assets/icons/icons';
import { version } from 'src/environments/version';
import { ModalService } from './services/modal.service';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
import { SettingsService } from './services/settings.service';
import { Subscription } from 'rxjs';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  public fullStructure = false;
  public requireCookie = true;
  public isLoading;
  public showVersion = environment.showVersion;
  public version = version;

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private location: Location,
    private authService: NbAuthService,
    private loadingService: LoadingService,
    private ref: ChangeDetectorRef,
    private iconsLibraries: NbIconLibraries,
    private settingsService: SettingsService,
    private userService: UserService,
    private modalService: ModalService,
    private utilsService: UtilsService
  ) {
    let lang = this.utilsService.getLanguage();
    translate.use(lang);
    moment.locale(lang);

    this.iconsLibraries.registerSvgPack('3logic', icons);

    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
      if (token.isValid()) {
        const user = token.getPayload();
      }
    });

    settingsService.load();

    this.titleService.setTitle(environment.title);
    location.onUrlChange((val) => {
      this.fullStructure = this.PathRequireFullStructure(this.location.path());
      this.requireCookie = this.PathRequireCookie(this.location.path());
    });
  }

  ngOnInit() {
    this.loadingService.isLoading.subscribe(val => {
      this.isLoading = !!val;
      this.ref.detectChanges();
    });
  }


  ngOnDestroy() {
  }

  PathRequireFullStructure(path) {
    if (path.indexOf('/auth') === 0) { return false; }
    return true;
  }

  PathRequireCookie(path) {
    console.log('---- ~ file: app.component.ts ~ line 84 ~ AppComponent ~ PathRequireCookie ~ path', path, path.indexOf('/auth/policy') === 0);
    if (path.indexOf('/auth/policy') === 0) { return false; }
    return true;
  }

}
