import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDatepickerComponent } from '@nebular/theme';
import * as moment from 'moment';
import { Moment } from 'moment';
import { take } from 'rxjs/operators';
import { agenda } from 'src/app/models/user.model';
import { ModalService } from 'src/app/services/modal.service';
import { StatusService } from 'src/app/services/status.service';
import { UserService } from 'src/app/services/user.service';
import { Location } from '@angular/common';

@Component({
  selector: 'oil-dateribbon',
  templateUrl: './dateribbon.component.html',
  styleUrls: ['./dateribbon.component.scss']
})
export class DateribbonComponent implements OnInit {


  @Input() selectedDate = moment();
  @Input() showFuture = false;
  @Input() dateSync = false;
  @Output() selectedChange = new EventEmitter();


  @ViewChild(NbDatepickerComponent) datepicker;

  public week = [];
  public currentRefDay = moment();
  public badge = {};
  

  constructor(
    private statusService: StatusService,
    private modalService: ModalService,
    public userService: UserService,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    if (!this.dateSync) {
      this.currentRefDay = moment(this.selectedDate);
    } else {
      this.currentRefDay = moment(this.statusService.getSelectedDate());
      this.selectedDate = moment(this.statusService.getSelectedDate());
      this.selectedChange.emit(this.selectedDate);
    }
    this.updateWeek();
    
  }

  select(day) {
    this.selectedDate = day;
    if (this.dateSync) {
      this.statusService.setSelectedDate(moment(day));
    }
    this.currentRefDay = moment(day);
    this.updateWeek();
    this.selectedChange.emit(day);
  }

  isSelected(day) {
    return moment(day).isSame(this.selectedDate, 'day');
  }

  isToday(day) {
    return moment().isSame(day, 'day');
  }

  isTomorrow(day) {
    return moment().add(1, 'day').isSame(day, 'day');
  }

  isFuture(day) {
    return !this.showFuture && moment(day).isAfter(moment());
  }

  updateWeek() {
    this.week = [];
    let weekStart = moment(this.currentRefDay.startOf('week'));
    for (let i = 0; i < 7; i++) {
      this.week.push(moment(weekStart).add(i, 'day'));
    }
    this.setbadge(this.week[0], this.week[this.week.length-1]);   

  }

  prev(value = 7) {
    // let elem = this.week.pop();
    // this.week.unshift(moment(elem).add(-7,'day'));    
    this.currentRefDay.add(-value, 'day');
    this.updateWeek();
  }

  canNext() {
    return this.showFuture || !moment(this.currentRefDay).add(7, 'day').isAfter(moment());
  }

  next() {
    // let elem = this.week.shift();
    // this.week.push(moment(elem).add(7,'day'));
    this.currentRefDay.add(7, 'day');
    this.updateWeek();
  }

  openCalendar() {
    this.modalService.dateSelector(this.selectedDate, this.showFuture ? null : moment()).pipe(take(1)).subscribe(x => {
      if (x) {
        this.select(x);
      }
    })
  }

  async setbadge(start: Moment, end: Moment){
    const agenda = await this.userService.updateAgendaRangeDate(start, end).toPromise();         
    const actualpath = this.location.path();
    
    if(actualpath == '/logistic/unassigned'){
      const logistic = agenda['logistic'];
      if(logistic && logistic.unassignedOrders && logistic.unassignedOrders.rangeDates){
        logistic.unassignedOrders.rangeDates.forEach(r => {
          this.badge[r.date] = r.unallocated;
        }); 
        this.badge['unassignedOlderOrders'] = logistic.unassignedOlderOrders        
        this.badge['unassignedFutureOrders'] = logistic.unassignedFutureOrders        
      }
    }
   
  }


}
