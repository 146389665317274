<nb-card>
    <nb-card-header>
        <p *ngIf="!forced">{{'SIDEBAR.PROFILEMODAL.TITLE' | translate}} </p>
        <p *ngIf="forced">{{'SIDEBAR.PROFILEMODAL.TITLEFORCED' | translate}} </p>
    </nb-card-header>
    <nb-card-body>

        <!-- <span class="logo"><img src="{{user.img}}"></span>

        <span class="role"> {{ 'ROLES.' + (user.role? user.role:'CLIENT') | translate }}</span>
        <strong>{{user.name}}</strong>
        <span class="email">{{user.email}}</span> -->
        <nb-user size="large" [name]="user?.name" [title]="user?.email" [picture]="user?.img"> </nb-user>

        <div *ngIf="policies && policies.length">
            <div class="privacy-title">{{'SIDEBAR.PROFILEMODAL.PRIVACY' | translate}}</div>
            <div class="privacy-policy" *ngFor="let policy of policies">
                <div class="policy-name" [ngClass]="{'active':!!policy.accept}">{{policy.policy}} </div>
                <div class="toggle">
                    <mat-slide-toggle [checked]="!!policy.accept" (change)="changePolicy($event,policy)">
                    </mat-slide-toggle>
                </div>
            </div>
        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <div class="error" *ngIf="error">{{error}}</div>
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="ok()">{{'SIDEBAR.PROFILEMODAL.OK' | translate}}</button>
        <!-- <button nbButton class="modal-buttons" (click)="cancel()">{{'SIDEBAR.PROFILEMODAL.CANCEL' |
            translate}}</button> -->
    </nb-card-footer>
</nb-card>