<nb-card size="big">
    <nb-card-header>
        {{ 'ORDERS.APPROVE.HEADER' | translate}}
        <div class="time-interval-container" nbTooltip="{{ 'ORDERS.APPROVE.FILTERTOOLTIP' | translate}}">
            <mat-button-toggle-group ([value])="timeInterval" (change)="checkTimeInterval($event)">
                <mat-button-toggle *ngFor="let val of [3,6,12]" [value]="val" [checked]="timeInterval == val">
                    {{ 'ORDERS.APPROVE.INTERVAL'+val | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="no-data" [hidden]="resultsLength>0"> {{ 'ORDERS.APPROVE.NODATA' | translate}}
        </div>

        <div [hidden]="!resultsLength">
            <table mat-table [dataSource]="dataSource" matSort #table>


                <!-- Preferiti -->
                <ng-container matColumnDef="favorite">
                    <th class="favorite" mat-header-cell *matHeaderCellDef>
                        <nb-icon icon="star-on" pack="3logic">
                        </nb-icon>
                    </th>
                    <td class="favorite" mat-cell *matCellDef="let element">
                        <nb-icon [icon]="element.favorite?'star-on':'star-off'" pack="3logic"  (click)="setFavorite(element)">
                        </nb-icon>
                    </td>
                </ng-container>

                <!-- Ragione sociale -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.APPROVE.TABLE.NAME' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <oil-company-name [element]="element" [id]="element.client_id" ></oil-company-name>
                    </td>
                </ng-container>

                <!-- Data -->
                <ng-container matColumnDef="deliveryDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.DATE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.deliveryDate  | amDateFormat:'DD/MM/YYYY' }}</td>
                </ng-container>

                <!-- Prodotto -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.PRODUCT' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.product}}</td>
                </ng-container>

                <!-- Quantità -->
                <ng-container matColumnDef="quantity">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.QUANTITY' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
                </ng-container>

                <!-- Prezzo -->
                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.PRICE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{ element.price/element.quantity | currency:'€': 'symbol' : '1.3-3'}}</td>
                </ng-container>

                <!-- Valore -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.VALUE' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.price | currency:'€': 'symbol' : '1.3-3'}}</td>
                </ng-container>

                <!-- Fido residuo  -->
                <ng-container matColumnDef="fido">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.APPROVE.TABLE.FIDO' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="credit"
                        [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                        {{element.remainingCredit | currency:'€'}}</td>
                </ng-container>

                <!-- Fuori Fido richiesti  -->
                <ng-container matColumnDef="requestTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.REQUESTTOTAL' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.totalOrdersInsufficient }}</td>
                </ng-container>

                <!-- Fuori Fido approvati  -->
                <ng-container matColumnDef="requestAprroved">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'ORDERS.APPROVE.TABLE.REQUESTAPPROVED' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.totalOrdersApproved }}</td>
                </ng-container>

                <!-- Modifica  -->
                <ng-container matColumnDef="modify">
                    <th mat-header-cell *matHeaderCellDef>{{ 'ORDERS.APPROVE.TABLE.MODIFY' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div class="actions">
                            <button nbButton class="confirm-button"
                                (click)="approve(element)">{{'ORDERS.APPROVE.TABLE.APPROVE' | translate}}</button>
                            <button nbButton class="delete-button"
                                (click)="refuse(element)">{{'ORDERS.APPROVE.TABLE.REFUSE' | translate}}</button>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                </tr>
            </table>

            <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator>
        </div>

    </nb-card-body>
</nb-card>