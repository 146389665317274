import { ApiResponse } from './generic.model';
import { Location } from './order.model';
import { DeltaProduct } from './settings.model';

export interface Truck {
    targa: string,
    targaMotrice: string,
    load: number,
    status: string;
    depotId: number;
}

export interface TrucksResponse extends ApiResponse {
    items: Truck[]
}

export interface modalTruckResponse {
    truck: string,
    tow: string
}

export interface WorkingRespone {
    isWorking: boolean,
    hasError: boolean,
    message?: string[],
    queryDate?: Date
}

export interface Depot {
    id?: number;
    code?: number;
    name?: string;
    address?: string;
    province?: string;
    location?: Location;
    primeCost?: number,
    secondary?: number,
    deltaAcquisition?: number,   
    
    deltas?: DeltaProduct[]
}

export interface DepotsResponse extends ApiResponse {
    items: Depot[]
}

export interface tripCategoryChart {
    total: number,
    status: string
}

export interface DailyTripsResponse {
    avgFillingDelta: number,
    avgFilling: number,
    distance: number,
    tripsDelta: number,
    usedTrucks: number,
    tripsNumber: number,
    trips: tripCategoryChart[]
}

export enum TripStatus {
    unconfirmed = 'unconfirmed',
    confirmed = 'confirmed',
    in_transit = 'in_transit',
    delivered = 'delivered',
}

export interface Driver{
    driverId: number;
    driverName: string;
    driverLastName: string;
	trips?: {
		id: number;
		totalOrders: number;
		durationSeconds: number;
	}[]
}


export interface DriversApiResponse extends ApiResponse {
    items: Driver[]
}




