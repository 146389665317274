import { number } from 'echarts';
import { ApiResponse } from './generic.model';
import { DeliveryTime } from './order.model';

export interface PriceBase {
    id: number,
    product_id: number,
    status: string,
    client_delivery_address_id: number,
    date: string,
    product_name: string,
    client_name: string,
    price_sent_to_client: number,
    price_promotional?: number,
    price_promotional_base?: number,
    quantity_promotional?: number,
    clientName: string,
    date_promotional: string,
    deliveryAddress: string,
    deliveryCap: string,
    deliveryCity: string,
    deliveryName: string,
    deliveryProvince: string,
    suggested_route: number,
    favorite?: boolean
}

export interface Price extends PriceBase {
    price_sent_to_client: number,
    price_proposed: number,
    price_modified: number,
    price_approved: number,
    price_computed: number,
    price_min: number,
    price_max: number,
    margin?: number,
    calculated_margin?: number,
    priceCalculatedMargin?: number,

    price_edit?: number,
    modifyPrice?: boolean,
    haveRequestedPrice?: boolean
    push_notification?: boolean
    deliveryTime? : DeliveryTime;
}

export interface PriceForClient extends PriceBase {

}

export interface SuccessResponse {
    success: boolean;
}


export interface PriceListApiResponse extends ApiResponse {
    items: Price[]
    hasPrices?: boolean,
    promotionExpire?: Date
}


export enum plattsVal {
    plattsRisc = 1,
    plattsAuto = 5,
    plattsAgricolo = 11,
    plattsBenzina = 17
}


export interface RawPrices {
    plattsRisc: number,
    plattsAuto: number,
    plattsAgricolo: number,
    plattsBenzina: number,
    manual_margins: ManualPrice[]
}

export interface Platts {
    id: number,
    value: number,
    date?: Date
}

export interface RawPricesAPI {
    platts: Platts[]
    manual_margins: ManualPrice[]
}

export interface ManualPrice {
    product_id: number,
    name: string,
    ranges: PriceRange[],
}

export interface PriceRange {
    value: number,
    max_range: number,
}
export interface MarginCategory {
    category: string,
    total: number
}

export interface DateTotal {
    date: Date,
    total: number

}

export interface PlattsValue {
    id: number,
    value: number,
    delta: number
}

export interface PriceDailyApiResponse {
    marginCategories: MarginCategory[],
    avgMargin: DateTotal[]
    platt: PlattsValue[],
    price: number,
    quantity: number
}