<nb-card>
    <nb-card-header>{{'LOGISTIC.STARTPROMOTION.TITLE'|translate}}</nb-card-header>

    <nb-card-body class="modal">
        <label>{{'LOGISTIC.STARTPROMOTION.LABEL'|translate}}</label>
        <div class="date">
            <input fullWidth type="number" step="1" min="0" max="23"  [(ngModel)]="endHour">
            <input fullWidth type="number" step="1" min="0" max="59"  [(ngModel)]="endMinute">
        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons"
            (click)="save()">{{'LOGISTIC.STARTPROMOTION.START'|translate}}</button>
        <button nbButton class="modal-buttons"
            (click)="cancel()">{{'LOGISTIC.STARTPROMOTION.CANCEL'|translate}}</button>
    </nb-card-footer>
</nb-card>