import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { CATEGORIES_COLORS } from 'src/app/models/constants.model';
import { dailyInputs } from 'src/app/models/generic.model';

@Component({
  selector: 'oil-daily-chart',
  templateUrl: './daily-chart.component.html',
  styleUrls: ['./daily-chart.component.scss']
})
export class DailyChartComponent implements OnInit {

  public initOpts: any = {};
  public options: any = {};
  public isWaiting: boolean = true;

  @Input() chartType: 'bar' | 'donut' | 'stacked' = 'donut';


  private _input: dailyInputs;
  @Input() set input(value: dailyInputs) {
    this._input = value;
    this.refresh();
  }
  get input(): dailyInputs {
    return this._input;
  }

  constructor(
  ) { }

  ngOnInit(): void {
  }

  formatNumber = (n: number) => {
    return n ? n.toLocaleString() : '';
  }

  refresh() {
    if (!this.input) {
      this.isWaiting = true;
      return;
    }

    let useInput = Object.assign({}, this.input);
    useInput.series.sort((a, b) => a.name ? a.name.localeCompare(b.name) : 0)

    let options: any = {
      tooltip: {
        trigger: 'item',
        textStyle: {
          fontSize: 9
        },
        formatter: (params: any) => {
          let res = ''
            + '<span>' + (params.name ? params.name : params.seriesName) + '</span>' + ': '
            + '<strong>' + this.formatNumber(params.value) + '</strong>';
          return res;
        }
      },
      legend: {
        selectedMode: false,
        top: 'center',
        left: 'right',
        orient: 'vertical',
        icon: 'rect',
        padding: 2,
        align: 'left',
        itemGap: 10,
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: "rgba(130, 130, 130, 1)",
          fontSize: 8
        }
      }
    };


    if (this.chartType == 'donut') {

      const getColors = (input) => {
        let ret = input.series.map(x => x.color);
        if (ret && ret.length && ret[0]) {
          return ret;
        }
        return null;
      }

      options.series = [
        {
          type: 'pie',
          avoidLabelOverlap: true,
          left: 0,
          right: '40%',
          top: 0,
          bottom: 0,
          radius: ['55%', '75%'],
          itemStyle: {
            borderWidth: 1,
            borderColor: '#FFF'
          },
          label: {
            show: true,
            position: 'center',
            left: 0,
            right: '10%',
            top: 0,
            bottom: 0,
            //formatter: [`{a|${useInput.centerValue}}`, `{b|${useInput.centerLabel ? useInput.centerLabel : ''}}`].join('\n'),
            formatter: `{a|${useInput.centerValue}}`,
            rich: {
              a: {
                fontSize: 15,
                color: '#16396F',
                fontWeight: 'bold',
                // padding: [30, 2, 2, 2],
                padding: [2, 2, 2, 2],
                lineHeight: 20
              },
              b: {
                color: '#AAA',
                fontWeight: 'bold',
                height: 40
              }
            }


          },
          data: useInput.series,
          color: getColors(useInput)
        }
      ];


    } else if (this.chartType == 'bar' || this.chartType == 'stacked') {
      options.grid = {
        left: 0,
        top: 15,
        right: 60,
        bottom: 20
      }
      options.xAxis = {
        type: '',
        data: [''],
        show: false,
        // axisLabel:{show:false}
      };
      options.yAxis = {
        type: 'value',
        show: false,
        // axisLabel:{show:false}
      };
      options.series = useInput.series.map((x) => {
        let ret: any =
        {
          name: x.name,
          type: 'bar',
          // barGap: 0,
          data: [x.value]
        }
        if (x.color) {
          ret.color = x.color;
        }
        if (this.chartType == 'stacked') {
          ret.stack = 'stack';
        }
        return ret;
      })
    }

    this.options = Object.assign({}, options);
    this.isWaiting = false;

  }

}
