<nb-card>
    <nb-card-body>
        <div class="filter-header">
            <mat-button-toggle-group name="filter" [(ngModel)]="filter" (change)="refresh()">
                <mat-button-toggle value="clients">{{ 'ADMIN.USERS.CLIENTS' | translate}}</mat-button-toggle>
                <mat-button-toggle value="workers">{{ 'ADMIN.USERS.WORKERS' | translate}}</mat-button-toggle>
                <mat-button-toggle value="drivers">{{ 'ADMIN.USERS.DRIVERS' | translate}}</mat-button-toggle>
                <mat-button-toggle value="all">{{ 'ADMIN.USERS.ALL' | translate}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>

        <table mat-table [dataSource]="dataSource" matSort matSortActive="email" matSortDirection="asc" #table>

            <!-- email -->
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ADMIN.USERS.TABLE.EMAIL' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.email }}
                </td>
            </ng-container>

            <!-- firstname -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ADMIN.USERS.TABLE.FIRSTNAME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName }}
                </td>
            </ng-container>

            <!-- lastname -->
            <ng-container matColumnDef="lastName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ADMIN.USERS.TABLE.LASTNAME' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName }}
                </td>
            </ng-container>

            <!-- role -->
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ADMIN.USERS.TABLE.ROLE' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{'ROLES.' + element.role | translate}}
                </td>
            </ng-container>

            <!-- client -->
            <ng-container matColumnDef="client">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ADMIN.USERS.TABLE.CLIENT' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <a class="decoration-none" [routerLink]="'/customers/details/'+element.clientId">
                        {{element.clientName}}
                    </a>
                </td>
            </ng-container>

            <!-- actions -->
            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef> {{ 'ADMIN.USERS.TABLE.ACTIONS' | translate}} </th>
                <td mat-cell class="actions" *matCellDef="let element">
                    <div *ngIf="!element.deleted">
                        <button nbButton (click)="edit(element)">{{ 'ADMIN.USERS.TABLE.EDITBTN' | translate}}</button>
                        <button nbButton (click)="delete(element)">{{ 'ADMIN.USERS.TABLE.DELETEBTN' |
                            translate}}</button>
                    </div>
                    <div *ngIf="element.deleted">
                        {{ 'ADMIN.USERS.TABLE.DELETED' | translate}}
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'deleted':row.deleted}">
            </tr>
        </table>

        <mat-paginator pageSize="30" [pageSizeOptions]="[30,60,100]" [length]="resultsLength">
        </mat-paginator>

    </nb-card-body>
    <nb-card-footer>
        <button nbButton (click)="addUser()">{{ 'ADMIN.USERS.ADD' | translate}}</button>
    </nb-card-footer>
</nb-card>