import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ChartGroupBy, ChartInputs, ClientChartType } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { } from 'ngx-echarts/public-api'
import { stringify } from '@angular/compiler/src/util';
import { color } from 'echarts';

@Component({
  selector: 'oil-client-chart',
  templateUrl: './client-chart.component.html',
  styleUrls: ['./client-chart.component.scss']
})
export class ClientChartComponent implements OnInit {

  @Input() type: ClientChartType;
  @Input() divId: string;

  private _input: ChartInputs;
  @Input() set input(value: ChartInputs) {
    this._input = value;
    this.refresh();
  }
  get input(): ChartInputs {
    return this._input;
  }

  public isWaiting = true;

  public options: any;

  public translation: [];

  inputDateFormat = 'YYYYMMDD';
  outputDateFormat = 'DD/MM/YY';


  formatNumber = (n: number) => {
    return n ? n.toLocaleString() : '';
  }

  basicOptions: any = {
    legend: {
      align: 'left',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: (params: any) => {
        let res = '';;
        let date = '';
        params.forEach(param => {
          date = param.axisValueLabel;
          res += `<div classs="chart-tooltip-value"> 
                <div  style="border-radius: 50%;
                width: 10px;
                height: 10px;
                display: inline-block;
                background:${param.color}"></div>
                ${param.seriesName}: <strong>${this.formatNumber(param.value)} </strong> </div>`
        });
        let ret = `
        <div class="chart-tooltip">
        <div style="width: 100%;text-align: right; margin-bottom:15px"> ${date}</div>
          ${res}
        </div>`;
        return ret;
      }
    },
    animationEasing: 'elasticOut',
  };

  initOpts = {
    locale: 'DE'
  }

  colors = ['#2767CF', '#FA9548', '#F9B104'];
  axisColors = ['#173D7A', '#B56C35', '#F9B104'];
  serviceColors = ['#173D7A', '#B56C35', '#A8940F'];


  constructor(
    private customerService: CustomerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.get([
      'CUSTOMER.DASHBOARD.CHART.AVGORDERS.DATA1',
      'CUSTOMER.DASHBOARD.CHART.AVGORDERS.DATA2',
      'CUSTOMER.DASHBOARD.CHART.AVGORDERS.AVERAGE',
      'CUSTOMER.DASHBOARD.CHART.AVGORDERS.YAXIS1',
      'CUSTOMER.DASHBOARD.CHART.AVGORDERS.YAXIS2',
      'CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.YAXIS1',
      'CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.XAXIS1',
      'CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.DATA1',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA1',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA2',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA3',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.AVERAGE',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.YAXIS1',
      'CUSTOMER.DASHBOARD.CHART.TOTALORDERS.YAXIS2',
      'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.YAXIS1',
      'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.YAXIS2',
      'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.DATA1',
      'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.DATA2',
      'CUSTOMER.DASHBOARD.CHART.AVGMARGIN.AVERAGE',
      'CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.YAXIS1',
      'CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.DATA1',
      'CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.AVERAGE'
    ]).subscribe(t => {
      this.translation = t;
    })
  }

  refresh() {
    if (this._input) {
      switch (this.type) {
        case ClientChartType.avgOrders:
          this.avgOrders();
          break;
        case ClientChartType.totalOrders:
          this.totalOrders();
          break;
        case ClientChartType.avgMargin:
          this.avgMargin();
          break;
        case ClientChartType.totalMargin:
          this.totalMargin();
          break;
        case ClientChartType.clientsQtyMargin:
          this.clientsQtyMargin();
          break;
      }
    }
  }

  getDateList() {

  }

  timeUnitForGrouBy(group: ChartGroupBy): moment.DurationInputArg2 {
    switch (group) {
      case ChartGroupBy.daily: return 'day';
      case ChartGroupBy.monthly: return 'month';
      case ChartGroupBy.weekly: return 'week';
      case ChartGroupBy.yearly: return 'year';
    }
  }

  formatByGroup(date: moment.Moment, group: ChartGroupBy): string {
    switch (group) {
      case ChartGroupBy.daily: return date.format('DD/MM/YY');
      case ChartGroupBy.monthly: return date.format('MMM YY');
      case ChartGroupBy.weekly: return date.format('WW/YYYY');
      case ChartGroupBy.yearly: return date.format('YYYY');
    }
  }

  isSameDate(periodN: string, date: moment.Moment, group: ChartGroupBy): boolean {
    periodN = '' + periodN;
    switch (group) {
      case ChartGroupBy.daily: return date.format('YYYYMMDD') == periodN;
      case ChartGroupBy.monthly: return date.format('YYYYMM') == periodN;
      case ChartGroupBy.weekly: return date.format('YYYYWW') == periodN;
      case ChartGroupBy.yearly: return date.format('YYYY') == periodN;
    }
  }

  firstDay(items: any[], group: ChartGroupBy): moment.Moment {
    if (!items || !items.length) { return moment() }
    switch (group) {
      case ChartGroupBy.daily: return moment(items[0].periodN, 'YYYYMMDD');
      case ChartGroupBy.monthly: return moment(items[0].periodN, 'YYYYMM');
      case ChartGroupBy.weekly: return moment(items[0].periodN, 'YYYYWW');
      case ChartGroupBy.yearly: return moment(items[0].periodN, 'YYYY');
    }
  }

  avgLine = (labelString: string, labelColor: string) => {
    return {
      symbol: 'none',
      precision: 0,
      data: [
        {
          type: 'average',
          label: {
            color: labelColor,
            position: 'start',
            formatter: (val) => {
              return `${labelString}`;
            }
          },
          emphasis: {
            label: {
              backgroundColor: '#FFF',
              formatter: (val) => {
                return `${val.value} `;
              }
            }
          }
        }
      ]
    }
  }


  yAxisBase(colorIndex, splitLineValue: number = null) {
    return {
      type: 'value',
      interval: splitLineValue,
      axisLine: {
        lineStyle: {
          color: this.axisColors[colorIndex]
        }
      },
      axisLabel: {
        formatter: (params) => {
          return this.formatNumber(params);
        },
      },
      splitLine: !splitLineValue ? null : {
        lineStyle: {
          color: this.axisColors[colorIndex]
        }
      }
    }
  }

  roundNumber(x: number, precision: number): number {
    return Math.round(x * Math.pow(10, precision)) / Math.pow(10, precision);
  }


  avgOrders() {
    this.isWaiting = true;
    this.customerService.chartAvgOrders(this._input).subscribe(res => {

      const xAxisData = [];
      const data1 = [];
      const data2 = [];

      for (let date = this.firstDay(res.items, this._input.groupBy); date.isSameOrBefore(this._input.endDate); date.add(1, this.timeUnitForGrouBy(this._input.groupBy))) {
        xAxisData.push(this.formatByGroup(date, this._input.groupBy));
        let reference = res.items.find(x => this.isSameDate(x.periodN, date, this._input.groupBy))
        if (reference) {
          data1.push(this.roundNumber(reference.avgNum, 3));
          data2.push(this.roundNumber(reference.avgQty, 0));
        } else {
          data1.push(0);
          data2.push(0);
        }

      }


      this.basicOptions.xAxis = {
        data: xAxisData
      };
      this.basicOptions.yAxis = [
        Object.assign(this.yAxisBase(0),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGORDERS.YAXIS2'],
          }),
        Object.assign(this.yAxisBase(1),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGORDERS.YAXIS1'],
          })
      ];
      this.basicOptions.series = [
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGORDERS.DATA2'],
          type: 'bar',
          data: data2,
          itemStyle: {
            color: this.colors[0]
          },
          markLine: this.avgLine(this.translation['CUSTOMER.DASHBOARD.CHART.AVGORDERS.AVERAGE'], this.colors[0])
        },
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGORDERS.DATA1'],
          type: 'line',
          data: data1,
          yAxisIndex: 1,
          itemStyle: {
            color: this.colors[1]
          }
        }
      ];

      this.options = Object.assign({}, this.basicOptions);
      this.isWaiting = false;
    })
  }

  totalOrders() {
    this.isWaiting = true;
    this.customerService.chartTotalOrders(this._input).subscribe(res => {

      const xAxisData = [];
      const data1 = [];
      const data2 = [];
      const data3 = [];

      for (let date = this.firstDay(res.items, this._input.groupBy); date.isSameOrBefore(this._input.endDate); date.add(1, this.timeUnitForGrouBy(this._input.groupBy))) {
        xAxisData.push(this.formatByGroup(date, this._input.groupBy));
        let reference = res.items.find(x => this.isSameDate(x.periodN, date, this._input.groupBy))
        if (reference) {
          data1.push(this.roundNumber(reference.quantity, 0));
          data2.push(this.roundNumber(reference.orders, 0));
          data3.push(this.roundNumber(reference.clients, 0));
        } else {
          data1.push(0);
          data2.push(0);
          data3.push(0);
        }
      }

      this.basicOptions.xAxis = {
        data: xAxisData
      };
      this.basicOptions.yAxis = [
        Object.assign(this.yAxisBase(0),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.YAXIS1'],
          }),
        Object.assign(this.yAxisBase(1),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.YAXIS2']
          })
      ];
      this.basicOptions.series = [
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA1'],
          type: 'bar',
          data: data1,
          itemStyle: {
            color: this.colors[0]
          },
          markLine: this.avgLine(this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.AVERAGE'], this.colors[0])
        },
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA2'],
          type: 'line',
          data: data2,
          yAxisIndex: 1,
          itemStyle: {
            color: this.colors[1]
          },
        },
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALORDERS.DATA3'],
          type: 'line',
          data: data3,
          yAxisIndex: 1,
          itemStyle: {
            color: this.colors[2]
          },
        },
      ];

      this.options = Object.assign({}, this.basicOptions);
      this.isWaiting = false;
    })
  }


  avgMargin() {
    this.isWaiting = true;
    this.customerService.chartAvgMargin(this._input).subscribe(res => {

      const xAxisData = [];
      const data1 = [];
      const data2 = [];

      for (let date = this.firstDay(res.items, this._input.groupBy); date.isSameOrBefore(this._input.endDate); date.add(1, this.timeUnitForGrouBy(this._input.groupBy))) {
        xAxisData.push(this.formatByGroup(date, this._input.groupBy));
        let reference = res.items.find(x => this.isSameDate(x.periodN, date, this._input.groupBy))
        if (reference) {
          data1.push(this.roundNumber(reference.avgMargin * 1000, 1));
          data2.push(this.roundNumber(reference.dev * 1000, 1));
        } else {
          data1.push(0);
          data2.push(0);
        }
      }

      // res.items.forEach(x => {
      //   // xAxisData.push(moment(x.period, this.inputDateFormat).format(this.outputDateFormat));
      //   xAxisData.push(x.period);
      //   data1.push(x.avgMargin);
      //   data2.push(x.dev);
      // })

      this.basicOptions.xAxis = {
        data: xAxisData
      };
      this.basicOptions.yAxis = [
        Object.assign(this.yAxisBase(2),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGMARGIN.YAXIS1'],
          }),
        Object.assign(this.yAxisBase(0),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGMARGIN.YAXIS2']
          })
      ];
      this.basicOptions.series = [
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGMARGIN.DATA1'],
          type: 'bar',
          data: data1,
          itemStyle: {
            color: this.colors[2]
          },
          markLine: this.avgLine(this.translation['CUSTOMER.DASHBOARD.CHART.AVGMARGIN.AVERAGE'], this.colors[2])
        },
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.AVGMARGIN.DATA2'],
          type: 'line',
          data: data2,
          yAxisIndex: 1,
          itemStyle: {
            color: this.colors[0]
          },
        },
      ];

      this.options = Object.assign({}, this.basicOptions);
      this.isWaiting = false;
    })
  }


  totalMargin() {
    this.isWaiting = true;
    this.customerService.chartTotalMargin(this._input).subscribe(res => {

      const xAxisData = [];
      const data1 = [];

      for (let date = this.firstDay(res.items, this._input.groupBy); date.isSameOrBefore(this._input.endDate); date.add(1, this.timeUnitForGrouBy(this._input.groupBy))) {
        xAxisData.push(this.formatByGroup(date, this._input.groupBy));
        let reference = res.items.find(x => this.isSameDate(x.periodN, date, this._input.groupBy))
        if (reference) {
          data1.push(this.roundNumber(reference.totalMargin, 0));
        } else {
          data1.push(0);
        }
      }

      this.basicOptions.xAxis = {
        data: xAxisData
      };
      this.basicOptions.yAxis = [
        Object.assign(this.yAxisBase(2),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.YAXIS1'],
          })
      ];
      this.basicOptions.series = [
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.DATA1'],
          type: 'bar',
          data: data1,
          itemStyle: {
            color: this.colors[2]
          },
          markLine: this.avgLine(this.translation['CUSTOMER.DASHBOARD.CHART.TOTALMARGIN.AVERAGE'], this.colors[2]),
        }
      ];

      this.options = Object.assign({}, this.basicOptions);
      this.isWaiting = false;
    })
  }



  clientsQtyMargin() {
    this.isWaiting = true;
    this.customerService.chartClientQtyMargin(this._input).subscribe(res => {

      const data = [];

      res.items.forEach(x => {
        data.push([this.roundNumber(x.avgMargin, 1), this.roundNumber(x.qtyTotal, 0), x.clientName]);
      })

      this.basicOptions.xAxis = {
        name: this.translation['CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.XAXIS1'],
        // nameTextStyle: {
        //   fontSize: 19,
        //   overflow: 'break',
        //   width: 10,
        // }

      };

      this.basicOptions.grid = { containLabel: true };

      this.basicOptions.yAxis =
        Object.assign(this.yAxisBase(0),
          {
            name: this.translation['CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.YAXIS1']
          });

      this.basicOptions.tooltip = {
        trigger: 'axis',
        textStyle: {
          fontSize: 10
        },
        formatter: (params: any) => {
          let res = '';
          params.forEach(param => {
            res += param.value[2] + ' :<br/>'
              + '<strong>' + this.formatNumber(param.value[1]) + '</strong>' + 'L '
              + '<strong>' + this.formatNumber(param.value[0]) + '</strong>' + '€ ' + ' <br/><br/>';
          });
          return res;
        }
      };

      this.basicOptions.series = [
        {
          name: this.translation['CUSTOMER.DASHBOARD.CHART.CLIENTSQTYMARGIN.DATA1'],
          type: 'scatter',
          data: data,
          itemStyle: {
            color: this.colors[0]
          },
        }
      ];

      this.basicOptions.dataZoom = [{
        type: 'inside'
      }, {
        type: 'slider'
      }];

      this.options = Object.assign({}, this.basicOptions);
      this.isWaiting = false;
    })
  }
}
