import { Injectable, Output } from '@angular/core';
import * as moment from 'moment';
import { CalendarService } from './calendar.service';
import { EventEmitter } from '@angular/core';
import { Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(
        private translate: TranslateService,
    ) {

    }

    public static compare = (a: string | number, b: string | number, isAsc: boolean, aBis: string | number = null, bBis: string | number = null) => {
        if (a == b && aBis && bBis) { return UtilsService.compare(aBis, bBis, isAsc) }
        if (typeof (a) === 'string') {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        } else {
            return (b as number - a) * (isAsc ? 1 : -1);
        }
    }

    public static decimal(num: string | number): number {
        return parseFloat(num as unknown as string)
    }

    public static isValidEmail(val: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(val).toLowerCase());
    }

    public static isValidPassword(val: string): boolean {
        return val && val.length > 6;
    }

    public getLanguage(): string {
        let lang = this.translate.getBrowserLang()
        lang = 'it'; // TODO set other languages?
        return lang;
    }
}
