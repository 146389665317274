<nb-card>
    <nb-card-body>
        <nb-list>
            <nb-list-item>
                {{ "SETTINGS.WEEKLONG" | translate }} 
                <select class="spaced" [(ngModel)]="workdays" (change)="updateWeek()">
                    <option value="5">{{ "SETTINGS.WEEKLONG4" | translate }}</option>
                    <option value="6">{{ "SETTINGS.WEEKLONG5" | translate }}</option>
                    <option value="7">{{ "SETTINGS.WEEKLONG6" | translate }}</option>
                </select>
            </nb-list-item>
            <nb-list-item>
                {{ "SETTINGS.STRUCTURE" | translate }}
                <input class="spaced" nbInput type="number" step="0.001" [(ngModel)]="structure" />
                <button  class="spaced"nbButton (click)="updateStructure(structure)">{{ "SETTINGS.STRUCTUREBTN" | translate }}</button>
            </nb-list-item>
        </nb-list>
    </nb-card-body>
</nb-card>

<nb-card>
    <nb-card-header>
        {{ "SETTINGS.PRODUCTS.TITLE" | translate }}
    </nb-card-header>
    <nb-card-body>
        <table mat-table [dataSource]="dataSourceProducts" #table>

            <!-- name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- category -->
            <ng-container matColumnDef="category">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.CATEGORY' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{element.category}} </td>
            </ng-container>

            <!-- accisa -->
            <ng-container matColumnDef="accisa">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.ACCISA' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="editable">
                    <oil-value-editor [value]="element.accisa" (updateValue)="updateAccisa($event,element)"
                        [step]="0.01">
                    </oil-value-editor>
                </td>
            </ng-container>

            <!-- aliquota_iva -->
            <ng-container matColumnDef="aliquota_iva">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.ALIQUOTA_IVA' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="editable">
                    <oil-value-editor [value]="element.aliquota_iva" (updateValue)="updateAliquota($event,element)"
                        [step]="1">
                    </oil-value-editor>
                </td>
            </ng-container>

            <!-- platt -->
            <ng-container matColumnDef="platt">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.PLATT' | translate}} </th>
                <td mat-cell *matCellDef="let element"> {{'PRICES.PLATT.'+ plattsCode(element.platt) | translate}}
                </td>
            </ng-container>

            <!-- availability -->
            <ng-container matColumnDef="availability">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.PRODUCTS.TABLE.AVAILABILITY' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <nb-toggle [(checked)]="element.availability" (checkedChange)="changeAvailability($event,element)">
                    </nb-toggle> -->

                    <mat-slide-toggle [(checked)]="element.availability" (change)="changeAvailability($event,element)">
                    </mat-slide-toggle>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsProducts"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsProducts;" class="row-{{row.platt}}"></tr>
        </table>
    </nb-card-body>
</nb-card>


<nb-card>
    <nb-card-header>
        {{ "SETTINGS.DEPOTS.TITLE" | translate }}
    </nb-card-header>
    <nb-card-body>
        <table mat-table [dataSource]="dataSourceDepots" multiTemplateDataRows #table>

            <!-- code -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.DEPOTS.TABLE.CODE' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="expandToogle(element)"> {{element.code}} </td>
            </ng-container>

            <!-- name -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.DEPOTS.TABLE.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="expandToogle(element)"> {{element.name}} </td>
            </ng-container>

            <!-- address -->
            <ng-container matColumnDef="address">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.DEPOTS.TABLE.ADDRESS' | translate}} </th>
                <td mat-cell *matCellDef="let element" (click)="expandToogle(element)"> {{element.address}} </td>
            </ng-container>

            <!-- primary -->
            <ng-container matColumnDef="primary">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.DEPOTS.TABLE.PRIMARY' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <oil-value-editor [value]="element.primeCost" (updateValue)="updateDepotPrimary($event,element)"
                        [step]="0.01">
                    </oil-value-editor>
                </td>
            </ng-container>

            <!-- secondary -->
            <ng-container matColumnDef="secondary">
                <th mat-header-cell *matHeaderCellDef> {{ 'SETTINGS.DEPOTS.TABLE.SECONDARY' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <oil-value-editor [value]="element.secondary" (updateValue)="updateDepotSecondary($event,element)"
                        [step]="0.01">
                    </oil-value-editor>
                </td>
            </ng-container>

            <!-- delta -->
            <ng-container matColumnDef="delta">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" class="editable" (click)="expandToogle(element)">
                    <div *ngIf="expandedElement !== element">
                        <nb-icon class="btn" icon="arrow-ios-downward-outline"></nb-icon>
                    </div>
                    <div *ngIf="expandedElement === element">
                        <nb-icon class="btn" icon="arrow-ios-upward-outline"></nb-icon>
                    </div>
                </td>
            </ng-container>

            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" class="expanded-element"
                    [attr.colspan]="displayedColumnsDepots.length">
                    <div class="example-element-detail"
                        [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <nb-card>
                            <div class="delta-title" [nbSpinner]="!element.deltas || element.deltas.length == 0 ">{{
                                'SETTINGS.DEPOTS.TABLE.DELTA' |
                                translate}}</div>
                            <div class="delta-depots-row" *ngFor="let delta of element.deltas">
                                <div class="delta-productname">{{delta.productName}}</div>
                                <div class="delta-value">
                                    <oil-value-editor [value]="delta.delta"
                                        (updateValue)="updateDepotDelta($event,delta)" [step]="0.01">
                                    </oil-value-editor> 
                                    <mat-slide-toggle [checked]="delta.available == 1" (change)="changeDepotProductAvailability($event,delta)">
                                    </mat-slide-toggle>
                                </div>
                            </div>
                        </nb-card>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsDepots"></tr>


            <tr mat-row *matRowDef="let element; columns: displayedColumnsDepots;" class="example-element-row"
                [class.example-expanded-row]="expandedElement === element">
            </tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

        </table>
    </nb-card-body>
</nb-card>