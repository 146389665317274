import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { SortPaginator } from 'src/app/models/generic.model';
import { Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';

@Component({
  selector: 'oil-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {

  public selected = moment();
  public dateFilter = moment(this.selected);

  public filterString: string;
  public filterType: string;

  public dataSource;
  public resultsLength = 0;

  public displayedColumns: string[] = [
    'name',
    'truck',
    'tow',
    'driver',
    'product',
    'destinations',
    'quantity',
    'distance',
    'status'
  ];


  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private translation: TranslateService,
    private logisticService: LogisticService
  ) { }
  ngOnInit(): void {

    // this.refresh();

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.refresh();
    //       return of([]);
    //     })
    //   ).subscribe(data => {
    //     console.log('-------: DashboardComponent -> ngOnInit -> data', data);
    //   });
  }

  refresh() {
    // const paginator: SortPaginator = {
    //   orderBy: this.sort.active,
    //   start: this.paginator.pageIndex,
    //   limit: this.paginator.pageSize
    // }
    // if(this.sort.direction){paginator.orderDirection = this.sort.direction}


    this.translation.get('LOGISTIC.DELIVERY.TRIPBASENAME').subscribe(tripbasename => {
      this.logisticService.getTravelsTrips(null, tripbasename, this.dateFilter.toDate(), this.filterString, this.filterType).subscribe(x => {
        this.dataSource = new MatTableDataSource<Trip>(x.items);
        this.resultsLength = x.total;
      });
    })

  }

  selectedDateChange(date) {
    this.dateFilter = date;
    this.refresh();
  }

  addTrip() {
    console.log('-------: DeliveryComponent -> addTrip -> addTrip');
  }

  openTrip(trip: Trip) {
    console.log('-------: DeliveryComponent -> openTrip -> trip', trip);
  }



  // DELETE DOWN
  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  public showPassword = false;



}
