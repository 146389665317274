import { GenericFilter } from './generic.model';

export enum ValuesComparators {
    "GT" = '>', "LT" = "<", "EQ" = "="
}


export interface CustomerDashboardFilter extends GenericFilter {
}

export interface CustomerSearchFilter {
    region?: string,
    province?: string,
    vat?: string,
    cluster?: string,
    name?: string,
    productId?: string,
    includeWithoutOrders?: boolean


    orderImportOperator?: string,
    orderImportVal?: number,

    orderAmountLT?: number,
    orderAmountGT?: number,
    orderAmountEQ?: number,

    district?: string,
    orderImportCurrency?: string,
    orderDateSize?: string,
    orderDate?: string,
    nonewcustomer?: boolean,
}

export interface multifilter {
    name: string,
    value: string[]
}



