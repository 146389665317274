import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Driver } from 'src/app/models/logistic_model';
import { Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';

@Component({
  selector: 'oil-modal-select-driver',
  templateUrl: './modal-select-driver.component.html',
  styleUrls: ['./modal-select-driver.component.scss']
})
export class ModalSelectDriverComponent implements OnInit {

  public trip: Trip;
  public drivers: Driver[];

  public selectedDriverId: number = null;

  constructor(
    protected ref: NbDialogRef<ModalSelectDriverComponent>,
    private logisticService: LogisticService
  ) { }

  ngOnInit(): void {
    this.logisticService.getDriversList(this.trip.routeId).subscribe(x => {
      if (x && x.items) {
        this.drivers = x.items
      }
    });
  }

  select(driver: Driver) {
    this.selectedDriverId = driver.driverId;
  }


  isValid() {
    return !!this.selectedDriverId;
  }



  ok() {
    this.ref.close(this.selectedDriverId);
  }

  cancel() {
    this.ref.close();
  }


}
