import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/services/customer.service';
import { Customer, CustomerDetail, CustomerPoint, Policy } from 'src/app/models/customer.model';
import { TranslateService } from '@ngx-translate/core';
import { map, startWith, switchMap, take } from 'rxjs/operators';
import { CalendarService } from 'src/app/services/calendar.service';
import * as moment from 'moment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { of } from 'rxjs';
import { OrderService } from 'src/app/services/order.service';
import { SortPaginator } from 'src/app/models/generic.model';
import { MatTableDataSource } from '@angular/material/table';
import { ClientOrder, DeliveryTime, OrderList, OrderListApiResponse } from 'src/app/models/order.model';
import { merge } from 'rxjs';
import { CATEGORIES_VALUES } from 'src/app/models/constants.model';
import { IUserEntity } from 'src/app/models/user.model';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'oil-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public customer: CustomerDetail
  public noValue = null;
  // public calendarOptions = {};

  public maxCalendarItem = 3;

  public today = new Date();

  public showFullCalendar: boolean[] = [];

  public categoriesForPoint: any = [];

  public orderOpen = false;
  public ordersCollapsed = true;

  public deliveryTimes: DeliveryTime[] = [];

  public dataSource;
  public resultsLength: number;
  public displayedColumns: string[] = [
    'date',
    'number',
    'deposit',
    'product',
    'quantity',
    'value',
    'price',
    'status',
  ];

  public truckSizes = [
    { id: 'TINY', label: 'tiny' },
    { id: 'SMALL', label: 'small' },
    { id: 'MEDIUM', label: 'medium' },
    { id: 'LARGE', label: 'large' }
  ]

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort') sort: MatSort;


  // @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
  @ViewChild('businessList', { read: TemplateRef }) businessList: TemplateRef<any>;

  constructor(
    private activatedRouter: ActivatedRoute,
    private customerService: CustomerService,
    private orderService: OrderService,
    private translate: TranslateService,
    private calendarService: CalendarService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
    this.refresh();

    this.orderService.getDeliveryTimes().subscribe(x => this.deliveryTimes = x)

    setTimeout(() => {
      if (this.sort) {
        this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0
        );
        merge(this.sort.sortChange, this.paginator.page).pipe(
          startWith({}),
          switchMap(() => {
            this.refreshTable();
            return of([]);
          })
        ).subscribe(data => {
        });
      }
    }, 1);

  }

  refresh() {
    this.activatedRouter.paramMap.subscribe(navParams => {
      const code = navParams.get('code');
      this.customerService.getDetails(code, true).subscribe(customer => {
        this.customer = customer;
        this.orderTag(customer);
        this.showFullCalendar = [];
        customer.delivery_addresses.forEach(p => {
          this.showFullCalendar.push(false);
          if (p.events) {
            p.events = p.events.sort((x, y) => { return moment(x.date).isBefore(moment(y.date)) ? 1 : -1 })
          }
          // this.customerService.getEventsForPoint(customer.id,'' + p.id).subscribe(apires => { // TODO call with correct point/customer id
          //   p.events = apires.items
          //   console.log('-------: DetailsComponent -> ngOnInit -> p', p);
          // })
        })
        this.refreshTable();
      })

    });

  }

  refreshTable() {
    if (this.customer) {
      const paginator: SortPaginator = {
        orderBy: this.sort.active,
        start: this.paginator.pageIndex,
        limit: this.paginator.pageSize
      }
      if (this.sort.direction) { paginator.orderDirection = this.sort.direction }
      this.orderService.getClientTable(this.customer.id, paginator).subscribe(x => {
        this.dataSource = new MatTableDataSource<OrderList>(x.items);
        this.resultsLength = x.total;
      });
    }
  }


  toUpperCase(s: string) {
    return s.toUpperCase();
  }

  requestPrice() {
    this.customerService.requestPrice(this.customer.id).subscribe(x => {
      this.customer.haveRequestedPrice = true;
    })
  }


  orderTag(customer: CustomerDetail) {
    customer.delivery_addresses.forEach(point => {
      point.tags = point.tags.sort((a, b) => {
        if (a.type === b.type) {
          return a.name.localeCompare(b.name);
        } else {
          const ret = a.type === 'CATEGORY' ? -1 : (b.type === 'CATEGORY' ? 1 : (a.type === 'CLUSTER' ? -1 : 1))
          return ret;
        }
      });
    })
  }

  addEvent(point: CustomerPoint) {
    console.log('-------: DetailsComponent -> addEvent -> point', point);
    this.calendarService.createEventForPoint(this.customer.id, point.id).subscribe((x) => {
      console.log('-------: DetailsComponent -> addEvent -> EVENT ADDED', x);
      this.refresh();
    })
  }

  // }
  // addOrder(point) {
  //   console.log('-------: DetailsComponent -> addOrder -> point', point);
  //   this.refresh();

  // }

  editEvent(event) {
    this.calendarService.editEvent(event.id, false).subscribe((x) => {
      this.refresh();
    });
  }

  groupTags(tags) {
    let ret = [];
    if (tags) {
      let res = tags.reduce((acc, cur) => {
        acc[cur.category] = [...acc[cur.category] || [], cur];
        return acc;
      }, {});
      for (let p in res) {
        if (res[p].length && res[p][0].name) {
          ret.push(res[p]);
        }
      }
    }
    return ret

  }

  setFavorite(point: CustomerPoint) {
    this.customerService.setCustomerPointFavorite(point.id, !point.favorite).subscribe(() => {
      point.favorite = !point.favorite;
    })
  }

  // getCalendarOptions(point) {
  //   return this.calendarOptions[point.id] ? this.calendarOptions[point.id] : { initialView: 'listYear' };
  // }

  addBusinessPop(point: CustomerPoint) {
    this.categoriesForPoint = [];
    for (let cat in CATEGORIES_VALUES) {
      if (!point.tags.find(x => x.category == CATEGORIES_VALUES[cat])) {
        this.categoriesForPoint.push(CATEGORIES_VALUES[cat]);
      }
    }
  }

  addBusiness(point: CustomerPoint, category: string) {
    this.customerService.addBusiness(point, category).subscribe(() => {
      this.refresh();
    })
  }

  updateDeliveryTime(point: CustomerPoint, deliveryTime: string) {
    this.customerService.updatePoint(point, null, deliveryTime).subscribe(() => {
      this.refresh();
    })
  }

  updateTruckMaxSize(point: CustomerPoint, truckSize: string) {
    this.customerService.updatePoint(point, truckSize).subscribe(() => {
      this.refresh();
    })
  }

  createUser() {

    this.modalService.createClientUser(this.customer.id).pipe(take(1)).subscribe(res => {
      if (res) {
        this.refresh();
      }
    })
  }

  getSellers() {
    let res: IUserEntity[] = [];
    if (this.customer && this.customer.delivery_addresses) {
      this.customer.delivery_addresses.forEach(delivery => {
        if (!res.find(x => x.id == delivery.salesAgent.id)) {
          res.push(delivery.salesAgent);
        }
      });
    }
    return res;
  }

  changePolcy($event, policy: Policy, customer: Customer) {
    this.customerService.setPolicy(customer.id, policy.policy, $event.checked).subscribe(res => {
      policy.accept = $event.checked;
    })
  }

  public openOrders(isOpen) {
    this.orderOpen = !isOpen;
  }

  public collapseOrders(isOpen) {
    this.ordersCollapsed = !isOpen;
  }

  tagNameForTranslate(tag) {
    const res = `FILTERS.${tag.type}.${tag.name}`;
    console.log('------- ~ file: details.component.ts ~ line 250 ~ tagNameForTranslate ~ res', res.toUpperCase());
    return res.toUpperCase();
  }

}
