<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected" [dateSync]="true" showFuture="true">
</oil-dateribbon>

<nb-card size="big">
    <nb-card-header>
        <div class="header-title">{{'LOGISTIC.DELIVERY.TITLE' | translate}}</div>
    </nb-card-header>

    <nb-card-body [hidden]="resultsLength">
        <div class="nodata">{{ 'LOGISTIC.DELIVERY.NODATA' | translate}}</div>
    </nb-card-body>

    <nb-card-body [hidden]="!resultsLength">

        <div class="filter">
            <div class="search">
                <input class="input-search" status="basic" type="text" nbInput [ngModel]="filterString"
                    (change)="refresh()">
            </div>
            <div class="radio">
                <nb-radio-group class="radio-container" layout="row" [(ngModel)]="filterType" (change)="refresh()">
                    <nb-radio value="orders" selected="true">{{ 'LOGISTIC.DELIVERY.ORDERS' | translate}}</nb-radio>
                    <nb-radio value="client">{{ 'LOGISTIC.DELIVERY.CLIENT' | translate}}</nb-radio>
                    <nb-radio value="truck">{{ 'LOGISTIC.DELIVERY.TRUCK' | translate}}</nb-radio>
                </nb-radio-group>
            </div>
        </div>


        <table mat-table [dataSource]="dataSource" matSort #table>


            <!-- Viaggio -->
            <ng-container matColumnDef="name">
                <th class="" mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" class="cell-trip"><a (click)="openTrip(element)">
                        {{element.name}} </a></td>
            </ng-container>

            <!-- Autobotte -->
            <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.DELIVERY.TABLE.TRUCK' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="cell-truck"> {{element.vehicleName}} </td>
            </ng-container>

            <!-- Rimorchio -->
            <ng-container matColumnDef="tow">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.DELIVERY.TABLE.TOW' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.towName}} </td>
            </ng-container>

            <!-- Autista -->
            <ng-container matColumnDef="driver">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'LOGISTIC.DELIVERY.TABLE.DRIVER' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.driver}} </td>
            </ng-container>

            <!-- PRODOTTO -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.DELIVERY.TABLE.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.product}} </td>
            </ng-container>

            <!-- Destinazioni -->
            <ng-container matColumnDef="destinations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.DELIVERY.TABLE.DESTINATION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> <span
                        *ngFor="let dest of element.destinations;let last = last">{{dest}} <span *ngIf="!last">
                            &nbsp;&nbsp;|&nbsp;&nbsp; </span></span></td>
            </ng-container>


            <!-- QUantità -->
            <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.DELIVERY.TABLE.QUANTITY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
            </ng-container>

            <!-- Distance -->
            <ng-container matColumnDef="distance">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'LOGISTIC.DELIVERY.TABLE.DISTANCE' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.distance}} </td>
            </ng-container>

            <!-- status -->
            <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                <td mat-cell *matCellDef="let element">
                    <nb-icon icon="close-circle-outline" status="danger" *ngIf="element.status=='canceled'"></nb-icon>
                    <nb-icon icon="clock-outline" status="warning" *ngIf="element.status=='delayed'"></nb-icon>
                    <span *ngIf="element.status!='ok'">{{element.status}} </span>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
        </table>

        <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator> -->

    </nb-card-body>
    <nb-card-footer>
        <div class="button-container">
            <button nbButton status="danger" class="actions-button"
                (click)="addTrip()">{{'LOGISTIC.DELIVERY.ADDTRIP'|translate}}</button>
        </div>
    </nb-card-footer>

</nb-card>