import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NB_THEME_OPTIONS } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import { ClosePoint } from 'src/app/models/customer.model';
import { Order } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-modal-closeby',
  templateUrl: './modal-closeby.component.html',
  styleUrls: ['./modal-closeby.component.scss']
})
export class ModalClosebyComponent implements OnInit {

  public order: Order;
  public rangeKm = 3;
  public clients: ClosePoint[] = [];
  public allClients: ClosePoint[] = [];
  public center: number[] = [11, 43];
  public timePeriodDays = 365;

  constructor(
    protected ref: NbDialogRef<ModalClosebyComponent>,
    private orderService: OrderService
  ) {
  }

  ngOnInit(): void {
    this.refresh();
    
  }

  refresh() {
    this.orderService.getCloseBy(this.order.order_id, this.rangeKm).subscribe(t => {
      this.center = t.order_delivery_location.location.coordinates;
      this.allClients = t.items;
      this.updateTimePeriod();
    }, err => {
      this.ref.close();
    })
  }

  formatLabel(value: number): string {
    return value + "km";
  }

  updateTimePeriod() {
    this.clients = this.allClients.filter(x => {return  Math.abs(x.nearestDateDiff) <= this.timePeriodDays })
  }


  cancel() {
    this.ref.close();
  }


}
