<nb-sidebar-header class="user-info">

    <nb-user size="large" [nbContextMenu]="profilemenu" nbContextMenuTag="my-context-menu" [name]="myUser?.name"
        [title]="'ROLES.'+(myUser?.role? myUser.role:'CLIENT') | translate" [picture]="myUser?.img">
    </nb-user>
    <!-- <p><strong>{{myUser.name}}</strong></p>
    <p>{{myUser.role}}</p> -->

</nb-sidebar-header>


<nb-card>
    <nb-card-header class="section-title">{{'SIDEBAR.FAV'|translate}}</nb-card-header>

</nb-card>
<nb-card>
    <nb-card-header class="section-title">{{'SIDEBAR.TODO'|translate}}
        <div class="icon-bell" [ngClass]="{'ring-bell':agendaEvents.length>0}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g data-name="Layer 2">
                    <g data-name="bell">
                        <rect width="24" height="24" opacity="0" />
                        <path
                            d="M20.52 15.21l-1.8-1.81V8.94a6.86 6.86 0 0 0-5.82-6.88 6.74 6.74 0 0 0-7.62 6.67v4.67l-1.8 1.81A1.64 1.64 0 0 0 4.64 18H8v.34A3.84 3.84 0 0 0 12 22a3.84 3.84 0 0 0 4-3.66V18h3.36a1.64 1.64 0 0 0 1.16-2.79zM14 18.34A1.88 1.88 0 0 1 12 20a1.88 1.88 0 0 1-2-1.66V18h4zM5.51 16l1.18-1.18a2 2 0 0 0 .59-1.42V8.73A4.73 4.73 0 0 1 8.9 5.17 4.67 4.67 0 0 1 12.64 4a4.86 4.86 0 0 1 4.08 4.9v4.5a2 2 0 0 0 .58 1.42L18.49 16z" />
                    </g>
                </g>
            </svg>
        </div>
    </nb-card-header>
    <nb-card-body>

        <nb-list>
            <nb-list-item *ngFor="let event of agendaEvents">
                <a class="decoration-none agenda" [routerLink]="event.url">
                    <span *ngIf="event.counter == 1">{{event.title+'.SINGLE' | translate}}</span>
                    <span *ngIf="event.counter > 1">{{event.title+'.MULTIPLE' | translate:{counter:event.counter} }}</span>
                </a>
            </nb-list-item>
        </nb-list>


    </nb-card-body>

</nb-card>