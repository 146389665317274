// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  showVersion: true,
  sendErrors: true,
  strategyname: 'email',
  title: 'oil project',
  domain: 'localhost',
  baseurl: '/api',
  // baseurlclient: 'https://oilproject.3logic.it/api/client',
  acl: {
    CLIENT: {
      view: ['CLIENT']
    },
    SALES_AGENT: {
      view: ['CUSTOMERS', 'PRICES', 'ORDERS']
    },
    OPERATION: {
      view: ['SUPPLY', 'LOGISTIC']
    },
    ADMIN: {
      view: ['SUPPLY', 'CUSTOMERS', 'PRICES', 'ORDERS', 'LOGISTIC', 'SETTINGS','ADMIN'],
      approve: ['PRICES', 'ORDERS'],
      remove: '*',
    },
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
