import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbIconModule, NbTooltipModule, NbProgressBarModule, NbRadioModule, NbFormFieldModule, NbSpinnerModule, NbAlertModule } from '@nebular/theme';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'src/app/components/component.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { RoutesComponent } from './routes/routes.component';
import { UnassignedordersComponent } from './unassignedorders/unassignedorders.component';
import { DeliveryComponent } from './delivery/delivery.component';
import { TrucksComponent } from './trucks/trucks.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbCardModule,
    // NbLayoutModule,
    // NbSelectModule,
    NbIconModule,
    // NbCheckboxModule,
    // NbPopoverModule,    
    // NbDatepickerModule,
    NbAlertModule,
    NbSpinnerModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbRadioModule,
    NbFormFieldModule,
    TranslateModule,
    ComponentModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSortModule,
    NgSelectModule,
    MomentModule,
    PipesModule
  ],
  declarations: [
  RoutesComponent,
  UnassignedordersComponent,
  DeliveryComponent,
  TrucksComponent],
})
export class LogisticModule {
}
