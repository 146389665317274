<nb-card>
    <nb-card-header>{{'LOGISTIC.TRUCKMODAL.TITLE'|translate}}</nb-card-header>

    <nb-card-body class=" modal">
        <div class="drop">
            <span class="label">{{'LOGISTIC.TRUCKMODAL.TRUCK' | translate}}</span>
            <ng-select size="small" [items]="trucks" bindLabel="targa" bindValue="targa" autofocus [(ngModel)]="truck"
                placeholder="{{ 'LOGISTIC.TRUCKMODAL.TRUCKPH' |translate}}"> </ng-select>
        </div>

        <div class="drop">
            <span class="label">{{'LOGISTIC.TRUCKMODAL.TOW' | translate}}</span>
            <ng-select size="small" [items]="tows" bindLabel="targa" bindValue="targa" autofocus [(ngModel)]="tow"
                placeholder="{{ 'LOGISTIC.TRUCKMODAL.TOWPH' |translate}}"> </ng-select>
        </div>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="danger" [disabled]="!isValid()"
            (click)="ok()">{{'LOGISTIC.TRUCKMODAL.ASSIGN'|translate}}</button>
        <button nbButton class="right" (click)="cancel()">{{'LOGISTIC.TRUCKMODAL.CANCEL'|translate}}</button>
    </nb-card-footer>
</nb-card>