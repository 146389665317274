import { Component, OnInit, ViewChild } from '@angular/core';
import { Price } from 'src/app/models/price.model';
import { MatSort } from '@angular/material/sort';
import { PriceService } from 'src/app/services/price.service';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { SortPaginator } from 'src/app/models/generic.model';
import { MatPaginator } from '@angular/material/paginator';
import { UtilsService } from 'src/app/services/utils.service';
import { MODAL } from 'src/app/models/constants.model';
import { ModalService } from 'src/app/services/modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'oil-requestapproval',
  templateUrl: './requestapproval.component.html',
  styleUrls: ['./requestapproval.component.scss']
})
export class RequestapprovalComponent implements OnInit {

  public displayedColumns: string[] = [
    "favorite",
    "name",
    "product",
    // "fido",
    "price",
    "price_modified",
    "margin_modified",
    "modify",
  ];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;


  public dataSource;
  public resultsLength = -1;


  constructor(
    private priceService: PriceService,
    private modalService: ModalService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.refresh();

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
      });

  }


  refresh() {
    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

    this.priceService.getPriceRequestList(paginator).subscribe(x => {
      this.dataSource = new MatTableDataSource<Price>(x.items);
      this.resultsLength = x.total;
    });

  }

  confirm(element: Price) {
    this.priceService.requestConfirm(element.id).subscribe(x => {
      this.refresh();
    });
  }
  delete(element: Price) {
    this.translate.get(['PRICES.REQUEST.DELETECONFIRMMSG', 'PRICES.REQUEST.DELETECONFIRMTITLE']).subscribe(translations => {
      this.modalService.confirm(translations['PRICES.REQUEST.DELETECONFIRMMSG'], translations['PRICES.REQUEST.DELETECONFIRMTITLE']).subscribe(res => {
        if (res == MODAL.confirmOk) {
          this.priceService.requestReset(element.id).subscribe(x => {
            this.refresh();
          });
        }
      })
    })
  }

  newMargin(element: Price) {
    const res = UtilsService.decimal(element.margin) + UtilsService.decimal(element.price_modified) - UtilsService.decimal(element.price_proposed)
    return res;
  }

}
