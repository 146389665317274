import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { NbAuthComponent, NbAuthService } from '@nebular/auth';

@Component({
  selector: 'ngx-auth',
  styleUrls: ['./auth.component.scss'],
  templateUrl: './auth.component.html',
})
export class NgxAuthComponent extends NbAuthComponent {

  constructor(
    auth: NbAuthService,
    public location: Location,
  ) {
    super(auth, location)

  }

  public isLogin() {
    let url = this.location.path(false);    
    return url.indexOf('login') >= 0;
  }

}
