import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';

@Component({
  selector: 'oil-company-name',
  templateUrl: './company-name.component.html',
  styleUrls: ['./company-name.component.scss']
})
export class CompanyNameComponent implements OnInit {

  @Input() element;
  @Input() id;
  @Input() hideDelivery:boolean = false;
  @Input() altClass:string;

  
  constructor(
    public accessChecker: NbAccessChecker
  ) { }

  ngOnInit(): void {
  }

}
