import { Component, OnInit } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { Location } from '@angular/common';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public btnTypes = config.mainAreas;


  constructor(
    public accessChecker: NbAccessChecker,
    public location: Location
  ) { }

  ngOnInit(): void {
  }

  isSelected(directory) {
    const val = this.location.path().indexOf(directory) === 0;
    return val;
  }

  search() {

  }
  calendar() {

  }

  settings(){
    
  }

}
