import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Policy } from 'src/app/models/customer.model';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'oil-modal-profile',
  templateUrl: './modal-profile.component.html',
  styleUrls: ['./modal-profile.component.scss']
})
export class ModalProfileComponent implements OnInit {

  public user: User;

  public forced: boolean = false;

  public error = '';

  public policies = [];

  constructor(
    protected ref: NbDialogRef<ModalProfileComponent>,
    private userService: UserService,) { }

  ngOnInit(): void {
    if (this.userService.iMClient())
      this.userService.getPrivacyPolicies().subscribe(x => {
        this.policies = x.items;
      })
  }

  isValid() {
    return true;;
  }

  changePolicy($event, policy: Policy) {
    this.userService.setPrivacyPolicy(policy.policy, $event.checked).subscribe(res => {
      if (res.error) {
        this.error = res.error.message;
      }
    });

  }

  ok() {
    this.ref.close();
  }

  // cancel() {
  //   this.ref.close();
  // }
}
