import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import * as moment from 'moment';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerPoint, CustomerBase } from 'src/app/models/customer.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DeliveryTime, Order } from 'src/app/models/order.model';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from 'src/app/services/order.service';
import { PriceService } from 'src/app/services/price.service';
import { Price } from 'src/app/models/price.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'oil-modal-new-order',
  templateUrl: './modal-new-order.component.html',
  styleUrls: ['./modal-new-order.component.scss']
})
export class ModalNewOrderComponent implements OnInit {

  public customers: CustomerBase[];
  public points: CustomerPoint[];

  public ckEditor = ClassicEditor;

  public prices: Price[];
  public deliveryDateMoment = CalendarService.nextWorkingDay();
  // public title : string;
  public thisOrder: Order = {
    id: null,
    // orderNumber: null,
    // product_id: null,
    // product_code: null,
    // product: null,
    // category: null,
    // client_id: null,
    // client_code: null,
    // name: null,
    // client_delivery_id: null,
    // client_delivery_name: null,
    // client_delivery_code: null,
    // client_delivery_address_1: null,
    // client_delivery_address_2: null,
    // client_delivery_cap: null,
    // client_delivery_city: null,
    // client_delivery_province: null,
    quantity: 0,
    // price: null,
    // notes: null,
    // sales_agent_id: null,
    // sales_agent_code: null,
    // creationDate: null,
    // deliveryDate: null,
    // deliveryTimeRangeFrom: null,
    // deliveryTimeRangeTo: null,
  };

  public modifyCustomer = true;
  public modifyPoint = true;
  public modifyProduct = true;
  public modifyDate = true;

  public remainingCredit = 0;
  public isLoadingSuggestion = false;

  
  public deliveryTimes: DeliveryTime[] = [];

  clientInput: CustomerBase;
  filteredOptions$: Observable<CustomerBase[]>;

  constructor(
    protected ref: NbDialogRef<ModalNewOrderComponent>,
    private customerService: CustomerService,
    private translate: TranslateService,
    private orderService: OrderService,
    private priceService: PriceService
  ) {



  }

  ngOnInit(): void {

    this.orderService.getDeliveryTimes().subscribe(x => this.deliveryTimes = x)

    if (this.thisOrder.deliveryDate) {
      this.deliveryDateMoment = moment(this.thisOrder.deliveryDate);
    }

    // this.customerService.getAll().subscribe(res => {
    //   this.customers = res;
    //   if (this.thisOrder.client_id) {
    //     this.updateCustomer({ id: this.thisOrder.client_id });
    //   }
    // })

    if (this.thisOrder.client_id) {
      this.customerService.getDetails('' + this.thisOrder.client_id, true).subscribe(res => {
        if (res && res.name) {
          this.updateCustomer({ id: this.thisOrder.client_id, name: res.name });
          this.clientInput = { id: this.thisOrder.client_id, name: res.name };
        }
      })

    }



  }

  save() {

    this.thisOrder.deliveryDate = moment(this.deliveryDateMoment).toDate();

    this.orderService.save(this.thisOrder).subscribe(X => {
      this.ref.close(this.thisOrder);
    })


  }

  cancel() {
    this.ref.close();
  }

  viewHandle(value: CustomerBase) {
    if (value && value.name)
      return value.name;
    return value;
  }

  updateCustomer($event: CustomerBase) {
    console.log('---- ~ file: modal-new-order.component.ts ~ line 113 ~ ModalNewOrderComponent ~ updateCustomer ~ $event', $event);
    this.thisOrder.client_delivery_id = this.modifyPoint ? null : this.thisOrder.client_delivery_id;
    if ($event && $event.id) {
      this.thisOrder.client_id = $event.id;
      this.customerService.getDetails('' + $event.id, true).subscribe(res => {
        const list = res.delivery_addresses.map((i) => { i.label = `${i.name ? i.name : ''}-${i.address_1 ? i.address_1 : ''}-${i.city ? i.city : ''}`; return i; });
        this.points = list;
        this.remainingCredit = res.remainingCredit ? res.remainingCredit : 0;
        if (!this.thisOrder.client_delivery_id && this.points.length === 1) {
          this.thisOrder.client_delivery_id = this.points[0].id;
          this.updatePoint(this.points[0]);
        } else if(this.thisOrder.client_delivery_id){
          this.updatePoint(this.points.find(x=>x.id === this.thisOrder.client_delivery_id));          
        }
      })

    } else {
      this.points = null;
    }

  }

  getClientFilteredOptions(filter: string): Observable<CustomerBase[]> {
    this.isLoadingSuggestion = true;
    return this.customerService.getFiltered(filter).pipe(map(x => { this.isLoadingSuggestion = false; return x.items; }));
  }

  onChangeClientInput(value: string | CustomerBase) {
    console.log('---- ~ file: modal-new-order.component.ts ~ line 159 ~ ModalNewOrderComponent ~ onChangeClientInput ~ value', value);
    if (typeof value == 'string') {
      // this.filteredOptions$ = of(null);
      this.filteredOptions$ = this.getClientFilteredOptions(value);
      // return this.filteredOptions$;
    } else {
      this.updateCustomer(value);
    }
  }


  updatePoint($event) {
    this.updatePrice();
  }

  updateProduct($event: Price) {
    this.thisOrder.price = PriceService.getEffectivePrice($event);
    this.thisOrder.maxQuantity = $event.quantity_promotional;
    this.thisOrder.deliveryTimeName = $event.deliveryTime.name;
  }

  updatePrice() {

    const pointId = this.thisOrder.client_delivery_id;
    const clientId = this.thisOrder.client_id;
    console.log('------- ~ file: modal-new-order.component.ts ~ line 182 ~ ModalNewOrderComponent ~ updatePrice ~ this.thisOrder', this.thisOrder);

    this.priceService.getPriceList({ start: 0, limit: 1000 }, moment(), '' + clientId, pointId, null).subscribe(res => {
      this.prices = res.items;
      if (this.thisOrder.product_id) {
        const price = this.prices.find(x => x.product_id === this.thisOrder.product_id);
        this.updateProduct(price);
      }
    });
  }

  changeQuantity() {
    if (this.thisOrder.quantity < 0) {
      this.thisOrder.quantity = 0;
    }
    if (this.thisOrder.maxQuantity && this.thisOrder.quantity > this.thisOrder.maxQuantity) {
      this.thisOrder.quantity = this.thisOrder.maxQuantity;
    }
  }

  isValid() {
    return this.thisOrder.client_id &&
      this.thisOrder.client_delivery_id &&
      this.deliveryDateMoment && this.deliveryDateMoment.isSameOrAfter(moment(), 'day') &&
      this.thisOrder.price &&
      this.thisOrder.product_id &&
      this.thisOrder.quantity && !Number.isNaN(this.thisOrder.quantity * 1)
  }

}
