<nb-card size="big" style="display: none">
    <nb-card-header>
        <div class="header-title">{{'ORDERS.PROMOTION.TRIPSTITLE' | translate}}</div>
        <div class="header-info remaining-time" *ngIf="isPromotionActive()"
            nbTooltip="{{promotionEnds | amDateFormat:'HH:mm:ss'}}">{{'ORDERS.PROMOTION.REMAININGTIME' | translate}}
            {{remainingtime() }}
        </div>
    </nb-card-header>

    <nb-card-body *ngIf="isWaiting">
        {{'ORDERS.PROMOTION.NORESULT' | translate}}
        <button nbButton (click)="refresh()">{{'ORDERS.PROMOTION.TRYAGAIN' | translate}}</button>
    </nb-card-body>

    <nb-card-body [hidden]="resultsLengthTrip || isWaiting">
        {{'ORDERS.PROMOTION.NODATA' | translate}}
    </nb-card-body>
    <nb-card-body [hidden]="!resultsLengthTrip || isWaiting">

        <table mat-table [dataSource]="dataSourceTrip" #sortTrips="matSort" matSort #tableTrip>

            <!-- Viaggio -->
            <ng-container matColumnDef="name">
                <th class="" mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.ROUTE' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="cell-trip"><a (click)="openTrip(element)">
                        {{element.routeId}} </a></td>
            </ng-container>

            <!-- Autobotte -->
            <ng-container matColumnDef="truck">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.TRUCK' | translate}} </th>
                <td mat-cell *matCellDef="let element" class="cell-truck"> {{element.vehicleName}} </td>
            </ng-container>

            <!-- RImorchio -->
            <ng-container matColumnDef="tow">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLETRIP.TOW' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.towName}} </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="trip-products">
                    <div *ngFor="let tank of element.tanks">{{productList(tank.products)}}</div>
                </td>
            </ng-container>

            <!-- Data di consegna  -->
            <ng-container matColumnDef="delivery">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.DELIVERY' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.deliveryDate | date : 'dd/MM/yyyy'}}</td>
            </ng-container>

            <!-- Destinazioni -->
            <ng-container matColumnDef="destinations">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.DESTINATION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> <span
                        *ngFor="let dest of element.destinations; let last = last">{{dest}} <span *ngIf="!last">
                            &nbsp;&nbsp;|&nbsp;&nbsp; </span> </span>
                </td>
            </ng-container>

            <!-- Riempimento autobotte -->
            <ng-container matColumnDef="filling">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.FILLING' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="filling">
                    <div *ngFor="let tank of element.tanks">
                        <div class="filling-bar">
                            <nb-progress-bar [value]="tank.fillingQuantity/tank.maxCapacity*100" size="tiny"
                                status="warning"></nb-progress-bar>
                        </div>
                        <div class="filling-percentual">{{tank.fillingQuantity/tank.maxCapacity*100 | number :
                            '1.0-0'}}% </div>
                    </div>
                </td>
            </ng-container>

            <!-- Capacità residua -->
            <ng-container matColumnDef="remain">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.REMAIN' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let tank of element.tanks">
                        {{tank.maxCapacity - tank.fillingQuantity | number | liter}}
                    </div>
                    <div class="remain-capacity">
                        ({{ 'ORDERS.PROMOTION.TABLETRIP.REMAINTOTAL' | translate}}{{remainigCapacity(element) | number | liter}})
                    </div>
                </td>
            </ng-container>

            <!-- In promozione -->
            <ng-container matColumnDef="promotion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.PROMOTION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngFor="let tank of element.tanks">
                        {{tank.inPromotion | number | liter}}
                    </div>
                </td>
            </ng-container>

            <!-- Aggiunta promozione -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLETRIP.ACTION' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="button-container">
                        <button nbButton *ngIf="isPromotionActive()" status="danger" class="actions-button" (click)="addClient(element)">
                            {{'ORDERS.PROMOTION.ADDCLIENT' | translate}}
                        </button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsTrip"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsTrip;"
                [ngClass]="{'suggested':row.routeId == suggestedRouteId,'today':isToday(row.deliveryDate)}"> </tr>
        </table>

        <!-- <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLengthTrip"></mat-paginator> -->

    </nb-card-body>
</nb-card>

<nb-card size="big">
    <nb-card-header>
        <div class="header-title">{{'ORDERS.PROMOTION.PROMOTIONTITLE' | translate}}</div>
        <div class="header-info">(
            <span class="client-suggested">{{'ORDERS.PROMOTION.CLIENT' | translate}}</span>
            {{'ORDERS.PROMOTION.BYSYSTEM' | translate}}
            <span class="client-added">{{'ORDERS.PROMOTION.CLIENT' | translate}}</span>
            {{'ORDERS.PROMOTION.BYMANAGER' | translate}}
            )
        </div>
    </nb-card-header>

    <nb-card-body [hidden]="remainingMinutes() > 0"> {{ 'ORDERS.PROMOTION.TABLEPROM.NODATA' | translate}}
    </nb-card-body>

    <nb-card-body [hidden]="remainingMinutes() <= 0">

        <div *ngIf="waitingPromotion">
            <nb-card [nbSpinner]="waitingPromotion" nbSpinnerStatus="warning">
                <nb-card-body>
                    {{'ORDERS.PROMOTION.TABLEPROM.WAITING' | translate}}
                </nb-card-body>
            </nb-card>
            <button nbButton class="tryagain-btn" (click)="refresh()">{{'ORDERS.PROMOTION.TABLEPROM.TRYAGAIN' |
                translate}}</button>
        </div>

        <table mat-table [dataSource]="dataSourceProm" #sortProm="matSort" matSort #tablePromotion>

            <!-- Status -->
            <ng-container matColumnDef="promo-status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'ORDERS.PROMOTION.TABLEPROM.STATUS' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="client-status">
                    <div class="client-status client-status-{{element.clientStatus}}">{{element.clientStatus}}</div>
                </td>
            </ng-container>

            <!-- Ragione sociale -->
            <ng-container matColumnDef="promo-name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLEPROM.NAME' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    <oil-company-name [element]="element" [id]="element.clientId"
                        [altClass]="element.suggestedByIA?null:'fromUser'"></oil-company-name>
                </td>
            </ng-container>

            <!-- Data -->
            <ng-container matColumnDef="promo-date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLEPROM.DATE' | translate}} </th>
                <td mat-cell *matCellDef="let element">
                    {{element.promotionDate  | date : 'dd/MM/yyyy'}}
                </td>
            </ng-container>

            <!-- Prodotto -->
            <ng-container matColumnDef="promo-product">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ 'ORDERS.PROMOTION.TABLEPROM.PRODUCT' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.product}}
                </td>
            </ng-container>

            <!-- Quantità massima in promozione -->
            <ng-container matColumnDef="promo-quantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.QUANTITY' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.maxQuantity | number | liter}}</td>
            </ng-container>

            <!-- Quantità rimasta in promozione -->
            <ng-container matColumnDef="promo-remainingQuantity">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.REMAINING_QUANTITY' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.remainingQuantity | number | liter}}</td>
            </ng-container>

            <!-- Prezzo promotion -->
            <ng-container matColumnDef="promo-price">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.PRICE' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.price | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>

            <!-- Margine promotion -->
            <ng-container matColumnDef="promo-margin">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.MARGIN' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.priceCalculatedMargin | currency:'€': 'symbol' : '1.3-3'}}</td>
            </ng-container>


            <!-- Fido residuo  -->
            <ng-container matColumnDef="promo-fido">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.FIDO' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="credit"
                    [ngClass]="{'nocredit':(element.remainingCredit<0)}">
                    {{element.remainingCredit | currency:'€': 'symbol' : '1.0-0'}}</td>
            </ng-container>

            <!-- Viaggio suggerito -->
            <ng-container matColumnDef="promo-route">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'ORDERS.PROMOTION.TABLEPROM.ROUTE' |
                    translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <a class="suggestedRoute" (click)="openPromotionTrip(element)">
                        {{routeName(element.suggestedRoute)}} </a>
                </td>
            </ng-container>

            <!-- invia  -->
            <ng-container matColumnDef="promo-send">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <button nbButton status="danger" class="actions-button" (click)="sendPromotion(element)"
                        *ngIf="element.promotionStatus != 'sent'">
                        {{'ORDERS.PROMOTION.TABLEPROM.SEND' | translate}}
                    </button>
                    <button nbButton status="basic" class="alt-actions-button" (click)="newOrder(element)"
                        *ngIf="element.promotionStatus == 'sent'">
                        {{'ORDERS.PROMOTION.TABLEPROM.ORDER' | translate}}
                    </button>
                </td>
            </ng-container>

            <!-- actions  -->
            <ng-container matColumnDef="promo-actions">
                <th mat-header-cell *matHeaderCellDef>
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="button-icon" *ngIf="element.promotionStatus != 'sent'">
                        <nb-icon icon="edit-outline" (click)="edit(element)"
                            [options]="{ animation: { type: 'pulse' } }" nbTooltip="{{'CTA.EDIT'|translate}}">
                        </nb-icon>
                    </div>
                    <div class="button-icon" (click)="delete(element)" *ngIf="element.promotionStatus != 'sent'">
                        <nb-icon icon="trash-2-outline" [options]="{ animation: { type: 'pulse' } }"
                            [nbPopover]="deleteTemplate" nbPopoverPlacement="top" nbPopoverTrigger="click">
                        </nb-icon>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumnsProm"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsProm;"
                [ngClass]="{'today':isToday(row.promotionDate)}" (mouseenter)="setSuggestedRoute(row.suggestedRoute)"
                (mouseleave)="setSuggestedRoute()"> </tr>
        </table>
        <!-- 
        <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLengthTrip"></mat-paginator> -->

    </nb-card-body>
</nb-card>

<ng-template #deleteTemplate>
    <nb-card class="margin-bottom-0">
        <nb-card-header>{{'ORDERS.PROMOTION.CONFIRMDELETE' | translate}}</nb-card-header>

        <nb-card-body>
            <button (click)="confirmDelete($event)">{{'ORDERS.PROMOTION.CONFIRMDELETEYES' | translate}}</button>
            <button (click)="cancelDelete($event)">{{'ORDERS.PROMOTION.CONFIRMDELETENO' | translate}}</button>
        </nb-card-body>

    </nb-card>
</ng-template>