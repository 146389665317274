import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbThemeModule, NbLayoutModule, NbSidebarModule, NbActionsModule, NbToastrModule, NbSpinnerModule, NbTabsetModule, NbUserModule, NbCardModule, NbSelectModule, NbPopoverModule, NbDatepickerModule, NbMenuModule, NbDialogModule } from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbIconModule } from '@nebular/theme';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NbPasswordAuthStrategy, NbAuthModule, NbDummyAuthStrategy, NbAuthJWTToken } from '@nebular/auth';
import { AuthGuard } from './services/auth-guard.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from 'src/environments/environment';

import { NbAlertModule, NbButtonModule, NbCheckboxModule, NbInputModule } from '@nebular/theme';
import { NgxAuthModule } from './pages/auth/auth.module';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { RoleProvider } from './services/role.provider.service';
import { LoadingService } from './services/loading.service';
import { NotifyService } from './services/notify.service';
import { ClientiModule } from './pages/clienti/clienti.module';
import { ComponentModule } from './components/component.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
// import { SlickModule } from 'ngx-slick';
import { OrdiniModule } from './pages/ordini/ordini.module';
import { LogisticModule } from './pages/logistic/logistic.module';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import { MatPaginatorIntlTranslate } from './providers/MatPaginatorTransl';
import { PipesModule } from './pipes/pipes.module';
import { PrezziModule } from './pages/prezzi/prezzi.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import * as moment from 'moment';
import { HomeComponent } from './pages/home/home.component';
import { ClientModule } from './pages/client/client.module';
// import { NgxEchartsModule } from 'ngx-echarts';
import 'echarts/i18n/langDE';

import { APP_INITIALIZER, ErrorHandler } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";
import { AdminModule } from './pages/admin/admin.module';
import { PolicyComponent } from './pages/policy/policy.component';


export function momentAdapterFactory() {
  return adapterFactory(moment);
};

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeFr);
registerLocaleData(localeIt);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PolicyComponent
  ],
  imports: [
    PipesModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    NbAlertModule,
    NbButtonModule,
    NbCheckboxModule,
    NbInputModule,
    NbActionsModule,
    NbThemeModule.forRoot({ name: 'corporate' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbIconModule,
    NbSpinnerModule,
    NbTabsetModule,
    NbUserModule,
    NbCardModule,
    NbSelectModule,
    NbPopoverModule,
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    HttpClientModule,
    NbSidebarModule.forRoot(),
    NbToastrModule.forRoot(),
    NbDialogModule.forRoot(),
    NbAuthModule.forRoot({
      strategies: [
        NbPasswordAuthStrategy.setup({
          name: environment.strategyname,
          baseEndpoint: environment.baseurl,
          token: {
            class: NbAuthJWTToken,
            key: 'token',
          },
          login: {
            endpoint: '/auth/login',
            method: 'post',
          },
          register: {
            endpoint: '/auth/sign-up',
          },
          logout: {
            endpoint: '/auth/sign-out',
          },
          requestPass: {
            endpoint: '/auth/request-pass',
          },
          resetPass: {
            endpoint: '/auth/reset-pass',
          },
        }),
      ],
      forms: {},
    }),
    NgxAuthModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    ClientiModule,
    ClientModule,
    OrdiniModule,
    AdminModule,
    LogisticModule,
    PrezziModule,
    ComponentModule,
    NbSecurityModule.forRoot({
      accessControl: environment.acl,
    }),
    NgSelectModule,
    FormsModule,
    MomentModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: momentAdapterFactory }),
    // SlickModule.forRoot(),
    // NgxEchartsModule.forRoot({
    //   echarts: () => import('echarts')
    // })
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    LoadingService,
    NotifyService,
    AuthGuard,
    { provide: NbRoleProvider, useClass: RoleProvider },
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlTranslate },
    { provide: LOCALE_ID, useValue: 'it-IT' } // TODO must work for every language
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
