import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbIconModule, NbPopoverModule, NbTooltipModule, NbProgressBarModule, NbSpinnerModule } from '@nebular/theme';

import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'src/app/components/component.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { OrderlistComponent } from './orderlist/orderlist.component';
import { OrderapprovalComponent } from './orderapproval/orderapproval.component';
import { OrderpromotionComponent } from './orderpromotion/orderpromotion.component';
import { OrderrequestComponent } from './orderrequest/orderrequest.component';
import { OrdernewComponent } from './ordernew/ordernew.component';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { OrderdetailComponent } from './orderdetail/orderdetail.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbCardModule,
    // NbLayoutModule,
    // NbSelectModule,
    NbIconModule,
    // NbCheckboxModule,
    NbPopoverModule,
    // NbDatepickerModule,
    NbProgressBarModule,
    NbTooltipModule,
    NbSpinnerModule,
    TranslateModule,
    ComponentModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatSortModule,
    NgSelectModule,
    MomentModule,
    PipesModule
  ],
  declarations: [
    OrderlistComponent,
    OrderdetailComponent,
    OrderapprovalComponent,
    OrderpromotionComponent,
    OrderrequestComponent,
    OrdernewComponent
  ],
})
export class OrdiniModule {
}
