import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { config } from 'src/environments/config';
import { updateLocale } from 'moment';
import * as mapboxgl from 'mapbox-gl';
import { ClosePoint, CustomerPoint } from 'src/app/models/customer.model';
import { CustomerOrder, FuelDepot, Order } from 'src/app/models/order.model';
import { CustomerService } from 'src/app/services/customer.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'oil-tripmap',
  templateUrl: './tripmap.component.html',
  styleUrls: ['./tripmap.component.scss']
})
export class TripmapComponent implements OnInit {

  private _geojson: any;
  @Input() set geojson(value: any) {
    this._geojson = value;
    this.update();
  }

  @Input() destinations: CustomerOrder[];
  @Input() depots: FuelDepot[];

  @Input() extraMarker: number[];

  @Input() set highlight(lngLat: number[]) {
    if (lngLat && lngLat.length > 1) {
      this.highlightCoords(lngLat[0], lngLat[1]);
    }
    else {
      this.highlightCoords();
    }
  }


  @Input() radius: number;
  private _centerMarker: number[];
  @Input() set centerMarker(value: number[]) {
    this._centerMarker = value;
    this.update();
  }


  private _closeClients: ClosePoint[];
  @Input() set closeClients(value: ClosePoint[]) {
    this._closeClients = value;
    this.update();
  }


  @Output() selectedStage = new EventEmitter();

  public map: any; // Mapbox GL Map object (Mapbox is ran outside angular zone, keep that in mind when binding events from this object)
  public layers = [];
  public markers = [];

  public center = config.map.center;
  public defaultZoom = config.map.defaultZoom;
  public bounds: any;

  private ignoreLATLONG = true; // TODO delete

  private highlightMarker: mapboxgl.Marker;


  private translations;

  constructor(
    private translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    this.translate.get(['TRIPMAP.DETAILS', 'TRIPMAP.NEXTORDERS', "CATEGORIES.GAS.RISC", "CATEGORIES.GAS.AUTO", "CATEGORIES.GAS.AGR", "CATEGORIES.ALTRI"]).subscribe(t => {
      this.translations = t;
    })
  }

  public setmap(ev) {
    this.map = ev;

    setTimeout(() => {
      this.map.resize();
    }, 1);

    this.update();
  }

  update() {
    if (!this.map) { //wait for map to be ready
      setTimeout(() => {
        this.update();
      }, 500)
      return;
    }
    this.clearMap(this.map);
    if (this._geojson) {
      let geometry = this.invertCoordinates(this._geojson.geometry);
      if (this._geojson.trips && this._geojson.trips.length && this._geojson.trips[0].geometry) {
        geometry = this.invertCoordinates(this._geojson.trips[0].geometry);
      }
      this.addGeometry(geometry, this.map)
      if (this.depots) {
        this.addStartMarker(this.depots, this.map);
      }
    }

    if (this.destinations) {
      this.addDestinationsMarkers(this.destinations, this.map);
    }

    if (this.extraMarker) {
      this.addExtraMarker(this.extraMarker, this.map);
    }


    if (this._centerMarker) {
      this.addCenterMarker(this._centerMarker, this.map);
      this.setCenter(this._centerMarker, this.radius)
    }


    if (this._closeClients) {
      this.addCloseClientsMarker(this._closeClients, this.map);
    }
  }

  private invertCoordinates(geometry) {
    if (geometry && geometry.coordinates && geometry.coordinates.length) {
      let inv = [];
      geometry.coordinates.forEach(coord => {
        inv.push([coord[1], coord[0]]);
      });
      geometry.coordinates = inv;
    }
    return geometry;
  }


  public highlightCoords(lng: number = null, lat: number = null) {
    if (this.highlightMarker) { this.highlightMarker.remove() }
    if (lng && lat) {
      this.highlightMarker = new mapboxgl.Marker({
        element: this.createMarkerElement(null, 'highlight-marker', null)
      }).setLngLat(new mapboxgl.LngLat(lng, lat))
        .addTo(this.map);
    }
  }

  clearMap(map) {
    this.layers.forEach(layer => {
      map.removeLayer(layer);
      map.removeSource(layer);
    })
    this.markers.forEach(marker => {
      marker.remove();
    })

    this.layers = [];
    this.markers = [];
  }

  addGeometry(geometry, map) {
    if (map) {
      map.addSource('route', {
        'type': 'geojson',
        'data': {
          'type': 'Feature',
          'properties': {},
          'geometry': geometry
        }
      });
      map.addLayer({
        'id': 'route',
        'type': 'line',
        'source': 'route',
        'layout': {
          'line-join': 'round',
          'line-cap': 'round'
        },
        'paint': {
          'line-color': config.map.routeColor,
          'line-width': config.map.routeWidth
        }
      });
      this.layers.push('route');

      this.setBoundaries(geometry);
    }
  }

  setBoundaries(geometry) {
    const boudaries = this.getBoudaries(geometry.coordinates);

    this.bounds = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(boudaries.minLat, boudaries.minLong),
      new mapboxgl.LngLat(boudaries.maxLat, boudaries.maxLong)
    );

    this.map.fitBounds(this.bounds, {
      padding: 20
    });
  }


  setCenter(center, radius) {

    var distanceX = radius / (111.320 * Math.cos(center[0] * Math.PI / 180));
    var distanceY = radius / 110.574;

    this.bounds = new mapboxgl.LngLatBounds(
      new mapboxgl.LngLat(center[0] - distanceX, center[1] - distanceY),
      new mapboxgl.LngLat(center[0] + distanceX, center[1] + distanceY)
    );

    this.map.fitBounds(this.bounds, {
      padding: 20
    });
  }

  addStartMarker(depots: FuelDepot[], map) {
    const features = [];
    const cleanName = (s) => { if (s) { return s; } else { return ''; } }
    if (depots && depots.length) {

      const depotsLetter = ['D', 'P', 'A']

      depots.forEach((depot: FuelDepot, idx) => {
        var marker = new mapboxgl.Marker({
          element: this.createMarkerElement(depots.length == 1 ? depotsLetter[idx] : depotsLetter[idx + 1 % depotsLetter.length], 'depot-marker', null)
        }).setLngLat(new mapboxgl.LngLat(depot.location.coordinates[0], depot.location.coordinates[1]))
          .setPopup(this.createPopup(`<strong>Deposito ${cleanName(depot.name)} </strong><p> ${cleanName(depot.address)} </p>`))
          .addTo(map);
        this.markers.push(marker);
      });

    }
  }

  addDestinationsMarkers(destinations: CustomerOrder[], map) {
    let idx = 1;
    const groupedDestinations = destinations.reduce((accumulator, currentValue) => {
      let found = accumulator.find(x => currentValue.location.coordinates[0] == x.location.coordinates[0] && currentValue.location.coordinates[1] == x.location.coordinates[1]);
      if (found) {
        found.label = found.label + ' ' + idx;
      } else {
        found = Object.assign({}, currentValue)
        found.label = idx + '';
        accumulator.push(found);
      }
      idx += 1;
      return accumulator;
    }, []);
    groupedDestinations.forEach((destination, idx) => {
      if ((destination.location && destination.location.coordinates && destination.location.coordinates[0] && destination.location.coordinates[1]) || this.ignoreLATLONG) {
        var marker = new mapboxgl.Marker({
          element: this.createMarkerElement(destination.label, destination.status == "delivered" ? 'stagedelivered-marker' : 'stage-marker', idx + 1)
        }).setLngLat(new mapboxgl.LngLat(destination.location.coordinates[0], destination.location.coordinates[1]))
          .setPopup(this.createStagePopup(destination, idx)) // sets a popup on this marker
          .addTo(map);
        this.markers.push(marker);
      }
    })
  }

  addExtraMarker(coordinates: number[], map) {
    if ((coordinates && coordinates[0] && coordinates[1])) {
      var marker = new mapboxgl.Marker({
        color: '#E60146'
      }).setLngLat(new mapboxgl.LngLat(coordinates[0], coordinates[1]))
        .addTo(map);
      this.markers.push(marker);
    }
  }

  addCenterMarker(coordinates: number[], map) {
    if ((coordinates && coordinates[0] && coordinates[1])) {
      var marker = new mapboxgl.Marker({
        //color: '#E60146'
        element: this.createMarkerElement('X', 'depot-marker', null)
      }).setLngLat(new mapboxgl.LngLat(coordinates[0], coordinates[1]))
        .addTo(map);
      this.markers.push(marker);
    }
  }

  addCloseClientsMarker(client_points: ClosePoint[], map) {
    if (client_points && client_points.length) {

      client_points.forEach((point: ClosePoint) => {

        let popupText = this.createCloseClientPopupMarkup(point);



        var marker = new mapboxgl.Marker({
          color: this.closeClientColor(point)
        }).setLngLat(new mapboxgl.LngLat(point.location.coordinates[0], point.location.coordinates[1]))
          .setPopup(this.createPopup(popupText))
          .addTo(map);
        this.markers.push(marker);
      });

    }
  }

  createCloseClientPopupMarkup(point: ClosePoint): string {
    let popupText = `<p><strong><big> ${point.name} </big></strong> <br>${point.address_1} </p>`
    if (point.orders_ranges) {
      popupText += `<p><strong>${this.translations['TRIPMAP.NEXTORDERS']}:</strong><br>`
      point.orders_ranges.forEach(range => {
        popupText += `${this.translations['CATEGORIES.' + range.category]} ${moment(range.min_range).format(config.shortDateFormat)} - ${moment(range.max_range).format(config.shortDateFormat)}<br>`
      });
      popupText += `</p>`
    }
    popupText += `<p><a href="/customers/details/${point.clientId}"> ${this.translations['TRIPMAP.DETAILS']} </a></p>`
    return popupText;
  }

  closeClientColor(point: ClosePoint): string {
    let color = '#0f4c81';
    switch (CustomerService.rangeClass(point.orders_ranges)) {
      case 'present': color = '#ffa500'; break;
      case 'past': color = '#F00'; break;
      case 'future': color = '#31AA2B'; break;
    }
    return color;
  }

  createStagePopup(destination, idx: number): mapboxgl.Popup {
    const cleanName = (s) => { if (s) { return s; } else { return ''; } }
    let html = `<strong>${idx + 1}. ${destination.deliveryName}</strong><p><a href="/customers/details/${destination.deliveryId}" target="_blank">${destination.deliveryName}</a> ${cleanName(destination.deliveryAddress_1)} ${cleanName(destination.deliveryAddress_2)} ${destination.deliveryCap} ${destination.deliveryCity} (${destination.deliveryProvince})</p>`;
    return this.createPopup(html);
  }

  createPopup(html: string): mapboxgl.Popup {
    var popup = new mapboxgl.Popup({ offset: 25 }).setHTML(
      html
    );
    return popup;
  }

  createMarkerElement(htmlContent: string, className: string, id: number): HTMLElement {
    var el = document.createElement('div');
    el.className = className + ' marker';
    this.setFontSizeForContent(el, htmlContent);
    el.innerHTML = htmlContent;
    if (id) {
      el.onmouseenter = (e) => { this.mouseOn(id) }
      el.onmouseleave = (e) => { this.mouseOn() }
    }
    return el;
  }

  setFontSizeForContent(el, htmlContent) {
    if (htmlContent && htmlContent.length) {
      el.style.fontSize = Math.max(6, (14 - htmlContent.length)) + 'px';
    }
  }

  mouseOn(id: number = null) {
    this.selectedStage.emit(id);
  }

  getBoudaries(coordinates) {
    let minLat = coordinates[0][0];
    let minLong = coordinates[0][1];
    let maxLat = coordinates[0][0];
    let maxLong = coordinates[0][1];
    coordinates.forEach(element => {
      minLat = Math.min(minLat, element[0])
      minLong = Math.min(minLong, element[1])
      maxLat = Math.max(maxLat, element[0])
      maxLong = Math.max(maxLong, element[1])
    });
    return { minLat, minLong, maxLat, maxLong };
  }

}
