import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { config } from 'src/environments/config';
import * as moment from 'moment';
import { DeltaApiResponse, DeltaProduct, Settings } from '../models/settings.model';
import { Moment } from 'moment';
import { Product } from '../models/generic.model';
import { Depot } from '../models/logistic_model';
import { subscribeOn } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    private static currentSettings: Settings = {
        week: 5,
        holydays: [],
    };


    constructor(
        private api: ApiService
    ) {

        // this.setSettings();
    }

    public load() {
        this.setSettings();
    }

    public static weekLength(): number {
        return SettingsService.currentSettings.week;
    }

    public static isHolyday(day: Moment): boolean {
        return !!this.currentSettings.holydays.find(d => {
            return moment(d).isSame(day, 'day');
        })
    }


    public setSettings() {

        this.getWorkdays().subscribe(val => {
            // SettingsService.currentSettings.week = val.total;
        });

    }


    // public saveSettings(newSettings: Settings) {
    //     SettingsService.currentSettings = newSettings;
    //     return this.api.call(`settings`, 'POST', null, newSettings);
    // }

    public getWorkdays(): Observable<any> {
        return this.api.call(`workdays`, 'get');
    }

    public saveWorkdays(val): Observable<any> {
        const dayArray = [];
        for (let i = 1; i <= val; i++) {
            dayArray.push(i);
        }
        const data = { days: dayArray }
        return this.api.call(`admin/config/workdays`, 'post', data);
    }


    public saveSettings(newSettings: Settings) {
        SettingsService.currentSettings = newSettings;
        return this.api.call(`settings`, 'POST', null, newSettings);
    }

    public updateProductAvailability(product: Product, availability: boolean) {
        let prod = Object.assign({}, product);
        prod.availability = availability ? 1 : 0;
        return this.api.call(`admin/config/product/update`, 'POST', prod);
    }

    public updateProductAccisa(product: Product, accisa: number) {
        let prod = Object.assign({}, product);
        prod.accisa = accisa;
        return this.api.call(`admin/config/product/update`, 'POST', prod);
    }

    public updateProductAliquota(product: Product, aliquota: number) {
        let prod = Object.assign({}, product);
        prod.aliquota_iva = aliquota;
        return this.api.call(`admin/config/product/update`, 'POST', prod);
    }

    public updateDepotDelta(delta: DeltaProduct, value: number, available: boolean) {
        let data =
        {
            depotId: delta.depotId,
            productId: delta.productId,
            delta: value,
            available: available
        }
        return this.api.call(`admin/config/depot/set-product-delta`, 'POST', data);
    }



    public getDepotDelta(depotId: number) {
        return this.api.call(`admin/config/depot/${depotId}/get-product-delta`, 'GET');
    }

    public updateDepotPrimary(depot: Depot, primary: number) {
        let dep = Object.assign({}, depot);
        dep.primeCost = primary;
        return this.api.call(`admin/config/depot/update`, 'POST', dep);
    }

    public updateDepotSecondary(depot: Depot, secondary: number) {
        let dep = Object.assign({}, depot);
        dep.secondary = secondary;
        return this.api.call(`admin/config/depot/update`, 'POST', dep);
    }

    public getStructureCost() {
        return this.api.call(`admin/config/get-structure-price`, 'get');
    }

    public updateStructureCost(structValue) {
        let data = { value: structValue };
        return this.api.call(`admin/config/set-structure-price`, 'POST', data);

    }


}
