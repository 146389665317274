<nb-card>
    <nb-card-header>{{'LOGISTIC.TRUCKLISTMODAL.TITLE'|translate}}</nb-card-header>

    <nb-card-body class=" modal">

        <oil-truckslist [date]="date"></oil-truckslist>

    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="danger" (click)="ok()">{{'LOGISTIC.TRUCKLISTMODAL.OK'|translate}}</button>
        <button nbButton (click)="cancel()">{{'LOGISTIC.TRUCKLISTMODAL.CANCEL'|translate}}</button>
    </nb-card-footer>
</nb-card>
