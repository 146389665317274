<h1>{{ 'POLICY.TITLE' | translate }}</h1>
<div class="policy-text">
    <!-- {{ 'POLICY.TEXT' | translate }} -->
    In questa pagina si descrivono le modalità di gestione della presente Piattaforma in riferimento al trattamento dei
    dati personali degli utenti che la utilizzano.
    Si tratta di un’informativa che è resa anche ai sensi dell’art. 13 del Regolamento 2016/679/UE - Regolamento
    generale sulla protezione dei dati (di seguito anche “Regolamento”) a coloro i quali interagiscono con i servizi web
    del Consorzio universitario in Ingegneria per la Qualità e l'Innovazione (di seguito anche “QUINN” o “Consorzio”),
    accessibili per via telematica a partire dall’indirizzo: ______________________________, corrispondente alla pagina
    iniziale della piattaforma “Smart Oil Solution” (di seguito anche “Piattaforma”).
    L’informativa deve considerarsi rilasciata, in via esclusiva, per i contenuti relativi alle pagine di questa
    piattaforma e non anche in relazione a risorse esterne e/o siti che l’utente si trovi a consultare tramite eventuali
    link presenti sulle pagine della Piattaforma.
     <h6>TITOLARE DEL TRATTAMENTO</h6>
    A seguito della consultazione della Piattaforma possono essere trattati dati relativi a persone identificate o
    identificabili. Il Titolare del trattamento è il Consorzio Universitario in Ingegneria per la Qualità e
    l’Innovazione, in persona del Presidente e legale rappresentante pro tempore, Prof. Marcello Braglia, con sede
    legale in Pisa (PI), Lungarno Pacinotti n. 43 e sede operativa in Pisa (PI), Piazza Carrara n. 19, iscritto al
    Registro delle Imprese della C.C.I.A.A. di Pisa, n. REA PI – 99864, codice fiscale/partita IVA n. 01098690504, pec:
    consorzioquinn@pec.it.
    <h6>BASE GIURIDICA DEL TRATTAMENTO</h6>
    I dati personali raccolti mediante la navigazione sulla Piattaforma sono trattati da QUINN sulla base,
    principalmente, della necessità di dare seguito al contratto in essere col cliente (art. 6 par. 1 lett. b del
    Regolamento). Per quanto riguarda alcuni trattamenti principalmente aventi finalità di marketing che possono essere
    compiuti tramite la Piattaforma (ad esempio quelli svolti mediante l’uso di appositi cookie), la base giuridica del
    trattamento si rinviene nel libero consenso dell’interessato (art. 6 par. 1 lett. a del Regolamento).
    LUOGO DI TRATTAMENTO E DESTINATARI DEI DATI
    I trattamenti connessi al funzionamento della Piattaforma hanno luogo presso la sede di QUINN, nonché presso i
    locali dei fornitori tecnologici di QUINN coinvolti nella loro gestione e allo scopo appositamente nominati
    responsabili dei relativi trattamenti e sono curati solo da personale autorizzato al trattamento. In particolare, i
    dati possono essere comunicati a 3logic MK S.r.l. con sede in via S. Frediano, 5 – 56126 Pisa tel. 050 2201368 quale
    fornitore informatico e manutentore della Piattaforma. Nessun dato derivante dalla Piattaforma viene comunicato ad
    altri soggetti oltre a quelli elencati nella presente informativa né diffuso.
    <h6>TIPI DI DATI TRATTATI E FINALITÀ</h6>
    Dati di navigazione
    Desideriamo informare che i sistemi informatici e le procedure software preposte al funzionamento di questa
    Piattaforma web acquisiscono, nel corso del loro normale esercizio, alcuni dati personali la cui trasmissione è
    implicita nell’uso dei protocolli di comunicazione di Internet.
    Si tratta di informazioni che non sono raccolte per essere associate a interessati identificati, ma che per loro
    stessa natura potrebbero, attraverso elaborazioni ed associazioni con dati detenuti da terzi, permettere di
    identificare gli utenti.
    In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti che
    si connettono alla Piattaforma, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
    richieste, il browser, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la
    dimensione del file ottenuto in risposta (buon fine, errore, ecc.), il codice numerico indicante lo stato della
    risposta data dal server ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
    Questi dati sono utilizzati al fine di ricavare informazioni statistiche anonime sull’uso della Piattaforma e per
    controllarne il corretto funzionamento e sono conservati per un periodo di tempo non superiore a 90 giorni, tempo
    ritenuto congruo a consentire le verifiche eventualmente necessarie all’accertamento di responsabilità in caso di
    reati informatici ai danni della Piattaforma.
    Dati forniti volontariamente dall’utente
    L’invio facoltativo, esplicito e volontario di posta elettronica agli indirizzi indicati su questa Piattaforma
    comporta la successiva acquisizione dell’indirizzo del mittente nonché degli eventuali altri dati personali inseriti
    e il successivo trattamento per le finalità ivi indicate e comunque per rispondere alle richieste degli interessati.
    Si assicura, tuttavia, che tale trattamento sarà improntato ai principi di correttezza, liceità e trasparenza e di
    tutela della riservatezza come indicato nel Regolamento. Normalmente, i dati raccolti in questo modo vengono
    cancellati all’esito della risposta alle richieste degli interessati. Già in questa sede precisiamo, però, che tutti
    i dati, qualora richiesti nei modi previsi per legge devono essere resi disponibili alla Polizia Postale e delle
    Comunicazioni, all'Autorità Giudiziaria e alla Polizia giudiziaria e, più in generale, ad ogni pubblica Autorità a
    ciò legittimata da disposizioni di legge o regolamento.
     <h6>COOKIES</h6>
    Nella Piattaforma di QUINN sono utilizzati dei cookie. Per maggiori informazioni sui cookie e per gestire le
    preferenze in relazione ad essi, gli utenti sono invitati a consultare la nostra "Cookie Policy"
    FACOLTATIVITÀ DEL CONFERIMENTO DEI DATI
    A parte quanto sopra specificato, resta ferma la possibilità, da parte dell’utente, di non fornire i propri dati
    personali. Il mancato conferimento, tuttavia, in alcuni casi e laddove precisato, può compromettere l'accesso, la
    fruizione e l'erogazione della Piattaforma, nonché la qualità della prestazione attesa.
    <h6>MODALITÀ DEL TRATTAMENTO</h6>
    Fatto salvo quanto indicato all’interno della Cookie Policy, i dati personali acquisiti per il funzionamento tecnico
    della Piattaforma sono trattati con strumenti automatizzati per il tempo strettamente necessario a conseguire gli
    scopi per i quali sono stati raccolti e come sopra indicato. Specifiche misure di sicurezza sono osservate per
    prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.
    <h6>LINK A SITI ESTERNI</h6>
    Questa Piattaforma contiene collegamenti ipertestuali detti “link” (ossia strumenti che consentono il collegamento
    ad una pagina web esterna alla Piattaforma): i siti esterni raggiungibili tramite link attraverso la Piattaforma di
    QUINN sono sviluppati e gestiti da soggetti sui quali il Consorzio non ha alcuna titolarità né controllo e non è in
    alcun modo responsabile circa contenuti, qualità, accuratezza e servizi offerti. La visita e l’utilizzo dei siti
    consultati dall’utente tramite link a partire dalla presente Piattaforma, quindi, è rimessa esclusivamente alla
    totale discrezionalità e responsabilità dell’utente utilizzatore.
    <h6>DIRITTI DEGLI INTERESSATI </h6>
    I soggetti cui si riferiscono i dati personali hanno il diritto in qualunque momento di chiedere al titolare del
    trattamento l'accesso ai dati personali e la rettifica o la cancellazione degli stessi o la limitazione del
    trattamento che lo riguardano o di opporsi al loro trattamento, oltre al diritto alla portabilità dei dati nei casi
    previsti per legge (artt. 15 e ss. del Regolamento 2016/679/UE). Le richieste possono, in tal senso, essere
    inoltrate, senza particolari formalità e comunque senza spese, al titolare del trattamento ai recapiti sopra
    indicati.
    Nel caso di violazione della normativa rilevante posta a tutela della riservatezza e dei dati personali,
    l’interessato ha anche il diritto di proporre reclamo all’Autorità Garante per la protezione dei dati personali i
    cui recapiti sempre aggiornati sono disponibili sul sito www.garanteprivacy.it.
     Considerando che lo stato di perfezionamento dei meccanismi automatici di controllo non li rende attualmente esenti
    da errori e disfunzioni, si precisa che il presente documento costituisce la “Privacy Policy” di questa Piattaforma
    che potrà essere soggetta ad aggiornamenti. Le modifiche potranno, tra l’altro, rendersi necessarie alla luce
    dell’evoluzione delle tecnologie informatiche e delle innovazioni normative. Sulla Piattaforma sarà sempre
    disponibile la versione aggiornata del presente documento.

    <h6>DIRITTI DEGLI INTERESSATI </h6>

    I cookie sono di regola stringhe di testo che le risorse web (cd. Publisher o "prima parte") visitate dall'utente ovvero siti o web server diversi (cd. "terze parti") posizionano e archiviano all'interno di un dispositivo terminale nella disponibilità dell'utente utilizzato per navigarli. 
Con la presente, QUINN informa che sulla propria Piattaforma web sono presenti le tipologie di cookie indicate nella tabella sotto allegata.
Gli unici cookie che possono essere memorizzati automaticamente sui dispositivi degli utenti sono quelli tecnici strettamente necessari per il funzionamento del sito.
Per tutti gli altri tipi di cookie, anche di terze parti, è necessario che l'utente, qualora d'accordo, presti il proprio consenso all'installazione sul suo dispositivo di tali strumenti.
A questo scopo, quando si visita per la prima volta una qualsiasi sezione della Piattaforma, viene visualizzato un banner per informare l'utente che utilizziamo anche cookie con finalità di marketing e profilazione e per permettergli di compiere le proprie scelte in merito in modo consapevole. Il banner richiede, infatti, all'utente se e quali cookie decide di ricevere e se desidera ulteriori informazioni che potrà ottenere consultando il link a questa pagina. Se si chiude semplicemente il banner o se si decide di rifiutare i cookie aventi finalità di marketing e/o profilazione, sarà comunque possibile continuare a navigare sul sito senza che tali cookie vengano scaricati sul terminale dell'utente. Qualora, invece, si decida di acconsentire all'uso di una o più tipologie di cookie non essenziali, la navigazione continuerà e tali strumenti verranno scaricati sul terminale dell'utente. In tutti i casi, il sito memorizzerà un cookie sul terminale dell'utente che tenga traccia di queste scelte e durante la successiva navigazione del sito, il banner non verrà più mostrato tranne se mutano una o più delle condizioni alle quali è stato raccolto o quando sia impossibile, per la Piattaforma, sapere se un cookie sia stato già memorizzato nel dispositivo. Gli utenti avranno, comunque, sempre la possibilità di rimuovere i cookie attraverso le impostazioni del browser e/o seguendo le istruzioni presenti su questa pagina.
In aggiunta a quanto è possibile fare col banner, ricordiamo che la maggior parte dei browser attualmente in uso accetta automaticamente i cookie, ma l'utente conserva la facoltà di configurare il proprio in maniera tale da escludere, visualizzare prima di accettare e cancellare i cookies.
Sarà peraltro sempre possibile richiedere la disattivazione dei cookie di tutti i siti modificando le impostazioni del browser, ma tale disattivazione potrà rallentare o impedire l'accesso ad alcune parti del sito. Per facilitare la configurazione del proprio browser, si ricapitolano di seguito le operazioni da compiere con quelli più diffusi:
<a href=">https://support.google.com/chrome/answer/95647?hl=en">Chrome </a>, 
<a href=">https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">Microsoft Edge </a>, 
<a href="> https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">Mozilla Firefox</a>, 
<a href=">https://www.opera.com/it/case-studies/clean-browser-and-remove-trackers">Opera </a>, 
<a href=">https://support.apple.com/it-it/guide/safari/sfri11471/mac">Safari </a>, 
<a href=">https://www.adobe.com/privacy/policies/flash-player.html">Adobe (flash cookies) </a>, 
Se il browser utilizzato non è tra quelli proposti, selezionare la funzione "Aiuto" sul proprio browser per trovare le informazioni su come procedere per la gestione dei cookie.
Maggiori informazioni su questi strumenti sono reperibili anche al seguente <a href="https://www.allaboutcookies.org">link</a>
Per avere maggiori informazioni in merito a QUINN, ai recapiti ai quali rivolgersi per eventuali richieste e informazioni ed esercitare i diritti previsti dalla normativa vigente e, più in generale, sulle modalità di trattamento dei dati personali, è possibile consultare la "Privacy Policy" della Piattaforma tramite l'apposita sezione dedicata alla privacy.
Descrizione di dettaglio dei cookie utilizzati e gestione del consenso:

</div>
<div class="policy-actions">
    <a class="policy-back" [routerLink]="'/'">
        <button nbButton>{{ 'POLICY.BACK' | translate }}</button>
    </a>
    <button nbButton class="policy-cookie" (click)="showCookieNow()">{{ 'POLICY.COOKIE' | translate }}</button>

</div>



<oil-cookies [forceShow]="showCookie" *ngIf="showCookie"></oil-cookies>