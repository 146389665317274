import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { CustomerFilterComponent } from './customer-filter/customer-filter.component';
import { NbIconModule, NbContextMenuModule, NbCardModule, NbButtonModule, NbUserModule, NbSidebarModule, NbDatepickerModule, NbInputModule, NbTooltipModule, NbListModule, NbBadgeModule, NbTabsetModule, NbProgressBarModule, NbSelectModule, NbCheckboxModule, NbToggleModule, NbSpinnerModule, NbAccordionModule } from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SectionheaderComponent } from './sectionheader/sectionheader.component';
import { HeaderComponent } from './header/header.component';
import { DateribbonComponent } from './dateribbon/dateribbon.component';
import { MomentModule } from 'ngx-moment';
import { ModalNewEventComponent } from './modal-new-event/modal-new-event.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalNewOrderComponent } from './modal-new-order/modal-new-order.component';
import { CtaComponent } from './cta/cta.component';
import { ModalOrderassignComponent } from './modal-orderassign/modal-orderassign.component';
import { ModalTripdetailsComponent } from './modal-tripdetails/modal-tripdetails.component';
import { TripmapComponent } from './tripmap/tripmap.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../pipes/pipes.module';
import { CompanyNameComponent } from './company-name/company-name.component';
import { SettingsComponent } from './settings/settings.component';
import { ModalPromotionStartComponent } from './modal-promotion-start/modal-promotion-start.component';
import { ModalNewPromotionComponent } from './modal-new-promotion/modal-new-promotion.component';
import { ModalSelectTruckComponent } from './modal-select-truck/modal-select-truck.component';
import { TruckslistComponent } from './truckslist/truckslist.component';
import { ModalTruckslistComponent } from './modal-truckslist/modal-truckslist.component';
import { MatSortModule } from '@angular/material/sort';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {MatSliderModule} from '@angular/material/slider';
import { ClientChartComponent } from './client-chart/client-chart.component';
import { ModalPromotionMapComponent } from './modal-promotion-map/modal-promotion-map.component';
import { NgxEchartsModule } from 'ngx-echarts';
import 'echarts/i18n/langDE';
import { DailyChartComponent } from './daily-chart/daily-chart.component';
import { ModalClientOrderComponent } from './modal-client-order/modal-client-order.component';
import { ModalCalendarComponent } from './modal-calendar/modal-calendar.component';
import { ValueEditorComponent } from './value-editor/value-editor.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { ModalCompetitorComponent } from './modal-competitor/modal-competitor.component';
import { ModalNewUserComponent } from './modal-new-user/modal-new-user.component';
import { ModalChangePasswordComponent } from './modal-change-password/modal-change-password.component';
import { ModalSelectDriverComponent } from './modal-select-driver/modal-select-driver.component';
import { ModalProfileComponent } from './modal-profile/modal-profile.component';
import { CookiesComponent } from './cookies/cookies.component';
import { ModalClosebyComponent } from './modal-closeby/modal-closeby.component';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    NbIconModule,
    NbButtonModule,
    NbUserModule,
    NbCardModule,
    NbSidebarModule,
    NbContextMenuModule,
    NbDatepickerModule,
    NbInputModule,
    NbTooltipModule,
    NbMomentDateModule,
    NbListModule,
    NbBadgeModule,
    NbTabsetModule,
    NbProgressBarModule,
    NbSelectModule,
    NbCheckboxModule,
    NbToggleModule,
    NbSpinnerModule,
    NbAccordionModule,
    MatTableModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    MatSliderModule,
    PipesModule,
    MomentModule,
    TranslateModule.forChild(),
    CKEditorModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiM25pY2NvIiwiYSI6ImNrZmNueG1uOTFobmwyenFzbW1udm5teXEifQ.nLg1gV8wovdr3KZzGZIvBw' // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    NgxEchartsModule.forRoot({
      echarts: () => {
        return import('echarts')
      }
    })
  ],
  declarations: [
    CustomerFilterComponent,
    SidebarComponent,
    SectionheaderComponent,
    HeaderComponent,
    DateribbonComponent,
    ModalNewEventComponent,
    ModalNewOrderComponent,
    CtaComponent,
    ModalOrderassignComponent,
    ModalTripdetailsComponent,
    TripmapComponent,
    CompanyNameComponent,
    SettingsComponent,
    ModalPromotionStartComponent,
    ModalNewPromotionComponent,
    ModalSelectTruckComponent,
    TruckslistComponent,
    ModalTruckslistComponent,
    ClientChartComponent,
    ModalPromotionMapComponent,
    DailyChartComponent,
    ModalClientOrderComponent,
    ModalCalendarComponent,
    ValueEditorComponent,
    ModalConfirmComponent,
    ModalCompetitorComponent,
    ModalNewUserComponent,
    ModalNewUserComponent,
    ModalChangePasswordComponent,
    ModalProfileComponent,
    ModalSelectDriverComponent,
    ModalProfileComponent,
    CookiesComponent,
    ModalClosebyComponent,
  ],
  exports: [
    CustomerFilterComponent,
    SidebarComponent,
    SectionheaderComponent,
    HeaderComponent,
    DateribbonComponent,
    ModalNewEventComponent,
    ModalNewOrderComponent,
    CtaComponent,
    CompanyNameComponent,
    TruckslistComponent,
    ModalTruckslistComponent,
    ClientChartComponent,
    ModalPromotionMapComponent,
    DailyChartComponent,
    ValueEditorComponent,
    ModalNewUserComponent,
    CookiesComponent
  ]
})
export class ComponentModule {
}
