import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { NbAccessChecker } from '@nebular/security';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { SortPaginator } from 'src/app/models/generic.model';
import { Order, OrderList } from 'src/app/models/order.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { LogisticService } from 'src/app/services/logistic.service';
import { ModalService } from 'src/app/services/modal.service';
import { StatusService } from 'src/app/services/status.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'oil-unassignedorders',
  templateUrl: './unassignedorders.component.html',
  styleUrls: ['./unassignedorders.component.scss']
})
export class UnassignedordersComponent implements OnInit {

  public selected = CalendarService.nextWorkingDay();
  public dateFilter = moment(this.selected);

  public dataSource: MatTableDataSource<OrderList>;
  public resultsLength = 0;


  public displayedColumns: string[] = [
    'number',
    'name',
    'category',
    'product',
    'quantity',
    'price',
    'time',
    'action',
  ];



  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private logisticService: LogisticService,
    private modalService: ModalService,
    private activatedRouter: ActivatedRoute,
    private locationService: Location,
    private statusService: StatusService
  ) { }

  ngOnInit(): void {
    // this.refresh();

    this.activatedRouter.paramMap.subscribe(navParams => {
      let date = navParams.get('date');
      let newdate;
      if (date) {
        switch (date) {
          case 'today':
            newdate = moment();
            break;
          case 'tomorrow':
            newdate = moment().add(1, 'day');
            break;
          default:
            newdate = moment(date);
        }
        if (newdate) {
          this.statusService.setSelectedDate(newdate);
        }
      }
      console.log('---- ~ file: unassignedorders.component.ts ~ line 58 ~ UnassignedordersComponent ~ ngOnInit ~ date', date);
      // this.locationService.go('unassigned');
    });


    this.sort.sortChange.subscribe(() => {

      const data = this.dataSource.data;

      this.dataSource.sort = this.sort;
      if (!this.sort.active || this.sort.direction === '') {
        return;
      }

      data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        switch (this.sort.active) {
          case 'number': return UtilsService.compare(a.orderNumber, b.orderNumber, isAsc);
          case 'name': return UtilsService.compare(a.name, b.name, isAsc);
          case 'category': return UtilsService.compare(a.category, b.category, isAsc);
          case 'product': return UtilsService.compare(a.product, b.product, isAsc);
          case 'quantity': return UtilsService.compare(a.quantity, b.quantity, isAsc);
          case 'price': return UtilsService.compare(a.price, b.price, isAsc);
          case 'time': return UtilsService.compare(a.deliveryTime, b.deliveryTime, isAsc);
          case 'action': return 0;
          default: return 0;
        }
      });

      this.dataSource = new MatTableDataSource<OrderList>(data);

    });

    // this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    // merge(this.sort.sortChange, this.paginator.page)
    //   .pipe(
    //     startWith({}),
    //     switchMap(() => {
    //       this.refresh();
    //       return of([]);
    //     })
    //   ).subscribe(data => {
    //     console.log('-------: DashboardComponent -> ngOnInit -> data', data);
    //   });
  }

  refresh() {
    // const paginator: SortPaginator = {
    //   orderBy: this.sort.active,
    //   start: this.paginator.pageIndex,
    //   limit: this.paginator.pageSize
    // }
    // if(this.sort.direction){paginator.orderDirection = this.sort.direction}

    this.logisticService.getOrderUnassignedList(this.dateFilter.toDate()).subscribe(x => {
      // console.log('-------: UnassignedordersComponent -> refresh -> x', x);
      this.dataSource = new MatTableDataSource<OrderList>(x.items);
      this.resultsLength = x.total;
    });
  }

  delayOrder(order: Order) {
    this.logisticService.delayOrder(order).subscribe(() => {
      this.refresh();
    })
  }


  selectedDateChange(date) {
    // console.log('-------: OrderlistComponent -> selectedDateChange -> date', date);
    this.dateFilter = date;
    this.refresh();
  }


  assign(order: Order) {
    console.log('-------: UnassignedordersComponent -> assign -> order', order);


    this.modalService.assignOrder(order).pipe(take(1)).subscribe(x => {
      // console.log('-------: OrderpromotionComponent -> openTrip -> x', x);
      this.refresh();
    })

  }

}
