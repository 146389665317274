
<nb-card>
    <nb-card-header >{{title}}</nb-card-header>


    <nb-card-body class="modal" >


        <div>

            {{message}}

        </div>

    </nb-card-body>

    <nb-card-footer>
        <button nbButton *ngIf="!onlyInfo" class="left" status="danger" (click)="ok()">{{'MODAL.CONFIRM.YES'|translate}}</button>
        <button nbButton *ngIf="!onlyInfo" class="right" (click)="cancel()">{{'MODAL.CONFIRM.NO'|translate}}</button>
        <button nbButton *ngIf="onlyInfo" class="right" (click)="cancel()">{{'MODAL.CONFIRM.OK'|translate}}</button>
    </nb-card-footer>

</nb-card>