<ng-template #childTemplate>
    <div class="company-name-name {{altClass}}">
        {{element.name || element.clientName}}
        <div class="no-privacy no-cluster" *ngIf="element.clustering === 0 || element.client_clustering === 0"
            nbTooltip="{{'GENERIC.COMPANY.NOCLUSTERPOLICY'|translate}}">
            <img src="/assets/images/no-cluster.png" alt="C" />
        </div>
        <div class="no-privacy no-push-notification"
            *ngIf="element.push_notification === 0 || element.client_push_notification === 0"
            nbTooltip="{{'GENERIC.COMPANY.NOPUSHPOLICY'|translate}}">
            <img src="/assets/images/no-push.png" alt="P" />
        </div>
    </div>
    <div *ngIf="!hideDelivery" class="company-name-delivery" [ngClass]="{'bad_location':element.bad_location}">
        {{element.deliveryName}} {{element.deliveryAddress}}
        {{element.deliveryCity}}
        <nb-icon icon="alert-triangle-outline" *ngIf="element.bad_location" nbTooltip="{{'GENERIC.BADLOCATION'|translate}}">
        </nb-icon>
    </div>
</ng-template>

<a class="decoration-none" [routerLink]="'/customers/details/'+id"
    *ngIf="(accessChecker.isGranted('view', 'CUSTOMERS') | async)">
    <ng-container [ngTemplateOutlet]="childTemplate"></ng-container>
</a>

<ng-container *ngIf="!(accessChecker.isGranted('view', 'CUSTOMERS') | async)">
    <ng-container [ngTemplateOutlet]="childTemplate"></ng-container>
</ng-container>