import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'liter'
})
export class LiterPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value) { return `L ${value}` }
    return value;
  }

}
