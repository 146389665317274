<nb-card>
    <nb-card-header>{{'LOGISTIC.DRIVERMODAL.TITLE'|translate}} {{trip.routeId}}</nb-card-header>

    <nb-card-body class=" modal">
        <nb-list>
            <nb-list-item *ngFor="let driver of drivers" (click)="select(driver)"
                [ngClass]="{'selected':selectedDriverId == driver.driverId}">
                <div class="driver">
                    <div class="driver-name">{{ driver.driverName }} {{ driver.driverLastName }} </div>
                    <div class="driver-info" *ngIf="driver.trips?.length">                        {{'LOGISTIC.DRIVERMODAL.TRIPS'|translate}}:{{driver.trips?.length}}</div>
                </div>
            </nb-list-item>
        </nb-list>
    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="danger" [disabled]="!isValid()"
            (click)="ok()">{{'LOGISTIC.DRIVERMODAL.OK'|translate}}</button>
        <button nbButton class="right" (click)="cancel()">{{'LOGISTIC.DRIVERMODAL.CANCEL'|translate}}</button>
    </nb-card-footer>
</nb-card>