
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()
export class MatPaginatorIntlTranslate extends MatPaginatorIntl {

    private OfString ='of';

    constructor(translate: TranslateService) {
        super();
        translate.get(['PAGINATOR.NEXT','PAGINATOR.PREV','PAGINATOR.OF','PAGINATOR.ITEMSPAGE']).subscribe(translations=>{
            
            super.itemsPerPageLabel = translations['PAGINATOR.ITEMSPAGE'];
            super.nextPageLabel = translations['PAGINATOR.NEXT'];
            super.previousPageLabel = translations['PAGINATOR.PREV'];
            this.OfString = translations['PAGINATOR.OF'];           

        })
    }



    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OfString} ` + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return  `${startIndex + 1} - ${endIndex} ${this.OfString} ${length}`;
    };

}