import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { OrderListApiResponse, Order, TripApiResponse, Trip, PromotionApiResponse, Promotion, DailyOrdersApiResponse, BasicOrder, OrderClientListApiResponse, NearbyApiResponse, DeliveryTimesApiResponse, DeliveryTime } from '../models/order.model';
import { SortPaginator, Product } from '../models/generic.model';
import { multifilter } from '../models/customerfilter.model';
import * as moment from 'moment';
import { config } from 'src/environments/config';
import { filter, map } from 'rxjs/operators';
import { RippleState } from '@angular/material/core';
import { LogisticService } from './logistic.service';
import { WorkingRespone } from '../models/logistic_model';
import { CustomerDetail } from '../models/customer.model';


@Injectable({
    providedIn: 'root'
})
export class OrderService {


    constructor(
        private api: ApiService,
        private logisticService: LogisticService
    ) {


    }

    public getListTable(filters: multifilter[], date, product, paginator: SortPaginator): Observable<OrderListApiResponse> {
        const parameters = {};
        if (product) { parameters['product'] = product; }
        if (filters) {
            filters.forEach(f => {
                parameters[f.name.toLowerCase() + '[]'] = f.value;
            });
        }
        parameters['date'] = moment(date).format(config.apiDateFormat);
        Object.assign(parameters, paginator);
        return this.api.call('cms/orders', 'GET', null, parameters);
    }

    public getClientTable(clientId: number, paginator: SortPaginator): Observable<OrderListApiResponse> {
        return this.api.call(`admin/orders/client/${clientId}/list`, 'GET', null, paginator);
    }

    public getDailyDetails(filters: multifilter[], date, product, paginator: SortPaginator): Observable<DailyOrdersApiResponse> {
        const parameters = {};
        if (product) { parameters['product'] = product; }
        filters.forEach(f => {
            parameters[f.name.toLowerCase() + '[]'] = f.value;
        });
        parameters['date'] = moment(date).format(config.apiDateFormat);
        Object.assign(parameters, paginator);
        return this.api.call(`admin/orders/daily-details`, 'GET', null, parameters)
    }

    public getProductList(): Observable<Product[]> {
        return this.api.call('cms/products', 'GET', null);
    }

    public save(order: Order): Observable<Order> {
        order.deliveryDate = moment(order.deliveryDate).format(config.apiDateFormat);
        delete order.price;
        delete order.maxQuantity;
        return this.api.call('cms/orders/create', 'POST', order);
    }


    public addClientOrder(order: BasicOrder): Observable<Order> {
        order.deliveryDate = moment(order.deliveryDate).format(config.apiDateFormat);
        return this.api.call('client/orders/create', 'POST', order, null, false);
    }


    public getClientNewOrdersList(): Observable<OrderClientListApiResponse> {
        return this.api.call(`client/orders`, 'GET', null, null, false)
    }

    public getClientPastOrdersList(paginator: SortPaginator): Observable<OrderClientListApiResponse> {
        const parameters = {};
        Object.assign(parameters, paginator);
        return this.api.call(`client/orders/past`, 'GET', null, parameters, false)
    }


    // public getDetails(code: string): Observable<OrderDetail> {
    //     return this.api.call(`admin/orders/${code}`, 'GET', null).pipe(map((res: OrderDetail[]) => res.length ? res[0] : null));
    // }



    // editOrder(OrderId: number, modifyCustomerPoint = true) {
    //     return this.orderService.getOrder(OrderId).pipe(flatMap(order => {
    //         let context: any = {
    //             thisOrder: order,
    //             modifyCustomer: modifyCustomerPoint,
    //             modifyPoint: modifyCustomerPoint
    //         }
    //         let modal = this.dialogService.open(ModalNewOrderComponent, { context: context });
    //         return modal.onClose;
    //     }));
    // }


    public getOrderRequestList(paginator: SortPaginator, timeInterval: number): Observable<OrderListApiResponse> {


        // const parameters = { OrderStatus: 'insufficient_credit', date: moment().format(config.apiDateFormat) };
        // Object.assign(parameters, paginator);
        // return this.api.call('cms/orders', 'GET', null, parameters);

        const parameters = { interval: timeInterval };
        Object.assign(parameters, paginator);
        return this.api.call('cms/orders/toSend', 'GET', null, parameters);
    }

    public getOrderApproveList(paginator: SortPaginator, timeInterval: number) {
        // const parameters = { OrderStatus: 'waiting', date: moment().format(config.apiDateFormat) };
        // Object.assign(parameters, paginator);
        // return this.api.call('cms/orders', 'GET', null, parameters);

        const parameters = { interval: timeInterval };
        Object.assign(parameters, paginator);
        return this.api.call('cms/orders/toApprove', 'GET', null, parameters);
    }


    public isWorkingPromotions(): Observable<WorkingRespone> {
        return this.api.call(`admin/orders/promotions/sync`, 'GET')
    }

    public getPromotion(paginator: SortPaginator): Observable<PromotionApiResponse> {
        const parameters = {
            // data: moment(date).format(config.apiDateFormat) 
        };
        Object.assign(parameters, paginator);
        return this.api.call('cms/orders/promotions', 'GET', null, parameters);
    }

    public getTrips(paginator: SortPaginator, date: Date, baseName: string): Observable<TripApiResponse> {
        const parameters = { data: moment(date).format(config.apiDateFormat) };
        Object.assign(parameters, paginator);
        return this.api.call('cms/orders/routes', 'GET', null, parameters).pipe(map((resp: TripApiResponse) => {
            if (resp.items) {
                resp.items.forEach((trip, index) => {
                    trip.name = baseName + ' ' + trip.routeId;// (index + 1);
                    // this.logisticService.addDestinations(trip);

                    LogisticService.setFillingQuantity(trip);
                })
            }

            return resp;
        }));
    }

    public getTrip(tripId: number, isConfirmed: boolean): Observable<Trip> {
        const params = { confirmed: isConfirmed ? 1 : 0 };
        return this.api.call(`admin/orders/${tripId}/trip`, 'GET', null, params).pipe(map((trip: Trip) => {
            this.logisticService.addDestinations(trip);
            return trip;;
        }))
    }

    public getCloseBy(orderId: number, rangeKm: number): Observable<NearbyApiResponse> {
        const params = { maxDistance: rangeKm };
        return this.api.call(`admin/orders/${orderId}/nearbyClients`, 'GET', null, params);
    }

    public requestConfirm(orderId) {
        return this.api.call(`admin/orders/${orderId}/send`, 'POST', null, null);
    }

    public delete(orderId) {
        return this.api.call(`admin/orders/${orderId}/delete`, 'POST', null, null);
    }

    public approve(orderId) {
        return this.api.call(`admin/orders/${orderId}/approve`, 'POST', null, null);
    }

    public refuse(orderId) {
        return this.api.call(`admin/orders/${orderId}/reject`, 'POST', null, null);
    }

    public addOrEditPromotion(prom: Promotion) {
        console.log('---- ~ file: order.service.ts ~ line 145 ~ OrderService ~ addOrEditPromotion ~ prom', prom);
        delete prom.remainingCredit;
        if (prom.promotionId) {
            return this.api.call(`admin/orders/promotions/${prom.promotionId}/edit`, 'POST', prom);
        } else {
            delete prom.promotionId;
            return this.api.call('cms/orders/promotions/add', 'POST', prom);

        }
    }

    public confirmPromotion(prom: Promotion) {
        return this.api.call(`admin/orders/promotions/${prom.promotionId}/confirm`, 'POST', prom);
    }

    public deletePromotion(prom: Promotion) {
        return this.api.call(`admin/orders/promotions/${prom.promotionId}`, 'DELETE', prom);
    }

    public cloneOrderForToday() {
        return this.api.call(`admin/orders/cloneDailyOrders`, 'POST', null); // TODO verify API url
    }

    private _deliveryTimes: DeliveryTime[] = null;
    public getDeliveryTimes(): Observable<DeliveryTime[]> {
        if (this._deliveryTimes)
            return of(this._deliveryTimes);
        return this.api.call(`admin/logistic/timeSlots`, 'GET').pipe(map(x => { this._deliveryTimes = x.items; return x.items; }));
    }

}
