import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'oil-value-editor',
  templateUrl: './value-editor.component.html',
  styleUrls: ['./value-editor.component.scss']
})
export class ValueEditorComponent implements OnInit {

  @Input() value;
  @Input() step = 0.1;
  public modify = false;
  public newValue;

  @Output() updateValue = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  startModify() {
    this.modify = true;
    this.newValue = this.value;
  }

  endModify() {
    this.modify = false;
  }

  sendValue() {
    this.modify = false;
    this.updateValue.emit(this.newValue);
  }

}
