<nb-card>
    <nb-card-header *ngIf="!provenienceTripId">{{'LOGISTIC.ASSIGNORDER.TITLE'|translate}}</nb-card-header>
    <nb-card-header *ngIf="provenienceTripId">{{'LOGISTIC.ASSIGNORDER.MOVETITLE'|translate}}</nb-card-header>

    <nb-card-body class="modal" [hidden]="resultsLength">
        {{'LOGISTIC.ASSIGNORDER.NODATA'|translate}}
    </nb-card-body>

    <nb-card-body class="modal" [hidden]="!resultsLength">


        <div>

            {{'LOGISTIC.ASSIGNORDER.SUBTITLE'|translate}}


            <table mat-table [dataSource]="dataSource" matSort #table>


                <!-- Viaggio -->
                <ng-container matColumnDef="name">
                    <th class="" mat-header-cell *matHeaderCellDef> </th>
                    <td mat-cell *matCellDef="let element" class="cell-trip"> 
                        
                    <img *ngIf="element.routeId == order?.suggestedRoute" class="promo-ico" src="/assets/images/gift.png">
                        {{element.name}} 
                    </td>
                </ng-container>

                <!-- Prodotto -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'LOGISTIC.ASSIGNORDER.TABLE.PRODUCT' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let tank of element.tanks">{{productList(tank.products)}}</div>
                    </td>
                </ng-container>

                <!-- Riempimento autobotte -->
                <ng-container matColumnDef="filling">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'LOGISTIC.ASSIGNORDER.TABLE.FILLING' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element" class="filling">
                        <div *ngFor="let tank of element.tanks">
                            <div class="filling-percentual">{{tank.fillingQuantity/tank.maxCapacity*100 | number : '1.0-0'}}% </div>
                            <div class="filling-bar">
                                <nb-progress-bar [value]="tank.fillingQuantity/tank.maxCapacity*100" size="tiny" status="warning"></nb-progress-bar>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <!-- Capacità residua -->
                <ng-container matColumnDef="remain">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'LOGISTIC.ASSIGNORDER.TABLE.REMAIN' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngFor="let tank of element.tanks">
                            {{tank.maxCapacity - tank.fillingQuantity  | number | liter}}
                        </div>
                    </td>
                </ng-container>


                <!-- Actions  -->
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>
                    </th>
                    <td mat-cell *matCellDef="let element" class="action-cell">
                        <div class="actions">
                            <button *ngIf="canAssign(element)" nbButton (click)="add(element)">{{'LOGISTIC.ASSIGNORDER.ADD'|translate}}</button>    
                            <div *ngIf="!canAssign(element)">{{'LOGISTIC.ASSIGNORDER.CANTADD'|translate}}</div>                         
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight':row.routeId == order?.suggestedRoute}"> </tr>
            </table>
            <!-- 
                <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength"></mat-paginator> -->

        </div>

    </nb-card-body>

    <nb-card-footer>
        <button nbButton status="danger" (click)="createTrip()">{{'LOGISTIC.ASSIGNORDER.CREATETRIP'|translate}}</button>
        <button nbButton (click)="cancel()">{{'LOGISTIC.ASSIGNORDER.CANCEL'|translate}}</button>
    </nb-card-footer>

</nb-card>