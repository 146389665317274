import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { PriceService } from 'src/app/services/price.service';
import { SortPaginator, GenericFilter, dailyInputs } from 'src/app/models/generic.model';
import { DateTotal, plattsVal, PlattsValue, Price, RawPrices } from 'src/app/models/price.model';
import { TranslateService } from '@ngx-translate/core';
import { merge, Observable, of, timer } from 'rxjs';
import { map, startWith, switchMap, take, timeout } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Customer, CustomerBase, CustomerPoint } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerFilterComponent } from 'src/app/components/customer-filter/customer-filter.component';
import { multifilter } from 'src/app/models/customerfilter.model';
import { config } from 'src/environments/config';
import { SmallNumber } from 'src/app/pipes/smallNumber.pipe';
import { CATEGORIES_COLORS, MODAL } from 'src/app/models/constants.model';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'oil-listino',
  templateUrl: './listino.component.html',
  styleUrls: ['./listino.component.scss']
})
export class ListinoComponent implements OnInit {


  public filters: GenericFilter[] = [
    CustomerFilterComponent.CATEGORIES(),
    CustomerFilterComponent.CLUSTER(),
    CustomerFilterComponent.STATUS(),
    CustomerFilterComponent.PUSHONLY()
  ];

  public activeFilters: multifilter[] = [];

  public selected = moment();
  public dateFilter = moment(this.selected);

  public favoriteonly: boolean = false;
  public requestOnly: boolean = false;

  public isWaiting: boolean = false;
  public priceModel: RawPrices = { plattsRisc: 0, plattsAuto: 0, plattsAgricolo: 0, plattsBenzina: 0, manual_margins: [] };

  public hasLoaded = false;

  public dataSource;
  public searchstring = null;
  public resultsLength = -1;
  public hasPrice = false;
  public initializing = true;

  public chartInput: dailyInputs;
  public sold: number;
  public platts: PlattsValue[];
  public isLoading: boolean = false;

  public client = null;

  public avgMarginChartOptions = {};

  public isLoadingSuggestion: boolean = false;
  public filteredOptions$: Observable<CustomerBase[]>;

  public translations;

  public customerId: string = null;
  public customer: Customer = null;
  public distributionPointId: string = null;
  public distributionPoint: CustomerPoint = null;

  private storage = window.localStorage;

  public displayedColumns: string[] = [
    'favorite',
    'request',
    'name',
    'product',
    // 'fido',
    'price',
    'price_computed',
    //'range', 
    'modify',
    'calculated_margin',
    'priceseleted',
    'pricepromotion',
    'sendprice',
    'competitor'
  ];



  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;




  constructor(
    private priceService: PriceService,
    private translate: TranslateService,
    private activatedRouter: ActivatedRoute,
    private customerService: CustomerService,
    private router: Router,
    private modalService: ModalService
  ) {
    setTimeout(() => {
      this.translate.get(
        [
          'CATEGORIES.GAS.RISC',
          'CATEGORIES.GAS.AUTO',
          'CATEGORIES.GAS.AGR',
          'CATEGORIES.ALTRO',
        ]).subscribe(translations => {
          this.translations = translations;
        })
    }, 1);
  }

  ngOnInit(): void {

    this.activatedRouter.paramMap.subscribe(navParams => {
      this.customerId = navParams.get('id');
      this.distributionPointId = navParams.get('pointid');
      if (this.customerId) {
        this.customerService.getDetails(this.customerId, true).subscribe(customer => {
          this.customer = customer;
          if (this.distributionPointId) {
            this.distributionPoint = customer.delivery_addresses.find(x => ('' + x.id) === this.distributionPointId);

          }
        })

      }
      // this.refresh();
    });


    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (!this.initializing) {
            this.refresh();
          }
          return of([]);
        })
      ).subscribe(data => {
      });

    this.priceService.getLastPriceModel(moment().add(-1, 'day')).subscribe(res => {
      this.priceModel = res;
    })

    setTimeout(() => {
      //   this.refresh();
      this.initializing = false;
    }, 1);

  }

  isValid() {
    let allRanges = true;
    this.priceModel.manual_margins.forEach(margin => {
      if (margin.ranges) {
        margin.ranges.forEach(val => { if (val.value <= 0) { allRanges = false; } })
      }
    })
    return this.priceModel.plattsRisc && this.priceModel.plattsAuto && this.priceModel.plattsAgricolo && this.priceModel.plattsBenzina && allRanges;

  }

  calculatePrices() {
    this.priceService.createPrices(this.priceModel).subscribe(() => {
      this.refresh();
    })
  }

  refresh() {
    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

    this.priceService.isWorkingPrices().subscribe(res => {
      this.isWaiting = res.isWorking;
    })

    this.priceService.getPriceList(paginator, this.dateFilter.format(config.apiDateFormat), this.customerId, this.distributionPointId, this.activeFilters, this.favoriteonly, this.requestOnly).subscribe(x => {

      this.dataSource = new MatTableDataSource<Price>(x.items);
      this.resultsLength = x.total;
      this.hasPrice = x.hasPrices;
      this.hasLoaded = true;
    });

    this.isLoading = true;
    this.priceService.getDailyDetails(this.dateFilter, this.activeFilters).subscribe(x => {
      this.sold = x.quantity;
      this.platts = x.platt;
      // console.log('---- ~ file: listino.component.ts ~ line 171 ~ ListinoComponent ~ this.priceService.getDailyDetails ~ this.platts', this.platts);
      let marginTotal = 0;

      let series = [];
      if (x.marginCategories) {
        x.marginCategories.forEach(trip => {
          series.push({
            name: this.translations[`CATEGORIES.${trip.category}`],
            value: trip.total,
            color: CATEGORIES_COLORS[trip.category]
          })
          marginTotal += trip.total;
        })
      }
      this.chartInput = {
        series: series,
        centerValue: new SmallNumber().transform(marginTotal) as string,
        // centerLabel: this.translations['LOGISTIC.LIST.CHARTLABEL']
      }
      this.updateAvgMarginChart(x.avgMargin);
      this.isLoading = false;
    });

  }


  formatNumber = (n: number) => {
    return n ? n.toLocaleString() : '';
  }

  updateAvgMarginChart(values: DateTotal[]) {

    let options: any = {
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          let res = ''
            + '<span>' + (params.name ? params.name : params.seriesName) + '</span>' + ': '
            + '<strong>' + this.formatNumber(Math.round(params.value * 100) / 100) + '</strong>';
          return res;
        }
      },
      legend: {
        this: false,
      },
      grid: {
        left: 0,
        top: 20,
        right: 0,
        bottom: 20
      },
      xAxis: {
        type: '',
        data: values.map(val => moment(val.date).format('DD/MM')),
        interval: null,
        axisLine: null
        // axisLabel:{show:false}
      },
      yAxis: {
        type: 'value',
        show: false,
        // axisLabel:{show:false}
      },
      series: [{
        name: '',
        type: 'bar',
        color: '#163970',
        data: values.map(val => val.total * 1000),
        label: {
          show: true,
          position: 'top',
          formatter: (x) => { return this.formatNumber(Math.round(x.value * 100) / 100); },
          fontWeight: 'bold'
        },
      }]
    }


    this.avgMarginChartOptions = Object.assign({}, options);
  }

  selectedDateChange(date) {
    // console.log('-------: OrderlistComponent -> selectedDateChange -> date', date);
    this.dateFilter = date;
    this.refresh();
  }

  modify(element) {
    // console.log('-------: ListinoComponent -> modify -> element', element);

  }

  async send(priceId: number = null, confirm = false) {
    const sendPrice = () => {
      this.priceService.sendToClient(priceId).subscribe(x => {
        this.refresh();
      });
    }
    if (confirm) {
      this.translate.get(['PRICES.LIST.MODALCONFIRMPRIVACY.TITLE', 'PRICES.LIST.MODALCONFIRMPRIVACY.MESSAGE']).subscribe(t => {
        this.modalService.confirm(t['PRICES.LIST.MODALCONFIRMPRIVACY.MESSAGE'], t['PRICES.LIST.MODALCONFIRMPRIVACY.TITLE']).pipe(take(1)).subscribe(res => {
          if (res == MODAL.confirmOk) {
            sendPrice();
          }
        })
      })
    } else {
      sendPrice();
    }

  }

  filterChange($event) {
    this.activeFilters = $event;
    //if (!this.initializing) { 
    this.refresh();
    //}
  }

  showBtnModify(element: Price) {
    return !element.price_modified && !element.price_edit;// && !element.price_sent_to_client;
  }

  cancelModify(element: Price) {
    element.price_edit = null;
    element.modifyPrice = false;
  }

  sendPrice(element: Price) {
    this.priceService.modify(element.id, element.price_edit).subscribe(x => {
      // element.modifyPrice = false;
      this.refresh();
    });

  }

  showPriceModify(element: Price) {
    return element.status != 'request_not_sent' || element.price_modified;
    // 'request_not_sent','request_sent','request_approved','request_not_approved'
  }

  priceClass(element: Price) {
    return element.status.replace('request_', '');
  }

  refusePrice(element: Price) {

    this.priceService.requestReset(element.id).subscribe(x => {
      element.price_modified = null;
      element.modifyPrice = false;
      element.status = 'request_not_sent';
    });

  }

  isToday() {
    return this.dateFilter.isSame(moment(), 'day');
  }

  plattsCode(id) {
    return PriceService.plattsCode(id);
  }

  isFinalPrice(price: Price, value: number) {
    if (price.quantity_promotional) {
      return value == price.price_promotional;
    } else if (price.price_approved) {
      return value == price.price_approved
    } else {
      return value == price.price_proposed;
    }
  }

  actualMargin(price: Price) {
    return price.calculated_margin;
    let ret = Number(price.margin);
    if (Number(price.quantity_promotional)) {
      ret = Number(price.margin) + Number(price.price_promotional) - Number(price.price_proposed);
    } else if (Number(price.price_approved)) {
      ret = Number(price.margin) + Number(price.price_approved) - Number(price.price_proposed);
    }
    return ret;
  }


  getClientFilteredOptions(filter: string): Observable<CustomerBase[]> {
    this.isLoadingSuggestion = true;
    return this.customerService.getFiltered(filter).pipe(map(x => { this.isLoadingSuggestion = false; return x.items; }));
  }

  onChangeClientInput(value: string | CustomerBase) {
    if (typeof value == 'string') {
      // this.filteredOptions$ = of(null);
      this.filteredOptions$ = this.getClientFilteredOptions(value);
      // return this.filteredOptions$;
    } else {
      this.updateCustomer(value);
    }
  }

  updateCustomer($event: CustomerBase) {
    this.router.navigateByUrl(`/prices/list/${$event.id}`);
  }

  viewHandle(value: CustomerBase) {
    if (value && value.name)
      return value.name;
    return value;
  }

  onChangeFavoriteOnly($event) {
    this.favoriteonly = $event;
    this.refresh();
  }

  onChangeRequestOnly($event) {
    this.requestOnly = $event;
    this.refresh();
  }

  reset() {
    this.translate.get(['PRICES.LIST.MODALRESET.TITLE', 'PRICES.LIST.MODALRESET.MESSAGE']).subscribe(t => {

      this.modalService.confirm(t['PRICES.LIST.MODALRESET.MESSAGE'], t['PRICES.LIST.MODALRESET.TITLE']).pipe(take(1)).subscribe(res => {
        // console.log('---- ~ file: listino.component.ts ~ line 385 ~ ListinoComponent ~ this.modalService.confirm ~ res', res);
        if (res == MODAL.confirmOk) {
          this.priceService.resetPrices().subscribe(res => {
            this.refresh();
          })
        }
      })
    })
  }

  competitor(price: Price) {
    this.modalService.competitor(price).pipe(take(1)).subscribe(x => {

    })
  }


  setFavorite(price: Price) {
    this.customerService.setCustomerPointFavorite(price.client_delivery_address_id, !price.favorite).subscribe(() => {
      price.favorite = !price.favorite;
    })
  }


}
