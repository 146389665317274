import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Depot, Truck } from 'src/app/models/logistic_model';
import { LogisticService } from 'src/app/services/logistic.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'oil-truckslist',
  templateUrl: './truckslist.component.html',
  styleUrls: ['./truckslist.component.scss']
})
export class TruckslistComponent implements OnInit {

  public statuses = [
    { label: 'LOGISTIC.TRUCKSLIST.STATUS.AVAILABLE', id: 'available' },
    { label: 'LOGISTIC.TRUCKSLIST.STATUS.NOT_AVAILABLE', id: 'not_available' },
    // { label: 'Manutenzione', id: 'maintaince' },
  ]

  public dataSource: MatTableDataSource<Truck>;
  public resultsLength = 0;

  public depots: Depot[];

  public past = false;


  public displayedColumns: string[] = [
    'targa',
    'tow',
    'load',
    'status',
    'depot'
  ];


  //@Input() date: Moment;
  private _date: Moment;
  @Input() set date(value: Moment) {
    this._date = value;
    this.past = !moment().isSameOrBefore(value, 'day')
    this.refresh();
  }
  get date(): Moment {
    return this._date;
  }

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private logisticService: LogisticService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {

    this.translate.get(this.statuses.map(x => x.label)).subscribe(translations => {
      this.statuses.forEach(x => {
        x.label = translations[x.label];
      })
    })

    this.logisticService.getDepots().subscribe(res => {
      this.depots = res;
    })


    this.sort.sortChange.subscribe(() => {

      this.sortData()

    });

  }

  sortData() {

    if (this.dataSource && this.dataSource.data) {
      const data = this.dataSource.data;
      this.dataSource.sort = this.sort;
      if (!this.sort.active || this.sort.direction === '') {
        return;
      }

      data.sort((a, b) => {
        const isAsc = this.sort.direction === 'asc';
        switch (this.sort.active) {
          case 'targa': return UtilsService.compare(a.targa, b.targa, isAsc);
          case 'tow': return UtilsService.compare(a.targaMotrice, b.targaMotrice, isAsc);
          case 'load': return UtilsService.compare(a.load, b.load, isAsc);
          case 'status': return UtilsService.compare(a.status, b.status, isAsc);
          case 'depot': return UtilsService.compare(this.getDepot(a.depotId).name, this.getDepot(b.depotId).name, isAsc);
          default: return 0;
        }
      });

      this.dataSource = new MatTableDataSource<Truck>(data);
    }
  }


  public getDepot(depotId): Depot {
    if (this.depots) {
      return this.depots.find(x => x.id == depotId);
    }
    return { name: null };

  }

  public statusLabel(id) {
    return this.statuses.find(x => x.id === id)?.label;
  }

  public depotLabel(id) {
    return this.getDepot(id)?.name;
  }

  refresh() {
    if (this.date) {
      this.logisticService.getTrucksCompleteList(this.date).subscribe(x => {
        this.dataSource = new MatTableDataSource<Truck>(x.items);
        this.resultsLength = x.total;
        this.sortData();
      });
    }

  }

  updateStatus(truck: Truck, status) {
    console.log('---- ~ file: truckslist.component.ts ~ line 106 ~ TruckslistComponent ~ updateStatus ~ status', truck, status);
    this.logisticService.updateTruckStatus(this.date, truck.targa, status, truck.depotId ? truck.depotId : 1).subscribe(() => { this.refresh() });
  }

  updateDepot(truck: Truck, depot: number) {
    console.log('---- ~ file: truckslist.component.ts ~ line 111 ~ TruckslistComponent ~ updateDepot ~ depot', truck, depot);
    this.logisticService.updateTruckStatus(this.date, truck.targa, truck.status ? truck.status : this.statuses[0].id, depot).subscribe(() => { this.refresh() });
  }

}
