import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NbCardModule, NbLayoutModule, NbSelectModule, NbIconModule, NbCheckboxModule, NbPopoverModule, NbInputModule, NbButtonModule, NbTooltipModule } from '@nebular/theme';
import { NbMomentDateModule } from '@nebular/moment';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentModule } from 'src/app/components/component.module';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSortModule } from '@angular/material/sort';
import { NgSelectModule } from '@ng-select/ng-select';
import { MomentModule } from 'ngx-moment';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/moment';
import { ClientOrdersComponent } from './client-orders/client-orders.component';
import { ClientDeliveryComponent } from './client-delivery/client-delivery.component';
import { ClientPricesComponent } from './client-prices/client-prices.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NbCardModule,
    NbLayoutModule,
    NbInputModule,
    NbButtonModule,
    NbSelectModule,
    NbIconModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbInputModule,
    // NbDatepickerModule,
    NbTooltipModule,
    TranslateModule,
    ComponentModule,
    MatTableModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatSortModule,
    NgSelectModule,
    NbMomentDateModule,
    MomentModule,
    PipesModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
  ],
  declarations: [ ClientOrdersComponent, ClientDeliveryComponent, ClientPricesComponent
  ],
})
export class ClientModule {
}
