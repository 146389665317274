import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Promotion, Trip } from 'src/app/models/order.model';
import { LogisticService } from 'src/app/services/logistic.service';

@Component({
  selector: 'oil-modal-promotion-map',
  templateUrl: './modal-promotion-map.component.html',
  styleUrls: ['./modal-promotion-map.component.scss']
})
export class ModalPromotionMapComponent implements OnInit {

  public thisPromotion: Promotion;
  public trip: Trip;

  constructor(
    protected ref: NbDialogRef<ModalPromotionMapComponent>,
    private logisticService: LogisticService,
  ) { }

  ngOnInit(): void {

    this.refresh();


  }

  refresh() {
    if (this.thisPromotion.suggestedRoute)
      this.logisticService.getTrip(this.thisPromotion.suggestedRoute, false).subscribe(t => {
        this.trip = t;
      }, err => {
        this.ref.close();
      })
  }

  cancel() {
    this.ref.close();
  }

}
