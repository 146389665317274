import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { NbAccessChecker } from '@nebular/security';

@Injectable()
export class AuthGuard implements CanActivate {

  private token: string;

  constructor(
    private authService: NbAuthService,
    private router: Router,
    protected tokenService: NbTokenService,
    public accessChecker: NbAccessChecker
  ) {

    const updateToken = (token: NbAuthJWTToken) => {
      if (token.isValid()) {
        this.token = token.getValue();
      }
    }

    this.authService.getToken().subscribe(updateToken);

    this.authService.onTokenChange().subscribe(updateToken);
  }



  canActivate(x) {
    return this.authService.isAuthenticated()
      .pipe(
        tap(authenticated => {
          if (!authenticated) {
            this.router.navigate(['auth/login']);
          }
          if (x.data && x.data.permission && x.data.resource) {
            // console.log('-------: AuthGuard -> canActivate -> x.data', x.data);
            this.accessChecker.isGranted(x.data.permission, x.data.resource).subscribe(authorized => {
              if (!authorized)
                this.router.navigate(['/']);
            })

          }
        }),
      );
  }

  public getToken(): string {
    return this.token;
  }

  public isLoggedin(): boolean {
    return !!this.token;
  }

  public logout() {
    this.authService.logout(environment.strategyname);
    this.tokenService.clear();
    this.token = null;
    this.router.navigate(['auth/login']);
  }
}
