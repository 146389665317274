import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { take } from 'rxjs/operators';
import { CalendarService } from 'src/app/services/calendar.service';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'oil-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss']
})
export class CtaComponent implements OnInit {

  @Input() customerId: string;
  @Input() pointId: string;

  @Input() details: boolean = true;

  @Output() eventAdded = new EventEmitter();

  constructor(
    private calendarService: CalendarService,
    private modalService: ModalService
  ) { }

  ngOnInit(): void {
  }


  addEvent() {
    if (this.pointId) {
      this.calendarService.createEventForPoint(this.customerId, this.pointId).subscribe((event) => {
        this.eventAdded.emit(event);
      })
    } else {
      this.calendarService.createEventForCustomer(this.customerId);
    }
  }

  addOrder() {
    this.modalService.createOrder(this.customerId).pipe(take(1)).subscribe(()=>{})
  }

}
