import { Route } from '@angular/compiler/src/core';
import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerBase, CustomerPoint } from 'src/app/models/customer.model';
import { Promotion, Trip } from 'src/app/models/order.model';
import { Price } from 'src/app/models/price.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { CustomerService } from 'src/app/services/customer.service';
import { NotifyService } from 'src/app/services/notify.service';
import { OrderService } from 'src/app/services/order.service';
import { PriceService } from 'src/app/services/price.service';

@Component({
  selector: 'oil-modal-new-promotion',
  templateUrl: './modal-new-promotion.component.html',
  styleUrls: ['./modal-new-promotion.component.scss']
})
export class ModalNewPromotionComponent implements OnInit {

  public customers: CustomerBase[];
  public points: CustomerPoint[];

  public maxCapacity: number;

  public modifyCustomer = true;
  public modifyPoint = true;

  public oldPrice = null;
  public minPrice = null;
  public dateMoment = CalendarService.nextWorkingDay();

  public prices: Price[];

  public linkedRoute: Trip;
  public isLoadingSuggestion = false;

  clientInput: CustomerBase;
  filteredOptions$: Observable<CustomerBase[]>;

  public thisPromotion: Promotion = {
    promotionId: 0,
    promotionDate: new Date()
  }

  constructor(
    protected ref: NbDialogRef<ModalNewPromotionComponent>,
    private customerService: CustomerService,
    private orderService: OrderService,
    private priceService: PriceService,
    private notifyService: NotifyService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    // this.customerService.getAll().subscribe(res => {
    //   this.customers = res;
    //   if (this.thisPromotion.clientId) {
    //     this.updateCustomer({ id: this.thisPromotion.clientId });
    //   }
    // })
    if (this.linkedRoute) {
      this.thisPromotion.suggestedRoute = this.linkedRoute.routeId;
      this.dateMoment = moment(this.linkedRoute.deliveryDate);
    }

    if (this.thisPromotion.clientId) {
      this.customerService.getDetails('' + this.thisPromotion.clientId, true).subscribe(res => {
        if (res && res.name) {
          this.updateCustomer({ id: this.thisPromotion.clientId, name: res.name });
          this.clientInput = { id: this.thisPromotion.clientId, name: res.name };
        }
      })

    }
  }

  save() {
    this.thisPromotion.promotionDate = moment(this.dateMoment).toDate();

    this.orderService.addOrEditPromotion(this.thisPromotion).subscribe(X => {
      this.ref.close(this.thisPromotion);
    })

  }

  cancel() {
    this.ref.close();
  }

  viewHandle(value: CustomerBase) {
    if (value && value.name)
      return value.name;
    return value;
  }

  updateCustomer($event: CustomerBase) {
    // this.thisPromotion.deliveryId = this.modifyPoint ? null : this.thisPromotion.deliveryId;
    if ($event && $event.id) {
      this.thisPromotion.clientId = $event.id;
      this.customerService.getDetails('' + $event.id, true).subscribe(res => {
        const list = res.delivery_addresses.map((i) => { i.label = `${i.name ? i.name : ''}-${i.address_1 ? i.address_1 : ''}-${i.city ? i.city : ''}`; return i; });
        this.points = list;
        this.thisPromotion.remainingCredit = res.remainingCredit ? res.remainingCredit : 0;
        if (!this.thisPromotion.deliveryId && this.points.length === 1) {
          this.thisPromotion.deliveryId = this.points[0].id;
          this.updatePoint(this.points[0]);
        } else if (this.thisPromotion.deliveryId) {
          this.updatePoint(this.points.find(x => x.id == this.thisPromotion.deliveryId));

        }
      })

    } else {
      this.points = null;
    }

  }


  getClientFilteredOptions(filter: string): Observable<CustomerBase[]> {
    this.isLoadingSuggestion = true;
    return this.customerService.getFiltered(filter, true).pipe(map(x => { this.isLoadingSuggestion = false; return x.items; }));
  }

  onChangeClientInput(value: string | CustomerBase) {
    if (typeof value == 'string') {
      // this.filteredOptions$ = of(null);
      this.filteredOptions$ = this.getClientFilteredOptions(value);
      // return this.filteredOptions$;
    } else {
      this.updateCustomer(value);
    }
  }

  updatePoint($event) {
    this.updatePrice();
  }


  updatePrice() {

    const pointId = this.thisPromotion.deliveryId;
    const clientId = this.thisPromotion.clientId;

    this.priceService.getPriceList({ start: 0, limit: 1000 }, moment(), '' + clientId, pointId, null).subscribe(res => {
      if (this.linkedRoute) {
        this.prices = [];

        res.items.forEach(price => {
          if (this.linkedRoute.tanks.find(x => { return !x.products || x.products.find(y => y == price.product_name) || x.products.length == 0 })) {
            this.prices.push(price);
          }
        })

      } else {
        this.prices = res.items;
      }
      if (this.prices.length == 0) {
        this.translate.get('ORDERS.PROMOTIONMODAL.NOPRICE').subscribe(t => {
          this.notifyService.toast(t, 'info');
        })
      }
    });
  }

  updatedPrice($event) {
    this.oldPrice = PriceService.getEffectivePrice($event);
    this.minPrice = PriceService.getBasePromotionPrice($event);
    if (!this.thisPromotion.price) {
      this.thisPromotion.price = this.minPrice;
    }

  }

  isValid() {
    return this.thisPromotion.clientId &&
      this.thisPromotion.deliveryId &&
      this.thisPromotion.price &&
      this.thisPromotion.productId &&
      this.dateMoment && this.dateMoment.isSameOrAfter(moment(), 'day') &&
      this.thisPromotion.maxQuantity && !Number.isNaN(this.thisPromotion.maxQuantity * 1)
  }


}
