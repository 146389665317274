import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Moment } from 'moment';

@Component({
  selector: 'oil-modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss']
})
export class ModalCalendarComponent implements OnInit {


  public selectedDate: Moment;
  public maxDate: Moment = null;

  constructor(
    protected ref: NbDialogRef<ModalCalendarComponent>,
  ) { }

  ngOnInit(): void {
  }


  ok() {
    this.ref.close(this.selectedDate);
  }

  cancel() {
    this.ref.close();
  }

  isValid() {
    return true;
  }

}
