import { Component, OnInit, ViewChild } from '@angular/core';
import { multifilter } from 'src/app/models/customerfilter.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ChartGroupBy, ChartInputs, Customer, CustomerDashboard, orderRange } from 'src/app/models/customer.model';
import { CustomerService } from 'src/app/services/customer.service';
import { MatSort } from '@angular/material/sort';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { SortPaginator, GenericFilter, dailyInputs } from 'src/app/models/generic.model';
import { CustomerFilterComponent } from 'src/app/components/customer-filter/customer-filter.component';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { connect, getInstanceByDom } from 'echarts';
import { CATEGORIES_COLORS } from 'src/app/models/constants.model';

@Component({
  selector: 'oil-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public msg: string;
  private PAGE_SIZE = 10;

  public filters: GenericFilter[] = [
    CustomerFilterComponent.CATEGORIES(),
    CustomerFilterComponent.CLUSTER(),
    CustomerFilterComponent.STATUS(),
    CustomerFilterComponent.PUSHONLY()
  ];

  public favoriteonly: boolean = null;


  public activeFilters: multifilter[] = [];
  public initializing = true;

  public chartInput: ChartInputs;

  private translations;

  public dataSource;
  public resultsLength = 0;

  public dailyChartInput: dailyInputs;
  public activeClientsPerc = 0;
  public newClients = 0;
  public soldTotalMC = 0;
  public marginAvg = 0;
  public isLoading = false;

  private storage = window.localStorage;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  public displayedColumns: string[] = [
    'favorite',
    'name',
    // 'orderavgcluster',
    'deliveryAvgOrderQuantity',
    'deliveryMaxOrderQuantity',
    //'clusterAvgOrderFreq',
    'annualOrders',
    //'marginavgcluster',
    'deliveryAvgOrderNetMargin',
    // 'distance',
    // 'paymentmode',
    'deliveryAvgLatePayment',
    'nearestDateDiffABS',
    'cta'
  ];

  public periods = [
    { name: 'CUSTOMER.DASHBOARD.CHART.PERIODS.MONTH1', value: 1 },
    { name: 'CUSTOMER.DASHBOARD.CHART.PERIODS.MONTH6', value: 6 },
    { name: 'CUSTOMER.DASHBOARD.CHART.PERIODS.MONTH12', value: 12 },
    // { name: 'CUSTOMER.DASHBOARD.CHART.PERIODS.MONTH60', value: 60 },
    { name: 'CUSTOMER.DASHBOARD.CHART.PERIODS.MONTH1000', value: 1000 },
  ];
  public chartPeriod = 6;
  public currentPeriodString: string;
  public groupby = [
    { name: 'CUSTOMER.DASHBOARD.CHART.GROUPBY.DAILY', value: ChartGroupBy.daily },
    { name: 'CUSTOMER.DASHBOARD.CHART.GROUPBY.WEEKLY', value: ChartGroupBy.weekly },
    { name: 'CUSTOMER.DASHBOARD.CHART.GROUPBY.MONTHLY', value: ChartGroupBy.monthly },
    { name: 'CUSTOMER.DASHBOARD.CHART.GROUPBY.YEARLY', value: ChartGroupBy.yearly },
  ];
  public chartGroupby = ChartGroupBy.monthly;

  constructor(
    private customerService: CustomerService,
    // private router: Router,
    // private calendarService: CalendarService,
    private translate: TranslateService
  ) {
    setTimeout(() => {
      this.translate.get(
        [
          'CATEGORIES.GAS.RISC',
          'CATEGORIES.GAS.AUTO',
          'CATEGORIES.GAS.AGR',
          'CATEGORIES.ALTRO',
        ]).subscribe(translations => {
          this.translations = translations;
        })
    }, 1)

  }


  ngAfterViewInit() {
    this.connectCharts();
  }

  connectCharts() {
    //setTimeout(() => {
    try {
      const chart1 = getInstanceByDom(document.getElementById('avgOrders'));
      const chart2 = getInstanceByDom(document.getElementById('totalOrders'));
      const chart3 = getInstanceByDom(document.getElementById('avgMargin'));
      const chart4 = getInstanceByDom(document.getElementById('totalMargin'));
      if (chart1 && chart2 && chart3 && chart4) {
        chart1.setOption({ locale: 'DE' })
        connect([chart1, chart2, chart3, chart4]);
      } else {
        setTimeout(() => {
          this.connectCharts();
        }, 1);
      }
    }
    catch (e) {
      //console.log('---- ~ file: dashboard.component.ts ~ line 124 ~ DashboardComponent ~ setTimeout ~ e', e);
    }
    //}, 0);
  }

  ngOnInit() {

    // this.paginator.pageSize = this.PAGE_SIZE;
    //this.refresh();

    this.translate.onLangChange.subscribe(() => {
      this.translateChartsOptions();
    });
    this.translateChartsOptions();

    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    merge(this.sort.sortChange, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (!this.initializing) {
            this.refresh();
          }
          return of([]);
        })
      ).subscribe(data => {
        // console.log('-------: DashboardComponent -> ngOnInit -> data', data);
      });


    setTimeout(() => {
      //   this.refresh();
      this.initializing = false;
    }, 1);


  }

  translateChartsOptions() {
    this.periods = [...this.setTransaltions(this.periods)];
    this.groupby = [...this.setTransaltions(this.groupby)];
  }

  setTransaltions(collectionWithName) {
    collectionWithName.forEach(elem => {

      let s = this.translate.instant(elem.name);
      elem.name = s;
    })
    return collectionWithName;
  }

  refresh() {
    const paginator: SortPaginator = {
      orderBy: this.sort.active,
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }
    if (this.sort.direction) { paginator.orderDirection = this.sort.direction }

    this.updateCharts();

    this.customerService.getDashboardTable(this.activeFilters, paginator, this.chartInput, this.favoriteonly).subscribe(x => {
      this.dataSource = new MatTableDataSource<Customer>(x.items);
      this.resultsLength = x.total;
    });




  }


  updateCharts() {
    this.chartInput = {
      startDate: moment().add(-this.chartPeriod, 'month'),
      endDate: moment(),
      groupBy: this.chartGroupby,
      filters: this.activeFilters,
      favoriteonly: this.favoriteonly
    }
    this.updateDaily();

    this.currentPeriodString = this.periods.find(x => x.value == this.chartPeriod).name;
  }

  updateDaily() {
    this.isLoading = true;
    this.customerService.getDailyDetails(this.chartInput).subscribe(x => {
      this.activeClientsPerc = x.activeClients / x.totalClients * 100;
      this.newClients = x.newClients;
      this.soldTotalMC = x.totalQuantity / 1000;
      // this.marginAvg = x.avgMargin * 1000;
      this.marginAvg = x.avgMarginWeight * 1000;
      let series = [];
      x.categories.forEach(stat => {
        if (this.activeFilters.find(filter => filter.value.find(val => val == stat.category))) {
          series.push({
            name: this.translations[`CATEGORIES.${stat.category}`],
            value: stat.total,
            color: CATEGORIES_COLORS[stat.category]
          })
        }
      })
      this.dailyChartInput = {
        series: series,
        centerValue: x.activeClients,
      }
      this.isLoading = false;
    });
  }

  filterChange($event) {
    console.log('------- ~ file: dashboard.component.ts ~ line 236 ~ DashboardComponent ~ filterChange ~ $even', $event);
    this.activeFilters = $event;
    // if (!this.initializing) { 
    this.refresh();
    // }
  }

  tooltip(row: CustomerDashboard) {
    return `${row.deliveryName}${row.deliveryAddress ? ', ' + row.deliveryAddress : ''} - ${row.deliveryCity}  `
  }

  rangeValue(ordersRange: orderRange[]): string {
    let min = 365;
    if (!ordersRange || ordersRange.length == 0) {
      return '';
    }
    ordersRange.forEach(range => {
      if (moment().isBetween(moment(range.min_range), moment(range.max_range), 'day', '[]'))
        return '0'
    });
    ordersRange.forEach(range => {
      let diff = moment().diff(moment(range.min_range), 'day');
      if (diff > 0) {
        diff = moment().diff(moment(range.max_range), 'day');
      }
      if (Math.abs(diff) < Math.abs(min)) {
        min = diff;
      }
    });

    return min + '';
  }

  rangeClass(ordersRange: orderRange[]): string {
    return CustomerService.rangeClass(ordersRange);
  }


  onChangeFavoriteOnly($event) {
    this.favoriteonly = $event ? true : null;
    this.refresh();
  }

  getInterationOrder(intervalInDays: number = 0, totalOrders: number = 0) {
    let intOrder = totalOrders != 0 ? Math.round(intervalInDays / totalOrders * 100) / 100 : 0;
    return intOrder > 0 ? intOrder + ' gg' : 'ND'
  }

  getNearstDate(date) {
    const label = date > 0 ? 'LABEL_DATE_POSITIVE' : 'LABEL_DATE_NEGATIVE';
    const datePositive = date < 0 ? date * -1 : date;
    let type = 'DAYS';
    type = datePositive == 1 ? 'DAY' : (datePositive >= 365 ? (parseInt((datePositive / 365) + '') == 1 ? 'YEAR' : 'YEARS') : 'DAYS');

    return {
      label: label,
      date: datePositive >= 365 ? parseInt((datePositive / 365) + '') : datePositive,
      type: type
    }
  }

  setFavorite(point: CustomerDashboard) {
    this.customerService.setCustomerPointFavorite(point.deliveryId, !point.favorite).subscribe(() => {
      point.favorite = !point.favorite;
    })
  }
}
