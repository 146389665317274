<nb-card class="company-card">
    <nb-card-body>
        <div class="column-data company-name">
            <strong> {{customer?.name}}</strong>

            <div class="tag-container">

                <div class="tag-category" *ngFor="let tags of groupTags(customer?.delivery_addresses[0].tags)">
                    {{"CATEGORIES."+tags[0].category | translate}} <div *ngFor="let tag of tags"
                        class="tag-div {{tag.type}}">{{'FILTERS.'+toUpperCase(tag.type)+'.'+toUpperCase(tag.name) |
                        translate }}</div>
                </div>

                <div class="tag-add" *ngIf="groupTags(customer?.delivery_addresses[0].tags).length < 4">
                    <button [nbPopover]="businessList" nbButton size="tiny"
                        (click)="addBusinessPop(customer?.delivery_addresses[0])"> +
                    </button>
                </div>

                <ng-template #businessList>
                    <nb-card class="margin-bottom-0">
                        <nb-card-header>{{'CUSTOMER.DETAILS.ADDCATEGORY' | translate }}</nb-card-header>
                        <button class="category-add-btn" *ngFor="let category of categoriesForPoint" nbButton
                            (click)="addBusiness(customer?.delivery_addresses[0],category)"> {{ "CATEGORIES."+category |
                            translate}}
                        </button>
                    </nb-card>
                </ng-template>
                <div>
                    <button nbButton class="requestprice requestpricebtn" *ngIf="!customer?.haveRequestedPrice"
                        (click)="requestPrice()">
                        {{'CUSTOMER.DETAILS.REQUESTPRICE' | translate}}
                        <nb-icon icon="listino-off" pack="3logic" class="requestpriceicon"> </nb-icon>
                    </button>
                    <nb-icon icon="listino-on" pack="3logic" class="requestprice" *ngIf="customer?.haveRequestedPrice">
                    </nb-icon>
                </div>
            </div>
        </div>

        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.VAT' | translate }}</label>
            <p class="value">{{customer?.vatCode ? customer?.vatCode : noValue}}</p>
        </div>
        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.ADDRESS' | translate }}</label>
            <p class="value">{{customer?.address ? customer.address +' '+ customer.province : noValue}}</p>
        </div>
        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.MONTHORDERS' | translate }}</label>
            <p class="value">{{customer?.monthlyOrders | currency:'€' }}</p>
        </div>
        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.NOTES' | translate }}</label>
            <p class="value">{{customer?.note?customer.note:noValue}}</p>
        </div>

        <div class="column-data company-name">

        </div>

        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.RESIDUAL' | translate }}</label>
            <p class="value"> {{(customer?.remainingCredit?customer.remainingCredit:noValue) | currency:'€': 'symbol' :
                '1.0-0'}}</p>
        </div>

        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.SELLER' | translate }}</label>
            <p class="value" *ngFor="let seller of getSellers()"> {{seller.firstName}} {{seller.lastName}}</p>
        </div>

        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.USER' | translate }}</label>
            <p class="value" *ngIf="customer?.user?.id"> {{customer?.user?.email}}</p>
            <p class="value no-user" *ngIf="!customer?.user?.id">
                {{'CUSTOMER.DETAILS.NOUSER' | translate }} :
                <span class="link" (click)="createUser()">{{'CUSTOMER.DETAILS.CREATEUSER' | translate }}</span>
            </p>
        </div>

        <div class="column-data">
            <label class="label">{{'CUSTOMER.DETAILS.PRIVACY' | translate }}</label>
            <div class="privacy-policy" *ngFor="let policy of customer?.policies">
                <div class="policy-name" [ngClass]="{'active':!!policy.accept}"
                    nbTooltip="{{'CUSTOMER.DETAILS.PRIVACYOBJ.'+policy.policy+'_tooltip' | translate}}">
                    {{'CUSTOMER.DETAILS.PRIVACYOBJ.'+policy.policy | translate}} </div>
                <div class="toggle">
                    <mat-slide-toggle [checked]="!!policy.accept" (change)="changePolcy($event,policy,customer)">
                    </mat-slide-toggle>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>

<nb-card>
    <nb-card-body>
        <nb-accordion class="no-border">
            <nb-accordion-item (collapsedChange)="openOrders($event)" [collapsed]="ordersCollapsed">
                <nb-accordion-item-header class="no-expansion-icon centered">{{'CUSTOMER.DETAILS.ORDERS' | translate }}
                </nb-accordion-item-header>
                <nb-accordion-item-body>

                    <table mat-table [dataSource]="dataSource" #sort="matSort" matSort #table>

                        <!-- Data -->
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.DETAILS.TABLE.DATE' | translate}} </th>
                            <td mat-cell *matCellDef="let element"> {{element.deliveryDate | amDateFormat:'DD/MM/YYYY'
                                }}
                            </td>
                        </ng-container>

                        <!-- Numero -->
                        <ng-container matColumnDef="number">
                            <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.DETAILS.TABLE.NUMBER' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.orderNumber}}</td>
                        </ng-container>

                        <!-- Deposito  -->
                        <ng-container matColumnDef="deposit">
                            <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.DETAILS.TABLE.DEPOSIT' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="credit">
                                <div class="deposit-address">
                                    {{element.deliveryName}}
                                    {{element.deliveryAddress}},{{element.deliveryCity}}
                                </div>
                            </td>
                        </ng-container>

                        <!-- Prodotto -->
                        <ng-container matColumnDef="product">
                            <th mat-header-cell *matHeaderCellDef> {{ 'CUSTOMER.DETAILS.TABLE.PRODUCT' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.product}}</td>
                        </ng-container>

                        <!-- Quantità -->
                        <ng-container matColumnDef="quantity">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'CUSTOMER.DETAILS.TABLE.QUANTITY' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element"> {{element.quantity | number | liter}}</td>
                        </ng-container>

                        <!-- Valore -->
                        <ng-container matColumnDef="value">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'CUSTOMER.DETAILS.TABLE.VALUE' | translate}}
                            </th>
                            <td mat-cell class="promotional" *matCellDef="let element">
                                {{element.price | currency:'€': 'symbol' : '1.2-2'}}
                            </td>
                        </ng-container>

                        <!-- Prezzo -->
                        <ng-container matColumnDef="price">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'CUSTOMER.DETAILS.TABLE.PRICE' | translate}}
                            </th>
                            <td mat-cell class="promotional" *matCellDef="let element">
                                {{element.price / element.quantity | number : '1.3-3'}}
                                {{element.marginUnitary | number : '1.3-3'}}
                                <img *ngIf="element.promotionalPrice  || element.suggestedRoute" class="promo-ico"
                                    src="/assets/images/gift.png">
                            </td>
                        </ng-container>


                        <!-- Stato -->
                        <ng-container matColumnDef="status">
                            <th mat-header-cell *matHeaderCellDef>
                                {{ 'CUSTOMER.DETAILS.TABLE.STATUS' | translate}}
                            </th>
                            <td mat-cell *matCellDef="let element" class="status  {{element.status}}">
                                {{'ORDERSTATUS.'+toUpperCase(element.status)|translate}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
                        </tr>
                    </table>

                    <mat-paginator pageSize="10" [pageSizeOptions]="[10,50,100]" [length]="resultsLength">
                    </mat-paginator>




                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>

        <div class="accordion-shower">
            <nb-icon [icon]="orderOpen?'chevron-up-outline':'chevron-down-outline'"
                [options]="{ animation: { type: 'pulse' } }" (click)="collapseOrders(!orderOpen)"></nb-icon>
        </div>

    </nb-card-body>
</nb-card>

<div class="details-container">
    <div class="details" *ngFor="let point of customer?.delivery_addresses; let idx = index">
        <nb-card>
            <nb-card-header>
                <div class="point-name">
                    <nb-icon [icon]="point.favorite?'star-on':'star-off'" pack="3logic" class="favorite"
                        (click)="setFavorite(point)">
                    </nb-icon>
                    <strong>{{point.name}}</strong>
                </div>
                <div class="actions">

                    <oil-cta [customerId]="customer.id" [pointId]="point.id" [details]="false" (eventAdded)="refresh()">
                    </oil-cta>

                </div>
                <div class="point-address" [ngClass]="{'bad_location':point.bad_location}">{{point.address_1}}{{point.address_2}},{{point.cap}} - {{point.city}}
                    ({{point.province}})
                    <nb-icon icon="alert-triangle-outline" *ngIf="point.bad_location" nbTooltip="{{'GENERIC.BADLOCATION'|translate}}">
                    </nb-icon>
                </div>
            </nb-card-header>
            <nb-card-body class="no-padding-right info-container">

                <div class="spacer" *ngIf="!showFullCalendar[idx]"></div>

                <div class="info-left" *ngIf="!showFullCalendar[idx]">
                    
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.DELIVERYTIME' | translate }}
                        </label>
                        <div class="point-details-value">
                            <ng-select [items]="deliveryTimes" [searchable]="false" [clearable]="false" bindLabel="name" bindValue="name" autofocus (change)="updateDeliveryTime(point,$event.name)" [(ngModel)]="point.deliveryTime.name"></ng-select>
                        </div>
                    </div>

                    
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.MAXTRUCKSIZE' | translate }}
                        </label>
                        <div class="point-details-value">
                            <ng-select [items]="truckSizes" [searchable]="false" [clearable]="false" bindLabel="label" bindValue="id" autofocus (change)="updateTruckMaxSize(point,$event.id)" [(ngModel)]="point.maxTruckSize"> </ng-select>        
                        </div>
                    </div>

                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.MONTHORDERS' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.monthlyOrders | minZero | currency:'€'}}
                        </div>
                    </div>
                    <!-- <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.PROMOTION' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.monthpromotions}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.COMPETITOR' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.monthcompetitor}}
                        </div>
                    </div> -->
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.AVGNETMARGIN' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.avgOrderNetMargin | minZero | currency:'€': 'symbol' : '1.3-3'}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.DEPOT' | translate }}
                        </label>
                        <div class="point-details-value" *ngIf="point.depot">
                            {{point.depot.name}} ({{point.depot.distance | number:'1.0-0'}}km)
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.PAYMENTCONDITION' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.paymentCondition}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.PAYMENTMETHOD' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.paymentMethod}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.PAYMENTDELAY' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.avgLatePayment}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.SECONDARY' | translate }}
                        </label>
                        <div class="point-details-value">
                            {{point.secondary | currency : "€"}}
                        </div>
                    </div>
                    <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.EXTENSIONCOST' | translate }}
                        </label>
                        <div *ngFor="let cost of point?.extensionCost" class="point-details-value">
                            {{cost.cost | currency : "€" : 'symbol' : '1.3-3' }} <span class="point-details-extrainfo">(
                                {{("CATEGORIES."+cost.productCategory) | translate}})</span>
                        </div>
                    </div>
                    <!-- <div class="point-details">
                        <label class="point-details-label">
                            {{'CUSTOMER.DETAILS.CONTACTS' | translate }}
                        </label>
                        <div class="point-details-value">
                            <div *ngFor="let contact of point.contacts">{{contact}}</div>
                        </div>
                    </div> -->
                </div>


                <div [ngClass]="{'fullCalendar':showFullCalendar[idx],'info-right':!showFullCalendar[idx]}" class="calendarcontainer">
                    <nb-icon class="btn" [icon]="showFullCalendar[idx]?'collapse':'expand'"
                        (click)="showFullCalendar[idx]=!showFullCalendar[idx]"
                        [options]="{ animation: { type: 'pulse' } }"></nb-icon>
                    <div class="calendar-list">

                        <div *ngIf="point.orders_ranges && point.orders_ranges.length">
                            <div class="calendar-event " *ngFor="let range of point.orders_ranges">
                                <div class="calendar-event-date">
                                    {{range.min_range | amDateFormat:'DD MMMM YYYY'}} - {{range.max_range |
                                    amDateFormat:'DD MMMM YYYY'}}
                                </div>
                                <div class="calendar-event-content">
                                    <p>{{'CUSTOMER.DETAILS.RANGEPREVIEW' | translate }}
                                        {{'CATEGORIES.'+range.category| translate}}</p>
                                </div>
                            </div>
                        </div>


                        <div class="calendar-event " *ngFor="let event of point.events; let cIdx = index"
                            (click)="editEvent(event)">
                            <ng-container *ngIf="cIdx<maxCalendarItem || showFullCalendar[idx]">
                                <div class="calendar-event-date">
                                    {{event.date | amDateFormat:'DD MMMM YYYY'}}
                                </div>
                                <div class="calendar-event-content">
                                    <!-- <label>{{'CUSTOMER.DETAILS.EVENTNOTE' | translate }} -->
                                    <!-- <nb-icon clas="btn" icon="edit-outline" (click)="editEvent(event)"
                                            [options]="{ animation: { type: 'pulse' } }"></nb-icon> </label> -->
                                    <div [innerHtml]="event?.note">
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <div class="calendar-event " *ngIf="!point.events || !point.events.length">
                            {{'CUSTOMER.DETAILS.NOEVENTS' | translate }}
                            <a (click)="addEvent(point)">{{'CUSTOMER.DETAILS.ADDEVENTS' | translate }}</a>
                        </div>
                    </div>
                </div>

            </nb-card-body>
        </nb-card>
    </div>
    <div class="card-col hide">

        <nb-card size="small">
            <nb-card-header>
                <h2 class="details-date">{{today | amDateFormat:'DD MMMM'}}</h2>
                <p class="details-weekday">{{today | amDateFormat:'dddd'}}</p>
            </nb-card-header>
            <nb-card-body>


            </nb-card-body>
        </nb-card>
    </div>
</div>

<div class="card-row">

</div>