import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { CustomerDetail, CustomerDashboardApiResponse, CustomerSearchApiResponse, CustomerEventApiResponse, CustomerEvent, CustomerBase, ChartGroupBy, ChartAvgOrdersApiResponse, ChartInputs, ClientQtyMarginApiResponse, ChartTotalOrdersApiResponse, ChartAvgMarginApiResponse, ChartTotalMarginApiResponse, CustomerBaseApiResponse, CustomerDailyApiResponse, CustomerPoint, CustomerDashboard, Customer, orderRange } from '../models/customer.model';
import { CustomerSearchFilter, multifilter, ValuesComparators } from '../models/customerfilter.model';
import { SortPaginator } from '../models/generic.model';
import * as moment from 'moment';
import { config } from 'src/environments/config';
import { ValueEditorComponent } from '../components/value-editor/value-editor.component';
import { CustomerFilterComponent } from '../components/customer-filter/customer-filter.component';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {


    constructor(
        private api: ApiService
    ) {


    }

    public getDashboardTable(filters: multifilter[], paginator: SortPaginator, chartInput: ChartInputs, favoriteonly: boolean = null): Observable<CustomerDashboardApiResponse> {
        const parameters: any = {
            min: chartInput.startDate.format(config.apiDateFormat),
            max: chartInput.endDate.format(config.apiDateFormat),
        };
        if (favoriteonly === true || favoriteonly === false) {
            parameters.favoriteonly = favoriteonly;
        }
        // const parameters = {}; //TODO add min max
        CustomerFilterComponent.addFilters(parameters, filters);
        Object.assign(parameters, paginator);
        return this.api.call('cms/clients/dashboard', 'GET', null, parameters);
    }

    public getDailyDetails(chartInput: ChartInputs): Observable<CustomerDailyApiResponse> {
        const parameters: any = {
            min: chartInput.startDate.format(config.apiDateFormat),
            max: chartInput.endDate.format(config.apiDateFormat)
        };
        if (chartInput.favoriteonly === true || chartInput.favoriteonly === false) {
            parameters.favoriteonly = chartInput.favoriteonly;
        }
        CustomerFilterComponent.addFilters(parameters, chartInput.filters);
        return this.api.call('cms/clients/daily-details', 'GET', null, parameters);
    }



    public getSearchTable(filterOriginal: CustomerSearchFilter, paginator: SortPaginator): Observable<CustomerSearchApiResponse> {
        const parameters = {};
        const filter = JSON.parse(JSON.stringify(filterOriginal))
        if (filter.orderImportVal) {
            switch (filter.orderImportOperator) {
                case ValuesComparators.EQ:
                    filter.orderAmountEQ = filter.orderImportVal;
                    break;
                case ValuesComparators.LT:
                    filter.orderAmountLT = filter.orderImportVal;
                    break;
                case ValuesComparators.GT:
                    filter.orderAmountGT = filter.orderImportVal;
                    break;

            }
        }
        delete filter.orderImportOperator;
        delete filter.orderImportVal;
        Object.assign(parameters, filter, paginator);
        return this.api.call('cms/clients/search', 'GET', null, this.api.clearParameters(parameters));
    }

    public getDetails(code: string, isAdmin: boolean): Observable<CustomerDetail> {
        return this.api.call(`admin/clients/profile/${code}`, 'GET', null);
    }

    // public getMyDetails(): Observable<CustomerDetail> {
    //     return this.api.call(`client/me`, 'GET', null, null, true, false);
    // }

    public getEvents(month: number, year: number): Observable<CustomerEventApiResponse> {
        const param = { month: month + 1, year: year, start: 0, limit: 1000 }
        return this.api.call(`admin/calendar`, 'GET', null, param);
    }

    public getEventsForPoint(customerId, pointId): Observable<CustomerEventApiResponse> {
        const param = { pointId: pointId, customerId: customerId }
        return this.api.call(`admin/calendar`, 'GET', null, param);
    }

    public getEvent(eventId: number): Observable<CustomerEventApiResponse> {
        // return this.api.call(`clients/event/${eventId}`, 'GET', null);
        return this.api.call(`admin/calendar/event/${eventId}`, 'GET', null);
    }

    public saveEvent(event: CustomerEvent) {
        event.date = moment(event.date).format(config.apiDateFormat);
        if (event.id) {
            return this.api.call(`admin/calendar/edit/${event.id}`, 'POST', event);
        } else {
            return this.api.call(`admin/calendar/add`, 'POST', event);
        }
    }

    public requestPrice(clientId: number): Observable<any> {
        return this.api.call(`admin/clients/${clientId}/request-list`, 'POST', null, null, null);
    }

    public setCustomerPointFavorite(pointId: number, isFavorite: boolean) {
        const params = { id: pointId, favorite: isFavorite };
        return this.api.call(`admin/clients/delivery/set-favorite`, 'POST', params);
    }

    public getAll(): Observable<CustomerBase[]> {
        return this.api.call('cms/clients/list', 'GET');
    }

    public getFiltered(filterString, onlyWithPushNotification = false): Observable<CustomerBaseApiResponse> {
        if (!filterString) {
            return of({ items: [], total: 0 });
        }
        return this.api.call('cms/clients/list/search', 'GET', null, { query: filterString, pushNotification: onlyWithPushNotification ? true : null }, true);
    }



    public chartAvgOrders(charInput: ChartInputs): Observable<ChartAvgOrdersApiResponse> {
        const parameters = {};
        CustomerFilterComponent.addFilters(parameters, charInput.filters);
        Object.assign(parameters, { min: charInput.startDate.format(config.apiDateFormat), max: charInput.endDate.format(config.apiDateFormat), groupBy: charInput.groupBy, favoriteonly: charInput.favoriteonly });
        return this.api.call('cms/clients/charts/avg-orders', 'GET', null, parameters, true);
    }

    public chartTotalOrders(charInput: ChartInputs): Observable<ChartTotalOrdersApiResponse> {
        const parameters = {};
        CustomerFilterComponent.addFilters(parameters, charInput.filters);
        Object.assign(parameters, { min: charInput.startDate.format(config.apiDateFormat), max: charInput.endDate.format(config.apiDateFormat), groupBy: charInput.groupBy, favoriteonly: charInput.favoriteonly });
        return this.api.call('cms/clients/charts/total-orders', 'GET', null, parameters, true);
    }

    public chartAvgMargin(charInput: ChartInputs): Observable<ChartAvgMarginApiResponse> {
        const parameters = {};
        CustomerFilterComponent.addFilters(parameters, charInput.filters);
        Object.assign(parameters, { min: charInput.startDate.format(config.apiDateFormat), max: charInput.endDate.format(config.apiDateFormat), groupBy: charInput.groupBy, favoriteonly: charInput.favoriteonly });
        return this.api.call('cms/clients/charts/avg-margin', 'GET', null, parameters, true);
    }

    public chartTotalMargin(charInput: ChartInputs): Observable<ChartTotalMarginApiResponse> {
        const parameters = {};
        CustomerFilterComponent.addFilters(parameters, charInput.filters);
        Object.assign(parameters, { min: charInput.startDate.format(config.apiDateFormat), max: charInput.endDate.format(config.apiDateFormat), groupBy: charInput.groupBy, favoriteonly: charInput.favoriteonly });
        return this.api.call('cms/clients/charts/total-margin', 'GET', null, parameters, true);
    }

    public chartClientQtyMargin(charInput: ChartInputs): Observable<ClientQtyMarginApiResponse> {
        const parameters = {};
        CustomerFilterComponent.addFilters(parameters, charInput.filters);
        Object.assign(parameters, { min: charInput.startDate.format(config.apiDateFormat), max: charInput.endDate.format(config.apiDateFormat) });
        return this.api.call('cms/clients/charts/qty-margin-per-client', 'GET', null, parameters, true);
    }

    public addBusiness(point: CustomerPoint, category: string) {
        const data = {
            deliveryId: point.id,
            productCategory: category
        }
        return this.api.call(`admin/clients/${point.clientId}/delivery/business/set`, 'POST', data);
    }

        public updatePoint(point: CustomerPoint, maxTruckSize: string = null, deliveryTime : string = null) {
        const data = {
            maxTruckSize: maxTruckSize ? maxTruckSize : point.maxTruckSize,
            deliveryTimeName: deliveryTime ? deliveryTime : point.deliveryTime.name
        }
        return this.api.call(`admin/clients/${point.id}/delivery-config`, 'POST', data);
    }

    public setPolicy(customerId: number, policyName: string, value: boolean) {
        const data = {
            policy: policyName,
            accept: value
        }
        return this.api.call(`admin/config/client/${customerId}/set-privacy-policy`, 'POST', data);
    }

    public static rangeClass(ordersRange: orderRange[]):string{
        if (!ordersRange || ordersRange.length == 0) {
            return '';
          }
          for (let i = 0; i < ordersRange.length; i++) {
            let range = ordersRange[i];
            if (moment().isBetween(moment(range.min_range), moment(range.max_range), 'day', '[]')) {
              return 'present'
            }
          };
          for (let i = 0; i < ordersRange.length; i++) {
            let range = ordersRange[i];
            if (moment().isAfter(moment(range.max_range), 'day')) {
              return 'past'
            }
          };
          return 'future';
    }


}