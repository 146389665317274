import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Price } from 'src/app/models/price.model';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { BasicOrder } from 'src/app/models/order.model';
import { NbDialogRef } from '@nebular/theme';
import { OrderService } from 'src/app/services/order.service';
import { PriceService } from 'src/app/services/price.service';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerDetail } from 'src/app/models/customer.model';
import { CalendarService } from 'src/app/services/calendar.service';

@Component({
  selector: 'oil-modal-client-order',
  templateUrl: './modal-client-order.component.html',
  styleUrls: ['./modal-client-order.component.scss']
})
export class ModalClientOrderComponent implements OnInit {

  public ckEditor = ClassicEditor;

  public price: Price;
  public customer: CustomerDetail;

  public quantity: number;
  public deliveryDateMoment: moment.Moment = CalendarService.nextWorkingDay();
  public notes: string;

  public distributionTxt: string;

  constructor(
    protected ref: NbDialogRef<ModalClientOrderComponent>,
    private orderService: OrderService
  ) { }

  ngOnInit(): void {
    this.distributionTxt = this.price.deliveryName; //todo maggiori dettagli address, etc
    if(this.price.quantity_promotional){
      this.deliveryDateMoment = moment(this.price.date_promotional);
    }

  }

  save() {

    let order: BasicOrder = {
      client_delivery_id: this.price.client_delivery_address_id,
      deliveryDate: this.deliveryDateMoment.toDate(),
      notes: this.notes,
      price: this.price.price_sent_to_client, //TODO Controlalre se è quello il prezzo
      product_id: this.price.product_id,
      quantity: this.quantity,
    }

    this.orderService.addClientOrder(order).subscribe(X => {
      this.ref.close(order);
    })

  }

  validPrice() {
    return 0 | this.price.quantity_promotional ? this.price.price_promotional : this.price.price_sent_to_client;
  }

  cancel() {
    this.ref.close();
  }

  changeQuantity() {
    if (this.quantity < 0) {
      this.quantity = 0;
    }
    if (this.price.quantity_promotional && this.quantity > this.price.quantity_promotional) {
      this.quantity = this.price.quantity_promotional;
    }
  }

  isValid() {
    return this.deliveryDateMoment && this.deliveryDateMoment.isSameOrAfter(moment(), 'day') && this.quantity && !Number.isNaN(this.quantity * 1)
  }


}
