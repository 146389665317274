import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { startWith, switchMap, take } from 'rxjs/operators';
import { ModalPromotionStartComponent } from 'src/app/components/modal-promotion-start/modal-promotion-start.component';
import { dailyInputs, SortPaginator } from 'src/app/models/generic.model';
import { Trip } from 'src/app/models/order.model';
import { CalendarService } from 'src/app/services/calendar.service';
import { LogisticService } from 'src/app/services/logistic.service';
import { ModalService } from 'src/app/services/modal.service';
import { NbDialogService } from '@nebular/theme';
import { MODAL, TRIPSTATUS_COLORS } from 'src/app/models/constants.model';
import { UtilsService } from 'src/app/services/utils.service';
import { TripStatus } from 'src/app/models/logistic_model';
import { NotifyService } from 'src/app/services/notify.service';
import { PriceService } from 'src/app/services/price.service';
import { config } from 'src/environments/config';

@Component({
  selector: 'oil-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {

  public nextDay = CalendarService.nextWorkingDay();
  public lastOptimDate = moment('0000-00-00');

  public selected = CalendarService.nextWorkingDay();
  public dateFilter = moment(this.selected);

  public dataSource: MatTableDataSource<Trip>;
  public results: Trip[];
  public resultsLength = 0;
  public openTripNumber = 0;
  // public fillingVariation = '0';

  public unassignedOrdersNumber = 0;
  public error = false;
  public errorList: string[] = [];

  public hasData = false;
  public isWaiting = false;
  public promotionActive = null;
  public isLoading = false;

  public tripsnumber: number;
  public tripskm: number;
  public tripsfilling: number;

  public tripsfillingDelta: number;
  public tripsDelta: number;

  public trucksNumber = 0;
  public translations;

  public isToday = false;


  public chartInput: dailyInputs;

  public displayedColumns: string[] = [
    'selected',
    'name',
    'truck',
    'driver',
    'orders',
    'destinations',
    'products',
    'filling',
    'modify',
    'remove'
  ];


  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private logisticService: LogisticService,
    private translation: TranslateService,
    private modalService: ModalService,
    private dialogService: NbDialogService,
    private notifyService: NotifyService,
    private priceService: PriceService
  ) {
    this.translation.get(
      [
        'ORDERS.PROMOTION.TRIPBASENAME',
        'LOGISTIC.LIST.CHARTLABEL',
        'TRIPSTATUS.confirmed',
        'TRIPSTATUS.unconfirmed',
        'TRIPSTATUS.in_transit',
        'TRIPSTATUS.delivered',
        'ORDERS.TRIPDETAILS.DELETECONFIRMTITLE',
        'ORDERS.TRIPDETAILS.REMOVE',
        'LOGISTIC.LIST.CONFIRMMULTIPLETITLE',
        'LOGISTIC.LIST.CONFIRMMULTIPLEMESSAGE'
      ]).subscribe(translations => {
        this.translations = translations;
      })
  }

  ngOnInit(): void {

    this.refresh();

    this.sort.sortChange.subscribe(() => {

      this.doSort();

    });
 
  }

  doSort() {

    // const compare = (a: string, b: string, isAsc: boolean, aBis: number = null, bBis: number = null) => {
    //   if (a == b && aBis && bBis) { return (bBis - aBis) * (isAsc ? 1 : -1); }
    //   return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    // }
    // const compareNumbers = (a: number, b: number, isAsc: boolean, aBis: number = null, bBis: number = null) => {
    //   if (a == b) { return (bBis - aBis) * (isAsc ? 1 : -1); }
    //   return (b - a) * (isAsc ? 1 : -1);
    // }


    const data = this.dataSource.data;

    // this.dataSource.sort = this.sort;
    if (!this.sort.active || this.sort.direction === '') {
      return;
    }

    data.sort((a, b) => {
      const isAsc = this.sort.direction === 'asc';
      switch (this.sort.active) {
        case 'name': return UtilsService.compare(a.routeId, b.routeId, isAsc);
        case 'truck': return UtilsService.compare(a.vehicleName, b.vehicleName, isAsc, b.routeId, a.routeId);
        case 'tow': return UtilsService.compare(a.towName, b.towName, isAsc);
        case 'driver': return UtilsService.compare(a.driverLastName + a.driverFirstName, b.driverLastName + b.driverFirstName, isAsc);
        case 'orders': return UtilsService.compare(a.orders.length, b.orders.length, isAsc, a.fillingPercentual, b.fillingPercentual);
        case 'destinations': return UtilsService.compare(a.destinations.length, b.destinations.length, isAsc, a.fillingPercentual, b.fillingPercentual);
        case 'products': return UtilsService.compare(this.productsList(a).length, this.productsList(b).length, isAsc);
        case 'filling': return UtilsService.compare(a.fillingPercentual, b.fillingPercentual, isAsc);
        case 'modify': return UtilsService.compare(a.confirmed, b.confirmed, isAsc);
        default: return 0;
      }
    });

    this.dataSource = new MatTableDataSource<Trip>(data);
  }



  refresh() {
    // const paginator: SortPaginator = {
    //   orderBy: this.sort.active,
    //   start: this.paginator.pageIndex,
    //   limit: this.paginator.pageSize
    // }    
    // if(this.sort.direction){paginator.orderDirection = this.sort.direction}

    this.chartInput = null;
    this.setLastOptimDate();
    this.isToday = moment().isSame(this.dateFilter, 'day');

    //this.logisticService.getTrips(null, this.translations['ORDERS.PROMOTION.TRIPBASENAME'], this.dateFilter.toDate()).subscribe(x => {
    this.logisticService.getTrips(null, null, this.dateFilter.toDate()).subscribe(x => {
      console.log("------- ~ RoutesComponent ~ this.logisticService.getTrips ~ x", x);
      this.hasData = !!(x.items && x.items.length);
      this.dataSource = new MatTableDataSource<Trip>(x.items);
      this.results = x.items;
      this.resultsLength = x.total;
      // this.openTripNumber = 0;

      // // this.tripsnumber = 0;
      // // this.tripskm = 0;
      // // this.tripsfilling = 0;
      // let trucksIds = {};
      // let totalFill = 0;
      // let totalCapacity = 0;
      // if (x.items && x.items.length) {
      //   this.tripsnumber = x.items.length;
      //   x.items.forEach(trip => {
      //     if (!trip.confirmed) { this.openTripNumber++; }
      //     // this.tripskm += Math.round(trip.distance / 1000);
      //     // totalCapacity += trip.maxTruckCapacity + trip.maxTowCapacity;
      //     trip.tanks.forEach(tank => {
      //       totalFill += tank.fillingQuantity;
      //     })
      //     trucksIds[trip.vehicleName] = 1;
      //   })
      //   // this.trucksNumber = Object.keys(trucksIds).length;
      //   // this.tripsfilling = Math.round(totalFill / totalCapacity * 100);

      //   // this.chartInput = {
      //   //   series: [
      //   //     {
      //   //       name: this.translations['LOGISTIC.LIST.TRIPUNCONFIRMED'],
      //   //       value: this.openTripNumber
      //   //     },
      //   //     {
      //   //       name: this.translations['LOGISTIC.LIST.TRIPCONFIRMED'],
      //   //       value: x.items.length - this.openTripNumber
      //   //     },
      //   //   ],
      //   //   centerValue: this.tripsnumber,
      //   //   centerLabel: this.translations['LOGISTIC.LIST.CHARTLABEL']
      //   // }
      // }

      this.openTripNumber = x && x.items ? x.items.reduce<number>((acc, trip, idx, trips) => { acc += !trip.confirmed ? 1 : 0; return acc; }, 0) : 0;

      this.doSort();
    });


    this.isLoading = true;
    this.logisticService.getDailyDetails(this.dateFilter).subscribe(x => {
      this.tripsfillingDelta = x.avgFillingDelta;
      this.tripsfilling = Math.round(x.avgFilling * 1000) / 10;
      this.tripsDelta = x.tripsDelta;
      this.trucksNumber = x.usedTrucks;
      this.tripskm = Math.round(x.distance / 1000);
      let series = [];
      if (x.trips) {
        x.trips.forEach(trip => {
          series.push({
            name: this.translations[`TRIPSTATUS.${trip.status}`],
            value: trip.total,
            color: TRIPSTATUS_COLORS[trip.status]
          })
        })
      }
      this.chartInput = {
        series: series,
        centerValue: x.tripsNumber,
        // centerLabel: this.translations['LOGISTIC.LIST.CHARTLABEL']
      }
      this.isLoading = false;
    });

    this.logisticService.isWorkingTrips().subscribe(res => {
      this.isWaiting = res.isWorking && moment(res.queryDate).isSame(this.dateFilter, 'day');
      this.error = res.hasError && moment(res.queryDate).isSame(this.dateFilter, 'day');
      // console.log('---- ~ file: routes.component.ts ~ line 209 ~ RoutesComponent ~ this.logisticService.isWorkingTrips ~ this.error', this.error);
      this.errorList = res.message;
    })

    this.logisticService.getOrderUnassignedList(this.dateFilter.toDate()).subscribe(x => {
      this.unassignedOrdersNumber = x.items.length;
      this.promotionActive = x.expireDate && moment().isBefore(moment(x.expireDate)) ? moment(x.expireDate) : null;
    })

  }

  selectedDateChange(date) {
    this.dateFilter = date;
    this.refresh();
  }


  launchPromotion() {

    this.priceService.hasTodayPrices(moment()).subscribe(res => {
      console.log('---- ~ file: routes.component.ts ~ line 260 ~ RoutesComponent ~ this.priceService.hasTodayPrices ~ res', res);
      if (!res.success) {
        this.translation.get('LOGISTIC.LIST.NOPRICES').subscribe(t => {
          this.notifyService.toast(t, 'info');
        })
      } else {
        // TODO get selected and add to promotion
        const includes = [];
        this.results.forEach(trip => {
          if (trip.checked) {
            includes.push(trip);
          }
        })
        this.modalService.startPromotion(includes, this.dateFilter).pipe(take(1)).subscribe(() => {
          this.refresh();
        });
      }
    })

  }

  openTrip(trip: Trip) {
    this.modalService.tripDetails(trip.routeId, trip.confirmed, true).pipe(take(1)).subscribe(x => {
      this.refresh();
    })
  }

  productsList(trip: Trip) {
    let ret = [];
    trip.tanks.forEach(tank => {
      if (tank.products) {
        tank.products.forEach(prod => {
          if (!ret.find(x => x == prod)) {
            ret.push(prod);
          }
        })
      }
    })
    return ret;
  }

  fuelDepot(trip: Trip, end: boolean = false) {
    if (end) {
      if (!trip.endfuelDepotName) {
        trip.tanks.forEach(tank => {
          trip.endfuelDepotName = trip.endfuelDepotName || tank.enddepotName;
        })
      }
      if (trip.endfuelDepotName) {
        return trip.endfuelDepotName;
      }
    }
    if (!trip.fuelDepotName) {
      trip.tanks.forEach(tank => {
        trip.fuelDepotName = trip.fuelDepotName || tank.depotName;
      })
    }
    return trip.fuelDepotName
  }

  calculateTrips() {
    this.modalService.trucksList(this.dateFilter).pipe(take(1)).subscribe(modalResult => {
      if (modalResult) {
        this.logisticService.calculateTrips(this.dateFilter).subscribe(x => {
          this.refresh();
        });
      }
    })
  }

  open(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog, { context: {} });
  }

  deleteTrips() {
    this.logisticService.cleanTrips(this.dateFilter).subscribe(x => {
      this.refresh();
    });
  }

  async confirmTrip(trip: Trip) {

    let relatedTrips = this.getRelatedTrips(trip);
    let confirmed = true;

    if (relatedTrips.length) {
      confirmed = await this.askConfirmMultipleTrips(relatedTrips, trip);
    }

    // relatedTrips.push(trip);
    relatedTrips = [trip];

    if (confirmed) {
      for (let t of relatedTrips) {
        const confirm = async (driverId) => {
          await this.logisticService.confirmTrip(t.routeId, driverId).toPromise();
        }

        if (config.assignDriverOnCofirmTrip) {
          const driverId = await this.modalService.selectDriver(t).pipe(take(1)).toPromise();
          if (driverId) {
            await confirm(driverId);
          }
        } else {
          await confirm(null);
        }
      }
      this.refresh();
    }
  }


  async unConfirmTrip(trip: Trip) {

    let relatedTrips = [];
    relatedTrips = this.getRelatedTrips(trip);
    // relatedTrips.push(trip);
    relatedTrips = [trip];

    for (let t of relatedTrips) {
      await this.logisticService.unconfirmTrip(t.routeId).toPromise();
    }
    this.refresh();
  }

  async askConfirmMultipleTrips(relatedTrips: Trip[], trip: Trip): Promise<boolean> {
    const title = this.translations['LOGISTIC.LIST.CONFIRMMULTIPLETITLE'] + trip.routeId;
    let msg = this.translations['LOGISTIC.LIST.CONFIRMMULTIPLEMESSAGE'];
    relatedTrips.forEach(t => {
      msg += ' ' + t.routeId;
    })
    const res = await this.modalService.confirm(msg, title).toPromise();
    return res == MODAL.confirmOk;
  }

  getRelatedTrips(trip: Trip): Trip[] {
    const ret = this.dataSource.data.filter(x => x.vehicleName == trip.vehicleName && x.towName == trip.towName && x.routeId != trip.routeId)
    return ret;

  }


  async delayTrip(trip: Trip) {


    // related handled clientside
    let relatedTrips = this.getRelatedTrips(trip);
    // relatedTrips.push(trip);
    relatedTrips = [trip];
    
    for (let t of relatedTrips) {
      await this.logisticService.delayTrip(t).toPromise();

    }
    this.refresh();

    // related handled serverside
    // this.logisticService.delayTrip(trip).subscribe(x => {
    //   this.refresh();
    // });


  }

  confirmTrips() {
    this.logisticService.confirmTrips(this.dateFilter).subscribe(x => {
      this.refresh();
    });
  }

  removeTrip(trip: Trip) {
    this.modalService.confirm(this.translations['ORDERS.TRIPDETAILS.DELETECONFIRMTITLE'], this.translations['ORDERS.TRIPDETAILS.REMOVE']).pipe(take(1)).subscribe(async (response) => {
      if (response) {
        this.logisticService.removeTrip(trip.routeId).subscribe(x => {
          this.refresh();
        });
      }
    });
  }


  startTrip(trip: Trip) {
    this.logisticService.setTripStatus(trip, TripStatus.in_transit).subscribe(x => {
      this.refresh();
    });
  }


  public remainingtime() {
    return this.promotionActive.from(moment());
  };

  showCalculateTrip(): boolean {
    // solo per giorni "futuri" e se ci sono (ordini non assegnati o viaggi aperti) e non in attesa di un ricalcolo
    // return moment(this.dateFilter).isSameOrAfter(this.nextDay, 'day') && (this.unassignedOrdersNumber > 0 || this.openTripNumber > 0) && !this.isWaiting
    return (moment(this.dateFilter).isSameOrAfter(moment(), 'day')
      && (this.unassignedOrdersNumber > 0 || this.openTripNumber > 0)
      && !this.isWaiting
      && !this.resultsLength
    )
  }

  showConfirmTrips(): boolean {
    // solo se viaggi non chiusi
    return this.openTripNumber > 0 && this.dateFilter.isSameOrAfter(moment(), 'day');
  }

  showConfirmActions(): boolean {
    // solo giorni futuri
    return this.dateFilter.isSameOrAfter(moment(), 'day');
  }

  showLaunchPromotion(): boolean {
    // solo se viaggi non chiusi
    return this.openTripNumber > 0 &&
      (this.dateFilter.isSameOrAfter(moment(), 'day')) && 
      this.dateFilter.isSameOrBefore(CalendarService.nextWorkingDay(), 'day')
      && this.enableLaunchPromotion();
  }

  enableLaunchPromotion(): boolean {
    return this.dateFilter.isSame(this.lastOptimDate, 'day');
  }

  async setLastOptimDate(){
    this.lastOptimDate = moment(await this.logisticService.getLastOptimDate()); 
  }

  showClean(): boolean {
    // solo giorni futuri
    return this.dateFilter.isSameOrAfter(moment(), 'day');
  }


  onCloseAlert() {
    this.error = !this.error;
  }

  isToptruck(element, i): boolean {
    if ((this.sort.active == 'truck' || this.sort.active == 'name') && this.dataSource.data.length > i + 1)
      return this.dataSource.data[i + 1].vehicleName == element.vehicleName;
    return false;
  }

  isBottomtruck(element, i): boolean {
    if ((this.sort.active == 'truck' || this.sort.active == 'name') && i > 0)
      return this.dataSource.data[i - 1].vehicleName == element.vehicleName;
    return false;
  }



}
