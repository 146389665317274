<nb-card>
    <nb-card-header *ngIf="!price.quantity_promotional">{{'CLIENT.ORDERMODAL.ADDORDER' | translate}}</nb-card-header>
    <nb-card-header *ngIf="price.quantity_promotional">{{'CLIENT.ORDERMODAL.ADDORDERPROMOTION' | translate}}</nb-card-header>
    <nb-card-body>

        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.DISTRIBUTIONPOINT' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput [ngModel]="distributionTxt">

        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.PRODUCT' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput [ngModel]="price.product_name">
        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.QUANTITY' | translate}} <span
                    *ngIf="price.quantity_promotional">({{'CLIENT.ORDERMODAL.MAXQUANTITY' | translate}}
                    {{price.quantity_promotional | number | liter}})</span> </span>
            <input fullWidth status="basic" type="number" (change)="changeQuantity()" (keyup)="changeQuantity()" nbInput
                [(ngModel)]="quantity">
        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.PRICE' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput [ngModel]="validPrice() | currency:'€': 'symbol' : '1.3-3'">
        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.CREDIT' | translate}}</span>
            <input fullWidth status="basic" disabled="true" type="text" nbInput
                [value]="customer.remainingCredit-(validPrice()*(quantity?quantity:0)) | currency:'€'">
        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.DELIVERYDATE' | translate}}</span>
            <input fullWidth status="basic" nbInput [nbDatepicker]="datepicker" [(ngModel)]="deliveryDateMoment" [disabled]="price.quantity_promotional">
            <nb-datepicker #datepicker format="DD/MM/YYYY"></nb-datepicker>
        </div>
        <div>
            <span class="label">{{'CLIENT.ORDERMODAL.NOTE' | translate}}</span>
            <input fullWidth status="basic" type="text" nbInput [(ngModel)]="notes">
        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="save()">{{'CLIENT.ORDERMODAL.INSERT' | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'CLIENT.ORDERMODAL.CANCEL' | translate}}</button>
    </nb-card-footer>
</nb-card>