<div class="sectionheader-container" *ngIf="sections && sections.length">
    <ng-container *ngFor="let section of sections">
        <div class="sectionheader-box" *ngIf="section.isVisible">
            <nb-badge [text]="badgeNumber(section.counter)" *ngIf="section.counter" status="danger" position="top right"></nb-badge>
            <a class="menu-element" [routerLink]="section.path" [ngClass]="{'selected':section.isSelected}"
                >{{section.label | translate }}</a>
        </div>
    </ng-container>
</div>


<!-- <nb-tabset (changeTab)="goto($event)">
    <nb-tab *ngFor="let section of sections" [active]="section.isSelected" [tabId]="section.path"
        tabTitle="{{section.label | translate }}">
    </nb-tab>
</nb-tabset> -->