<nb-card>
    <nb-card-header>
        <p *ngIf="!user?.id">{{'ADMIN.NEWUSER.TITLECREATE' | translate}} </p>
        <p *ngIf="user?.id">{{'ADMIN.NEWUSER.TITLEEDIT' | translate}} </p>
    </nb-card-header>
    <nb-card-body>
        <div>
            {{'ADMIN.NEWUSER.FIRSTNAME' | translate}}
            <input fullWidth nbInput type="text" [(ngModel)]="user.firstName" />
            {{'ADMIN.NEWUSER.LASTNAME' | translate}}
            <input fullWidth nbInput type="text" [(ngModel)]="user.lastName" />
            {{'ADMIN.NEWUSER.EMAIL' | translate}}
            <input fullWidth nbInput type="email" [(ngModel)]="user.email" />
            {{'ADMIN.NEWUSER.EMAIL2' | translate}}
            <input fullWidth nbInput type="email" [(ngModel)]="email2" />
            {{'ADMIN.NEWUSER.ROLE' | translate}}
            <nb-select fullWidth [(ngModel)]="user.role" [disabled]="lockForClient">
                <nb-option *ngFor="let role of roleList" [value]="role">{{ 'ROLES.' + role | translate }}</nb-option>
            </nb-select>


            <span [hidden]="!roleForClient()">{{'ADMIN.NEWUSER.CLIENT' | translate}}</span>




            <input fullWidth #autoInput nbInput type="text" (ngModelChange)="onChangeClientInput($event)"
                [matAutocomplete]="auto" [ngModel]="client" [disabled]="lockForClient" [hidden]="!roleForClient()" />
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="viewHandle">
                <mat-option *ngIf="isLoadingSuggestion" class="is-loading">
                    <mat-spinner diameter="20"></mat-spinner>
                </mat-option>
                <mat-option *ngFor="let option of filteredOptions$ | async" [value]="option">
                    <span>{{ option?.name }}</span>
                </mat-option>
            </mat-autocomplete>


        </div>

    </nb-card-body>
    <nb-card-footer class="modal-footer">
        <button nbButton status="danger" class="modal-buttons" [disabled]="!isValid()"
            (click)="save()">{{(user?.id?'ADMIN.NEWUSER.OKEDIT':'ADMIN.NEWUSER.OKCREATE') | translate}}</button>
        <button nbButton class="modal-buttons" (click)="cancel()">{{'ADMIN.NEWUSER.CANCEL' |
            translate}}</button>
    </nb-card-footer>
</nb-card>