<oil-dateribbon (selectedChange)="selectedDateChange($event)" [(selectedDate)]="selected" [dateSync]="true"  showFuture="true">
</oil-dateribbon>

<nb-card size="big">
    <nb-card-header>
        {{'LOGISTIC.TRUCKSLIST.TITLE' | translate}}
    </nb-card-header>


    <nb-card-body>
        <oil-truckslist [date]="dateFilter"></oil-truckslist>
    </nb-card-body>
</nb-card>