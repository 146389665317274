import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { merge, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { CustomerDetail } from 'src/app/models/customer.model';
import { SortPaginator } from 'src/app/models/generic.model';
import { ClientOrder, Order } from 'src/app/models/order.model';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'oil-client-orders',
  templateUrl: './client-orders.component.html',
  styleUrls: ['./client-orders.component.scss']
})
export class ClientOrdersComponent implements OnInit {



  public customer: CustomerDetail;

  public todayDataSource;
  public todayResultsLength: number;
  public todayDisplayedColumns: string[] = [
    'date',
    'number',
    'deposit',
    'product',
    'quantity',
    'value',
    'status',
    'deliveryTime'
  ];


  public historyDataSource;
  public historyResultsLength: number;
  public historyDisplayedColumns: string[] = [
    'date',
    'number',
    'deposit',
    'product',
    'quantity',
    'value',
    'status',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;


  @ViewChild('sortToday') sortToday: MatSort;
  @ViewChild('sortHistory') sortHistory: MatSort;

  constructor(
    private orderService: OrderService,
    // private translate: TranslateService,    
    // private customerService: CustomerService
    private userService: UserService) { }

  ngOnInit(): void {
    this.userService.getMeAsClient().subscribe(customer => {
      this.customer = customer;
    })

    this.refresh();


    if (this.sortToday) {
      this.sortToday.sortChange.subscribe(() => this.paginator.pageIndex = 0
      );
      merge(this.sortToday.sortChange, this.paginator.page).pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
      });
    }

    if (this.sortHistory) {
      this.sortHistory.sortChange.subscribe(() => this.paginator.pageIndex = 0
      );
      merge(this.sortHistory.sortChange, this.paginator.page).pipe(
        startWith({}),
        switchMap(() => {
          this.refresh();
          return of([]);
        })
      ).subscribe(data => {
      });
    }
  }

  toUpperCase(s: string) {
    return s.toUpperCase();
  }


  refresh() {
    this.orderService.getClientNewOrdersList().subscribe(x => {
      let items = x.items.filter(x => moment(x.deliveryDate).isSameOrBefore(moment().add(1, 'day'), 'day'));
      this.todayDataSource = new MatTableDataSource<ClientOrder>(items);
      // this.todayResultsLength = x.total;
      this.todayResultsLength = items.length;
    });

    const paginator: SortPaginator = {
      orderBy: this.sortHistory ? this.sortHistory.active : '',
      start: this.paginator.pageIndex,
      limit: this.paginator.pageSize
    }

    this.orderService.getClientPastOrdersList(paginator).subscribe(x => {
      this.historyDataSource = new MatTableDataSource<ClientOrder>(x.items);
      this.historyResultsLength = x.total;
    });

  }

}
